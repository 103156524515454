import axios from "axios"

export async function fetchArticles(query = '') {
    return await axios.get(
            `stocks/article?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getArticle(id) {
    return await axios.get(
            `stocks/article/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createArticle(data) {
    return await axios.post(
            `stocks/article`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateArticle(id, data) {
    return await axios.patch(
            `stocks/article/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchFamilies() {
    return await axios.get(
            `stocks/family`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getFamily(id) {
    return await axios.get(
            `stocks/family/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createFamily(data) {
    return await axios.post(
            `stocks/family`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateFamily(id, data) {
    return await axios.patch(
            `stocks/family/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function fetchUnits() {
    return await axios.get(`stocks/unit`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getUnit(id) {
    return await axios.get(
            `stocks/unit/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createUnit(data) {
    return await axios.post(
            `stocks/unit`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateUnit(id, data) {
    return await axios.patch(
            `stocks/unit/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchStores() {
    return await axios.get(`stocks/store`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getStore(id) {
    return await axios.get(
            `stocks/store/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createStore(data) {
    console.log(data)
    return await axios.post(
            `stocks/store`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateStore(id, data) {
    return await axios.patch(
            `stocks/store/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function fetchTipos() {
    return await axios.get(`stocks/tipo`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getTipo(id) {
    return await axios.get(
            `stocks/tipo/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createTipo(data) {
    return await axios.post(
            `stocks/tipo`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateTipo(id, data) {
    return await axios.patch(
            `stocks/tipo/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function fetchCenters() {
    return await axios.get(`stocks/center`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getCenter(id) {
    return await axios.get(
            `stocks/center/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createCenter(data) {
    return await axios.post(
            `stocks/center`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateCenter(id, data) {
    return await axios.patch(
            `stocks/center/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchStock(query) {
    return await axios.get(
            `stocks/stock/?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createStock(data) {
    return await axios.post(
            `stocks/stock`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateStock(id, data) {
    return await axios.patch(
            `stocks/stock/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createTransaction(data) {
    return await axios.post(
            `stocks/transaction`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchTransactions(query) {
    return await axios.get(
            `stocks/transaction?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchPurchase(query) {
    return await axios.get(
            `stocks/purchase-order?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createPurchase(data) {
    return await axios.post(
            `stocks/purchase-order`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getPurchase(id) {
    return await axios.get(
            `stocks/purchase-order/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updatePurchase(id, data, query = '') {
    return await axios.patch(
            `stocks/purchase-order/${id}?${query}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw new Error(e.response.data.error)
        })
}

export async function updatePODetail(id, data) {
    return await axios.patch(
            `stocks/purchase-order-receive/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchProvider(query) {
    return await axios.get(
            `stocks/provider?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createProvider(data) {
    return await axios.post(
            `stocks/provider`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getProvider(id) {
    return await axios.get(
            `stocks/provider/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateProvider(id, data) {
    return await axios.patch(
            `stocks/provider/${id}`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}