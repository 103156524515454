<template>
  <v-container>
    <v-row class="mb-10 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo"><v-icon class="mr-2 align-items-center">fas fa-dolly-flatbed</v-icon>Registrar Movimientos Entradas/Salidas</h1>
      <h1 v-if="tipo" class="text text-h5 titulo"><v-icon class="mr-2 align-items-center">{{tipo.in_out?'login':'logout'}}</v-icon>{{tipo.in_out?'Entrada':'Salida'}}</h1>
    </v-col>
    </v-row>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(save)" ref="formulario">
        <v-row>  
          <v-col cols="12" md="4">
            <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
              <v-select
              v-model="tipo"
              :items="tipos"
              :loading="loading"
              :disabled="loading"
              item-text="name"
              item-value="id"
              label="Tipo de Movimiento"
              placeholder="Seleccione un Tipo de Movimiento"
              
              outlined
              return-object
              :error-messages="errors"
              >
              <template v-slot:item="data">
                <div class="d-flex">
                  <div class="text mr-2">{{data.item.name}}<v-icon class="ml-2 align-items-center">{{data.item.in_out?'login':'logout'}}</v-icon> </div>
                </div>
              </template>
              <template v-slot:selection="data">
                <div class="d-flex">
                  <div class="text mr-2">{{data.item.name}}<v-icon class="ml-2 align-items-center">{{data.item.in_out?'login':'logout'}}</v-icon> </div>
                </div>
              </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
              <v-select
              v-model="store"
              :items="stores"
              :loading="loading"
              :disabled="loading"
              item-text="name"
              item-value="id"
              label="Bodegas"
              placeholder="Seleccione una Bodega"
              
              outlined
              :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
            v-model="center"
            :items="centers"
            :loading="loading"
            :disabled="loading"
            item-text="name"
            item-value="id"
            label="Centro de Costo"
            placeholder="Centro de Costo"
            
            outlined
            />
           
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" >
            <v-text-field
              v-model='notes'
              label="Referencia"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-if="tipo.in_out&&tipo.price_effect">
            <add-purchase-order @add="addFromPurchaseOrder" :disabled="store==''||tipo==''"/>
          </v-col>

        </v-row>
        <!-- <v-row> 
          <v-col cols="12" md="8">
            <add-article :disabled="store==''&&tipo==''" @add="addArticle" />
          </v-col>
        </v-row> -->
        <v-row class="mt-4 align-center">
          <v-col cols="12" md="4">
            <h1 class="text text-h5 titulo"><v-icon class="mr-2 align-items-center">fas fa-plus</v-icon>Artículos</h1>
          </v-col>
          <v-col cols="12" md="8">
            <add-article :disabled="store==''||tipo==''" @add="addArticle" />
          </v-col>
        </v-row>
        <v-row v-if="articles.length>0">
          <v-col>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="table-header2">
                    Código
                  </th>
                  <th class="table-header2">
                    Nombre
                  </th>
                  <th class="table-header2">
                    Unidad
                  </th>
                  <th >
                    Fecha Ult. Compra
                  </th>
                  <th >
                    Precio Ult. Compra
                  </th>
                  <!-- <th >
                    Cant. Ult. Compra
                  </th> -->
                  <th class="table-header2 text-center">
                    Bodega / Total
                  </th>
                  <th class="table-header2 text-center">
                    Cantidad
                  </th>
                  <th class="table-header2 text-center" v-if="tipo.in_out&&tipo.price_effect">
                    Precio Unitario
                  </th>
                  <th class="table-header2 text-end" v-if="tipo.in_out&&tipo.price_effect">
                    Total
                  </th>
                  <th>

                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in articles" :key="item.id">
                  <td>
                    {{item.sku}}
                  </td>
                  <td>
                    {{item.name}}
                  </td>
                  <td>
                    {{item.unit_name}}
                  </td>
                  <td>
                    <div v-if="item.last_buy">
                      {{item.last_buy_date | moment("DD/MM/YYYY")}}
                    </div>
                  </td>
                  <td>
                    <div v-if="item.last_buy">
                      {{item.last_buy_price | currency('$', 0)}}
                    </div>
                  </td>
                  <!-- <td>
                    <div v-if="item.last_buy">
                      {{item.last_buy_qty | number('0,0[.]0')}}
                    </div>
                  </td> -->
                  <td class="text-center">
                    <strong>{{item.store_stock | number('0,0[.]0')}}</strong> / {{item.total_stock | number('0,0[.]0')}} 
                    <v-menu bottom left offset-y> 
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs"
                        v-on="on"
                        text
                      small class="ml-2">fas fa-chevron-down</v-icon>
                    </template>
                    <v-card class="pa-2">
                      <div v-if="item.stocks.length>0">
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th>
                                Bodega
                              </th>
                              <th>
                                Existencia
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for='register in item.stocks' :key='register.id'>
                              <td :class="register.add_stock?'':'error--text'">
                                {{register.store_name}}
                              </td>
                              <td class="text-end" :class="register.add_stock?'':'error--text'">
                                {{register.qty | number('0,0[.]0')}}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                      <h3 v-else class="text text-center pa-4">
                        No hay existencias
                      </h3>
                    </v-card>
                  </v-menu>
                  </td>
                  <td style="max-width:50px">
                    <v-text-field
                    type="number"
                    v-model='item.qty'
                    :max="tipo.in_out?9000000:item.store_stock"
                    :min="1"
                    hide-details
                    solo
                    dense
                    ></v-text-field>
                  </td>
                  <td class="text-end" style="max-width:50px" v-if="tipo.in_out&&tipo.price_effect">
                    <v-text-field
                    type="number"
                    v-model='item.price'
                    :min="0"
                    solo
                    dense
                    hide-details
                    ></v-text-field>
                  </td>
                  <td class="text-end" v-if="tipo.in_out&&tipo.price_effect">
                    {{item.price*item.qty | currency('$', 0)}}
                  </td>
                  <td>
                    <div class="d-flex center">
                      <consume-by-o-t  v-if="!tipo.in_out" :article="item" />
                      <v-icon class="error--text" x-small @click.stop="removeArticle(item)">fas fa-trash</v-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center mt-4">
            <v-btn color="success" type="submit" :disabled="!articles.length>0"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-dialog 
    v-model="showProgress"
    persistent
    max-width="500"
    >
      <v-card class="pa-10"> 
        <v-card-title class="titulo text-h4">Registrando...</v-card-title>
        <v-row>
          <v-col>
            <v-progress-linear v-model="progress" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center">
            <h1 class="text text-h4 titulo">{{progress | number('0.0')}}%</h1>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { fetchTipos, fetchStores,fetchCenters,updateStock,createStock,createTransaction,updateArticle,getArticle,updatePODetail} from '@/api/stocks'
import {createConsumptionBitacoraByOT} from '@/api/production'
import AddArticle from '@/components/stocks/AddArticle'
import AddPurchaseOrder from '@/components/stocks/AddPurchaseOrder'
import notifications from '@/utils/notifications'
import ConsumeByOT from '@/components/stocks/ConsumeByOT.vue'
export default {
  name:'CreateTransaction',
  components:{AddArticle,AddPurchaseOrder,ConsumeByOT},
  data() {
    return {
      loading: false,
      tipos:[],
      tipo:'',
      stores:[],
      store:'',
      centers:[],
      center:'',
      notes:'',
      articles:[],
      progress:'',
      showProgress:false,
    }
  },
  methods:{
    addConsume(item,payload){
      console.log(item)
      console.log(payload)
    },
    storeStock(item){
      const stock=item.stocks.filter(s=>s.store==this.store)
      if(stock.length==0) return {qty:0,id:-1}
      else return {qty: stock[0].qty,id:stock[0].id}
    },
    totalStock(item){
      return item.stocks.reduce((acc, val)=>{ return acc + parseInt(val.qty) }, 0)
    },
    addArticle(item){
      //Busco los stocks, el total y el ID del registro
      const storeStock=this.storeStock(item)
      item.store_stock=storeStock.qty
      item.store_stock_id=storeStock.id
      item.total_stock=this.totalStock(item)
      //Agrego la propiedad de consumo de bodega
      item.consume=[]
      //Chequeo si viene de una OC porque incluye ordered y received
      if(item.ordered){
        item.qty=item.ordered-item.received
        //If item qty es 0 no lo agrego a la lista porque 
        //debe estar todo recepcionado
        if(item.qty==0) return 0
      }
      else {
        item.qty=0
        item.price=0
      }
      const alreadyExist=this.articles.filter(a=>a.id==item.id).length
      if(alreadyExist<1){
        this.articles.push(item)
      }
    },
    addFromPurchaseOrder(item){
      this.notes='OC'+item.id+' '+item.title
      console.log(item.details)
      item.details.forEach(async i=>{
        await getArticle(i.article)
        .then(data=>{
          //Datos solo para la OC
          data.oc=item.id
          data.oc_detail=i.id
          data.ordered=i.qty
          data.received=i.received
          data.price=i.price
          this.addArticle(data)
        })
        .catch(err=>{throw err})
      })
    },
    removeArticle(item){
      this.articles=this.articles.filter(i=>i.id!=item.id)
    },
    async incoming(){
      this.showProgress=true
      this.progress=0
      const progressStep=100/this.articles.length
      //Registro de Entrada
      this.articles.forEach(async item=>{
        if(this.tipo.price_effect){
          // Calculo del precio promedio ponderado
          item.calculated_avg=(parseFloat(item.avg)*parseFloat(item.total_stock)+parseFloat(item.qty)*parseFloat(item.price))/(parseFloat(item.total_stock)+parseFloat(item.qty))
        }
        else{
          item.calculated_avg=item.avg
        }
        const transactionData={
          tipo: this.tipo.id,
          tarticle: item.id,
          store: this.store,
          qty: item.qty,
          price:parseFloat(item.price).toFixed(2),
          initial_qty: parseFloat(item.total_stock).toFixed(2),
          balance: (parseFloat(item.total_stock)+parseFloat(item.qty)).toFixed(2),
          initial_avg: parseFloat(item.avg).toFixed(2),
          calculated_avg: parseFloat(item.calculated_avg).toFixed(2),
          notes: this.notes,
        }
        await createTransaction(transactionData)
        .then(async (res)=>{
          const transactionId=res.id
          //Chequeo si el artículo existe en Bodega
          if(item.store_stock_id>-1){
            // Actualizo Inventario
            await updateStock(item.store_stock_id,{qty:parseFloat(item.store_stock)+parseFloat(item.qty)})
            .then(async (res)=>{
              // Actualizo precio del artículo
              const articleData={
                avg:parseFloat(item.calculated_avg).toFixed(2),
              }
              if(this.tipo.price_effect) articleData.last_buy=transactionId
              await updateArticle(item.id,articleData)
              .catch(err=>{
                notifications.showError('Error creando Actualizando Artículo '+err)
                return 0
              })
            })
            .catch(err=>{
              notifications.showError('Error creando Actualizando Stock '+err)
              return 0
            })
          }
          else{
            await createStock(
              {store:this.store,
              article:item.id,
              qty:parseFloat(item.store_stock)+parseFloat(item.qty)
              })
            .then(async res=>{
              // Actualizó el precio del artículo
              const articleData={
                avg:parseFloat(item.calculated_avg).toFixed(2),
              }
              if(this.tipo.price_effect) articleData.last_buy=transactionId
              await updateArticle(item.id,articleData)
              .catch(err=>{
                notifications.showError('Error creando Actualizando Artículo '+err)
                return 0
              })
            })
            .catch(err=>{
              notifications.showError('Error creando Creando Stock '+err)
              return 0
            })
          }
        })
        .catch(err=>{
          notifications.showError('Error creando Transacción '+err)
          return 0
        })
        if(item.oc){
          const received=parseFloat(item.received)+parseFloat(item.qty)
          await updatePODetail(item.oc_detail,{received})
          .then((res)=>console.log('Detalle Actualizado'))
          .catch(err=>{console.log('error actualizando detalle '+err)})
        }
        this.progress+=progressStep
        if(this.progress>=99.9){
          this.showProgress=false
          this.$router.replace('/stocks/success-transaction')
          
          return 0
        }
      })
    },
    outcoming(){
      //Valido si hay centro de costo seleccionado
      if(this.center==''){
        notifications.showError('Debe seleccionar un Centro de Costo')
        return 0
      }
      //Valido si los artículos poseen stock en bodega
      var errorExistencias=false
      this.articles.forEach(item=>{
        if(parseFloat(item.qty)>parseFloat(item.store_stock)){
          notifications.showError('Artículo '+'['+item.sku+'] '+item.name+ ' no posee existencias suficientes')
          errorExistencias=true
        }
      })
      if(errorExistencias) return 0

      this.showProgress=true
      this.progress=0
      const progressStep=100/this.articles.length

      //Creando las transacciones
      this.articles.forEach(async item=>{
        const transactionData={
          tipo: this.tipo.id,
          tarticle: item.id,
          store: this.store,
          qty: item.qty,
          price:parseFloat(item.avg).toFixed(2),
          initial_qty: parseFloat(item.total_stock).toFixed(2),
          balance: (parseFloat(item.total_stock)-parseFloat(item.qty)).toFixed(2),
          initial_avg: parseFloat(item.avg).toFixed(2),
          calculated_avg: parseFloat(item.avg).toFixed(2),
          notes: this.notes,
        }
        if(this.center) transactionData.center=this.center
        await createTransaction(transactionData)
        .then(async res=>{
          await updateStock(item.store_stock_id,{qty:(parseFloat(item.store_stock)-parseFloat(item.qty))})
          .catch(err=>{
            notifications.showError('Error creando Actualizando Stock '+err)
            return 0
          })
          
          //Obteniendo los datos de la transaccion
          const {id, tarticle } = res
          // Creando el registro de consumo por OT
          const {consume} = item

          const consume_data=consume.map(c=>({
              article_id:tarticle,
              workorder_id:c.work_order,
              articlePrice:parseFloat(item.avg),
              convertedPrice:parseFloat(item.avg)*(item.unit_consume_factor||1),
              qty:c.qty,
              unit:item.unit_name,
              unitConsume:item.unit_consume_name||item.unit_name,
              unitConsume_factor:item.unit_consume_factor||1
          }))

          await createConsumptionBitacoraByOT({'transaction_id':id,consume_data})
          .catch(err=>{
            notifications.showError('Error creando Consumo '+err)
            return 0
          })

          /* consume.forEach(async c=>{
            const consumeData={
              article_id:tarticle,
              transaction_id:id,
              workorder_id:c.work_order,
              articlePrice:parseFloat(item.avg),
              convertedPrice:parseFloat(item.avg)*(item.unit_consume_factor||1),
              qty:c.qty,
              unit:item.unit_name,
              unitConsume:item.unit_consume_name||item.unit_name,
              unitConsume_factor:item.unit_consume_factor||1
            }
            await createConsumptionBitacoraByOT(consumeData)
            .catch(err=>{
              notifications.showError('Error creando Consumo '+err)
            })
          }) */
          
          console.log(res)
        })
        this.progress+=progressStep
        if(this.progress>=100){
          this.showProgress=false
          this.$router.replace('/stocks/success-transaction') 
          return 0
        }
      })
    },
    save(){
      if (this.tipo.in_out) this.incoming()
      else this.outcoming()
    }
  },
  watch:{
    store(){
      this.articles=[]
    },
    tipo(){
      this.articles=[]
    }
  },
  async created(){
    this.tipos=await fetchTipos()
    this.stores=await fetchStores()
    this.centers=await fetchCenters()
  }
}
</script>

<style>

</style>