<template>
  <v-card
      class="mx-auto pt-1"
      max-width="300"
      min-width="260"
    >     
        <v-card-text class="pt-0 mt-4 align-items-center">
            <div class="text-h4 font-weight-light mb-2 text-center">
                <slot/>
            </div>
            <div class="subheading font-weight-light text-center">
                {{title}}
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name:'NumberCard',
  props:['title'],
}
</script>