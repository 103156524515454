<template>
  <v-container>
    <v-row class="mb-2 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo"><v-icon class="mr-2" x-large>fas fa-gamepad</v-icon>Control de Piso</h1>
      </v-col>
      <v-col>
        <v-select
          :items="machines"
          item-text="name"
          item-value="id"
          v-model="machine"
          placeholder="Seleccione un Área de Producción"
          outlined
          :loading="loading"
          clearable
        />
      </v-col>
    </v-row>
    <machine-planner-alone
    v-if="machine"
    :machine_id='machine' 
    @displayWorkOrder='displayWorkOrder'
    :dragEnable="false"
    />
    <div v-else class="text-center titulo">
      <h2>Área de Producción</h2>
    </div>
    <v-dialog  v-model='showWorkOrder' max-width="1200">
      <v-card class="pa-10" >
        <work-order-card :work_order='workOrderDisplay' @close="showWorkOrder=false" /> 
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {fetchMachines,getWorkOrder} from '@/api/production'
import MachinePlannerAlone from '@/components/production/MachinePlannerAlone.vue'
import WorkOrderCard from '@/components/production/WorkOrderCard'
export default {
  name:'FloorControl',
  components:{MachinePlannerAlone,WorkOrderCard},
  data() {
    return {
      machines: [],
      loading:false,
      machine:'',
      showWorkOrder:false,
      workOrderDisplay:{},
      work_order_card_loading:false,
    }
  },
  methods:{
    reload(){
      console.log('reload')
      this.machines=[]
      this.fetch()
    },
    async fetch(){
      this.loading=true
      const query='enabled=true&ordering=sort'
      await fetchMachines(query)
      .then((res)=>{
        this.machines=res
        this.loading=false
      })
      .catch((e)=>{
        notifications.showError('Error Cargando Datos '+e)
        this.loading=false
      })
    },
    async displayWorkOrder(item){
      await getWorkOrder(item.product.work_order.id)
      .then((data)=>{
        this.workOrderDisplay=data
        this.showWorkOrder=true
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
      })
    },
  },
  async mounted(){
    await this.fetch()
  },
}
</script>

<style>

</style>