var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-2 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h5 titulo"},[_vm._v("Historial")]),_c('h2',[_vm._v(_vm._s(_vm.cost.name))])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total,"sort-by":"created_at","item-class":"table-body","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY HH:mm"))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }