<template>
  <v-container>
  <v-row class="mb-10 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo">Areas de Producción</h1>
    </v-col>
    <v-col class="text-end">
      <v-btn class="ml-4" color="success" @click="create">
          <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify="end">
    <v-col cols="6">
      <v-text-field
          v-model="search"
          label="Buscar"
          class="mx-4"
          append-icon="fas fa-search"
        ></v-text-field>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="machines"
        :items-per-page="10"
        :loading="loading"
        loading-text="Cargando Datos"
        :search="search"
        :sort-by="['name']"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }"
      >
        <template v-slot:item.capacity="{ item }">
          {{item.capacity}} <v-icon>{{item.production_unit_m2?'square_foot':'view_comfy'}}</v-icon>
        </template>
        <template v-slot:item.saturday="{ item }">
          <v-icon small>{{item.saturday?'fas fa-check':'fas fa-xmark'}}</v-icon>
        </template>
        <template v-slot:item.sunday="{ item }">
          <v-icon small>{{item.sunday?'fas fa-check':'fas fa-xmark'}}</v-icon>
        </template>
        <template v-slot:item.equipments="{ item }">
          <v-chip :disabled="!equipment.enabled" v-for="equipment in item.equipments" :key="equipment.id">{{equipment.name}}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn text class="mr-2" @click.stop="edit(item)"><v-icon>fas fa-edit</v-icon></v-btn>
        </template>
        <template v-slot:item.enabled="{ item }">
            <v-switch v-model="item.enabled" @change="()=>{machine=item;save()}"></v-switch>
        </template>
      </v-data-table>

    </v-col>
    <v-dialog
            v-model="showDialog"
            max-width="500px"
          >
          <v-card class="pa-4">
            <machine-create-update 
              :machine='machine'
              :loading='loading'
              @save="save"
            /> 
          </v-card>
            
          </v-dialog>
  </v-row>
</v-container>
</template>

<script>
import {fetchMachines,createMachine,updateMachine} from '@/api/production'
import notifications from '@/utils/notifications'
import MachineCreateUpdate from '../../components/production/MachineCreateUpdate.vue'

export default {
  name:'machines',
  components:{MachineCreateUpdate},
  data() {
    return {
      machines:[],
      machine:{},
      search:'',
      headers: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
            class:'table-header2'
          },
          { text: 'Capacidad Hora', value: 'capacity',class:'table-header2',align:'end'},
          { text: 'Horas/Dia', value: 'hours_per_day',class:'table-header2',align:'end'},
          { text: 'Sabado', value: 'saturday',class:'table-header2',align:'center'},
          { text: 'Domingo', value: 'sunday',class:'table-header2',align:'center'},
          {text:'Equipos',value:'equipments',class:'table-header2'},
          {text:'Orden',value:'sort',class:'table-header2'},
          {text:'',value:'enabled',class:'table-header2'},
          {text:'',value:'actions',class:'table-header2'}
        ],
        showDialog:false,
    }
  },
  async created(){
    this.loading=true
    this.machines = await fetchMachines()
    .catch((err)=>{
      notifications.showError('Error cargando máquinas '+err)
      this.loading=false
    })
    this.loading=false
  },
  methods:{
    create(){
      this.machine={
        id:-1,
        name:'',
        capacity:1,
        production_unit_m2:false,
      }
      this.showDialog=true
    },
    edit(item){
      this.machine=item
      this.showDialog=true
    },
    async save(){
      this.loading=true
      if(this.machine.id>0){
        await updateMachine(this.machine)
        .then(async (machine)=>{
        notifications.showSuccess('Máquina '+ machine.name + ' Actualizada')
        this.showDialog=false
        this.machines = await fetchMachines()
        .catch((err)=>{
          notifications.showError('Error cargando máquinas '+err)
          this.loading=false
        })
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error Actualizando Máquina '+err)
        this.loading=false
      })
      }
      else{
        await createMachine(this.machine)
        .then(async (machine)=>{
          notifications.showSuccess('Máquina '+ machine.name + ' Creado')
          this.showDialog=false
          this.machines = await fetchMachines()
          .catch((err)=>{
            notifications.showError('Error cargando máquinas '+err)
            this.loading=false
          })
          this.loading=false
        })
        .catch((err)=>{
          notifications.showError('Error creando Máquina '+err)
          this.loading=false
        })
      }
    }
  }
}
</script>

<style>

</style>