<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo">Artículos</h1>
    </v-col>
    <v-col class="text-end">
      <Can I="create" a="StockArticle">
        <v-btn class="ml-4" color="primary" @click="$router.push({name:'ImportStock'})" text>
            <v-icon class="mr-2">fas fa-file-import</v-icon>Importar
        </v-btn>
        <v-btn class="ml-4" color="success" @click="update(-1)">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
        </v-btn>
      </Can>
    </v-col>
  </v-row>
  <v-card class="pa-4">
    <v-row>
      <v-col>
        <v-select
          v-model="family"
          :items="families"
          item-text="name"
          item-value="id"
          label="Grupo"
          placeholder="Todas las Familias"
          single-line
          outlined
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          @keyup="searchTimeOut"
          label="Buscar"
          append-icon="fas fa-search"
          :disabled="loading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
            class=""
            :headers="headers"
            :items="articles"
            :items-per-page="10"
            :loading="loading"
            :options.sync="options"
            :sort-desc="true"
            :server-items-length="total"
            :search="search"
            dense
            sort-by='sku'
            item-class="table-body"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right',
              itemsPerPageOptions: [10, 20, 30, 40, 50,100],
            }">
            <template v-slot:item.name="{ item }">
              {{item.name}}
              <v-tooltip top v-if="item.cost">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 info--text" small v-bind="attrs" v-on="on">fas fa-link</v-icon>
                </template>
                <span>{{item.cost.name}}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <Can I="update" a="StockArticle">
                <v-switch v-model="item.status" @change="updateStatus(item)"></v-switch>
              </Can>
            </template>
            <template v-slot:item.actions="{ item }">
              <Can I="update" a="StockArticle">
                <v-icon  class="mr-2" @click.stop="update(item.id)">fas fa-edit</v-icon>
              <!-- <v-icon class="error--text" @click.stop="deleteItem(item)">delete</v-icon> -->
              </Can>
            </template>
            
          </v-data-table>
      </v-col>
    </v-row>
  </v-card>
  <v-dialog v-model="showDialog" max-width="1000">
    <v-card class="px-10 py-4">
      <article-create-update 
      :families="families" 
      :units="units" 
      :id="article"
      @updated="fetch"
      @close="showDialog=false"
      />
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchArticles,fetchFamilies,fetchUnits,updateArticle} from '@/api/stocks'
import notifications from '@/utils/notifications'
import ArticleCreateUpdate from '@/components/stocks/ArticleCreateUpdate'

export default {
  name:'Articles',
  components:{ArticleCreateUpdate},
  data() {
    return {
      loading: false,
      family:'',
      families:[],
      units:[],
      articles:[],
      article:'',
      timer:'',
      options:{},
      search:'',
      total:0,
      showDialog:false,
      headers: [
      {
        text: 'Código',
        align: 'start',
        value: 'sku',
        class: 'table-header2',
        
      },
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        class: 'table-header2'
      },
      {
        text: 'Familia',
        align: 'start',
        value: 'family_name',
        class: 'table-header2'
      },
      {
        text: 'Unidad',
        align: 'start',
        value: 'unit_name',
        class: 'table-header2'
      },
      {
        text: 'Estatus',
        align: 'start',
        value: 'status',
        class: 'table-header2'
      },
      { text: '',value:'actions',class: 'table-header2',align:'center',sortable: false}
    ],
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='family_name'){
        sort = 'family__name'
      }
      else if(sortBy[0]=='unit_name'){
        sort = 'unit__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.family) this.family=''
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&page=${page}&&page_size=${itemsPerPage}&&search=${this.search}&&family=${this.family}`
      await fetchArticles(query)
      .then(res=>{
        this.total=res.count
        this.articles=res.results
      })
      .catch((err)=>{
        notifications.showError('Error cargando artículos '+err)
      })
      .finally(()=>{
        this.loading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 1000);
    },
    update(item){
      this.article=item
      this.showDialog=true
    },
    async updateStatus(item){
      console.log(item)
      await updateArticle(item.id,{status: item.status})
      .then(()=>{
        notifications.showSuccess('Estatus '+ item.name + ' Actualizado')
      })
      .catch((e)=>{
        notifications.showError('Error Actualizando Estatus '+e)
      })
    },
  },
  watch:{
    async family(){
      this.fetch()
    },
    options:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.fetch()
    this.loading=true
    this.families=await fetchFamilies()
    this.units=await fetchUnits()
    this.loading=false
  }
}
</script>

<style>

</style>