<template>
  <div>
    <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="showDialog=true" text><v-icon class="mr-2">far fa-file-excel</v-icon>Exportar Bitacora</v-btn>
    <v-dialog max-width="400" v-model="showDialog" persistent>
      <v-card>
        <v-card-title>Exportar Bitacora</v-card-title>
        <v-card-subtitle>Elija las opciones de filtrado</v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="local_filter.date_after"
            label="Ingreso desde"
            type="date"
            outlined
            clearable
            dense
          />
          <v-text-field 
            v-model="local_filter.date_before"
            label="Ingreso hasta"
            type="date"
            outlined
            clearable
            dense
          />
             
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel">Cancelar</v-btn>
          <v-btn color="primary" @click="getExcel">Descargar</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
  </template>
  
  <script>
  import notifications from '@/utils/notifications'
  import {excelPrestage} from '@/api/production'
  import moment from 'moment'
  import numeral from 'numeral'
  export default {
    name:"ExportPrestage",
    //props:['filters','options','disabled','search'],
    props:{
      filter:{
        type:Object,
        required:true
      },
      options:{
        type:Object,
        required:true
      },
      disabled:{
        type:Boolean,
        required:false,
        default:false
      },
      search:{
        type:String,
        required:false,
        default:''
      }
    },
    data() {
      return {
        loading: false,
        showDialog:false,
        local_filter:{
          date_after:'',
          date_before:''
        }
      }
    },
    methods:{
      async getExcel(detail){
        this.loading=true
        const {sortBy, sortDesc, page, itemsPerPage }=this.options
        let sort = sortBy[0]

        if(!this.local_filter.date_after || !this.local_filter.date_before){
          notifications.showError('Error','Debe seleccionar un rango de fechas')
          this.loading=false
          return
        }

        //Formato a la fecha para el query
        var created_before = ''
        var created_after = '' 
        if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
        if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
        var date_after = ''
        var date_before = ''
        if(this.local_filter.date_after) date_after = moment(this.local_filter.date_after).utc().format('yyyy-MM-DD HH:mm')
        if(this.local_filter.date_before) date_before=moment(this.local_filter.date_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
        var stage = this.filter.stage==0?'':this.filter.stage
        
        const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=1&search=${this.search}&created_after=${created_after}&created_before=${created_before}&date_after=${date_after}&date_before=${date_before}&stage=${stage}`
        await excelPrestage(query)
        .then((response) => {
          const link = document.createElement('a');
          link.href=window.URL.createObjectURL(response);
          link.setAttribute('download', `export_prestage_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loading=false
          this.cancel()
        })
        .catch((error) => {
          if(error.code=="ECONNABORTED") notifications.showError('Error Exportando','Tiempo de Espera Excedido')
          else notifications.showError('Error', 'Exportando')
          this.loading=false
        });
      },
      cancel(){
        this.showDialog=false
        this.local_filter.date_after=''
        this.local_filter.date_before=''
      }
    }
  
  }
  </script>