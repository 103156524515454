<template>
  <v-container>
    <v-row justify="center">
      <v-img
          max-height="100"
          max-width="100"
          :src="logo"
        ></v-img>
    </v-row>
    <v-row>
      <v-col>
        <commercial-graph/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <production-dashboard/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

import DayGraph from '@/components/sales/DayGraph.vue'
import ProductionDashboard from '@/components/production/ProductionDashboard'
import CommercialGraph from '../components/sales/CommercialGraph.vue'

export default {
  name: 'Home',
  components: {
    DayGraph,
    ProductionDashboard,
    CommercialGraph
   
  },
  computed:{
      
    logo(){
        return this.$store.getters.getCompany.logo_url
      },
    },
}
</script>
