var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveUser)}}},[_c('v-row',{staticClass:"mb-10 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v(_vm._s(_vm.user.id ? 'Actualizar Usuario':'Crear Usuario'))])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"type":"submit","color":"success"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/users')}}},[_c('v-icon',[_vm._v("fas fa-angle-left")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","outlined":"","error-messages":errors,"dense":""},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Apellido","outlined":"","error-messages":errors,"dense":""},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo Electrónico","disabled":_vm.user.id,"outlined":"","error-messages":errors,"dense":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.user.id)?_c('ValidationProvider',{attrs:{"name":"Contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contraseña","placeholder":"Contraseña","persistent-hint":"","hint":"Debe poseer mas de 4 caracteres números y letras","outlined":"","error-messages":errors,"dense":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}):_vm._e(),(_vm.user.id)?_c('div',{staticClass:"mb-6"},[_c('v-icon',[_vm._v("lock")]),(_vm.user.id)?_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.sent?true:false,"color":_vm.error?'error':'primary'},on:{"click":_vm.sendEmail}},[_vm._v(_vm._s(_vm.sent? 'Correo Enviado':'Restaurar Contraseña'))]):_vm._e()],1):_vm._e()],1)],1),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Teléfono","outlined":"","error-messages":errors,"dense":""},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{staticClass:"mr-4",attrs:{"default":"","label":"Vendedor"},model:{value:(_vm.user.is_seller),callback:function ($$v) {_vm.$set(_vm.user, "is_seller", $$v)},expression:"user.is_seller"}}),_c('v-checkbox',{attrs:{"default":"","label":"Staff"},model:{value:(_vm.user.is_staff),callback:function ($$v) {_vm.$set(_vm.user, "is_staff", $$v)},expression:"user.is_staff"}})],1)]),_c('v-col',{attrs:{"col":"12","md":"6"}},[(_vm.user.is_seller)?_c('ValidationProvider',{attrs:{"name":"Comision","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Comisión","type":"number","outlined":"","error-messages":errors,"min":0,"dense":""},model:{value:(_vm.user.comision),callback:function ($$v) {_vm.$set(_vm.user, "comision", $$v)},expression:"user.comision"}})]}}],null,true)}):_vm._e()],1),_c('v-col',{attrs:{"col":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Unidad de Negocios","placeholder":"Unidad de Negocios","outlined":"","error-messages":_vm.errors,"min":0,"dense":""},model:{value:(_vm.user.bussiness_unit),callback:function ($$v) {_vm.$set(_vm.user, "bussiness_unit", $$v)},expression:"user.bussiness_unit"}})],1),(_vm.hasSoftnetIntegration)?_c('v-col',{attrs:{"col":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"SOFTNET ID","outlined":"","dense":""},model:{value:(_vm.user.softnet_id),callback:function ($$v) {_vm.$set(_vm.user, "softnet_id", $$v)},expression:"user.softnet_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.roles,"item-value":"id","item-text":"name","label":"Rol","dense":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1)],1),_c('v-row')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }