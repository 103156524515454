<template>
  <v-container>
    <v-card-title class="titulo text-h4 mb-4">
      <v-icon class="mr-2">fas fa-file-invoice-dollar</v-icon> Registrar de Factura OT <strong>{{work_order.id}}</strong>
    </v-card-title>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(saveInvoice)"> 
      <v-row>
        <v-col>
          <ValidationProvider name="Número de documento" rules="required" v-slot="{ errors }">
            <v-text-field
            outlined
            label="Número de documento" 
            v-model="document_id"
            class="mr-2"
            :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider name="Fecha de Emisión" rules="required" v-slot="{ errors }">
            <v-text-field
            outlined
            label="Fecha de Emisión" 
            v-model="date"
            type="date"
            :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="table-header">
                Item
              </th>
              <th class="table-header">
                Código
              </th>
              <th class="table-header">
                Descripción
              </th>
              <th class="table-header">
                Cantidad
              </th>
              <th class="table-header"> 
                Valor Unitario
              </th>
              <th class="table-header">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product,index) in work_order.products" :key="index">
              <td>
                {{index}}
              </td>
              <td>
                {{product.library_product.sku}}
              </td>
              <td>
                {{product.name}}
              </td>
              <td>
                <v-text-field 
                v-model="product.invoice_qty"
                hide-details
                type="number"
                outlined
                min="0"
                dense
                solo
                />
              </td>
              <td>
                <v-text-field 
                v-model="product.invoice_price"
                outlined
                hide-details
                type="number"
                min="0"
                dense
                solo
                />
              </td>
              <td class="text-end">
                {{product.invoice_total | currency('$',0)}}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-row>
      <v-row justify="end">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-start">
                Subtotal:
              </td>
              <td class="text-end">
                <strong>{{total | currency('$',0)}}</strong>
              </td>
            </tr>
            <tr>
              <td class="text-start">
                IVA (19%):
              </td>
              <td class="text-end">
                <strong>{{iva | currency('$',0)}}</strong>
              </td>
            </tr>
            <tr>
              <td class="text-start">
                Total:
              </td>
              <td class="text-end">
                <strong>{{total_mas_iva | currency('$',0)}}</strong>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-row>
        <div class="text-center mb-4">
          <v-btn :loading="loading" type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
        </div>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import {createInvoice} from '@/api/salesNotes'
import notifications from '@/utils/notifications'
export default {
  name:'InvoiceRegistration',
  props:['work_order'],
  data() {
    return {
      document_id:'',
      total:0,
      date:'',
      loading:false,
    }
  },
  methods:{
    productTotal(){
      this.total=0
      this.work_order.products.map(product=>{
        if(product.invoice_qty&&product.invoice_price){
          product.invoice_total=product.invoice_qty*product.invoice_price
        }
        else product.invoice_total=0
        this.total+=product.invoice_total
      })
    },
    async saveInvoice(){
      this.loading=true
      console.log({
        document_id: this.document_id,
        date: new Date(this.date).toISOString(),
        products: this.work_order.products,
        total:this.total_mas_iva,
        work_order:this.work_order.id
      })
      await createInvoice({
        document_id: this.document_id,
        date: new Date(this.date).toISOString(),
        products: this.work_order.products,
        total:this.total_mas_iva,
        work_order:this.work_order.id
      })
      .then((res)=>{
        notifications.showSuccess('Registro Factura '+res.id+' Creado')
        this.work_order.invoices.push(res)
        this.$emit('close')
        this.loading=false
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
      })
      this.loading=false
    }
  },
  watch:{
    work_order:{
      handler:"productTotal",
      deep: true
    }
  },
  computed:{
    iva(){
      return this.total*0.19
    },
    total_mas_iva(){
      return this.total + this.iva
    }
  }
  
}
</script>

