<template>
<v-container>
  <v-row justify="center">
    <v-col >
      <v-data-table
        :headers="headers"
        :items="families"
        :items-per-page="10"
        :loading="loading"
        :search="search"
        :sort-by="['name']"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }"
      >
          <template v-slot:top>
          <v-row justify="space-between">
            <v-col>
              <v-text-field
              v-model="search"
              label="Buscar"
              class="mx-4"
              append-icon="fas fa-search"
            ></v-text-field>
            </v-col>
            <v-col class="text-end">
              <v-btn
                color="success"
                dark
                class="mb-2"
                @click="create"
              >
                <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
                </v-btn>
            </v-col>
            
            
          </v-row>
            <v-dialog
              v-model="showDialog"
              max-width="500px"
            >
              <ValidationObserver v-slot="{handleSubmit}">
                <v-form @submit.prevent="newFamily.id>0? handleSubmit(updateFamily): handleSubmit(createFamily)">
                  <v-card class="pa-4">
                    <v-card-title>
                      <span class="headline titulo">Agregar Nueva Familia</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                              <v-text-field
                                v-model="newFamily.name"
                                outlined
                                label="Nombre de Familia"
                                :error-messages="errors"
                                autofocus
                              ></v-text-field>
                            </ValidationProvider>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                      <v-spacer></v-spacer>
                      <v-btn type="submit" color="success">
                        <v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </ValidationObserver>
            </v-dialog>
          
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn text class="mr-2" @click.stop="edit(item)"><v-icon>fas fa-edit</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import {fetchFamily,createFamily, updateFamily} from '@/api/products'
import notifications from '@/utils/notifications'

export default {
  name:'Family',
  data:()=>({
    families:[],
    search:'',
    showDialog:false,
    loading:false,
    newFamily:{
      id:-1,
      name:'',
    },
    headers: [
          {
            text: 'Descripción',
            align: 'start',
            value: 'name',
            class:'table-header'
          },
          { class:'table-header',text: 'Acciones',value:'actions', align:"center",sortable: false}
        ],
  }),
  methods:{
    edit(item){
      this.newFamily=item
      this.showDialog=true
    },
    create(){
      this.newFamily={
        id:-1,
        name:''
      }
      this.showDialog=true
    },
    async createFamily(){
      await createFamily(this.newFamily)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.families=await fetchFamily()
        notifications.showSuccess('Familia '+ this.newFamily.name + ' Creada')
        this.$store.dispatch('getFamilies')
        })
      .catch(()=>{
        notifications.showError('Error Creando Nuevo Familia ')
        this.loading=false
        })
    },
    async updateFamily(){
      await updateFamily(this.newFamily)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.families=await fetchFamily()
        notifications.showSuccess('Grupo '+ this.newFamily.name + ' Actualizado')
        this.$store.dispatch('getFamilies')
        })
      .catch(async (e)=>{
        this.loading=false
        notifications.showError('Error Actualizando Grupo' + e)
        this.families=await fetchFamily()
        })
    }
  },
  async created(){
    this.loading=true
    await fetchFamily()
    .then((res)=>{
      this.families=res
      this.loading=false
    })
  }
}
</script>
