<template>
<div>
  <v-btn :disabled="disabled" text icon color="primary"  title="Exportar" :loading="loading" @click="fetch"><v-icon>fas fa-file-export</v-icon></v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
import {fetchDistribution} from '@/api/salesNotes'

export default {
  name:"ExportDistribution",
  props:['items','salesnote'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      dataReady:false,
      items:[],
      options:{},
      labels:{ 
        created_at:{title:'Fecha'},
        salesnote:{title:'Cotización'},
        customer_rut:{title:'Rut'},
        customer_name:{title:'Cliente'},
        ordered: { title: 'Pedido' },
        delivered:{title:'Despachado'},
        returned:{title:'Retornado'},
        toinvoice:{title:'Facturar'},
        price:{title:'Precio'},
        total:{title:'Total'},
        created_by_name:{title:'Usuario'},
       },
       csvTitle:`export_distribution_list_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.items=this.items.map(item=>{
        item.created_at=moment(item.created_at).format('DD-MM-YYYY')
        item.toinvoice=parseFloat(item.delivered)-parseFloat(item.returned)
        item.total=item.toinvoice*item.price
        item.salesnote=this.salesnote
        return item
      })
      this.dataReady = true
    },
  },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Distribucion Exportada')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando')
      this.dataReady=false
    }
  }
</script>