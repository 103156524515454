<template>
  <v-container>
    <div>
      <v-card-title
        class="titulo text-h5 mb-4"
      >
       <v-icon class="mr-2 align-items-center">fas fa-box</v-icon>{{item.id>-1?'Actualizar Tipo de Movimiento':'Crear Tipo de Movimiento'}}
      </v-card-title>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-radio-group v-model="item.in_out" :error-messages="errors" :column="false">
              <v-radio
                :value="true"
                class="mr-6"
              >
                <template v-slot:label>
                  <div :class="item.in_out?'font-weight-black':''">Entrada</div>
                </template>
              </v-radio>
              <v-radio
                :value="false"
              >
                <template v-slot:label>
                  <div :class="!item.in_out?'font-weight-black':''">Salida</div>
                </template>
              </v-radio>
            </v-radio-group>
          </ValidationProvider>
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Nombre'
            prepend-icon="mdi-file-document"
            v-model='item.name'
            outlined
            :error-messages="errors"
            :loading="loading"
            ></v-text-field>
          </ValidationProvider>
          <v-checkbox
            v-model="item.price_effect"
          >
            <template v-slot:label>
              <div :class="item.price_effect?'font-weight-black':''">Afecta al Precio</div>
            </template>
          </v-checkbox>
          <div class="text-center">
            <v-btn type="submit" color="success" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
          </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-container>
</template>
<script>
import {getTipo,createTipo,updateTipo} from '@/api/stocks'
import notifications from '@/utils/notifications'
export default {
  name:'TipoCreateUpdate',
  props:['id'],
  data() {
    return {
      item:'',
      loading:false,
    }
  },
  methods:{
    async save(){
      this.loading=true
      if(this.id>-1){
        await updateTipo(this.id,{
          name:this.item.name,
          in_out:this.item.in_out,
          price_effect:this.item.price_effect
        })
        .then((res)=>{
          notifications.showSuccess('Tipo guardado correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Tipo '+err)
        })
        this.loading=false
      }
      else{
        await createTipo({
          name:this.item.name,
          in_out:this.item.in_out
        })
        .then((res)=>{
          notifications.showSuccess('Tipo creado correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Tipo '+err)
        })
        this.loading=false
      }
    },
    async get(){
      if(this.id>-1){
        this.loading=true
        await getTipo(this.id)
        .then(res=>{
          this.item=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando Tipo '+err)
        })
        this.loading=false
      }
      else{
        this.item={
          id:this.id,
          name:'',
          in_out:true,
          price_effect:false
        }
      }
    }
  },
  watch:{
    id:{
      handler:'get'
    }
  },
  created(){
    this.get()
  }

}
</script>

<style>

</style>