<template>
  <v-text-field 
  outlined 
  hide-details
  class='font-weight-black blue--text'  
  prepend-icon="fas fa-calendar-alt"  
  label="Fecha de Entrega Estimada" 
  readonly :value=" estimatedDelivery|moment('DD/MM/YYYY')" 
  append-icon="fas fa-sync"
  @click:append="estimateWorkOrderDelivery"
  :loading="loading"
  />
</template>

<script>
import notifications from '@/utils/notifications'
import {getWorkOrderEstimate} from '@/api/production'
export default {
  name:'DeliveryEstimation',
  props:['products'],
  mounted(){
    this.estimateWorkOrderDelivery()
  },
  data() {
    return {
      estimatedDelivery: '',
      loading:false,
    }
  },
  watch:{
    products:{
      deep:true,
      handler:'estimateWorkOrderDelivery'
    }
  },
  methods:{
    async estimateWorkOrderDelivery(){
        this.loading=true
        await getWorkOrderEstimate(this.products)
        .then((data)=>{
          this.estimatedDelivery = data
          this.estimationLoading=false
          //notifications.showSuccess('Fecha Estimada')
        })
        .catch((e)=>{
          //notifications.showError('Error calculando estimación '+e)
        })
        this.loading=false
    }
  }  
}
</script>

<style>

</style>