import Vue from 'vue'
import updateAbility from '../config/updateAbility'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Customers from '../views/Customers/Customers.vue'
import CreateupdateCustomer from '../views/Customers/CreateupdateCustomer.vue'
import ImportCustomers from '../views/Customers/ImportCustomers.vue'
import CustomersConfiguration from '../views/Customers/CustomersConfiguration.vue'

import SalesNoteCreateUpdate from '../views/Sales/SalesNoteCreateUpdate.vue'
import SalesNotes from '../views/Sales/SalesNotes.vue'
import SalesNoteDetail from '../views/Sales/SalesNoteDetail.vue'
import SalesNoteConfiguration from '../views/Sales/SalesNoteConfiguration.vue'
import Activities from '../views/Sales/Activities.vue'

import Products from '../views/Products/Products.vue'
import ImportProducts from '../views/Products/ImportProducts.vue'
import FamilyLine from '../views/Products/FamilyLine.vue'
import ProductCreateUpdate from '../views/Products/ProductCreateUpdate.vue'
import Costs from '../views/Products/Costs.vue'
import Processes from '../views/Products/Processes.vue'
import ImportProcesses from '../views/Products/ImportProcesses.vue'
import ImportSupplies from '../views/Products/ImportSupplies.vue'
import BatchUpdate from '../views/Products/BatchUpdate.vue'
import Supplies from '../views/Products/Supplies.vue'
import CostsGroupUnit from '../views/Products/CostsGroupUnit.vue'
import Login from '../views/Auth/Login.vue'
import Users from '../views/Users/Users.vue'
import CreateupdateUser from '../views/Users/CreateupdateUser.vue'
import PasswordResetEmail from '../views/Auth/PasswordResetEmail.vue'
import PasswordResetRequest from '../views/Auth/PasswordResetRequest.vue'
import ParametersManager from '../views/Parameters/ParametersManager.vue'
import RoleCreateUpdate from '../views/Users/RoleCreateUpdate.vue'
import machines from '../views/production/machines.vue'
import WorkOrders from '../views/production/WorkOrders.vue'
import ProductsInPrestage from '../views/production/ProductsInPrestage.vue'

import MainPlanner2 from '../views/production/MainPlanner2.vue'
import CompletedProcess from '../views/production/CompletedProcess.vue'
import WorkOrderUpdate from '../views/production/WorkOrderUpdate.vue'
import FloorControl from '../views/production/FloorControl.vue'
import CompromiseCalendar from '../views/production/CompromiseCalendar.vue'
import Stages from '../views/production/Stages.vue'
import PostCostWorkOrders from '../views/Reports/PostCostWorkOrders.vue'
import DetailedPostCost from '../views/Reports/DetailedPostCost.vue'
import ProductInvoiceReport from '../views/Reports/ProductInvoiceReport.vue'
import InvoicedWorkOrders from '../views/Reports/InvoicedWorkOrders.vue'
import ProductSalesReport from '../views/Reports/ProductSalesReport.vue'
import FamilySalesReport from '../views/Reports/FamilySalesReport.vue'
import ProductionBitacora from '../views/Reports/ProductionBitacora.vue'
import ConsumeBitacora from '../views/Reports/ConsumeBitacora.vue'
import ConsumeOTBitacora from '../views/Reports/ConsumeOTBitacora.vue'
import DistributionList from '../views/Reports/DistributionList.vue'
import WorkOrdersMoney from '../views/Reports/WorkOrdersMoney.vue'

import SupplyOrdersPendingReport from '../views/Reports/SupplyOrdersPendingReport.vue'
import StocksConfiguration from '../views/Stocks/StocksConfiguration'
import CreateTransaction from '../views/Stocks/CreateTransaction'
import SuccessTransaction from '../views/Stocks/SuccessTransaction'
import Articles from '../views/Stocks/Articles'
import Stock from '../views/Stocks/Stock'
import Transactions from '../views/Stocks/Transactions'
import ImportStock from '../views/Stocks/ImportStock'
import ImportProviders from '../views/Stocks/ImportProviders'
import DetailedArticle from '../views/Stocks/DetailedArticle.vue'
import PurchaseOrders from '../views/Stocks/PurchaseOrders.vue'
import CreatePurchaseOrder from '../views/Stocks/CreatePurchaseOrder.vue'
import Invoices from '../views/Sales/Invoices.vue'
import CreateDTE from '../views/Sales/CreateDTE.vue'
import Documents from '../views/Softnet/Documents.vue'
import KameDocuments from '../views/Kame/KameDocuments.vue'
import store from '../store'

import notifications from '../utils/notifications'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: '/customers',
        name: 'Customers',
        component: Customers,
        meta: { requiresAuth: true }
    },
    {
        path: '/customers/create-update/:customerId?',
        name: 'CustomersCreateUpdate',
        component: CreateupdateCustomer,
        meta: { requiresAuth: true }
    },
    {
        path: '/customers/import',
        name: 'ImportCustomers',
        component: ImportCustomers,
        meta: { requiresAuth: true }
    },
    {
        path: '/customers/configuration',
        name: 'CustomersConfiguration',
        component: CustomersConfiguration,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/costs',
        name: 'Costs',
        component: Costs,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/processes',
        name: 'Processes',
        component: Processes,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/processes/import/:data?',
        name: 'ImportProcesses',
        component: ImportProcesses,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/supplies/import/:data?',
        name: 'ImportSupplies',
        component: ImportSupplies,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/supplies',
        name: 'Supplies',
        component: Supplies,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/costs/groups-units',
        name: 'CostsGroupUnit',
        component: CostsGroupUnit,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/create-update/:product?',
        name: 'ProductCreateUpdate',
        component: ProductCreateUpdate,
        meta: { requiresAuth: true }
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/import',
        name: 'ImportProducts',
        component: ImportProducts,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/batch-update',
        name: 'BatchUpdate',
        component: BatchUpdate,
        meta: { requiresAuth: true }
    },
    {
        path: '/products/family-line',
        name: 'FamilyLine',
        component: FamilyLine,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales',
        name: 'SaleNotes',
        component: SalesNotes,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/invoices',
        name: 'Invoices',
        component: Invoices,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/create-dte/:id?/:tipo?',
        name: 'CreateDTE',
        component: CreateDTE,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/sales-note-detail/:sales_note',
        name: 'SalesNoteDetail',
        component: SalesNoteDetail,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/sale-note/:id?/:duplicate?',
        name: 'SalesNoteCreateUpdate',
        component: SalesNoteCreateUpdate,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/softnet-documents',
        name: 'Documents',
        component: Documents,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/kame-documents',
        name: 'KameDocuments',
        component: KameDocuments,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/configuration',
        name: 'SalesNoteConfiguration',
        component: SalesNoteConfiguration,
        meta: { requiresAuth: true }
    },
    {
        path: '/sales/activities',
        name: 'Activities',
        component: Activities,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/machines',
        name: 'machines',
        component: machines,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/work-orders',
        name: 'WorkOrders',
        component: WorkOrders,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/products-in-prestage',
        name: 'ProductsInPrestage',
        component: ProductsInPrestage,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/main-planner2',
        name: 'MainPlanner',
        component: MainPlanner2,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/completed-process',
        name: 'CompletedProcess',
        component: CompletedProcess,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/work-order-update/:workOrder',
        name: 'WorkOrderUpdate',
        component: WorkOrderUpdate,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/floor-control',
        name: 'FloorControl',
        component: FloorControl,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/compromise-calendar',
        name: 'CompromiseCalendar',
        component: CompromiseCalendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/production/stages',
        name: 'Stages',
        component: Stages,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/post-cost',
        name: 'PostCostWorkOrders',
        component: PostCostWorkOrders,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/post-cost/:id',
        name: 'DetailedPostCost',
        component: DetailedPostCost,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/product-sales',
        name: 'ProductSalesReport',
        component: ProductSalesReport,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/product-invoice',
        name: 'ProductInvoiceReport',
        component: ProductInvoiceReport,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/work-order-invoice',
        name: 'InvoicedWorkOrders',
        component: InvoicedWorkOrders,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/family-sales',
        name: 'FamilySalesReport',
        component: FamilySalesReport,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/supply-orders',
        name: 'SupplyOrdersPendingReport',
        component: SupplyOrdersPendingReport,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/production-bitacora',
        name: 'ProductionBitacora',
        component: ProductionBitacora,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/consume-bitacora',
        name: 'ConsumeBitacora',
        component: ConsumeBitacora,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/work-order-money',
        name: 'WorkOrdersMoney',
        component: WorkOrdersMoney,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/consume-ot-bitacora',
        name: 'ConsumeOTBitacora',
        component: ConsumeOTBitacora,
        meta: { requiresAuth: true }
    },
    {
        path: '/reports/distribution-list',
        name: 'DistributionList',
        component: DistributionList,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/articles',
        name: 'Articles',
        component: Articles,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/configuration',
        name: 'StocksConfiguration',
        component: StocksConfiguration,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/create-transaction',
        name: 'CreateTransaction',
        component: CreateTransaction,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/success-transaction',
        name: 'SuccessTransaction',
        component: SuccessTransaction,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/stock',
        name: 'Stock',
        component: Stock,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/transactions',
        name: 'Transactions',
        component: Transactions,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/import-stock',
        name: 'ImportStock',
        component: ImportStock,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/import-providers',
        name: 'ImportProviders',
        component: ImportProviders,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/detailed-article/:id',
        name: 'DetailedArticle',
        component: DetailedArticle,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/purchase-orders',
        name: 'PurchaseOrders',
        component: PurchaseOrders,
        meta: { requiresAuth: true }
    },
    {
        path: '/stocks/purchase-orders-create/:id?/:duplicate?',
        name: 'CreatePurchaseOrder',
        component: CreatePurchaseOrder,
        meta: { requiresAuth: true }
    },
    {
        path: '/parameters',
        name: 'ParametersManager',
        component: ParametersManager,
        meta: { requiresAuth: true }
    },

    {
        path: '/auth/login',
        name: 'Login',
        component: Login,

    },
    {
        path: '/auth/password-reset/request',
        name: 'PasswordResetEmail',
        component: PasswordResetEmail,

    },
    {
        path: '/auth/password-reset/:token',
        name: 'PasswordResetRequest',
        component: PasswordResetRequest,

    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: { requiresAuth: true }
    },
    {
        path: '/users/create-update/:id',
        name: 'UsersCreateUpdate',
        component: CreateupdateUser,
        meta: { requiresAuth: true }
    },
    {
        path: '/users/role-create-update/:id',
        name: 'RoleCreateUpdate',
        component: RoleCreateUpdate
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//Solución para resguardar las rutas

router.beforeEach(async(to, from, next) => {
    const token = localStorage.getItem('access_token')
    var user = store.getters.getUser
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //Condición de prueba para evitar la tomada de datos al principio
        //Se probará con el docker
        if (to.matched.some(record => record.name == 'Home') && !user) {
            next('/auth/login')
            return 0
        }
        if (!user) {
            await store.dispatch('getMe')
                .then(() => {
                    user = store.getters.getUser
                    updateAbility(user)
                })
                .catch(() => next('/auth/login'))
        }
        if (!token | !user) {
            next('/auth/login')
        } else {
            if (store.getters.getPaymentRequest==1) {
                notifications.showError('Su servicio presenta un problema de pago, por favor comuníquese con el administrador para evitar la suspención de su cuenta.')
                setTimeout(() => {
                    next()
                }, 5000);
            }
            else next()
        }
    } else next();
});

export default router