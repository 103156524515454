export function cmsToMts(value) {
    return parseFloat(value) * 0.01
}

export function mtsToCms(value) {
    return parseFloat(value) * 100
}

export function getMts2(width, height) {
    //Width and Height in cms
    return parseFloat(width) * parseFloat(height) * 0.0001
}