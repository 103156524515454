<template>
  <div>
      <vertical-bar-chart
        :chart-data="datacollection"
        :options='chartOptions'
        :height="280"
      />
  </div>
</template>

<script>
import {fetchWorkOrders} from '@/api/production'
import VerticalBarChart from '@/components/charts/VerticalBarChart'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:'NextOrdersGraph',
  components:{VerticalBarChart},
  props:[],
  data() {
    return {
      outDataCollection:'',
      loadingPendingOrders:'',
      datacollection:'',
      chartOptions:{
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: value => numeral(value).format('0,0[.]0')
          }
        }],
        y: {
          suggestedMin: 1000,
        }
      },
      tooltips: {
        mode: 'index',
        displayColors:true,
        callbacks: {
          label: function(context) {
            var label = numeral(context.value).format('0,0[.]0')
            return label;
          }
        }
      }
      },
      
    }
  },
  methods:{
    async fetchNextWorkOrders(){
      this.loadingPendingOrders=true
      await fetchWorkOrders(1,-1,'','','','',moment(new Date().toString()).add(7,'day').format('YYYY-MM-DD'),'false','','','-created_at')
      .then(res=>{
       this.groupByDeliveryDate(res.results)
       this.loadingPendingOrders=false
      })
    },
    groupByDeliveryDate(workOrders){
      workOrders.forEach(w=>{
        w.stringDeliveryDate=moment(w.delivery_date).format('YYYY-MM-DD')
      })
      
      //Agrupo por día
      const groupByDay=workOrders.reduce((acc,value)=>{
        if(!acc[value.stringDeliveryDate]){
          acc[value.stringDeliveryDate]=[]
        }
        acc[value.stringDeliveryDate].push(value);
        return acc;
        
      },{})
      const results=[]
      Object.keys(groupByDay).map(day=>{
        results.push(
          {
            day:day,
            workOrders:groupByDay[day],
            total_mts2:groupByDay[day].reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0)
            })
      })
      //Ordenes Atrasadas
      const delayed = results.filter(w=>moment(w.day).isBefore())
      delayed.total_mts2=delayed.reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0)
      //Ordenes por entregar
      const today=results.filter(w=>w.day==moment().format('YYYY-MM-DD'))
      const pending=results.filter(w=>moment(w.day).isSameOrAfter())
    
      const dataResults=[]
      dataResults.push({
        label:'Atraso',
        mts2:delayed.reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0),
        color:'#F65A5B'
      })

      dataResults.push({
        label:'Hoy',
        mts2:today.reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0),
        color:'#88C4E1'
      })

      pending.reverse().forEach(w=>{
        dataResults.push({
          label:moment(w.day).format('dddd'),
          mts2:w.total_mts2,
          color:'#7A3D8A'
        })
      })

      this.datacollection={
          labels: dataResults.map(d=>d.label),
          datasets:[
            {
              label:'Salidas',
              data: dataResults.map(d=>d.mts2),
              backgroundColor: dataResults.map(d=>d.color),
              borderColor:'#144d3f',
            }
          ]
        }
    }
  },
  async mounted(){
    this.fetchNextWorkOrders()
  },
}
</script>

<style>

</style>