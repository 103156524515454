<template>
  <v-container class="fill-height" >
      <v-card class="pa-4 mx-auto" max-width="500">
        <v-img
          max-height="100"
          max-width="100"
          class="mx-auto"
          src="@/assets/runflow_logo.png"
        ></v-img>
        <v-form v-if="!sent" @submit.prevent="sendEmail">
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <p class="text text-h6 mb-4">Por favor ingrese su correo electrónico para crear una nueva contraseña.</p>
              <v-text-field
                outlined
                prepend-inner-icon="mdi-account"
                placeholder="Correo Electrónico"
                v-model="email"
                type="email"
              ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
            <v-btn :loading="loading" type="submit">Enviar</v-btn>
            <v-btn to="/auth/login" text>recuerdo mi Contraseña</v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
        <div v-if="sent&&success">
          <p class="text text-h6 mb-4">Exito! Hemos enviado un correo electrónico con instrucciones para crear una nueva Contraseña.</p>
          <v-card-actions>
            <v-row justify="center">
              <v-btn @click="$router.push('/auth/login')">Aceptar</v-btn>
            </v-row>
          </v-card-actions>
        </div>
        <div v-if="sent&&!success">
          <p class="text text-h6 mb-4">Ha ocurrido un error. Por favor revise la dirección de correo o contacte al administrador.</p>
          <v-card-actions>
            <v-row justify="center">
              <v-btn @click="$router.push('/auth/login')">Aceptar</v-btn>
            </v-row>
          </v-card-actions>
        </div>
      </v-card>
  </v-container>
</template>
<script>
import {passwordResetSendEmail} from '@/api/auth'
export default {
  name:'PasswordResetEmail',
  data: ()=>({
    loading:false,
    email:'',
    sent:false,
    success:true,
  }),
  methods:{
    async sendEmail(){
      this.loading=true
      await passwordResetSendEmail(this.email)
      .then(()=>{
        this.loading=false
        this.sent=true
        this.success=true
      })
      .catch(()=>{
        this.loading=false
        this.sent=true
        this.success=false
      })
    }
  },
}
</script>