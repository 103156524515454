<template>
<div>
  <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchDocuments} from '@/api/softnet'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportDocuments",
  props:['filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      dataReady:false,
      labels:{ 
        created_at:{title:'Fecha'},
        document:{title:'Folio'},
        workorder:{title:'OT'},
        sales_note: { title: 'Cot.' },
        customer_rut:{title:'Rut'},
        customer_name: { title: 'Cliente' },
        seller_name:{title:'Vendedor'},
        neto:{title:'Neto.'},
        created_by_name:{title:'Creada por'},
       },
       csvTitle:`export_softnet_notas_venta_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='customer_name'){
        sort = 'customer__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.filter.not_null) this.filter.not_null=''
      if (!this.filter.tipo) this.filter.tipo=''
      if (!this.filter.seller) this.filter.seller=''
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&page=1&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&workorder__sales_note__customer__seller=${this.filter.seller}`
      await fetchDocuments(query)
      .then(res=>{
        console.log(res)
        this.items=res.results.map(i=>{
          i.created_at=moment(i.created_at).format('DD-MM-YYYY')
          return i
        })
        this.dataReady=true
      })
      .catch((err)=>{
        notifications.showError('Error cargando documentos '+err)
      })
      this.loading=false
    },
    handleSuccess(val){
      notifications.showSuccess('Documentos Exportados')
      this.dataReady=false
    },
    handleError(val){
      notifications.showError('Error Exportando Documentos')
      this.dataReady=false
    }
  }

}
</script>