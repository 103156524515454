var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Procesos")])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ml-4",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({name:'ImportProcesses',params:{data:{machines:_vm.machines,units:_vm.units,groups:_vm.groups,processes:_vm.costs}}})}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-file-import")]),_vm._v("Importar ")],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":_vm.create}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1)],1)],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.costs,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":"Cargando Datos","search":_vm.search,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right'
            }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.groups,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Todos los Grupos","prepend-inner-icon":"filter_alt","single-line":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.groupFilter),callback:function ($$v) {_vm.groupFilter=$$v},expression:"groupFilter"}})],1),_c('v-col',[_c('v-select',{staticClass:"pa-0",attrs:{"items":_vm.machines,"item-text":"name","item-value":"id","label":"Area de Producción","placeholder":"Todas las Áreas","prepend-inner-icon":"filter_alt","single-line":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.machineFilter),callback:function ($$v) {_vm.machineFilter=$$v},expression:"machineFilter"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('cost-create-update',{attrs:{"cost":_vm.newCost,"groups":_vm.groups},on:{"save":_vm.save}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.merma>0)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","dark":"","color":" text-center orange darken-1"}},[_vm._v("+ "+_vm._s(item.merma))]):_vm._e()]}},{key:"item.price",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price,'$',0))+" ")]}},{key:"item.unit_name",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"chip",attrs:{"outlined":"","label":""}},[_vm._v(_vm._s(item.unit_name))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_vm._v("fas fa-edit")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }