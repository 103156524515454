<template>
  <div>
    <v-app-bar color="success" dense dark>
      <v-toolbar-title>
        <v-icon class="mr-2">fas fa-rss</v-icon>SOFTNET
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')" small fab ><v-icon small>fas fa-close</v-icon></v-btn>
    </v-app-bar>
    <div class="d-flex justify-center align-items-center mt-6" v-if="loading">
      <v-progress-circular
        indeterminate
        color="success"
        class="mr-2"
      />
    </div>
    
   
    <div class="mt-8 pa-6" v-else>
      <v-row>
        <v-col cols="10" class="d-flex align-center">
          <h1 class="text text-h4">Nota de venta <span class="titulo text-h4">#{{softnet_data.idDoc.folio}}</span></h1>
          <div class="d-block ml-6">
            <div class="text caption" title="Creada"> <v-icon class="mr-2" small>fas fa-square-plus</v-icon>{{created_by}} - {{softnet_data.idDoc.fechaEmis}}</div>
          </div>
        </v-col>
        <v-col cols="12" md="3" >
          <v-text-field
            :value="softnet_data?softnet_data.receptor.RUTRecep:' '"
            label="RUT"
            dense
            :loading="loading"
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            dense
            label="Razón Social"
            :value="softnet_data?softnet_data.receptor.RznSocRecep:' '"
            :loading="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
          <v-col cols="12" md="4" >
          <v-text-field
            dense
            label="Giro"
            :value="softnet_data?softnet_data.receptor.GiroRecep:' '"
            :loading=loading
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
      </v-row>
      <v-row>
            <v-col>
              <v-data-table
              :headers="headers"
              :items="softnet_data.Detalle"
              item-key="NroLinDet"
              class=""
              :loading="loading"
              hide-default-footer
              >
                <template v-slot:no-data>
                  <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                    No hay productos para mostrar
                  </v-alert>
                </template>
                <template v-slot:item.PrcItem="{ item }">
                  {{item.PrcItem | currency('$', 0)}}
                </template>
                <template v-slot:item.MontoItem="{ item }">
                  {{item.MontoItem | currency('$', 0)}}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-4">
            <v-col>
                <v-card class="py-6">
                  <v-simple-table dense class='strip-table'>
                    <thead>
                      <tr>
                        <th class="text-center table-header2">Neto</th>
                        <th class="text-center table-header2">IVA</th>
                        <th class="text-center table-header2">Total</th> 
                       
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center strip-body"><strong>{{softnet_data.totales.MntNeto|currency('$', 0)}}</strong></td>
                        <td class="text-center strip-body d-flex align-center justify-center" style="min-height:80px;">
                          ({{softnet_data.totales.TasaIVA}}%)
                          <strong class="ml-4">{{softnet_data.totales.IVA|currency('$', 0)}}</strong>
                        </td>
                        <td class="text-center strip-body"><strong class="">{{softnet_data.totales.MntTotal|currency('$', 0)}}</strong></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
      </v-row>
      <v-row justify="center">
          
        </v-row>
    </div>
  </div>
</template>
<script>
import { getFullData,createDocument,getDocument } from "@/api/softnet";
import notifications from "../../utils/notifications";

export default{
  name:'DocumentDetail',
  props:['document_id'],
  data(){
    return{
      loading:false,
      showConfirm:false,
      sending:false,
      softnet_data:{},
      created_by:'',
      softnet_data:{},
      customer_data:{},
      sales_note:{},
      formas_pago:[],
      forma_pago:'',
      direccion:'',
      areas_negocio:[],
      area_negocio:'',
      seller_name:'',
      productos:[],
      exento:0,
      iva:parseInt(this.$store.getters.getCompany.tax),
      headers:[
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'VlrCodigo',
          show:false,
          class:'table-header2'
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'NmbItem',
          show:true,
          class:'table-header2'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value: 'QtyItem',
          width:'10%',
          show:true,
          class:'table-header2'
        },
        {
          text: 'Precio Unit.',
          align: 'end',
          sortable: false,
          value: 'PrcItem',
          show:true,
          width:'12%',
          class:'table-header2'
        },
        {
          text: 'Total',
          align: 'end',
          sortable: false,
          value: 'MontoItem',
          show:true,
          class:'table-header2'
        },
        ],

    }
  },
  methods:{
    removeItem(item){ 
      this.productos.splice(this.productos.indexOf(item),1)  
    },
    confirm(){
      this.showConfirm=true
    },
    async fetchSoftnetData(){
      this.loading = true;
      await getDocument(this.document_id).then(response => {
        const {softnet_data, created_by}=response;
        this.softnet_data = softnet_data;
        this.created_by = created_by;
        this.loading = false;
      })
      .catch(error => {
        notifications.showError(error);
        this.loading = false;
      })
    },
    async sendData(){
      this.sending=true
      const data={
        workorder_id:this.sales_note.work_order,
        area_negocio:this.area_negocio,
        condicion_pago:this.forma_pago,
        direccion:this.direccion,
        neto:this.neto,
        exento:this.exento,
        descuento:0,
        total:this.neto+this.exento,
        observacion:'RUNFLOW PRUEBA',
        detalle:this.productos.map(p=>{
          return {
            Codigo:p.sku,
            Cantidad:p.qty,
            Precio:p.price,
            Descuento:0,
            Afecto:true
          }
        }),
      }

      await createDocument(data).then(response => {
        const {mensaje,folio} = response[0];
        notifications.showSuccess(`${mensaje} folio: ${folio}`);
        this.sending=false
        this.showConfirm=false
        this.$emit('close')
      })
      .catch(e=>{
        notifications.showError(e);
        this.sending=false
        this.showConfirm=false
      })
    }
  },
  computed:{
    neto(){
      return this.productos.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
    },
    company(){
      return this.$store.getters.getCompany
    },
  },
  watch:{
    document_id(newValue){
      console.log(newValue)
      this.fetchSoftnetData();
    }
  },
  async created(){
    this.fetchSoftnetData();
  }
}

</script>