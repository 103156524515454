var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-card-title',{staticClass:"titulo text-h4 mb-4"},[_c('v-icon',{staticClass:"mr-2 align-items-center"},[_vm._v("fas fa-box")]),_vm._v(_vm._s(_vm.article.id>-1?'Actualizar Artículo':'Crear Artículo')+" ")],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Familia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","prepend-icon":"fas fa-object-group","items":_vm.families,"item-text":"name","item-value":"id","label":"Familia","placeholder":"Seleccione una Familia","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.article.family),callback:function ($$v) {_vm.$set(_vm.article, "family", $$v)},expression:"article.family"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"name":"Codigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Código","prepend-icon":"fas fa-barcode","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.article.sku),callback:function ($$v) {_vm.$set(_vm.article, "sku", $$v)},expression:"article.sku"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","error-messages":errors,"loading":_vm.loading,"prepend-icon":"fas fa-tag","outlined":"","dense":""},model:{value:(_vm.article.name),callback:function ($$v) {_vm.$set(_vm.article, "name", $$v)},expression:"article.name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Unidades","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"text-end mr-2",attrs:{"prepend-icon":"fas fa-tachometer-alt","items":_vm.units,"dense":"","item-text":"name","item-value":"id","label":"Unidad de Compra","placeholder":"Seleccione una Unidad","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.article.unit),callback:function ($$v) {_vm.$set(_vm.article, "unit", $$v)},expression:"article.unit"}})]}}],null,true)})],1),_c('v-col',[_c('div',{staticClass:"d-flex d-inline-flex"},[_c('ValidationProvider',{attrs:{"name":"Crítico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Inventario Crítico","type":"number","prepend-icon":"fas fa-exclamation","outlined":"","min":0,"error-messages":errors,"loading":_vm.loading},model:{value:(_vm.article.critic),callback:function ($$v) {_vm.$set(_vm.article, "critic", $$v)},expression:"article.critic"}})]}}],null,true)}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"dense":"","label":"Alertar"},model:{value:(_vm.article.alert),callback:function ($$v) {_vm.$set(_vm.article, "alert", $$v)},expression:"article.alert"}})],1)])],1),_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"text-end mr-2",attrs:{"prepend-icon":"fas fa-tachometer","items":_vm.units,"dense":"","item-text":"name","item-value":"id","label":"Unidad de Consumo","placeholder":"Seleccione una Unidad","outlined":"","clearable":"","loading":_vm.loading},model:{value:(_vm.article.unit_consume),callback:function ($$v) {_vm.$set(_vm.article, "unit_consume", $$v)},expression:"article.unit_consume"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Factor de Conversion","rules":"minvalue:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","value":1,"label":"Factor de Conversión","type":"number","prepend-icon":"fas fa-repeat","outlined":"","min":0,"error-messages":errors,"loading":_vm.loading},model:{value:(_vm.article.unit_consume_factor),callback:function ($$v) {_vm.$set(_vm.article, "unit_consume_factor", $$v)},expression:"article.unit_consume_factor"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Observaciones","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Observaciones","prepend-icon":"fas fa-sticky-note","outlined":"","loading":_vm.loading,"error-messages":errors},model:{value:(_vm.article.notes),callback:function ($$v) {_vm.$set(_vm.article, "notes", $$v)},expression:"article.notes"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"type":"submit","color":"success","disabled":_vm.loading,"loading":_vm.saving}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }