<template>
  <v-app id="app">
    <div >
      <v-app-bar
        app
        clipped-left
        color="main"
        dark
        v-if="$route.name!='Login'&&$route.name!='PasswordResetEmail'&&$route.name!='PasswordResetRequest'"
      >
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-md-none"></v-app-bar-nav-icon>
        <v-img
          max-height="50"
          max-width="60"
          src="./assets/runflow_logo.png"
          @click="$router.push('/')"
          style="cursor:pointer"
        ></v-img>
        
        <top-menu class="hidden-sm-and-down ml-6"/>
        
        <v-spacer></v-spacer>
        <div class="text dark ml-3 mr-5">{{user.first_name + ' '+user.last_name}} [{{user.user_role.name}}]</div>
        <div class="d-inline-flex">
        </div>
        <div class="d-inline-flex">
          <v-icon @click="logOut">fas fa-sign-out-alt</v-icon>
        </div>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        v-if="user"
        color="#EEEEEE"
        temporary
        class="hidden-md-and-up"
      >
      <div v-for="(menu,i) in menus"
            :key="i">
          <v-list-group
            active-class="custom_orange--text"
          >
            <template v-slot:activator >
              <v-list-item-content>
                <v-list-item-title v-text="menu.title" class="dark_blue--text"></v-list-item-title>
              </v-list-item-content>
            </template>
            <div v-for="(item,index) in menu.items" :key="index" >
              <Can I="read" :a="item.gate" :key="item.name">
                <v-list-item :to="`/${item.link}`" active-class="light_blue-text">
                  <v-list-item-action>
                    <v-icon class="dark_blue--text">{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="dark_blue--text">
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </Can>
            </div>
            </v-list-group>
            </div>
        </v-navigation-drawer>
        
       
      </div>
    <v-main>
      <v-container
        fluid
      >
      <notifications
        group="principal"
        width="30%"
        position="top center"
        animation-type="velocity"
        :speed="350" />
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {logout} from '@/api/auth'
import SideMenu from '@/components/menu/SideMenu'
import TopMenu from '@/components/menu/TopMenu'
import menu from '@/utils/menu'

  export default {
    components:{SideMenu,TopMenu},
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      items: [
        { heading: 'Comercial' },
        { icon: 'fas fa-shopping-cart', text: 'Notas de Venta', link:'sales'},
        { icon: 'fas fa-city', text: 'Clientes', link:'customers'},
        { icon: 'fas fa-book', text: 'Productos', link:'products' },
        { icon: 'tune', text: 'Familia y Línea', link:'products/family-line' },
        { divider: true },
        { heading: 'Producción' },
        { icon: 'fas fa-hard-hat', text: 'Órdenes de Trabajo', link:'production/work-orders'},
        { icon: 'fas fa-calendar-alt', text: 'Planificador', link:'production/main-planner'},
        { icon: 'fas fas fa-gamepad', text: 'Control de Piso', link:'production/floor-control'},
        { icon: 'fas fa-calendar-minus', text: 'Compromisos', link:'production/compromise-calendar'},
        { icon: 'fas fa-flag-checkered', text: 'Post Costeo', link:'reports/post-cost'},
        { divider: true },
        { heading: 'Configuración' },
        { icon: 'fas fa-cogs', text: 'Procesos', link:'products/processes' },
        { icon: 'fas fa-calculator', text: 'Materiales', link:'products/supplies' },
        { icon: 'tune', text: 'Grupos', link:'products/costs/groups-units' },
        { icon: 'fas fa-atom', text: 'Areas de Producción', link:'production/machines' },
        { divider: true },
        { icon: 'people', text: 'Usuarios', link:'users' },
        { icon: 'tune', text: 'Parámetros', link:'parameters' },
      ],
      /* menus:[
        {
          title: 'Comercial',
          gate:'SalesNote',
          items:[
            { icon: 'fas fa-shopping-cart', text: 'Cotizaciones', link:'sales',gate:'SalesNote'},
            { icon: 'fas fa-city', text: 'Clientes', link:'customers',gate:'Customer'},
            { icon: 'fas fa-book', text: 'Productos', link:'products',gate:'Product'},
            { icon: 'tune', text: 'Familia y Línea', link:'products/family-line',gate:'Product' },
          ]
        }, 
        {
          title: 'Producción',
          gate:'WorkOrder',
          items:[
            { icon: 'fas fa-hard-hat', text: 'Órdenes de Trabajo', link:'production/work-orders',gate:'WorkOrder'},
            { icon: 'fas fa-calendar-alt', text: 'Planificador', link:'production/main-planner',gate:'Planner'},
            { icon: 'fas fas fa-gamepad', text: 'Control de Piso', link:'production/floor-control',gate:'FloorControl'},
            { icon: 'event_available', text: 'Compromisos', link:'production/compromise-calendar',gate:'Calendar'},
          ]
        }, 
        {
          title: 'Inventarios',
          gate:'Stocks',
          items:[
            { icon: 'fas fa-boxes', text: 'Inventario', link:'stocks/stock',gate:'Stocks'},
            { icon: 'fas fa-shopping-basket', text: 'Ordenes de Compra', link:'stocks/purchase-orders',gate:'Stocks'},
            { icon: 'fas fa-dolly-flatbed', text: 'Entradas/Salidas', link:'stocks/create-transaction',gate:'Stocks'},
            { icon: 'fas fa-box', text: 'Artículos', link:'stocks/articles',gate:'Stocks'},
            { icon: 'fas fa-cog', text: 'Mantenedores', link:'stocks/configuration',gate:'Stocks'},
          ]
        },
        {
          title: 'Finanzas',
          gate:'Reports',
          items:[
            { icon: 'fas fa-file-invoice-dollar', text: 'Facturación', link:'sales/invoices',gate:'SalesNote'}
          ]
        }, 
        {
          title: 'Gestión',
          gate:'Reports',
          items:[
            { icon: 'fas fa-flag-checkered', text: 'Post Costeo', link:'reports/post-cost',gate:'PostCost'},
            { icon: 'fas fa-chart-bar', text: 'Ventas por Producto', link:'reports/product-sales',gate:'PostCost'},
            { icon: 'fas fa-chart-area', text: 'Facturación por Producto', link:'reports/product-invoice',gate:'PostCost'},
            { icon: 'fas fa-chart-line', text: 'Facturación por OT', link:'reports/work-order-invoice',gate:'PostCost'},
            { icon: 'leaderboard', text: 'Ventas por Familia', link:'reports/family-sales',gate:'PostCost'},
            { icon: 'fas fa-truck', text: 'Material Comprometido', link:'reports/supply-orders',gate:'PostCost'},
          ]
        },
        {
          title: 'Configuración',
          gate:'Configuration',
          icon:'fas fa-cog',
          items:[
            { icon: 'fas fa-cogs', text: 'Procesos', link:'products/processes', gate:'Cost'},
          { icon: 'fas fa-calculator', text: 'Materiales', link:'products/supplies',gate:'Cost' },
          { icon: 'tune', text: 'Grupos', link:'products/costs/groups-units',gate:'Cost' },
          { icon: 'fas fa-atom', text: 'Areas de Producción', link:'production/machines',gate:'Cost' },
          { icon: 'people', text: 'Usuarios', link:'users',gate:'User',gate:'User' },
          { icon: 'tune', text: 'Parámetros', link:'parameters',gate:'Parameter',gate:'Parameter' },
          ]
        } 
      ], */
      snackbar: true,
      menus:menu
    }),
    methods:{
      logOut(){
        logout()
        this.$router.push('/auth/login')
      },
      toggleDark(){
        this.$vuetify.theme.dark=!this.$vuetify.theme.dark
      },
      goTo(link){
        this.$router.push('/'+link)
      },
    },
    computed:{
      user(){
        return this.$store.getters.getUser
      },
      fantasy(){
        return this.$store.getters.getCompany.fantasy
      }
    },
  }
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins");

#app {
  font-family:'Poppins', sans-serif;
  background-color: #f3f4f6;
}
.titulo{
  font-family: 'Roboto', sans-serif;
  font-weight:900 !important;
  font-size:2rem !important;
  color:#33425B !important;
  font-size: medium !important;
}

.titulo-2{
  font-family: 'Roboto', sans-serif;
  font-weight:500 !important;
  font-size:2rem !important;
  color:#33425B !important;
  font-size: medium !important;
}

.table-header {
    //font-family:'Roboto Condensed', sans-serif;
    font-weight:normal;
    font-size:medium !important;
    color:#02293e !important;
}
.table-header-dense {
    //font-family:'Roboto Condensed', sans-serif;
    font-weight:normal;
    font-size:small !important;
    color:#02293e !important ;
}
.table-body {
 // font-family:'Roboto Condensed', sans-serif;
  font-weight:normal;
  font-size:large !important;
  color:#484CB0 !important;
}

#keep .v-navigation-drawer__border {
  display: none
}
//Estilos para las notificaciones
.vue-notification {
  border-radius: 5px;
  // Style of the notification itself 
  
  .notification-title {
    font-weight: 10 ;
  }
 
  .notification-content {
    // Style for content
    font-size: large; 
  }
  
 }

</style>

