<template>
  <v-container>
    <v-row class="mb-10" justify="space-between" align-content="center">
      <h1 class="text text-h4 titulo"><v-icon class="mr-2" x-large>fas fa-cog</v-icon>Configuración</h1>
    </v-row>
    <v-tabs 
      background-color="transparent"
      color="primary"
      center-active
      v-model="tab"
      
    >
      <v-tab v-model="tab" class="primary--text">Proveedores</v-tab>
      <v-tab v-model="tab" class="primary--text">Familias</v-tab>
      <v-tab v-model="tab" class="primary--text">Unidades de Compra</v-tab>
      <v-tab v-model="tab" class="primary--text">Bodegas</v-tab>
      <v-tab v-model="tab" class="primary--text">Tipos de Movimiento</v-tab>
      <v-tab v-model="tab" class="primary--text">Centro de Costo</v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item class="pa-4">
          <providers/>
        </v-tab-item>
        <v-tab-item class="pa-4">
          <families/>
        </v-tab-item>
        <v-tab-item class="pa-4">
          <units/>
        </v-tab-item>
        <v-tab-item class="pa-4">
          <stores/>
        </v-tab-item>
        <v-tab-item class="pa-4">
          <tipos/>
        </v-tab-item>
        <v-tab-item class="pa-4">
          <centers/>
        </v-tab-item>
    </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import Providers from '@/components/stocks/Providers'
import Families from '@/components/stocks/Families'
import Units from '@/components/stocks/Units'
import Stores from '@/components/stocks/Stores'
import Tipos from '@/components/stocks/Tipos'
import Centers from '@/components/stocks/Centers'
export default {
  name:'StocksConfiguration',
  components:{Families,Units,Stores,Tipos,Centers,Providers},
  data() {
    return {
      tab: null,
    }
  },
}
</script>

<style>

</style>