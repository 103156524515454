var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.families,"item-text":"name","item-value":"id","label":"Familia","placeholder":"Todos las Familias","single-line":"","outlined":"","dense":"","clearable":"","prepend-inner-icon":"fas fa-filter","disabled":_vm.loading||this.families.length==0},model:{value:(_vm.family),callback:function ($$v) {_vm.family=$$v},expression:"family"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.lines,"item-text":"name","item-value":"id","label":"Linea","placeholder":"Todas las Líneas","single-line":"","outlined":"","dense":"","clearable":"","prepend-inner-icon":"fas fa-filter","disabled":_vm.loading||this.lines.length==0},model:{value:(_vm.line),callback:function ($$v) {_vm.line=$$v},expression:"line"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total,"search":_vm.search,"item-class":"table-body","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',[_vm._v(" "+_vm._s(item.sell_unit_m2 ? 'square_foot':'view_comfy')+" ")])]}},{key:"item.customer_name",fn:function(ref){
        var item = ref.item;
return [(item.customer_name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"medium_blue--text",staticStyle:{"cursor":"pointer"},attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-circle")])]}}],null,true)},[_c('span',[_vm._v("Solo para "+_vm._s(_vm.customer.name.slice(0,20)+"..."))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 success--text",attrs:{"text":""},on:{"click":function($event){return _vm.add(item)}}},[_c('v-icon',[_vm._v("fas fa-cart-plus")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }