<template>
  <v-container>
    <div>
      <v-card-title
        class="titulo text-h4 mb-4"
      >
       <v-icon class="mr-2 align-items-center">dns</v-icon>{{item.id>-1?'Actualizar Etapa':'Crear Etapa'}}
      </v-card-title>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Nombre'
            prepend-icon="mdi-file-document"
            v-model='item.name'
            outlined
            :error-messages="errors"
            :loading="loading"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Orden'
            type="number"
            prepend-icon="fas fa-sort"
            v-model='item.sort'
            outlined
            :error-messages="errors"
            :loading="loading"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <div class="d-flex justify-center">
              <v-color-picker hide-inputs v-model="item.color"></v-color-picker>
            </div>
          </ValidationProvider>
          
            <div class="text-center">
              <v-btn type="submit" color="success" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
            </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-container>
</template>
<script>
import {getStage,createStage,updateStage} from '@/api/production'
import notifications from '@/utils/notifications'
export default {
  name:'StageCreateUpdate',
  props:['id'],
  data() {
    return {
      item:'',
      loading:false,
    }
  },
  methods:{
    async save(){
      this.loading=true
      if(this.id>-1){
        await updateStage(this.id,{
          name:this.item.name,
          sort:this.item.sort,
          color:this.item.color
        })
        .then((res)=>{
          notifications.showSuccess('Etapa guardada correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Etapa '+err)
        })
        this.loading=false
      }
      else{
        await createStage({
          name:this.item.name,
          sort:this.item.sort,
          color:this.item.color
        })
        .then((res)=>{
          notifications.showSuccess('Etapa creada correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Etapa '+err)
        })
        this.loading=false
      }
      //Actualizo el Store
      this.$store.dispatch('getStages')
    },
    async get(){
      if(this.id>-1){
        this.loading=true
        await getStage(this.id)
        .then(res=>{
          this.item=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando Etapa '+err)
        })
        this.loading=false
      }
      else{
        this.item={
          id:this.id,
          name:'',
          color:'#ffffff'
        }
      }
    }
  },
  watch:{
    id:{
      handler:'get'
    }
  },
  created(){
    this.get()
  }

}
</script>

<style>

</style>