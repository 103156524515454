var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.displayDialog($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-rss")]),_vm._v(" Importar Factronica ")],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.customer.rut!='')?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitCustomer)}}},[_c('v-card',[_c('v-card-item',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v("Importar Cliente")]),_c('v-card-subtitle',[_vm._v("Cliente Encontrado ID:"+_vm._s(_vm.customer.id_empresa))]),_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","type":"text","label":"RUT","filled":"","outlined":"","readonly":""},model:{value:(_vm.customer.rut),callback:function ($$v) {_vm.$set(_vm.customer, "rut", $$v)},expression:"customer.rut"}}),_c('v-text-field',{attrs:{"dense":"","type":"text","label":"Razon Social","filled":"","outlined":"","readonly":""},model:{value:(_vm.customer.razon_social),callback:function ($$v) {_vm.$set(_vm.customer, "razon_social", $$v)},expression:"customer.razon_social"}}),_c('v-text-field',{attrs:{"dense":"","type":"text","label":"Nombre Fantasia","filled":"","outlined":"","readonly":""},model:{value:(_vm.customer.nombre_fantasia),callback:function ($$v) {_vm.$set(_vm.customer, "nombre_fantasia", $$v)},expression:"customer.nombre_fantasia"}}),_c('ValidationProvider',{attrs:{"name":"Vendedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('seller-selection',{attrs:{"icon":"fas fa-id-badge","errors":errors},model:{value:(_vm.customer.seller),callback:function ($$v) {_vm.$set(_vm.customer, "seller", $$v)},expression:"customer.seller"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"submit","loading":_vm.loading,"color":"success"}},[_vm._v(" Enviar ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()]}}])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.customer.rut=='')?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitLookforCustomer)}}},[_c('v-card',[_c('v-card-item',[_c('v-card-title',[_vm._v("Importar Cliente")]),_c('v-card-subtitle',[_vm._v("Busqueda de cliente en FACTRONICA")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"RUT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"text","label":"RUT del Cliente","hint":"Sin puntos con guion","outlined":"","error-messages":errors},model:{value:(_vm.customer_rut),callback:function ($$v) {_vm.customer_rut=$$v},expression:"customer_rut"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"submit","loading":_vm.loading,"color":"success"}},[_vm._v(" Enviar ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }