var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-10 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Areas de Producción")])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":_vm.create}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar","append-icon":"fas fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.machines,"items-per-page":10,"loading":_vm.loading,"loading-text":"Cargando Datos","search":_vm.search,"sort-by":['name'],"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }},scopedSlots:_vm._u([{key:"item.capacity",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.capacity)+" "),_c('v-icon',[_vm._v(_vm._s(item.production_unit_m2?'square_foot':'view_comfy'))])]}},{key:"item.saturday",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.saturday?'fas fa-check':'fas fa-xmark'))])]}},{key:"item.sunday",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.sunday?'fas fa-check':'fas fa-xmark'))])]}},{key:"item.equipments",fn:function(ref){
        var item = ref.item;
return _vm._l((item.equipments),function(equipment){return _c('v-chip',{key:equipment.id,attrs:{"disabled":!equipment.enabled}},[_vm._v(_vm._s(equipment.name))])})}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_c('v-icon',[_vm._v("fas fa-edit")])],1)]}},{key:"item.enabled",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{on:{"change":function (){_vm.machine=item;_vm.save()}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('machine-create-update',{attrs:{"machine":_vm.machine,"loading":_vm.loading},on:{"save":_vm.save}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }