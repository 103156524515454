<template>
  <v-container>
    <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
        v-show="loading"
      ></v-progress-linear>
    <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
        v-show="internalLoading"
      ></v-progress-linear>
  <div class="px-6" v-if="!loading">
    <v-row class="mb-2 justify-space-between">
      <v-col cols="8">
        <h1 class="text text-h4 titulo" v-if="!inSet">Orden de Compra #{{purchaseOrder.id}}</h1>
        <div class="text mt-2 titulo">Creada por: <strong>{{purchaseOrder.created_by_full_name}} - {{purchaseOrder.created_at| moment('DD/MM/YYYY h:mm a')}}</strong></div>
        <div v-if="purchaseOrder.approved_by" class="text mt-2 titulo">Aprobada por: <strong>{{purchaseOrder.approved_by_full_name}} - {{purchaseOrder.approved_at| moment('DD/MM/YYYY h:mm a')}}</strong></div>
      </v-col>
      <v-col class="text-end" v-if="!inSet">
        <div class="flex justify-space-around">
            <v-chip label outlined :color="getStatusColor">{{getStatusName}}</v-chip>
            <!-- <v-menu offset-y >
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                v-bind="attrs"
                v-on="on"
                text
                 class="ml-2">more_vert</v-icon> 
              </template>
              
            </v-menu> -->
          <v-btn @click="$emit('close')" class="ml-2" text><v-icon class="mr-2">fas fa-chevron-left</v-icon></v-btn>
          </div>
      </v-col>
    </v-row>
    <v-row justify="start" v-if="!inSet">
      <v-col cols="12" md="6">
        <table>
          <tbody>
            <tr>
              <td class="text-right">
                Referencia:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{purchaseOrder.title}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Proveedor:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{purchaseOrder.provider_name}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Condición de Pago:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{purchaseOrder.payment}} días</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Moneda:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{purchaseOrder.moneda}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="12" md="6">
        <table>
          <tbody>
            <tr>
              <td class="text-right">
                Tipo de Orden:
              </td>
              <td class="text-left px-3">
                <div class=" text font-weight-black">{{purchaseOrder.stock_service?'Artículos':'Servicios'}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Centro de Costo:
              </td>
              <td class="text-left px-3">
                <div class=" text font-weight-black">{{purchaseOrder.center_name}}</div>
              </td>
            </tr>
            <tr v-if="purchaseOrder.work_order">
              <td class="text-right">
                Orde de Trabajo:
              </td>
              <td class="text-left px-3">
                <div class=" text font-weight-black">{{purchaseOrder.work_order}}</div>
              </td>
            </tr>
            <tr v-if="purchaseOrder.notes">
              <td class="text-right">
                Observaciones:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black">{{purchaseOrder.notes}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="filteredHeaders"
            :items="this.purchaseOrder.details"
            :single-expand="true"
            item-key="id"
            show-expand
            class=""
            
            >

             <template v-slot:top>
              <div class="text titulo text-h6 pa-4">Detalle</div>
            </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-row align="center">
                    <v-col cols="4" align-items-center>
                      <div class="d-block">
                        Precio Ult. Compra: <strong>{{item.last_buy_price | currency('$', 0)}}</strong>
                      </div>
                      <div class="d-block">
                        Cant. Ult. Compra: <strong>{{item.last_buy_qty}}</strong>
                      </div>
                      <div class="d-block">
                        Fecha Ult. Compra: <strong>{{item.last_buy_date | moment('DD/MM/YYYY')}}</strong>
                      </div>
                    </v-col>
                    
                    <v-col align-items-center>
                      Observaciones: <strong>{{item.notes}}</strong>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-slot:item.name="{ item }">
                {{purchaseOrder.stock_service?item.article_name:item.name}}
              </template>
              <template v-slot:item.qty="{ item }">
                {{item.qty| number('0[.]0')}}
              </template>
              <template v-slot:item.price="{ item }">
                {{item.price| currency('$', 2)}}
              </template>
              <template v-slot:item.total="{ item }">
                {{item.price*item.qty | currency('$', 0)}}
              </template>
              
            </v-data-table>
        </v-col>
      </v-row>

      <v-row class="justify-center mt-4">
        <!-- <v-col cols="12" md="4">
          <v-card class="pa-4">
            <v-simple-table dense class="pa-0" width="100%">
              <tbody>
                <tr>
                  <td>Sub Total</td>
                  <td class="text-end strip-header"><strong>{{neto| currency('$',0)}}</strong></td>
                </tr>
                <tr v-if="purchaseOrder.discount>0">
                  <td>Descuento</td>
                  
                  <td class="strip-header text-end">
                    {{purchaseOrder.discount}}%<strong class="ml-2">({{neto*purchaseOrder.discount/100 | currency('$',0)}})</strong>
                  </td>
                </tr>
                <tr>
                  <td>Neto</td>
                  <td class="text-end strip-header"><strong>{{neto-neto*purchaseOrder.discount/100| currency('$',0)}}</strong></td>
                </tr>
                <tr>
                  <td>IVA</td>
                  <td class="text-end strip-header"><strong class="">{{(neto-neto*purchaseOrder.discount/100)*0.19| currency('$',0) }}</strong></td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td class="text-end strip-header"><strong class="purple--text">{{(neto-neto*purchaseOrder.discount/100)*1.19| currency('$',0)   }}</strong></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col> -->
        <v-col>
            <v-card>
              <v-simple-table dense class='strip-table'>
                <thead>
                  <tr>
                    <th class="text-center strip-header">Subtotal</th>
                    <th class="text-center strip-header">Descuento ({{parseInt(purchaseOrder.discount)}}%)</th>
                    <th class="text-center strip-header">Neto</th>
                    <th class="text-center strip-header">IVA ({{purchaseOrder.iva}}%)</th>
                    <th class="text-center strip-header">Total</th> 
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center strip-header"><strong class="ml-2">{{neto| currency('$',0)}}</strong></td>
                    <td class="text-center strip-header"><strong>({{neto*purchaseOrder.discount*0.01 | currency('$',0)}})</strong></td>
                    <td class="text-center strip-header"><strong>{{neto-neto*purchaseOrder.discount*0.01| currency('$',0)}}</strong></td>
                    <td class="text-center strip-header"><strong class="">{{(neto-neto*purchaseOrder.discount*0.01)*purchaseOrder.iva*0.01| currency('$',0) }}</strong></td>
                    <td class="text-center strip-header">{{(neto-neto*purchaseOrder.discount*0.01)*(1+purchaseOrder.iva*0.01)| currency('$',0)   }}</strong></td>
                    
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
      </v-row>
      

 
      
       
    </div>
   
  </v-container>
</template>
<script>


export default {
  name:'DetailedPurchaseOrder',
  components:{},
  props:['purchaseOrder','loading','inSet'],
  data() {
    return {
    internalLoading:false,
    headers:[
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'article_sku',
          show:false,
          class:'strip-header dark_blue--text'
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name',
          show:true,
          class:'strip-header dark_blue--text'
        },
        
        {
          text: 'Unidad',
          align: 'start',
          sortable: false,
          value: 'article_unit_name',
          show:false,
          class:'strip-header dark_blue--text'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value: 'qty',
          width:'10%',
          show:true,
          class:'strip-header dark_blue--text'
        },
        {
          text: 'Recibido',
          align: 'end',
          sortable: false,
          value: 'received',
          width:'10%',
          show:false,
          class:'strip-header dark_blue--text'
        },
        {
          text: 'Precio Unitario',
          align: 'end',
          sortable: false,
          value: 'price',
          show:true,
          width:'12%',
          class:'strip-header dark_blue--text'
        },
        {
          text: 'Total',
          align: 'end',
          sortable: false,
          value: 'total',
          show:true,
          class:'strip-header dark_blue--text'
        },
        ],
        estatuses:[
        {
          value:1,
          text:'Ingresada',
          color:'info'
        },
        {
          value:2,
          text:'Aprobada',
          color:'success'
        },
        {
          value:4,
          text:'Recepcionada',
          color:''
        },
        {
          value:3,
          text:'Anulada',
          color:'error'
        },
      ],
    }
  },
  computed:{
    neto(){
      return this.purchaseOrder.details.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
    },
    filteredHeaders(){
      return this.headers.filter(h=>h.show||this.purchaseOrder.stock_service)
    },
     getStatusName(){
      return this.estatuses.find(i=>i.value==this.purchaseOrder.estatus).text
    },
    getStatusColor(){
      return this.estatuses.find(i=>i.value==this.purchaseOrder.estatus).color
    },
  },
  watch:{
    
  },
  methods:{
    
  }
}
</script>