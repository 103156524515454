import axios from "axios"

export async function fetchCustomers(sort = '', page = '', page_size = '', search = '',seller = '', status = '',rut='') {
    return await axios.get(`customer/?ordering=${sort}&page=${page}&page_size=${page_size}&search=${search}&seller=${seller}&status=${status}&rut=${rut}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchCustomersQuery(query) {
    return await axios.get(`customer/?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getCustomer(id) {
    return await axios.get(`customer/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
            return e
        })
}

export async function getCustomerByRUT(rut) {
    return await axios.get(`customer/rut/${rut}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
            return e
        })
}

export async function createCustomer(customer) {
    console.log(customer)
    return await axios.post(`customer/`, {
            rut: customer.rut,
            name: customer.name,
            giro: customer.giro,
            fantasy: customer.fantasy,
            phone: customer.phone,
            com_agencia: customer.com_agencia,
            seller: customer.seller.id,
            payment: customer.payment,
            status: customer.status,
            branch_office: customer.branch_office,
            tipo: customer.tipo,
            industry: customer.industry,
            rubro: customer.rubro,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function uploadCustomers(customers) {
    return await axios.post(`customer/batch-upload`, customers)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateCustomer(customer) {
    return await axios.put(`customer/${customer.id}`, {
            rut: customer.rut,
            name: customer.name,
            giro: customer.giro,
            fantasy: customer.fantasy,
            phone: customer.phone,
            com_agencia: customer.com_agencia,
            payment: customer.payment,
            status: customer.status,
            seller: customer.seller.id,
            branch_office: customer.branch_office,
            tipo: customer.tipo,
            industry: customer.industry,
            rubro: customer.rubro
        })
        .then(res => {
            return res.data
        })
        .catch(e  => {if(e.response.data.error) throw e.response.data.error})
}

export async function createAddress(address) {
    return await axios.post(`customer/address`, {
            customer: address.customer,
            name: address.name,
            region: address.region,
            comune: address.comune,
            street: address.street,
            city: address.city,
            number: address.number,
            suite: address.suite,
            default: false,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateAddress(address) {
    return await axios.patch(`customer/address/${address.id}`, {
            name: address.name,
            region: address.region,
            comune: address.comune,
            city: address.city,
            street: address.street,
            number: address.number,
            suite: address.suite,
            default: address.default,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteAddress(address) {
    return await axios.delete(`customer/address/${address.id}`)
        .then(res => {
            console.log(res)
            return res
        })
        .catch(e => {
            throw e
        })
}

export async function fetchAddresses(query = '') {
    return await axios.get(`customer/address${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchContacts(query = '') {
    return await axios.get(`customer/contact${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createContact(contact) {
    return await axios.post(`customer/contact`, {
            customer: contact.customer,
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone: contact.phone,
            cargo: contact.cargo,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateContact(contact) {
    return await axios.patch(`customer/contact/${contact.id}`, {
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone: contact.phone,
            cargo: contact.cargo,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function deleteContact(contact) {
    return await axios.delete(`customer/contact/${contact.id}`)
        .then(res => {
            console.log(res)
            return res
        })
        .catch(e => {
            throw e
        })
}

export async function fetchIndustries(query = '') {
    return await axios.get(`customer/industry${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createIndustry(industry) {
    return await axios.post(`customer/industry`, industry)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getIndustry(id) {
    return await axios.get(`customer/industry/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateIndustry(id,industry) {
    return await axios.put(`customer/industry/${id}`, industry)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteIndustry(id) {
    return await axios.delete(`customer/industry/${id}`)
        .then(res => {
            return res
        })
        .catch(e => {
            throw e
        })
}

export async function fetchRubros() {
    return await axios.get(`customer/rubro`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getRubro(id) {
    return await axios.get(`customer/rubro/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createRubro(rubro) {
    return await axios.post(`customer/rubro`, rubro)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateRubro(id, rubro) {
    return await axios.put(`customer/rubro/${id}`, rubro)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteRubro(id) {
    return await axios.delete(`customer/rubro/${id}`)
        .then(res => {
            return res
        })
        .catch(e => {
            throw e
        })
}

export async function excelCustomers(query) {
    return await axios.get(
            `customer/export-excel?${query}`,{
                responseType: 'blob',
                timeout: 1000 * 60
            })
        .then(blob => {
            return blob.data
        })
        .catch(e => {
            throw e
        })
}