var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4",attrs:{"justify":"space-between","align-content":"center"}},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Material Comprometido")]),_c('export-supply-pending',{attrs:{"data":_vm.supplies}})],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex align-end",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedMomentjs,"label":"Periodo Entrega","dense":"","readonly":"","outlined":"","hide-details":""},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function (){_vm.menuDate = false;_vm.fetch()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","text":""},on:{"click":_vm.clearDate}},[_vm._v("Limpiar")])],1),_c('v-col',{attrs:{"col":"12","md":"6","align-self":"center"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading,"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.supplies,"search":_vm.search,"sort-by":"pending","sort-desc":true,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        itemsPerPageOptions: [10, 20, 30, 40, 50,100],
      }},scopedSlots:_vm._u([{key:"item.qty",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.qty,'0,0.00'))+" ")]}},{key:"item.consumed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.consumed,'0,0.00'))+" ")]}},{key:"item.pending",fn:function(ref){
      var item = ref.item;
return [_c('strong',{class:(item.pending)<0?'error--text':''},[_vm._v(_vm._s(_vm._f("number")(item.pending,'0,0.00')))])]}},{key:"item.article_sku",fn:function(ref){
      var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name:'DetailedArticle',params:{id:item.article_id}})}}},[_vm._v(_vm._s(item.article_sku))])]}},{key:"item.stock",fn:function(ref){
      var item = ref.item;
return [_c('strong',{class:(item.stock)<0?'error--text':''},[_vm._v(_vm._s(item.stock))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }