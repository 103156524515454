<template>
  <v-container>
    <div>
      <v-card-title
        class="titulo text-h4 mb-4"
      >
       <v-icon class="mr-2 align-items-center"></v-icon>{{provider.id>-1?'Actualizar Proveedor':'Crear Proveedor'}}
      </v-card-title>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
          <ValidationProvider name="RUT" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            class="mb-2"
            label='RUT'
            prepend-icon="fas fa-id-badge"
            v-model='provider.rut'
            hint="Ingresar número con guión y dígito verificador"
            outlined
            :rules="[rules.rut]"
            :error-messages="errors"
            :loading="loading"
            :append-icon="rutValido?'fas fa-check':''"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='Nombre'
            prepend-icon="mdi-file-document"
            v-model='provider.name'
            outlined
            :error-messages="errors"
            :loading="loading"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider name="Condición de Pago" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='Condición de Pago (Días)'
            v-model='provider.payment'
            prepend-icon="fas fa-calendar-alt"
            type='number'
            :min="0"
            outlined
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
            <div class="text-center">
              <v-btn type="submit" color="success" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
            </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-container>
</template>
<script>
import {getProvider,createProvider,updateProvider} from '@/api/stocks'
import notifications from '@/utils/notifications'
import {validaRUT }from '@/utils/rut_validator'
export default {
  name:'ProviderCreateUpdate',
  props:['id'],
  data() {
    return {
      provider:'',
      loading:false,
      rules:{
        rut:value=>validaRUT(value)||'Rut Inválido'
      }
    }
  },
  methods:{
    async save(){
      if(!this.rutValido){
        notifications.showError('RUT Inválido')
        return 0
      }
      this.loading=true
      if(this.id>-1){
        await updateProvider(this.id,{
          rut: this.provider.rut,
          name:this.provider.name,
          payment:this.provider.payment
        })
        .then((res)=>{
          notifications.showSuccess('Proveedor guardado correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Proveedor - Revisar RUT duplicado'+err)
        })
        this.loading=false
      }
      else{
        await createProvider({
          rut: this.provider.rut,
          name:this.provider.name,
          payment: this.provider.payment
        })
        .then((res)=>{
          notifications.showSuccess('Proveedor creado correctamente')
          this.$emit('close')
          this.$emit('updated',res)
        })
        .catch(err=>{
          notifications.showError('Error creando Proveedor - Revisar RUT duplicado '+err)
        })
        this.loading=false
      }
    },
    async get(){
      if(this.id>-1){
        this.loading=true
        await getProvider(this.id)
        .then(res=>{
          this.provider=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando proveedor '+err)
        })
        this.loading=false
      }
      else{
        this.provider={
          id:this.id,
          rut:'',
          name:'',
        }
      }
    }
  },
  computed:{
    rutValido(){
      var valido = false
      if(this.provider.rut){
        valido = validaRUT(this.provider.rut)
      }
      return valido
    },
  },
  watch:{
    id:{
      handler:'get'
    }
  },
  created(){
    this.get()
  }

}
</script>

<style>

</style>