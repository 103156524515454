<template>
<v-container>
  <group/> 
</v-container>
</template>
<script>
import Group from '@/components/products/costs/Group'

export default {
  name:'CostsGroupUnit',
  components:{Group},
  
}
</script>
