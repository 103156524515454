<template>
  <v-container>
    <v-row class="mb-4" justify="space-between" align-content="center">
      <h1 class="text text-h4 titulo">Facturacion por Producto</h1>
      <export-product-invoice :filter='filter' :options='options'/>
    </v-row>
    
    <v-card class="pa-4">
      <v-row class="" >
        <v-col align-self="center" cols="12" md="6">
          <v-text-field hide-details :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Emitida desde:"></v-text-field>
        </v-col>
        <v-col align-self="center" cols="12" md="6">
          <v-text-field hide-details :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Emitida hasta:" ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
          <Can I="readAll" a="SalesNote">
            <v-select
              v-model="filter.seller"
              :items="sellers"
              item-text="email"
              item-value="id"
              label="Vendedor"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              hide-details
            />
          </Can>
        </v-col>
        <v-col align="center" cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            :disabled="loading"
            hide-details
            @keyup="searchTimeOut"
          ></v-text-field>
        </v-col>
      </v-row>
        <v-row justify="center">
          <v-col>
            <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          sort-by="product__sku"
          :sort-desc="true"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :item-class="()=>{return 'row-style-normal'}"
          :server-items-length="totalItems"
          @click:row='displayDetail'
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }"
        >
          
          <template v-slot:item.total="{ item }">
            {{item.total | currency('$', 0)}}
          </template>
          </v-data-table>
          </v-col>
          
        </v-row>
    </v-card>
    <v-dialog v-model="toDetail" max-width="1200">
      <v-card class="pa-6">
        <v-card-title class="titulo text-h5">Detalle Facturas [{{item.product__sku}}] {{item.product__name}}</v-card-title>
        <product-invoice-detail :product="item" :filter="filter"/>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {fetchInvoicedProducts} from '@/api/reports'
import {fetchSellers} from '@/api/auth'
import ProductInvoiceDetail from '@/components/reports/ProductInvoiceDetail'
import ExportProductInvoice from '@/components/reports/ExportProductInvoice'
import moment from 'moment'
export default {
  name:"ProductInvoiceReport",
  components:{ProductInvoiceDetail,ExportProductInvoice},
  async mounted(){
    this.loading = true
    this.fetch()
    this.sellers=await fetchSellers()
    this.loading = false
  },
  data() {
    return {
      loading: false,
      sellers: [],
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        seller:''
      },
      totalItems:0,
      search:'',
      options:{},
      items:[],
      iva:this.$store.getters.getCompany.tax,
      headers:[
          {text: 'Código',align: 'start',value: 'product__sku',class: 'table-header'},
          {text: 'Descripción',align: 'start',value: 'product__name',class: 'table-header'},
          {text: 'Familia',value:'product__family__name',class: 'table-header'},
          {text: 'Línea',align: 'start',value: 'product__line__name',class: 'table-header'},
          {text: 'Neto',align: 'end',value: 'total',class: 'table-header'},
        ],
      toDetail:false,
      item:''
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.seller) this.filter.seller=''
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      let  sort = (sortDesc[0] ? '-'+sortBy[0] : sortBy[0])
      if (!sort||!sortBy[0]) sort = 'product__sku'

      if (!this.$can('readAll', 'SalesNote')){
          const user = this.$store.getters.getUser
          this.filter.seller=user.id
        }
        
      const query=`page=${page}&page_size=${itemsPerPage}&search=${this.search}&ordering=${sort}&emited_after=${this.filter.created_after}&emited_before=${this.filter.created_before}&dte__customer__seller__id=${this.filter.seller}`
      await fetchInvoicedProducts(query)
      .then((res)=>{
        this.loading=false
        this.totalItems=res.count
        this.items=res.results
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    displayDetail(item){
      this.item=item
      this.toDetail=true
    } 
  },
  watch:{
    filter:{
      deep: true,
      handler: 'fetch'
    },
    options:{
      handler:'fetch',
      deep:true
    },
  },
  computed:{
    
  }

}
</script>