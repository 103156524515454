<template>
  <v-data-table
  dense
  :headers="headers"
  :items="value"
  :loading="loading"
  >
  <template v-slot:top>
    <div class="text-end">
      <address-create-update-2 class="mb-4" :action="'create'" @created="fetch" :customerId="customerId"/>
    </div>
  </template>

  <template v-slot:item.name="{ item }">
    <v-chip
      label
      small
      dark
      class=""
    >
      {{item.name}}
    </v-chip>
  </template>

  <!--Menu de acciones-->
  <template v-slot:item.actions="{item}">
    <address-create-update-2 :action="'edit'" v-model="item" @updated="fetch" @deleted="fetch" :customerId="customerId"/>
  </template>
  </v-data-table>

</template>

<script>
import AddressCreateUpdate2 from './AddressCreateUpdate2.vue';
import {fetchAddresses} from '@/api/customers';
import notifications from '@/utils/notifications';
export default {
  name:'AddressList',
  components:{
    AddressCreateUpdate2
  },
  props:{
    value:{
      type:Array,
    },
    customerId:{
      type:Number,
      required:false,
    },
    embeded:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      headers:[],
      loading:false
    };
  },
  methods:{
    fetch(){
      this.loading=true;
      var query=`?customer=${this.customerId}`;
      fetchAddresses(query)
      .then(response=>{
        this.$emit('input',response);
      })
      .catch(error=>{
        console.log(error);
        notifications.showError('Error','No se pudo cargar los contactos del cliente')
      })
      .finally(()=>{
        this.loading=false;
      })

    },
  },

  mounted(){
    this.headers=this.embeded? [
      { text: 'Nombre', value: 'name' },
      { text: 'Calle', value: 'street' },
      { text:'Número',value:'number'},
      { text:'Región',value:'region'},
      { text:'Comuna',value:'comune'},
      { text:'Ciudad',value:'city'},
      { text: '', value: 'actions', sortable: false}
    ]:
    [
      { text: 'Nombre', value: 'name' },
      { text: 'Calle', value: 'street' },
      { text:'Número',value:'number'},
      { text:'Región',value:'region'},
      { text:'Comuna',value:'comune'},
      { text:'Ciudad',value:'city'},
      { text: '', value: 'actions', sortable: false}
    ]
    
  }
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>