<template>
  <v-container>
    <v-row justify="space-between">
      
     <Can I="readAll" a="SalesNote">
      <v-col>
        <v-card class="pa-2" :loading="loading">
          <v-card-title >
            <div class="subheading font-weight-light grey--text text-center">
                Ranking Clientes
            </div>
          </v-card-title>
          <v-card-text>
            <bar-chart
              :chart-data="customerDataColletion"
              :options='horizontalBarChartOptions'
              label="Ranking Vendedores"
              :height="180"
            />
          </v-card-text>
        </v-card>
      </v-col>
      </Can>
      <Can I="readAll" a="SalesNote">
      <v-col>
        <v-card class="pa-2" :loading="loading">
          <v-card-title >
            <div class="subheading font-weight-light grey--text text-center">
                Ranking de Vendedores
            </div>
          </v-card-title>
          <v-card-text>
            <bar-chart
              :chart-data="sellerDataColletion"
              :options='horizontalBarChartOptions'
              label="Ranking Vendedores"
              :height="180"
            />
          </v-card-text>
        </v-card>
      </v-col>
      </Can>
    </v-row>
    
  </v-container>
</template>

<script>
import LineChart from '@/components/charts/LineChart'
import BarChart from '@/components/charts/BarChart'

const numeral = require('numeral');
const moment = require('moment')
export default {
  name:'DayGraphComplete',
  components:{LineChart,BarChart},
  props:['customers','sellers','loading'],
  data:()=>({
    chartOptions:{
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: value => numeral(value).format('$0,0')
          }
        }],
      },
      tooltips: {
        mode: 'index',
        displayColors:true,
        callbacks: {
          label: function(context) {
            console.log('Context',context)
            var label = `${context.dataset.label}: ${numeral(context.value).format('$0,0')}`
            return label;
          }
        }
      }
    },
    horizontalBarChartOptions:{
      indexAxis: 'y',
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: false,
            callback: value => numeral(value).format('$0,0a')
          }
        }],
      },
      tooltips: {
        mode: 'index',
        displayColors:true,
        callbacks: {
          label: (context)=> (numeral(context.value).format('$0,0')) 
          }
        }
    },
    customerDataColletion:[],
    sellerDataColletion:[],
  }),
  methods:{
    processData(){
      //Getting all customers from 2 arrays
      const customerDataSet=this.customers[0].map(customer=>{
        return{
          ...customer,
          'invoiced':this.customers[1].find(c=>c.customer__id===customer.customer__id)?.total
        }
      })
      //Getting all sellers from 2 arrays
      const sellerDataSet=this.sellers[0].map(seller=>{
        return{
          ...seller,
          'invoiced':this.sellers[1].find(s=>s.customer__seller__id===seller.customer__seller__id)?.total
        }
      })

      this.customerDataColletion={
        labels: customerDataSet.map(d=>d.name.substring(0,15)),
        datasets: [
          {
            axis:'y',
            label:'Confirmado',
            backgroundColor: '#FFB224',
            borderColor:'#FFB224',
            data: customerDataSet.map(d=>d.total)
          }, 
          {
            axis:'y',
            label:'Invoiced',
            backgroundColor: '#5d9ef6',
            borderColor:'#5d9ef6',
            data: customerDataSet.map(d=>d.invoiced?d.invoiced:0)
          },
        ]
      }

      this.sellerDataColletion={
        labels: sellerDataSet.map(d=>d.full_name),
        datasets: [
          {
            axis:'y',
            label:'Confirmado',
            backgroundColor: '#FFB224',
            borderColor:'#FFB224',
            data: sellerDataSet.map(d=>d.total)
          }, 
          {
            axis:'y',
            label:'Invoiced',
            backgroundColor: '#5d9ef6',
            borderColor:'#5d9ef6',
            data: sellerDataSet.map(d=>d.invoiced?d.invoiced:0)
          },
        ]
      }
    },
  },
  watch:{
    //Revisa cambios en options para solicitar data nuevamente
    customers:{
      deep: true,
      handler: 'processData'
    },
    sellers:{
      deep: true,
      handler: 'processData'
    },
  },
}
</script>

