<template>
  <v-container>
    <div>
      <v-card-title
        class="titulo text-h4 mb-4"
      >
       <v-icon class="mr-2 align-items-center">fas fa-bars-staggered</v-icon>Cambio de Etapa 
      </v-card-title>
      <v-simple-table class="mb-10">
          <thead>
            <tr>
              <th>Codigo</th>
              <th>Producto</th>
              <th>OT</th>
              <th>Etapa</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products">
              <td>{{product.library_product__sku}}</td>
              <td>{{product.name}}</td>
              <td>{{product.work_order__id}}</td>
              <td>
                <v-chip
                  class="ma-2"
                  :color="product.prestage.color"
                  label
                  outlined
                  style="max-width:150px"
                  >
                  {{product.prestage.name}}
                  </v-chip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(confirmation)" class="mb-4">
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-select
              v-model="prestage_id"
              :items="stages"
              label="Nueva Etapa"
              item-text="name"
              item-value="id"
              outlined
              required>
              <template v-slot:item="data">
                <div class="d-flex">
                  <div class="text text-caption mr-2">{{data.item.sort}} - <v-chip
                    class="ma-2"
                    :color="data.item.color"
                    label
                    outlined
                    style="max-width:150px"
                    >
                {{data.item.name}}
                </v-chip>
                </div>
                </div>
              </template>
            </v-select>
          </ValidationProvider>
            <div class="text-center">
              <v-btn type="submit" color="success" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
              <v-btn color="secondary" text @click.prevent="()=>{
                prestage_id=''
                products=[]
                $emit('close')
                }">Cancelar</v-btn>
            </div>
        </v-form>
      </ValidationObserver>
    </div>
    <confirm
    v-model="showProductToProduction" 
    :message="`¿Confirma pasar a Produccion?`"
    @ok="save(false)"
    @cancel="()=>{
      showProductToProduction=false
      }"
      />
  </v-container>
</template>
<script>
import {updateProductionProduct} from '@/api/production'
import DetailPrestages from '@/components/production/DetailPrestages'
import notifications from '@/utils/notifications'
export default {
  name:'ChangePrestage',
  props:['products'],
  components:{
    DetailPrestages
  },
  data() {
    return {
      loading:false,
      stages:[],
      prestage_id:'',
      showProductToProduction:false
    }
  },
  methods:{
    confirmation(){
      if (this.prestage_id<0 )this.showProductToProduction=true
      else this.save(true)
    },
    async save(in_prestage){
      this.showProductToProduction=false
      this.loading=true
      let data={}
      if(in_prestage){
        data={in_prestage,prestage_id:this.prestage_id}
      }
      else data={in_prestage}
      const updateProducts = async ()=>{
        for (let product of this.products){
          await updateProductionProduct(product.id,data)
          .catch((e)=>{
            notifications.error(e.message)
          })
        }
        this.loading=false
        this.prestage_id=''
        this.products=[]
        this.$emit('close')
        this.$emit('updated')
      }
      updateProducts() 
    },
  },
  computed:{
    
  },
  mounted(){
    this.stages=this.stages=[...this.$store.getters.getStages,{id:-1,name:'Produccion',color:'green'}]
  }
}
</script>

<style>

</style>