<template>
  <div>
    <v-app-bar color="success" dense dark>
      <v-toolbar-title>
        <v-icon class="mr-2">fas fa-rss</v-icon>KAME
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="fetchData" text  small fab><v-icon small>fas fa-arrows-rotate</v-icon></v-btn>
      <v-btn text @click="$emit('close')" small fab ><v-icon small>fas fa-close</v-icon></v-btn>
    </v-app-bar>
    <div class="d-flex justify-center align-items-center mt-6" v-if="loading">
      <v-progress-circular
        indeterminate
        color="success"
        class="mr-2"
      />
    </div>
    
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form v-if="!loading" class="mt-8 pa-4" @submit.prevent="handleSubmit(confirm)">
      <v-row>
        <v-col cols="12" md="2" >
          <v-text-field
            :value="customer_data?customer_data.rut:' '"
            label="RUT"
            dense
            :loading="loading"
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            dense
            label="Razón Social"
            :value="customer_data?customer_data.name:' '"
            :loading="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
          <v-col cols="12" md="5" >
          <v-text-field
            dense
            label="Giro"
            :value="customer_data?customer_data.giro:''"
            :loading=loading
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
          <v-col cols="12" md="6" >
          <v-text-field
            dense
            label="Vendedor"
            :value="seller_name"
            :loading=loading
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              dense
              class="text-end mr-2"
              :items="customer_data?customer_data.addresses:[]"
              :loading="loading"
              :disabled="loading"
              item-text="text"
              item-value="id"
              label="Direccion"
              placeholder="Selecciona Direccion"
              outlined
              v-model="direccion"
              >
            </v-select>
          </v-col>
            <v-col cols="12" md="6">
              <v-select
                dense
                class="text-end mr-2"
                :items="areas_negocio"
                :loading="loading"
                :disabled="loading"
                item-text="Nombre"
                item-value="Nombre"
                label="Area de Negocio"
                placeholder="Selecciona Area de Negocio"
                outlined
                v-model="area_negocio"
                >
              </v-select>
            </v-col>
      </v-row>
      <v-row>
            <v-col>
              <v-data-table
              :headers="headers"
              :items="productos"
              item-key="key"
              class=""
              :loading="loading"
              hide-default-footer
              :items-per-page="-1"
              >
                <template v-slot:no-data>
                  <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                    No hay productos para mostrar
                  </v-alert>
                </template>
                <template v-slot:item.qty="{ item }">
                  <ValidationProvider name="qty" rules="required" v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      v-model='item.qty'
                      :min="1"
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                      solo
                      dense
                      style="width:100px"
                      ></v-text-field>
                    </ValidationProvider>
                </template>
                <template v-slot:item.price="{ item }">
                  <ValidationProvider name="price" rules="required" v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      v-model='item.price'
                      :min="0"
                      solo
                      dense
                      style="width:150px"
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <template v-slot:item.total="{ item }">
                  {{item.price*item.qty | currency('$', 0)}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon class="error--text" x-small @click.stop="removeItem(item)">fas fa-trash</v-icon>
                </template>
                
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-4">
            <v-col>
                <v-card class="py-6">
                  <v-simple-table dense class='strip-table'>
                    <thead>
                      <tr>
                        <th class="text-center table-header2">Neto</th>
                        <th class="text-center table-header2">IVA</th>
                        <th class="text-center table-header2">Total</th> 
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center strip-body"><strong>{{neto| currency('$',0)}}</strong></td>
                        <td class="text-center strip-body d-flex align-center justify-center" style="min-height:80px;">
                          (<ValidationProvider name="IVA" rules="required" v-slot="{ errors }">
                            <v-text-field 
                            type="number" 
                            v-model="iva" 
                            dense solo 
                            style="max-width:70px;" 
                            hide-details
                            max="100"
                            min="0"/>
                          </ValidationProvider>)%
                          <strong class="ml-4">{{(neto)*iva*0.01| currency('$',0) }}</strong>
                        </td>
                        <td class="text-center strip-body"><strong class="">{{neto*(1+iva/100)| currency('$',0)   }}</strong></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
      </v-row>
      <v-row justify="center">
          <v-col class="text-center mt-4">
            <v-btn color="success" type="submit" :disabled="false" :loading="sending"><v-icon class="mr-2">fas fa-file-arrow-up</v-icon>Guardar</v-btn>
          </v-col>
        </v-row>
    </v-form>
    </ValidationObserver>
    <confirm 
    v-model="showConfirm" 
    :message="sales_note?`Se creara un nuevo documento en Kame vinculado a la OT ${sales_note.work_order} `:'-'"
    @ok="sendData"
    @cancel="showConfirm=false"/>
  </div>
</template>
<script>

import {getSalesNote} from '@/api/salesNotes.js'
import {getListUnidadNegocio,createDocument} from '@/api/kame.js'
import notifications from "../../utils/notifications";
import numeral from "numeral";
import moment from 'moment';

export default{
  name:'CreateDocument',
  props:['sales_note_id'],
  data(){
    return{
      loading:false,
      showConfirm:false,
      sending:false,
      customer_data:{},
      sales_note:{},
      formas_pago:[],
      forma_pago:'',
      direccion:'',
      areas_negocio:[],
      area_negocio:'',
      seller_name:'',
      productos:[],
      exento:0,
      iva:parseInt(this.$store.getters.getCompany.tax),
      headers:[
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'sku',
          show:false,
          class:'table-header2'
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name',
          show:true,
          class:'table-header2'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value: 'qty',
          width:'10%',
          show:true,
          class:'table-header2'
        },
        {
          text: 'Precio Unit.',
          align: 'end',
          sortable: false,
          value: 'price',
          show:true,
          width:'12%',
          class:'table-header2'
        },
        {
          text: 'Total',
          align: 'end',
          sortable: false,
          value: 'total',
          show:true,
          class:'table-header2'
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          show:true,
          class:'table-header2'
        },
        ],

    }
  },
  methods:{
    removeItem(item){ 
      this.productos.splice(this.productos.indexOf(item),1)  
    },
    confirm(){
      this.showConfirm=true
    },
    async fetchData(){
      this.loading = true;
      await getSalesNote(this.sales_note_id).then(async response => {
        const {sales_note_customer,details,totals} = response
        this.sales_note=response
        this.customer_data=sales_note_customer
        this.customer_data.addresses=this.customer_data.addresses.map(a=>({
          text:`${a.street} ${a.number} ${a.comune} ${a.region}`,
          id:a.id
        }))

        if (this.customer_data.addresses.length>0) this.direccion=this.customer_data.addresses[0]

        const discountFactor=1-parseFloat(totals.discount)/100;

        this.productos=details.products.map(producto => {
           producto.price=parseFloat(producto.qty)!=0?parseFloat(producto.totalPrice/producto.qty)*discountFactor:0;
           producto.qty=parseFloat(producto.qty);
           return producto})
          
        this.seller_name=sales_note_customer.seller.first_name+' '+sales_note_customer.seller.last_name;

        await getListUnidadNegocio()
        .then(res=>{
          this.areas_negocio=res
          if (this.areas_negocio.length>0) this.area_negocio=this.areas_negocio[0]
        })
        .catch(err=>{
          notifications.showError(err)
        })
      })
      .catch(error=>{
        notifications.showError(error)
      })
      .finally(()=>{
        this.loading=false
      })

    },
    async sendData(){
      this.sending=true
      const data={
        workorder_id:this.sales_note.work_order,
        area_negocio:this.area_negocio,
        direccion:this.direccion.id,
        neto:this.neto,
        exento:0,
        descuento:0,
        total:this.neto,
        observacion:`COT ${this.sales_note.id} - OT ${this.sales_note.work_order}`,
        iva:this.iva,
        fecha_pago:moment().add(this.customer_data.payment,'days').format('YYYY-MM-DD'),
        fecha:moment().format('YYYY-MM-DD'),
        detalle:this.productos.map(p=>(
          {
            Descripcion:p.name,
            Cantidad:p.qty,
            PrecioUnitario:Math.round(p.price),
            Descuento:0,
            Total:p.qty*Math.round(p.price),
            UnidadMedida:'UN',
            UnidadNegocio:this.area_negocio.Nombre,
            PorcDescuento:0.00,
            DescripcionDetallada:'',
            Exento:''
          }
        )),
      }

      await createDocument(data).then(response => {
        const {Estado,Id,Folio} = response;
        if (Estado=='Ok'){
          notifications.showSuccess(`Pedido creado correctamente Folio: ${Folio}`);
          this.sending=false
          this.showConfirm=false
          this.$emit('close')
        }
        else{
          const {Error} = response;
          notifications.showError(`Error al crear pedido ${Id} ${JSON.stringify(Error)}`);
          this.sending=false
          this.showConfirm=false
        }
      })
      .catch(e=>{
        notifications.showError(e);
        this.sending=false
        this.showConfirm=false
      })
    }
  },
  computed:{
    neto(){
      return this.productos.reduce((acc,val)=>{return acc + parseFloat(val.qty)*Math.round(parseFloat(val.price));},0)
    },
    company(){
      return this.$store.getters.getCompany
    },
  },
  watch:{
    sales_note_id(newValue){
      console.log(newValue)
      this.fetchSoftnetData();
    }
  },
  async created(){
    this.fetchData();
  }
}

</script>