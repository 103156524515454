<template>
<div>
  <v-btn :disabled="disabled" color="primary" :loading="loading" @click="fetch" text><slot> </slot></v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import notifications from '@/utils/notifications'
import {fetchProducts, fetchFamily,fetchLines} from '@/api/products'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
export default {
  name:"ExportProducts",
  props:['family','line','disabled','for_update'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      dataReady:false,
      
       csvTitle:`export_productos_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  computed:{
    labels(){
      if(this.for_update){
        return { 
          sku:{title:'sku'},
          name:{title:'name'},
          family:{title:'family'},
          line:{title:'line'},
          width:{title:'width'},
          height:{title:'height'},
          price:{title:'price'},
          sell_unit_m2:{title:'sell_unit_m2'},
          status:{title:'status'}
          }
        
      }else{
        return {
          sku:{title:'Código'},
        name:{title:'Descripción'},
        family_name: { title: 'Familia' },
        line_name:{title:'Línea'},
        width:{title:'Ancho'},
        height:{title:'Alto'},
        price:{title:'Precio'},
        sell_unit_m2:{title:'Venta Mts2'},
       }
         
      }
    },
  },
  methods:{
    totalStock(item){
      if(this.store){
        const filteredStocks = item.stocks.filter(i=>i.store==this.store)
        return filteredStocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
      }
      else return item.stocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
    },
    async fetch(){
      this.loading=true
      const query=`ordering=-sku&family=${this.family}&line=${this.line}`
      await fetchProducts(query)
      .then((data)=>{
        this.items = data.results
        data.results.forEach(p => {
          p.width=parseFloat(p.width).toFixed(2)
          p.height=parseFloat(p.height).toFixed(2)
          p.price=parseFloat(p.price).toFixed(2)
        });
        this.dataReady=true
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando productos '+err)
        this.loading=false
      })
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Productos Exportados')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Productos')
      this.dataReady=false
    }
  }

}
</script>