<template>
  
</template>

<script>
import notifications from '@/utils/notifications'
export default {
  name:'TransactionSuccess',
  created(){
    notifications.showSuccess('Movimiento Registrado Correctamente')
    this.$router.replace({name:'CreateTransaction'})
  }
}
</script>

<style>

</style>
