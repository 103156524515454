<template>
<div>
  <v-btn :disabled="disabled" color="primary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Lista</b>
          </v-btn>
        </vue-json-to-csv>
        <vue-json-to-csv
          class="ml-2"
          :json-data="items_detail"
          :labels="labels_detail"
          :csv-title="csvTitleDetail"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Detalle</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import notifications from '@/utils/notifications'
import {fetchPurchase} from '@/api/stocks'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
import { VListItemContent } from 'vuetify/lib'
const totalOrder=(item)=> item.details.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
    
export default {
  name:"ExportPurchaseOrders",
  props:['filter','options','disabled','search','estatuses'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      items_detail:[],
      dataReady:false,
      labels:{ 
        id:{title:'Numero'},
        created_at:{title:'Creada'},
        created_by_full_name:{title:'Creada por'},
        provider_name: { title: 'Proveedor' },
        payment:{title:'Cond. Pago'},
        title:{title:'Referencia'},
        center_name:{title:'C Costo'},
        work_order:{title:'OT'},
        moneda:{title:'Moneda'},
        subtotal:{title:'Subtotal'},
        discount:{title:'Descuento'},
        neto:{title:'Neto'},
        iva:{title:'IVA'},
        total_order:{title:'Total'},
        estatus:{title:'Estatus'},
        approved_at:{title:'Aprobada'},
        approved_by_full_name:{title:'Aprobada por'},
        notes:{title:'Observaciones'},
       },
       labels_detail:{ 
        id:{title:'Numero'},
        created_at:{title:'Creada'},
        estatus:{title:'Estatus'},
        approved_at:{title:'Aprobada'},
        work_order:{title:'OT'},
        provider_name: { title: 'Proveedor' },
        title:{title:'Referencia'},
        article_sku:{title:'Codigo'},
        article_name:{title:'Articulo'},
        qty:{title:'Cantidad'},
        received:{title:'Recibido'},
        article_unit_name:{title:'Unidades'},
        price:{title:'Precio'},
        subtotal:{title:'Subtotal'},
        notes:{title:'Observaciones'},
       },
       csvTitle:`export_ordenesdecompra_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`,
       csvTitleDetail:`export_ordenesdecompra_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true

      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      const {sortBy, sortDesc }=this.options
      let sort=''
      if(sortBy[0]=='center_name'){
        sort = 'center__name'
      }
      else if(sortBy[0]=='provider_name'){
        sort = 'provider__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.filter.estatus) this.filter.estatus=''
      if (!this.filter.tipo) this.filter.tipo=''
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&search=${this.search}&estatus=${this.filter.estatus}&stock_service=${this.filter.tipo}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}`
      await fetchPurchase(query)
      .then(res=>{ 
        this.items_detail=[]
        this.items=res.results.map(item=>{
          item.provider_name=`${item.provider_rut} ${item.provider_name}`
          item.subtotal=totalOrder(item)
          item.discount=item.subtotal*item.discount/100
          item.neto=item.subtotal-item.discount
          item.iva=item.neto*item.iva/100
          item.total_order=item.neto+item.iva
          item.created_at=moment(item.created_at).format('DD-MM-YYYY')
          item.approved_at=item.approved_at?moment(item.approved_at).format('DD-MM-YYYY'):'-'
          item.work_order=item.work_order?item.work_order:'-'
          item.estatus=this.estatuses.find((i)=>item.estatus==i.value).text
          item.details.forEach(detail=>{
            this.items_detail.push(
              {...detail,
              id:item.id,
              created_at: item.created_at,
              approved_at:item.approved_at,
              work_order:item.work_order,
              provider_name:item.provider_name,
              title:item.title,
              subtotal:detail.price*detail.qty,
              estatus:item.estatus,
              notes:item.notes?item.notes:'-'})
          })
          return item
        })

        this.dataReady=true
      })
      .catch((err)=>{
        notifications.showError('Error cargando artículos '+err)
      })
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Inventario Exportado')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Inventario')
      this.dataReady=false
    }
  }

}
</script>