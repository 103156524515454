<template>
<div>
  <v-btn :disabled="disabled" color="primary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="products"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchProductionProducts} from '@/api/production'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
export default {
  name:"ExportPrestageProducts",
  props:['filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      products:[],
      dataReady:false,
      labels:{ 
        work_order__id:{title:'OT'},
        library_product__sku:{title:'Codigo'},
        name:{title:'Producto'},
        work_order__sales_note__customer__name:{title:'Cliente'},
        work_order__created_at:{title:'Creada'},
        work_order__delivery_date: { title: 'Entrega' },
        stage:{title:'Etapa'}
       },
       csvTitle:`export_productos_preproduccion_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.delivery_after) this.filter.delivery_after=''
      if(!this.filter.delivery_before) this.filter.delivery_before=''
      if(!this.filter.seller) this.filter.seller=''
      if(!this.filter.stage) this.filter.stage=''

      if (this.filter.stage===-1){
        notifications.showError('No se puede exportar productos en etapa de produccion')
        this.loading=false
        return false
      }
      const {sortBy, sortDesc}=this.options

      const query=`ordering=${sortDesc[0]?'-'+sortBy[0]:sortBy[0]}&stage=${this.filter.stage}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&delivery_after=${this.filter.delivery_after}&delivery_before=${this.filter.delivery_before}&seller=${this.filter.seller}&in_prestage=true&prestage__id=${this.filter.stage}`

      await fetchProductionProducts(query)
      .then((data)=>{
        this.products = data.results
        if (this.products.length===0){
          notifications.showInfo('No se encontraron productos para exportar')
          this.loading=false
          return false
        }
        this.products.forEach(w=>{
          w.work_order__created_at=moment(w.work_order__created_at).format("DD/MM/yyyy HH:mm")
          w.work_order__delivery_date=moment(w.work_order__delivery_date).format("DD/MM/yyyy HH:mm")
          w.stage=w.prestage.name
        })
        this.dataReady=true
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Productos Exportados')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Productos')
      this.dataReady=false
    }
  }

}
</script>