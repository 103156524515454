//Usado en el Create Customer y en Filtros 
const tipoCustomer =[{
  value:"A",
  text:"A / Cautivo"
},
{
  value:"B",
  text:"B / Recurrente"
},
{
  value:"C",
  text:"C / Nuevo"
},
{
  value:"D",
  text:"D / Prospecto"
},
]

export default tipoCustomer;
