<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo"><v-icon class="mr-2 align-items-center">fas fa-shopping-basket</v-icon>{{id>0?`Actualizar Orden de Compra #${id}`:`Crear Orden de Compra`}}</h1>
      <h1 class="text text-h5 titulo">{{tipo?'Artículos':'Servicios'}}</h1>
    </v-col>
    <v-col class="text-end">
      <v-btn text @click.stop="$router.push({name:'PurchaseOrders'})"><v-icon>fas fa-chevron-left</v-icon></v-btn>
    </v-col>
    </v-row>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(confirm)" ref="formulario" @keydown="return event.key != 'Enter';">

        <v-card class="pa-4"> 
          <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Proveedor" rules="required" v-slot="{ errors }">
              <provider-complete 
              v-model="provider" 
              :error_messages="errors" 
              :provider_name="provider_name" 
              @added="addProvider"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="3">
            <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
              <v-select
              v-model="tipo"
              :items="tipos"
              :loading="loading"
              :disabled="loading"
              item-text="text"
              item-value="value"
              label="Tipo de Orden"
              single-line
              outlined
              :error-messages="errors"
              @change="articles=[]"
              >
              <template v-slot:item="data">
                <div class="d-flex">
                  <div class="text mr-2"><v-icon class="mr-2 align-items-center">{{data.item.value?'fas fa-boxes':'fa fa-tools'}}</v-icon>{{data.item.text}} </div>
                </div>
              </template>
              <template v-slot:selection="data">
                <div class="d-flex">
                  <div class="text mr-2"><v-icon class="mr-2 align-items-center">{{data.item.value?'fas fa-boxes':'fa fa-tools'}}</v-icon>{{data.item.text}} </div>
                </div>
              </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="3">
            <ValidationProvider name="Centro de Costo" rules="required" v-slot="{ errors }">
              <v-select
              v-model="center"
              :items="centers"
              :loading="loading"
              :disabled="loading"
              item-text="name"
              item-value="id"
              label="Centro"
              placeholder="Centro de Costo"
              single-line
              outlined
              :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" >
          <ValidationProvider name="Condición de Pago" rules="required" v-slot="{ errors }">
            <v-text-field
              v-model='provider.payment'
              label="Condición de Pago"
              :min="0"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col cols="12" md="3" >
          <ValidationProvider name="Moneda" rules="required" v-slot="{ errors }">
            <v-text-field
              v-model='currency'
              label="Moneda"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" >
          <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
            <v-text-field
              v-model='title'
              label="Referencia"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4" >
            <v-checkbox
              v-model="linkToWorkOrder"
              label="Vincular a Orden de Trabajo"
              @change="()=>{
                if(!linkToWorkOrder){
                  work_order=''
                  work_order_details=''
                  work_order_finded=false
                }
              }"
              
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="3" v-if="linkToWorkOrder">
            <v-text-field
              v-model='work_order'
              label="Orden de Trabajo"
              outlined
              :append-icon="work_order_finded?'fas fa-check':'fas fa-search'"
              :loading="loadingWorkOrder"
              @keydown="searchWorkOrder"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5" v-if="linkToWorkOrder&&work_order_finded">
            <v-textarea
              v-model='work_order_details'
              label="Detalles"
              :readonly="true"
              outlined
              rows="3"
              no-resize
              overflow="auto"
              :loading="loadingWorkOrder"
            ></v-textarea>
          </v-col>
        </v-row>
        
        </v-card>
        <v-card class="pa-4 mt-4 mb-6">
          <v-row class="mt-4 align-center">
            <v-col cols="12" md="4" v-if="tipo">
              <h1 class="text text-h5 titulo"><v-icon class="mr-2 align-items-center">fas fa-plus</v-icon>Artículos</h1>
            </v-col>
            <v-col cols="12" md="4" v-else>
              <h1 class="text text-h5 titulo"><v-icon class="mr-2 align-items-center">fas fa-plus</v-icon>Servicios</h1>
              
            </v-col>
            <v-col cols="12" md="8" v-if="tipo">
              <add-article @add="addArticle" />
            </v-col>
            <v-col cols="12" md="8" v-else>
              <div class="d-flex align-center">
                <v-text-field
                  v-model='service'
                  label="Servicio"
                  placeholder="Ingrese el Nombre del Servicio"
                  hide-details
                  outlined
                  @keydown.enter.prevent="addService"
                ></v-text-field>
                <v-btn @click="addService" class="ml-2"><v-icon>fas fa-plus</v-icon></v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
              :headers="filteredHeaders"
              :items="articles"
              :single-expand="true"
              item-key="name"
              show-expand
              class=""
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row align="center" class="py-2">
                      <v-col cols="12" md="4" class="align-content-center" v-if="tipo">
                      <div class="d-block">
                        Precio Ult. Compra: <strong>{{item.last_buy_price | currency('$', 0)}}</strong>
                      </div>
                      <div class="d-block">
                        Cant. Ult. Compra: <strong>{{item.last_buy_qty}}</strong>
                      </div>
                      <div class="d-block">
                        Fecha Ult. Compra: <strong>{{item.last_buy_date | moment("DD/MM/YYYY")}}</strong>
                      </div>
                      </v-col>
                      <v-col align-items-center>
                        <v-text-field
                        v-model='item.notes'
                        placeholder="Observaciones"
                        hide-details
                        solo
                        dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </td>
                </template>
                <template v-slot:no-data>
                  <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                    Agregue Artículos o Servicios
                  </v-alert>
                </template>
                <template v-slot:item.unit_name="{ item }">
                  <div v-if="tipo">
                    {{item.unit_name}}
                  </div>
                </template>
                <template v-slot:item.stock="{ item }">
                  <div v-if="tipo">
                    {{item.total_stock | number('0,0[.]0')}} 
                      <v-menu bottom left offset-y> 
                      <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs"
                          v-on="on"
                          text
                        small class="ml-2">fas fa-chevron-down</v-icon>
                      </template>
                      <v-card class="pa-2">
                        <div v-if="item.stocks.length>0">
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th>
                                  Bodega
                                </th>
                                <th>
                                  Existencia
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for='register in item.stocks' :key='register.id'>
                                <td :class="register.add_stock?'':'error--text'">
                                  {{register.store_name}}
                                </td>
                                <td class="text-end" :class="register.add_stock?'':'error--text'">
                                  {{register.qty | number('0,0[.]0')}}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                        <h3 v-else class="text text-center pa-4">
                          No hay existencias
                        </h3>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                <template v-slot:item.qty="{ item }">
                  <v-text-field
                    type="number"
                    v-model='item.qty'
                    :min="1"
                    hide-details
                    solo
                    dense
                    ></v-text-field>
                </template>
                <template v-slot:item.price="{ item }">
                  <v-text-field
                  type="number"
                  v-model='item.price'
                  :min="0"
                  solo
                  dense
                  hide-details
                  ></v-text-field>
                </template>
                <template v-slot:item.total="{ item }">
                  {{item.price*item.qty | currency('$')}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon class="error--text" small @click.stop="removeArticle(item)">fas fa-trash</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

        </v-card>
        <v-row justify="end">
          <v-col cols="12" md="6">
            <v-textarea
              v-model="notes"
              label="Observaciones"
              outlined
              rows="7"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="pa-4">
            <v-simple-table dense class="pa-0" width="100%">
              <tbody>
                <tr>
                  <td>Sub Total</td>
                  <td></td>
                  <td class="text-end strip-header"><strong>{{ neto | currency('$')}}</strong></td>
                </tr>
                <tr>
                  <td>Descuento</td>
                  <td>
                    <div max-width="500px" class="mr-4 text-end">
                        <v-text-field 
                          solo
                          dense
                          hide-details
                          class='discount mx-auto px-0 py-1'
                          type='number' 
                          append-icon="fas fa-percent"
                          v-model="discount"
                          :min="0"
                        />
                      </div>
                  </td>
                  <td class="strip-header text-end">
                    <strong>({{neto*discount/100 | currency('$')}})</strong>
                  </td>
                </tr>
                <tr>
                  <td>Neto</td>
                  <td></td>
                  <td class="text-end strip-header"><strong>{{neto-neto*discount/100| currency('$')}}</strong></td>
                </tr>
                <tr>
                  <td>IVA</td>
                  <td>
                    <div max-width="500px" class="mr-4 text-end">
                        <v-text-field 
                          solo
                          dense
                          hide-details
                          class='discount mx-auto px-0 py-1'
                          append-icon="fas fa-percent"
                          type='number' 
                          v-model="iva"
                          :min="0"
                        />
                      </div>
                  </td>
                  <td class="text-end strip-header"><strong class="">{{(neto-neto*discount/100)*iva*0.01| currency('$') }}</strong></td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td class="text-end strip-header"><strong class="purple--text">{{(neto-neto*discount*0.01)*(1+iva*0.01)| currency('$')  }}</strong></td>
                </tr>
              </tbody>
            </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center mt-4">
            <v-btn color="success" type="submit" :disabled="false" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-dialog 
    v-model="showProgress"
    persistent
    max-width="500"
    >
      <v-card class="pa-10"> 
        <v-card-title class="titulo text-h4">Registrando...</v-card-title>
        <v-row>
          <v-col>
            <v-progress-linear v-model="progress" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center">
            <h1 class="text text-h4 titulo">{{progress | number('0.0')}}%</h1>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <confirm 
    v-model="showConfirm" 
    :message="id>0?'Se actualizadá la Orden de Compra #'+id:'Se creará una nueva Orden de Compra'" 
    @ok="create"
    @cancel="showConfirm=false"/>
  </v-container>
</template>

<script>
import {fetchCenters,createPurchase,getPurchase,getArticle,updatePurchase} from '@/api/stocks'
import {fetchCompany} from '@/api/parameters'
import {getWorkOrder} from '@/api/production'
import ProviderComplete from '@/components/stocks/ProviderComplete'
import AddArticle from '@/components/stocks/AddArticle'
import notifications from '@/utils/notifications'
import Vue from 'vue'
import moment from 'moment'

export default {
  name:'CreatePurchaseOrder',
  components:{AddArticle,ProviderComplete},
  data() {
    return {
      loading: false,
      showConfirm:false,
      discount:0,
      provider:'',
      provider_name:'',
      payment:'',
      iva:parseInt(this.$store.getters.getCompany.tax),
      currency:'',
      centers:[],
      center:'',
      notes:'',
      title:'',
      service:'',
      articles:[],
      progress:'',
      showProgress:false,
      id:-1,
      tipo:true,
      linkToWorkOrder:false,
      work_order:'',
      work_order_details:'',
      loadingWorkOrder:false,
      work_order_finded:false,
      timer:'',
      tipos:[
        {
          value:true,
          text:'Artículos'
        },
        {
          value:false,
          text:'Servicios'
        },
      ],
      headers:[
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'sku',
          show:false,
          class:'table-header'
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name',
          show:true,
          class:'table-header'
        },
        {
          text: 'Unidad',
          align: 'start',
          sortable: false,
          value: 'unit_name',
          show:false,
          class:'table-header'
        },
        {
          text: 'Existencia',
          align: 'start',
          sortable: false,
          value: 'stock',
          show:false,
          class:'table-header'
        },
        {
          text: 'Cantidad',
          align: 'start',
          sortable: false,
          value: 'qty',
          width:'10%',
          show:true,
          class:'table-header'
        },
        {
          text: 'Precio Unitario',
          align: 'start',
          sortable: false,
          value: 'price',
          show:true,
          width:'12%',
          class:'table-header'
        },
        {
          text: 'Total',
          align: 'end',
          sortable: false,
          value: 'total',
          show:true,
          class:'table-header'
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          show:true,
          class:'table-header'
        },
        ]
    }
  },
  methods:{
    storeStock(item){
      const stock=item.stocks.filter(s=>s.store==this.store)
      if(stock.length==0) return {qty:0,id:-1}
      else return {qty: stock[0].qty,id:stock[0].id}
    },
    totalStock(item){
      return item.stocks.filter(i=>i.add_stock).reduce((acc, val)=>{ return acc + parseInt(val.qty) }, 0)
    },
    addArticle(item){
      //Busco los stocks, el total y el ID del registro
      const storeStock=this.storeStock(item)
      item.store_stock=storeStock.qty
      item.store_stock_id=storeStock.id
      item.total_stock=this.totalStock(item)
      item.qty=0
      item.price=0
      const alreadyExist=this.articles.filter(a=>a.id==item.id).length
      if(alreadyExist<1){
        this.articles.push(item)
      }
    },
    addService(){
      if(this.service){
        this.articles.push({
        id:this.service,
        sku:'',
        name:this.service,
        qty:1,
        price:0,
        notes:'',
        stocks:[],
        store_stock:[],
        })
      }
      this.service=''
    },
    removeArticle(item){
      this.articles=this.articles.filter(i=>i.id!=item.id)
    },
    confirm(){
      //Valida que existan artículos en la OC
      if(this.articles.length<=0){
        notifications.showError('Deben existir items en la Orden')
        return 0
      }
      this.showConfirm=true
    },
    save(){
      console.log('Guardando')
    },
    searchWorkOrder() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(async () => {
          this.loadingWorkOrder = true
          await getWorkOrder(this.work_order)
            .then((data)=>{
              this.work_order_finded=true 
              this.loadingWorkOrder=false
              const {customer_name,created_at,name}=data
              this.work_order_details=`${customer_name} ${moment(created_at).format('DD/MM/yyy')} ${name}`
            })
            .catch((e)=>{
              notifications.showInfo('Orden de Trabajo no encontrada')
              this.work_order_finded=false
              this.loadingWorkOrder=false
            })
      }, 800);
    },
    async create(){
      if(this.articles.length<=0){
        notifications.showError('Deben existir items en la Orden')
        return 0
      }
      this.loading=true
      this.showConfirm=false
      //Getting de Limit Parameter
      const {stocks_po_approve_limit}=this.$store.getters.getCompany
      let details=''
      //Preparing Articles Data
      if(this.tipo){
        details = this.articles.map(item=>{
          return {
            article: item.id,
            qty: parseFloat(item.qty).toFixed(2),
            price: parseFloat(item.price).toFixed(2),
            notes:item.notes
          }
        })
      }
      else{
        details = this.articles.map(item=>{
          return {
            name: item.name,
            qty: parseFloat(item.qty).toFixed(2),
            price: parseFloat(item.price).toFixed(2),
            notes:item.notes
          }
        })
      }
      
      console.log(details)

       //Preparing Main Data
      let data={
        provider:this.provider.id,
        payment:this.provider.payment,
        title:this.title,
        notes:this.notes,
        moneda:this.currency,
        discount:parseFloat(this.discount).toFixed(2),
        stock_service:this.tipo,
        center:this.center,
        details:details,
        iva:this.iva,
        estatus:this.neto*(1-this.discount/100)>stocks_po_approve_limit?1:2
      }
      if(this.work_order_finded) data={...data,work_order:this.work_order}
      else data={...data,work_order:null}
      //Chequeo si id es menor que cero para crear una nueva OC
      if(this.id<0){
        await createPurchase(data)
        .then((res)=>{
          notifications.showSuccess('Orden de Compra '+res.id+' creada')
          this.$router.go(-1)
        })
        .catch(err=>{
          notifications.showError('Error creando Orden de compra '+err)
        })
      }
      else{
        console.log(data)
        await updatePurchase(this.id,data,'force=true')
        .then((res)=>{
          notifications.showSuccess('Orden de Compra '+res.id+' actualizada')
          this.$router.go(-1)
        })
        .catch(err=>{
          notifications.showError('Error actualizando Orden de compra '+err)
        })
      }
      this.loading=false
    },
    addProvider(item){
      console.log(item)
      this.provider=item
      this.provider_name=item.name
      this.payment=item.payment
    }
  },
  computed:{
    neto(){
      return this.articles.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0) 
    },
    filteredHeaders(){
      return this.headers.filter(h=>h.show||this.tipo)
    }
    
  },
  async mounted(){
    console.log(this.$route)
    const {default_currency}= this.$store.getters.getCompany
    this.currency=default_currency
    if (this.$route.params.id>0){
      this.id=this.$route.params.id
      await getPurchase(this.id)
      .then((ocData)=>{
        this.provider={id:ocData.provider}
        this.provider_name=ocData.provider_name
        this.provider.payment=ocData.payment
        this.currency=ocData.moneda
        this.center=ocData.center
        this.notes=ocData.notes
        this.title=ocData.title
        this.tipo=ocData.stock_service
        this.iva=parseInt(ocData.iva)
        if (this.tipo){
          ocData.details.forEach(async item=>{
            await getArticle(item.article)
            .then(data=>{
              data.total_stock=this.totalStock(data)
              data.qty=item.qty
              data.price=parseInt(item.price)
              data.notes=item.notes
              this.articles.push(data)
            })
            .catch(err=>{throw err})
          })
        }
        else {
          ocData.details.forEach(item=>{
            this.articles.push(item)
          })
        }
        //Reviso si tiene OT vinculada
        if(ocData.work_order){
          this.work_order=ocData.work_order
          this.linkToWorkOrder=true
          this.work_order_finded=true
          this.searchWorkOrder()
        }
      })
      .catch(err=>{
        notifications.showError('Error cargando Orden de Compra '+err)
      })
    }
    if(this.$route.params.duplicate){
      this.title='(Copia)'+this.title 
      this.id=-1
    }
    this.centers=await fetchCenters()
  }
}
</script>

<style lang="scss" >
.discount{
    width: 6rem !important;
    .v-input__control{
      .v-text-field__details{
      min-height: 0px !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
      .v-messages{
      min-height: 0px !important;
      }
    }
    }
  }
.titulo{
  color:#707070;
  font-size: medium !important;
}
</style>