<template>
<div>
    <v-btn text small class="dark_blue--text" @click="openDialog" :loading="loading">
        <v-icon class="mr-2">fas fa-truck-fast</v-icon>
        Distribucion
    </v-btn>
    
    <v-dialog persistent v-model="display" max-width="1200">
        <v-card class="py-4">
            <v-card-title>
                
                    <div class="titulo text-h5 mr-2">
                        Lista de Distribución 
                        [{{sales_note.id}}]
                    </div>
            </v-card-title>
            <v-card-text class="mt-2">
                <v-row class="my-4">
                    <v-col class="align-items-center">
                        <v-file-input
                                v-model=file
                                accept = '.csv'
                                prepend-icon="fas fa-cloud-upload-alt"
                                outlined
                                show-size
                                hint="Selecciona el archivo CSV a importar"
                                persistent-hint
                                dense
                                @change="onFileChange"
                            />
                    </v-col>
                    <v-col class="justify-center">
                        <export-distribution :items="items" :salesnote="sales_note.id" />
                    </v-col>

                </v-row>
                <v-simple-table>
                    <thead v-show="items.length>0">
                        <th class="table-header2">
                            RUT
                        </th>
                        <th class="table-header2">
                            Cliente
                        </th>
                        <th class="table-header2">
                            Pedido
                        </th>
                        <th class="table-header2">
                            Despachado
                        </th>
                        <th class="table-header2">
                            Devuelto
                        </th>
                        <th class="table-header2">
                            Precio
                        </th>
                        <th class="table-header2">
                            Por Facturar
                        </th>
                        <th class="table-header2">
                            Total
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in items" :key="index" class="py-2">
                            <td width="550" class="text-start">
                                
                                <v-text-field
                                    
                                    v-model="item.customer_rut"
                                    
                                    
                                    outlined
                                    hide-details 
                                    dense
                                      
                                />
                                <!-- <customer-complete2 v-else v-model="item.customer"/> -->
                            </td>
                            <td width="550">
                                
                                <v-text-field
                                    
                                    v-model="item.customer_name"
                                    
                                    required
                                    outlined
                                    hide-details 
                                    dense
                                      
                                />
                                <!-- <customer-complete2 v-else v-model="item.customer"/> -->
                            </td>
                            <td width="250">
                                <v-text-field
                                    v-model="item.ordered"
                                    placeholder="Pedido"
                                    type="number"
                                    min="1"
                                    required
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td width="250">
                                <v-text-field
                                    v-model="item.delivered"
                                    placeholder="Despachado"
                                    type="number"
                                    min="1"
                                    required
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td width="250">
                                <v-text-field
                                    v-model="item.returned"
                                    placeholder="Retornado"
                                    type="number"
                                    min="1"
                                    required
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td width="300">
                                <v-text-field
                                    v-model="item.price"
                                    placeholder="Precio"
                                    type="number"
                                    min="1"
                                    required
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td width="250" class="text-end">
                                <v-text-field
                                    :value="item.delivered-item.returned"
                                    placeholder="Facturar"
                                    type="number"
                                    min="1"
                                    filled
                                    readonly
                                    outlined
                                    hide-details 
                                    dense   
                                />
                                
                            </td>
                            <td width="250" class="text-end">
                                {{(item.delivered-item.returned) * item.price | currency('$')}}
                            </td>
                            <td>
                                <v-icon class="error--text" x-small @click.stop="removeItem(item)">fas fa-trash</v-icon>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7" class="text-center pa-4">
                                <v-btn 
                                fab 
                                x-small 
                                class="pa-4" 
                                color="primary" 
                                @click.stop="addRow"
                                title="Agregar fila">
                                    <v-icon>fas fa-add</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        <tr>
                            <td class="titulo text-end" colspan="2">
                                Total
                            </td>
                            <td>
                                <v-text-field
                                    v-model="total_ordered"
                                    placeholder="Total Pedido"
                                    type="number"
                                    min="1"
                                    filled
                                    readonly
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td>
                                <v-text-field
                                    :value="total_delivered"
                                    placeholder="Total Pedido"
                                    type="number"
                                    min="1"
                                    filled
                                    readonly
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td>
                                <v-text-field
                                    :value="total_returned"
                                    placeholder="Total Pedido"
                                    type="number"
                                    min="1"
                                    filled
                                    readonly
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td></td>
                            <td>
                                <v-text-field
                                    :value="total_delivered-total_returned"
                                    placeholder="Total Pedido"
                                    type="number"
                                    min="1"
                                    filled
                                    readonly
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td class="text-end">
                                {{total | currency('$')}}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-divider></v-divider>
                
                <v-divider></v-divider>
            </v-card-text>    
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="saving" @click="save" :disabled="cannotSave">Guardar</v-btn>
                <v-btn text  
                @click="cancel"
                :disabled="loading"
                >Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>

</template>
<script>
import {fetchDistribution,createDistribution} from '@/api/salesNotes'
import CustomerComplete2 from '@/components/customers/CustomerComplete2.vue';
import notifications from '@/utils/notifications';
import ExportDistribution from '@/components/sales/ExportDistribution.vue';
export default {
    name: 'DistributionList',
    components: {
        CustomerComplete2,
        ExportDistribution
    },
    props:['sales_note'],
    data() {
        return {
            display: false,
            items:[{
                id:-1,
                customer_rut:'',
                customer_name:'',
                ordered:'',
                delivered:'',
                returned:'',
                price:'',
            }],
            loading: false,
            saving:false,
            file:''
        }
    },
    methods: {
        onFileChange(){
        const config={
            header: true,
            complete: (results) =>{
             const {data} = results;
                const newItems = data.map(item=>{
                    return {
                        id:1,
                        customer_rut:item.rut,
                        customer_name:item.cliente,
                        ordered:item.pedido,
                        delivered:item.despachado,
                        returned:item.devuelto,
                        price:item.precio,
                    }
                }).filter(item=>item.customer!='');
                this.items = [...this.items,...newItems];
            },
            skipEmptyLines: true
        }
        this.$papa.parse(this.file, config)
        },
        addRow(){
            this.items.push({
                customer_rut:'',
                customer_name:'',
                ordered:'',
                delivered:'',
                returned:'',
                price:'',
            });
        },
        async openDialog(){
            if(this.sales_note.id){
                this.loading = true;
                const query = `salesnote=${this.sales_note.id}`;
                await fetchDistribution(query)
                .then(data => {
                    this.items = data.results;
                    notifications.showSuccess('Lista cargada correctamente');
                })
                .catch(error => {
                    notifications.showError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
            }
            this.display = true;
        },
        cancel(){
            this.display=false;
        },
        async save(){
            this.saving=true;
            //Filtrando los items que no tienen cliente
            this.items = this.items.filter(item => item.customer!='');
            const data ={
                salesnote_id:this.sales_note.id,
                distribution_data:this.items.map((item)=>({
                        customer_name:item.customer_name,
                        customer_rut:item.customer_rut,
                        ordered:item.ordered||0,
                        delivered:item.delivered||0,
                        returned:item.returned||0,
                        price:item.price||0,
                    }))
            }
            console.log(data);
            await createDistribution(data)
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                notifications.showError(error);
            })
            .finally(() => {
                this.saving=false;
                this.display=false;
            });
        },
        removeItem(item){
            this.items.splice(this.items.indexOf(item),1)
        },
    },
    computed:{
        cannotSave(){
            return false 
        },
        total_ordered(){
            return this.items.reduce((total,item)=>total+parseFloat(item.ordered),0)
        },
        total_delivered(){
            return this.items.reduce((total,item)=>total+parseFloat(item.delivered),0)
        },
        total_returned(){
            return this.items.reduce((total,item)=>total+parseFloat(item.returned),0)
        },
        total(){
            return this.items.reduce((total,item)=>total+parseFloat(item.price)*(item.delivered-item.returned),0)
        }
    }
}

</script>