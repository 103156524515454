<template>
  <v-data-table
  dense
  :headers="headers"
  :items="value"
  :loading="loading"
  >
  <template v-slot:top>
    <div class="text-end">
      <contact-create-update-2 class="mb-4" :action="'create'" @created="fetch" :customerId="customerId"/>
    </div>
  </template>

  <template v-slot:item.name="{ item }">
    {{item.first_name }} {{item.last_name}}  
  </template>

  <!--Menu de acciones-->
  <template v-slot:item.actions="{item}">
    <contact-create-update-2 :action="'edit'" v-model="item" @updated="fetch" @deleted="fetch" :customerId="customerId"/>
  </template>
  </v-data-table>

</template>

<script>
import ContactCreateUpdate2 from './ContactCreateUpdate2.vue';
import {fetchContacts} from '@/api/customers';
import notifications from '@/utils/notifications';
export default {
  name:'ContactList',
  components:{
    ContactCreateUpdate2
  },
  props:{
    value:{
      type:Array,
    },
    customerId:{
      type:Number,
      required:false,
    },
    embeded:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      headers:[],
      loading:false
    };
  },
  methods:{
    fetch(){
      this.loading=true;
      var query=`?customer=${this.customerId}`;
      fetchContacts(query)
      .then(response=>{
        this.$emit('input',response);
      })
      .catch(error=>{
        console.log(error);
        notifications.showError('Error','No se pudo cargar los contactos del cliente')
      })
      .finally(()=>{
        this.loading=false;
      })

    },
  },

  mounted(){
    this.headers=this.embeded? [
      { text: 'Nombre', value: 'name' },
      { text: 'Correo Electrónico', value: 'email' },
      { text:'Teléfono',value:'phone'},
      { text:'Cargo',value:'cargo'},
      { text: '', value: 'actions', sortable: false,align:'end',width:'10px'}
    ]:
    [
      { text: 'Embeded', value: 'name' },
      { text: 'Correo Electrónico', value: 'email' },
      {text:'Teléfono',value:'phone'},
      {text:'Cargo',value:'cargo'},
      { text: '', value: 'actions', sortable: false}
    ]
    
  }
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>