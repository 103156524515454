<template>
  <v-container>
    <v-card
    color=""
  >
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="value"
              :items="accessList"
              chips
              outlined
              label="Seleccione Accesos"
              item-text="name"
              item-value='id'
              multiple
              >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  color="blue lighten-4"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.subject"></v-list-item-subtitle>
                  </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
  </v-container>
</template>

<script>
  import {fetchAccess} from '@/api/auth'
  import notifications from '@/utils/notifications'
  import access_list from '@/utils/access_list'
  export default {
    data () {
      return {
        accessList:access_list,
        autoUpdate: true,
        isUpdating: false,
      }
    },
    props:['value'],
    watch: {
      value(){
        this.$emit('input',this.value)
      }
    },
    methods: {
      remove (item) {
        const index = this.value.indexOf(item.id)
        if (index >= 0) this.value.splice(index, 1)
      },
      updateValue(){
        this.value ? this.$emit('input',this.value) : this.$emit('input','')
      },
    },
    async created(){
      /* this.accessList=await fetchAccess()
      .catch((e)=>notifications.showError('Error de conexión ' + e )) */
    }
  }
</script>