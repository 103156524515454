import axios from "axios"

export async function getFullData(id){
    return await axios.get(`softnet/data?sales_note=${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e.response.data.error
        })
}

export async function createDocument(data){
    console.log(data)
    return await axios.post("softnet/document", data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            console.log(e.response.data)
            if (e.response.data.error) {
                throw e.response.data.error
            }
            else throw e.response.data
        })
}

export async function fetchDocuments(query){
    return await axios.get("softnet/document?" + query)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e.response.data.error
        })
}

export async function getDocument(id){
    return await axios.get("softnet/document/" +id )
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e.response.data.error
        })
}