var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-row',{staticClass:"mb-4",attrs:{"justify":"space-between","align-content":"center"}},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Lista de Distribución")]),_c('export-distribution-list',{attrs:{"filter":_vm.filter,"options":_vm.options,"disabled":_vm.loading,"search":_vm.search}})],1),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"align-self":"center","cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"hide-details":"","disabled":_vm.loading,"dense":"","outlined":"","clearable":"","type":"date","label":"Creada Desde:"},on:{"change":function (){_vm.options.page=1;_vm.fetch()}},model:{value:(_vm.filter.created_after),callback:function ($$v) {_vm.$set(_vm.filter, "created_after", $$v)},expression:"filter.created_after"}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-text-field',{attrs:{"hide-details":"","disabled":_vm.loading,"dense":"","clearable":"","outlined":"","type":"date","label":"Creada Hasta:"},on:{"change":function (){_vm.options.page=1;_vm.fetch()}},model:{value:(_vm.filter.created_before),callback:function ($$v) {_vm.$set(_vm.filter, "created_before", $$v)},expression:"filter.created_before"}})],1),_c('v-col',{attrs:{"justify-end":"","cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading,"hide-details":""},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('div',[_c('v-data-table',{staticClass:"pa-4",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"search":_vm.search,"sort-by":"created_at","sort-desc":false,"single-expand":true,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        itemsPerPageOptions: [10, 20, 30, 40, 50,100],
      }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch]},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY")))])]}},{key:"item.customer",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"caption mb-1"},[_vm._v(_vm._s(item.customer_rut))]),_c('div',{staticClass:"caption mb-1"},[_vm._v(_vm._s(item.customer_name))])]}},{key:"item.workorder",fn:function(ref){
      var item = ref.item;
return [_c('show-work-order',{attrs:{"work_order_id":item.workorder}},[_c('div',{staticClass:"caption mb-1"},[_vm._v(_vm._s(item.name))]),_vm._v(" "+_vm._s(item.workorder)+" ")])]}},{key:"item.price",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price,'$',0))+" ")]}},{key:"item.ordered",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.ordered,'0,0'))+" ")]}},{key:"item.delivered",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.delivered,'0,0'))+" ")]}},{key:"item.returned",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.returned,'0,0'))+" ")]}},{key:"item.toinvoice",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.delivered - item.returned,'0,0'))+" ")]}},{key:"item.total",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")((item.delivered - item.returned)*item.price,'$',0))+" ")]}},{key:"item.created_by_name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.created_by_name))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }