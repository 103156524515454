<template>
  <v-container class="fill-height" >
      <v-card class="pa-4 mx-auto" max-width="500">
        <v-img
          max-height="100"
          max-width="100"
          class="mx-auto"
          src="@/assets/runflow_logo.png"
        ></v-img>
        <v-form v-if="!sent&&validated&&!loading" @submit.prevent="changePassword">
          <v-card-title>
            Cambio de Contraseña
          </v-card-title>
          <v-card-text>
              <v-text-field
                outlined
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword1 = !showPassword1"
                placeholder="Ingrese su Contraseña"
                v-model="password1"
                :type="showPassword1 ? 'text' : 'password'"
              ></v-text-field>
              <v-text-field
                outlined
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword2 = !showPassword2"
                placeholder="Reingrese su Contraseña"
                v-model="password2"
                :type="showPassword2 ? 'text' : 'password'"
              ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
            <v-btn :loading="loading" type="submit">Enviar</v-btn>
            <v-btn text>Regresar</v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
        <div v-if="sent&&success">
          <p class="text text-h6 mb-4">Exito! Su contraseña ha sido cambiada</p>
          <v-card-actions>
            <v-row justify="center">
              <v-btn @click="$router.push('/auth/login')">Aceptar</v-btn>
            </v-row>
          </v-card-actions>
        </div>
        <div v-if="sent&&error">
          <p class="text text-h6 mb-4">Ha ocurrido un error. {{message}}</p>
          <v-card-actions>
            <v-row justify="center">
              <v-btn @click='retry'>Reintentar</v-btn>
              <v-btn @click="$router.push('/auth/login')" text>Salir</v-btn>
            </v-row>
          </v-card-actions>
        </div>
      </v-card>
  </v-container>
</template>
<script>
import {passwordResetValidate,passwordReset} from '@/api/auth'
import notifications from '@/utils/notifications'
export default {
  name:'PasswordResetRequest',
  data: ()=>({
    loading:false,
    password1:'',
    password2:'',
    showPassword1:false,
    showPassword2:false,
    sent:false,
    success:false,
    error:false,
    validated:false,
    message:'',
    token:''
  }),
  async created(){
    console.log(this.$route.params.token)
    //Verificación del token
    if(this.$route.params.token){
      this.token=this.$route.params.token
      this.loading=true
      await passwordResetValidate(this.token)
      .then(()=>{
        this.loading=false
        this.validated=true
      })
      .catch((e)=>{
        this.sent=true
        this.loading=false
        this.message='Error de Validación. Por favor envíe su correo nuevamente.'
        this.error=true
      })
    }
    else{
      this.loading=false
      this.error=true
    }
  },
  methods:{
    async changePassword(){
      this.sent=true
      this.loading=true
      //Detecta si los passwords son diferentes
      if(this.password1!=this.password2){
        this.loading=false
        this.message="Las Contraseñas deben coincidir"
        this.error=true
      }
      else{
        await passwordReset({token: this.token, password:this.password1})
        .then(()=>{
          this.loading=false
          this.success=true
          notifications.showSuccess('La Constraseña ha sido cambiada exitosamente')
          this.$router.push('/auth/login')
        })
        .catch((e)=>{
          this.loading=false
          console.log(e)
          notifications.showError('Error actualizando su contraseña. Intente nuevamente' +' '+e.response.data.password[0])
          this.$router.push('/auth/login')
        })
      }
    },
    retry(){
      this.password1=''
      this.password2=''
      this.loading=false
      this.error=false
      this.sent=false
    }
  },
  
}
</script>