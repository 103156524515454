<template>
  <div>
    <!-- <v-radio-group 
      v-model="optionSelected" 
      class="py-0"
      row
      dense
      hide-details
    >
      <div v-for="data in dataRange" :key="data.text">
        <v-radio
          :label="data.text"
          :value="data"
        ></v-radio>
      </div>
    </v-radio-group> -->
    <div class="titulo text text-h6 py-4">Salidas</div>
      <vertical-bar-chart
        :chart-data="datacollection"
        :options='chartOptions'
        :height="150"
      />
  </div>
</template>

<script>
import {fetchTransactions} from '@/api/stocks'
import VerticalBarChart from '@/components/charts/VerticalBarChart'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:'ArticleOutGraph',
  components:{VerticalBarChart},
  props:['datacollection'],
  data() {
    return {
      chartOptions:{
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: value => numeral(value).format('0,0[.]0')
          }
        }],
        y: {
          suggestedMin: 1000,
        }
      },
      tooltips: {
        mode: 'index',
        displayColors:true,
        callbacks: {
          label: function(context) {
            var label = numeral(context.value).format('0,0[.]0')
            return label;
          }
        }
      }
      },
      // dataRange:[
      //   {
      //   text:  moment(new Date().toString()).format('MMMM-YYYY'),
      //   after: moment(new Date().toString()).format('YYYY')+'-'+moment(new Date().toString()).format('MM')+'-01',
      //   before:moment(new Date().toString()).format('YYYY-MM-DD'),
      //   format:'DD-MM-YYYY',
      //   },
      //   {
      //     text:'30 días',
      //     after: moment(new Date().toString()).add(-1,'month').format('YYYY-MM-DD'),
      //     before:moment(new Date().toString()).format('YYYY-MM-DD'),
      //     format:'DD-MM-YYYY',
      //   },
      //   {
      //     text:'12 Meses',
      //     after:moment(new Date().toString()).add(-1,'year').format('YYYY-MM-DD'),
      //     before:moment(new Date().toString()).format('YYYY-MM-DD'),
      //     format:'MMM',
      //   }
      // ],
      // optionSelected:{},
    }
  },
  methods:{
    // async fetch(){
    //   this.loading=true
    //   //Arreglando variables de fecha en caso de ser null
    //   const query=`tarticle=${this.article}&created_after=${this.optionSelected.after}&created_before=${this.optionSelected.before}`
    //   await fetchTransactions(query)
    //   .then(res=>{
    //     console.log(res)
    //     this.datacollection={
    //       labels: res.map(d=>moment(d.created_at).format('DD/MM/YY')),
    //       datasets: [
    //         {
    //           label: 'Precio Promedio',
    //           borderColor: '#88c4e1',
    //           data: res.map(d=>d.calculated_avg)
    //         }
    //       ]
    //     }
    //     this.loading=false
    //   })
    // }
  },
  async created(){
    // this.optionSelected = this.dataRange[0]
    // this.fetch()
  },
}
</script>

<style>

</style>