<template>
<div>
  <v-btn color="primary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="salesNotes"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>

      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchSalesNote} from '@/api/salesNotes'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
import rejectedReasons from '@/utils/rejectedReasons'
export default {
  name:"ExportSalesNotes",
  props:['filter','options'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      salesNotes:[],
      dataReady:false,
      origins:this.$store.getters.getOrigins,
      labels:{ 
        id:{title:'Nro'},
        created_at:{title:'Creada'},
        name: { title: 'Proyecto' },
        origin:{title:'Origen'},
        closing_date: { title: 'Fecha de Cierre' },
        customer_name:{title:'Cliente'},
        tipo:{title:'Tipo'},
        industry:{title:'Industria'},
        rubro:{title:'Rubro'},
        seller:{title:'Vendedor'},
        seller_bussiness_unit:{title:'Unidad de Negocio'},
        total_sale:{title:'Venta'},
        work_order:{title:'Orden de Trabajo'},
        status:{title:'Estado'},
        rejected_at:{title:'Fecha de rechazo'},
        rejected_by_full_name:{title:'Rechazada por'},
        rejected_reason:{title:'Motivo Rechazo'},
        rejected_note:{title:'Observaciones Rechazo'},
       },
       csvTitle:`export_cotizaciones_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = ''
      var closing_before = ''
      var closing_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
      if(this.filter.closing_after) closing_after = moment(this.filter.closing_after).format('yyyy-MM-DD')
      if(this.filter.closing_before) closing_before=moment(this.filter.closing_before).format('yyyy-MM-DD')
      //Tomando las opciones de
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      //Bloqueo solo a NV donde el usuario es el vendedor
      if (!this.$can('readAll', 'SalesNote')){
        const user = this.$store.getters.getUser
        this.filter.seller=user.id
      }
      await fetchSalesNote(
        sortDesc[0] ? `${sortBy[0]}` : `-${sortBy[0]}`,
        this.search,
        created_after,
        created_before,
        closing_after,
        closing_before,
        this.filter.customer.id,
        this.filter.seller,
        '',
        1,
        -1,
        this.filter.status?this.filter.status:'')
      .then((data)=>{
        this.salesNotes=data.results
        this.salesNotes.map(salesNote=>{
          salesNote.created_at=moment(salesNote.created_at).format("DD/MM/yyyy HH:mm")
          salesNote.closing_date=salesNote.closing_date ? moment(salesNote.closing_date).format("DD/MM/yyyy"):''
          salesNote.customer_name=salesNote.sales_note_customer.name
          salesNote.tipo=salesNote.sales_note_customer.tipo
          salesNote.industry=salesNote.sales_note_customer.industry
          salesNote.rubro=salesNote.sales_note_customer.rubro
          salesNote.seller=`${salesNote.sales_note_customer.seller.first_name} ${salesNote.sales_note_customer.seller.last_name}`
          salesNote.total_sale=numeral(salesNote.totals.total).format('0')
          salesNote.work_order=salesNote.work_order?salesNote.work_order:''
          salesNote.origin=this.origins.find(o=>o.id==salesNote.origin)?.name
          salesNote.origin=salesNote.origin?salesNote.origin:''
          salesNote.seller_bussiness_unit=salesNote.seller_bussiness_unit||''
          switch(salesNote.status){
            case 'pending':
              salesNote.status='Abierta'
              salesNote.rejected_at=''
              salesNote.rejected_by_full_name=''
              salesNote.rejected_reason=''
              salesNote.rejected_notes=''
              break
            case 'confirmed':
              salesNote.status='Ganada'
              salesNote.rejected_at=''
              salesNote.rejected_by_full_name=''
              salesNote.rejected_reason=''
              salesNote.rejected_note=''
              break
            case 'rejected':
              salesNote.status='Cerrada'
              salesNote.rejected_at=moment(salesNote.rejected_at).format("DD/MM/yyyy")
              salesNote.rejected_reason=rejectedReasons.find(r=>r.value==salesNote.rejected_reason)?.text
            } 
        })
        this.dataReady=true
      })
      .catch((error)=>{
        console.log(error)
        notifications.showError('Error', error)
      })
      .finally(()=>this.loading=false)
      
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Notas de Venta Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Notas de Venta')
      this.dataReady=false
    }
  }

}
</script>