<template>
  <v-container>
    <v-row class="mb-4" justify="space-between" align-content="center">
      <h1 class="text text-h4 titulo">Material Comprometido</h1>
      <export-supply-pending :data="supplies"/>
    </v-row>
    <v-card class="pa-4">
      <v-row justify="space-between" >
        <!-- <v-col  align-self="center">
            <v-select
              v-model="filter_group"
              :items="groups"
              item-text="text"
              item-value="value"
              label="Grupo"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              hide-details
              dense
              :disabled="loading"
            />
          </v-col> -->
          <v-col cols="12" md="3" class="d-flex align-end" >
            <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                max-width="290"
                hide-details
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :value="computedDateFormattedMomentjs"
                    label="Periodo Entrega"
                    dense
                    readonly
                    v-bind="attrs"
                    outlined
                    v-on="on"
                    @click:clear="date = null"
                    hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateRange"
                    @change="()=>{menuDate = false;fetch()}"
                    range
                ></v-date-picker>
                </v-menu>
                <v-btn x-small text class="ml-2" @click="clearDate">Limpiar</v-btn>
        </v-col>
        <v-col col="12" md="6" align-self="center">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table
        :headers="headers"
        :items="supplies"
        :search="search"
        sort-by="pending"
        :sort-desc="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
      >
        <template v-slot:item.qty="{item}">
            {{item.qty | number('0,0.00')}}
        </template>
        <template v-slot:item.consumed="{item}">
            {{item.consumed| number('0,0.00')}}
        </template>
        <template v-slot:item.pending="{item}">
          <strong :class="(item.pending)<0?'error--text':''">{{item.pending| number('0,0.00')}}</strong>
        </template>
        <template v-slot:item.article_sku="{item}">
          <a @click.prevent="$router.push({name:'DetailedArticle',params:{id:item.article_id}})">{{item.article_sku}}</a>
        </template>
        <template v-slot:item.stock="{item}">
          <strong :class="(item.stock)<0?'error--text':''">{{item.stock}}</strong>
        </template>
        </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {fetchSupplyOrders} from '@/api/reports'
import SalesProductGraph from '@/components/reports/SalesProductGraph'
import ExportSupplyPending from '@/components/reports/ExportSupplyPending'
import moment from 'moment'
import notifications from '../../utils/notifications'
export default {
  name:"SupplyOrdersPendingReport",
  components:{SalesProductGraph,ExportSupplyPending},
  created(){
    this.fetch()
  },
  data() {
    return {
      loading: false,
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
      },
      search:'',
      supplies:[],
      headers:[
          //{text: 'Código',align: 'start',value: 'sku',class: 'table-header'},
          {text: 'Descripción',align: 'start',value: 'name',class: 'table-header-2'},
          //{text: 'Grupo',value:'group',class: 'table-header-2'},
          {text: 'Unidades',align: 'end',value: 'unit',class: 'table-header-2'},
          {text: 'Cantidad',align: 'end',value: 'qty',class: 'table-header-2'},
          {text: 'Consumido',align: 'end',value: 'consumed',class: 'table-header-2'},
          {text: 'Pendiente',align: 'end',value: 'pending',class: 'table-header-2'},
          {text: 'Arti.',align: 'start',value: 'article_sku',class: 'table-header-2'},
          {text:'Exist.',align:'end',value:'stock',class:'table-header-2'},
        ],
    groups:[],
    filter_group:'',
    menuDate:false,
    dateRange:['', '']
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      var delivery_after = ''
      var delivery_before = ''
      if(this.dateRange[0]) delivery_after  = moment(new Date(this.dateRange[0])).format('yyyy-MM-DD HH:mm')
      if(this.dateRange[1]) delivery_before =moment(new Date(this.dateRange[1])).add(1439,'minutes').format('yyyy-MM-DD HH:mm')

      
      //const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`product__completed=false&delivery_after=${delivery_after}&delivery_before=${delivery_before}`
      await fetchSupplyOrders(query)
      .then((res)=>{
        this.loading=false
        this.supplies=this.processData(res)
      })
      .catch((err)=>{
        this.loading=false
        notifications.showError(err)
      })
    },
    processData(rawSupplies){
      console.log(rawSupplies)
      //Agrupo las ordenes por nombre de material
      let unique = [...new Set(rawSupplies.map(item =>
        item.name
      ))];

      //Agrupo las ordenes por grupo
      this.groups =  [...new Set(rawSupplies.map(item =>
        item.group_name
      ))];

      //Saco resultados del filtro por nombre
      const results=unique.map(name=>{
        const supplies=rawSupplies.filter(p=> p.name==name)
        return{
          name:supplies[supplies.length-1].name,
          sku:supplies[supplies.length-1].sku,
          group:supplies[supplies.length-1].group_name,
          unit: supplies[supplies.length-1].unit_name,
          article_sku:supplies[supplies.length-1].article?supplies[supplies.length-1].article.sku:null,
          article_id:supplies[supplies.length-1].article?supplies[supplies.length-1].article.id:null,
          stock:supplies[supplies.length-1].article?supplies[supplies.length-1].article.stocks.filter(i=>i.add_stock).reduce((acc, val)=>{ return acc + parseFloat(val.qty); }, 0):null,
          qty:supplies.reduce((acc, val)=>{ return acc + parseFloat(val.total_pp); }, 0),
          consumed:supplies.reduce((acc, val)=>{ return acc + parseFloat(val.consumed); }, 0),
          pending: supplies.reduce((acc, val)=>{ return acc + parseFloat(val.total_pp); }, 0) - supplies.reduce((acc, val)=>{ return acc + parseFloat(val.consumed); }, 0)
        }
      })
      if(this.filter_group){
        return results.filter(s=>s.group==this.filter_group)
      }
      else return results
    },
    clearDate(){
      this.dateRange=['','']
      this.fetch()
    },
  },
  watch:{
    filter:{
      deep: true,
      handler: 'fetch'
    },
  },
  computed: {
      computedDateFormattedMomentjs () {
        //return `${this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[0]?this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[0]:''} - ${this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[1]?this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[1]:''}`
        return `${this.dateRange[0]!=''?moment(this.dateRange[0]).format('DD-MMM-YYYY'):''} - ${this.dateRange[1]!=''?moment(this.dateRange[1]).format('DD-MMM-YYYY'):''}`
      
      },
    },
}
</script>