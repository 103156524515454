<template>
  <div>
    <div class="d-flex d-inline align-center">
      <div class="mr-2">
        <v-icon>{{icon}}</v-icon>
      </div>
      <div class="number-wrapper">
        <div class="label-number">
          <slot/>
        </div>
      </div>
    </div>
    <div class="mt-2 ml-8 text text-caption">
      {{title}}
    </div>
  </div>
</template>

<script>
export default {
  name:'NumberLabel',
  props:['icon','title']
}
</script>

<style lang="scss">
.label-number{
  font-size: xx-large !important;
  color: rgba(0, 0, 0, 0.705) !important;
  //padding-top: 7px;
  padding-left: 10px;
  padding-right: 8px;
}
.number-wrapper{
  border:rgba(0,0,0,.42) 0px solid;
  border-radius: 5px;
}

</style>