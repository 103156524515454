<template>
  <v-card class="pa-4">
    <v-card-title>{{selected.name}}</v-card-title>
    <v-card-subtitle>{{selected.fantasy}}</v-card-subtitle>
    <v-card-text>
      RUT: {{selected.rut}}
      <v-list>
        <v-list-group prepend-icon="mdi-account-box-multiple">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Contactos</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item 
            v-for="contact in selected.contacts" 
            :key="contact.id">
            {{contact.first_name + ' '+contact.last_name}}
            <v-spacer></v-spacer>
            <div class="text align--end">{{contact.email}}</div>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-crosshairs-gps">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Direcciones</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item 
            v-for="address in selected.addresses" 
            :key="address.id">
            {{address.street + ' '+address.number}}
          </v-list-item>
        </v-list-group>
      </v-list>
      <div class="text">Vendedor: {{selected.seller.email}}</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name:"CustomerCard",
  props:{
    selected:{}
  }
}
</script>