var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-card-title',{staticClass:"titulo text-h4 mb-4"},[_c('v-icon',{staticClass:"mr-2 align-items-center"},[_vm._v("fas fa-bars-staggered")]),_vm._v("Cambio de Etapa ")],1),_c('v-simple-table',{staticClass:"mb-10"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Codigo")]),_c('th',[_vm._v("Producto")]),_c('th',[_vm._v("OT")]),_c('th',[_vm._v("Etapa")])])]),_c('tbody',_vm._l((_vm.products),function(product){return _c('tr',[_c('td',[_vm._v(_vm._s(product.library_product__sku))]),_c('td',[_vm._v(_vm._s(product.name))]),_c('td',[_vm._v(_vm._s(product.work_order__id))]),_c('td',[_c('v-chip',{staticClass:"ma-2",staticStyle:{"max-width":"150px"},attrs:{"color":product.prestage.color,"label":"","outlined":""}},[_vm._v(" "+_vm._s(product.prestage.name)+" ")])],1)])}),0)]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"mb-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmation)}}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.stages,"label":"Nueva Etapa","item-text":"name","item-value":"id","outlined":"","required":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text text-caption mr-2"},[_vm._v(_vm._s(data.item.sort)+" - "),_c('v-chip',{staticClass:"ma-2",staticStyle:{"max-width":"150px"},attrs:{"color":data.item.color,"label":"","outlined":""}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)])]}}],null,true),model:{value:(_vm.prestage_id),callback:function ($$v) {_vm.prestage_id=$$v},expression:"prestage_id"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"type":"submit","color":"success","loading":_vm.loading}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){$event.preventDefault();return (function (){
              _vm.prestage_id=''
              _vm.products=[]
              _vm.$emit('close')
              })($event)}}},[_vm._v("Cancelar")])],1)],1)]}}])})],1),_c('confirm',{attrs:{"message":"¿Confirma pasar a Produccion?"},on:{"ok":function($event){return _vm.save(false)},"cancel":function (){
    _vm.showProductToProduction=false
    }},model:{value:(_vm.showProductToProduction),callback:function ($$v) {_vm.showProductToProduction=$$v},expression:"showProductToProduction"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }