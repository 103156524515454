<template>
<div>
  <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="`export_invoices-by-${product.product__sku}_report`"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchInvoicedDetailByProducts} from '@/api/reports'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportProductInvoiceDetail",
  props:['product','filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      dataReady:false,
      labels:{ 
        dte__date_emited:{title:'Emitido',},
        dte__document_id:{title:'Documento'},
        dte__rznSocRecep:{title:'Razon Social'},
        dte__work_order__id: { title: 'OT' },
        dte__work_order__sales_note__id: { title: 'Cotizacion' },
        dte__work_order__sales_note__name: { title: 'Proyecto' },
        avg_price:{title:'Precio Promedio'},
        qty:{title:'Cantidad'},
        total:{title:'Neto'},
       },
       csvTitle:`export_invoices-by-${this.product.product__sku}_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.seller) this.filter.seller=''
      const {sortBy, sortDesc}=this.options
      let  sort = (sortDesc[0] ? '-'+sortBy[0] : sortBy[0])
      if (!sort||!sortBy[0]) sort = 'dte__date_emited'
      const query=`page=${1}&ordering=${sort}&product=${this.product.product}&emited_after=${this.filter.created_after}&emited_before=${this.filter.created_before}&dte__customer__seller__id=${this.filter.seller}`
      await fetchInvoicedDetailByProducts(query)
      .then((res)=>{
        this.loading=false
        this.items=res.results
        this.dataReady=true
      })
      .catch((err)=>{
        notifications.showError('Error cargando documentos '+err)
      })
      this.loading=false
    },
    handleSuccess(val){
      notifications.showSuccess('Documentos Exportados')
      this.dataReady=false
    },
    handleError(val){
      notifications.showError('Error Exportando Documentos')
      this.dataReady=false
    }
  }

}
</script>