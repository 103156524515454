<template>
<v-container>
    <div class="text">Producto: <strong>{{product.name}}</strong></div>
    <div class="text  mt-4 mb-6">
      <span>Material: </span>
      <strong class="text-h7">[{{supply.sku}}] {{supply.name}}</strong> 
    </div>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
          <div>
            <div class="d-flex d-inline-flex">
              <ValidationProvider name="Cantidad" rules="required" v-slot="{ errors }">
                <v-text-field 
                v-model="qty"
                type="number"
                outlined
                label="Cantidad a Consumir"
                :error-messages="errors"
                />
              </ValidationProvider>
              <v-chip class="ml-2" label outlined large>{{supply.unit_name}}</v-chip>
            </div>
            <v-text-field 
              v-model="notes"
              type=""
              outlined
              label="Notas"
              />
          </div>
          <div class="text-center">
            <v-btn color="success" type="submit" :loading="loading">
              <v-icon class="mr-2">fas fa-sd-card</v-icon>
              Guardar
            </v-btn>
          </div>
        </v-form>
      </ValidationObserver>
</v-container>
</template>
<script>
import notifications from '@/utils/notifications'
import {createSupplyConsumption} from '@/api/production'
export default {
  name:'SupplyConsume',
  data() {
    return {
      qty: 0,
      notes:'',
      loading:false,
    }
  },
  props: {
    product: {
      type: Object,
      default: {}
    },
    supply: {
      type: Object,
      default: {}
    },
  },
  methods:{
    async save(){
      this.loading=true
      await createSupplyConsumption({
        qty:this.qty,
        notes:this.notes,
        supply_order: this.supply.id
      })
      .then((res)=>{
        this.$emit('close')
        this.$emit('added')
        notifications.showSuccess('Consumo creado!')
        this.loading=false
      })
      .catch((e)=>{
        notifications.showError('Error Realizando el Registro '+e)
        this.loading=false
      })
      
    }
  }
}
</script>

<style>

</style>