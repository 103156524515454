<template>
  <purchase-order-complete v-model="selected" :disabled="disabled"/>
</template>

<script>
import PurchaseOrderComplete from '@/components/stocks/PurchaseOrderComplete'
export default {
  name:'AddPurchaseOrder',
  components:{PurchaseOrderComplete},
  props:['disabled'],
  data() {
    return {
      selected: '',
    }
  },
  watch:{
    selected(){
      if(this.selected!=''){
        var selected={}
        selected = Object.assign({}, selected, this.selected)
        this.$emit('add',selected)
      }
    }
  }
}
</script>

<style>

</style>