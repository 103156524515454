<template>
  <div class="my-4">
    <v-row justify="center">
        <v-col>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>
                            <span class="">Total Venta</span>
                        </th>
                        <th style="border-right:1px solid lightgrey;">
                            <span class="dark_blue--text">Cost. Teorico [margen]</span>
                        </th>
                        <th>
                            <span class="">Cost. Produccion</span>
                        </th>
                        <th>
                            <span class="">Cost. Reproceso</span>
                        </th>
                        <th>
                            <span class="">Consumo</span>
                        </th>
                        <th>
                            <span class="">Consumo Reproceso</span>
                        </th>
                        <th>
                            <span class="">Consumo Bodega</span>
                        </th>
                        <th>
                            <span class="">Adicionales</span>
                        </th>
                        <th>
                            <span class="">O. Compras</span>
                        </th>
                        <th>
                            <span class="dark_blue--text">Cost. Real [margen]</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="border-bottom: 1px solid lightgrey;">
                        <td style="border-bottom: 1px solid lightgrey">
                            <span class="">{{data.sales_total | currency('$', 0)}}</span>
                        </td>
                        <td style="border-right:1px solid lightgrey;border-bottom: 1px solid lightgrey">
                            <span class="dark_blue--text">{{data.sales_cost | currency('$', 0)}}</span>
                            <span class="dark_blue--text ml-2">[{{(1-data.sales_cost/data.sales_total)| percent(1) }}]</span>
                        </td>
                        <td style="border-bottom: 1px solid lightgrey">
                            <span>{{data.production_cost | currency('$', 0)}}</span>
                        </td>
                        <td style="border-bottom: 1px solid lightgrey">
                            <span>{{data.reprocess_cost | currency('$', 0)}}</span>
                        </td>
                        <td style="border-bottom: 1px solid lightgrey">
                            <span>{{data.consume_cost| currency('$', 0)}}</span>
                        </td>
                        <td style="border-bottom: 1px solid lightgrey">
                            <span>{{data.consume_cost_reprocess| currency('$', 0)}}</span>
                        </td>
                        <td style="border-bottom: 1px solid lightgrey">
                            <span>{{data.warehouse_consumption| currency('$', 0)}}</span>
                        </td>
                        <td style="border-bottom: 1px solid lightgrey">
                            <span>{{data.additional_costs| currency('$', 0)}}</span>
                        </td>
                        <td style="border-bottom: 1px solid lightgrey">
                            <span>{{data.purchase_orders_costs| currency('$', 0)}}</span>
                        </td >
                        <td style="border-bottom: 1px solid lightgrey">
                            <span class="dark_blue--text">{{data.total| currency('$', 0)}}</span>
                            <span class="dark_blue--text ml-2">[{{(1-data.total/data.sales_total)| percent(1) }}]</span>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-col>
    </v-row> 
  </div>
</template>

<script>
import {getWorkOrderCostResume} from '@/api/production.js'
import notifications from '@/utils/notifications' 
export default {
    name:'RunningCost',
    props:['work_order'],
    data(){
        return {
            salesNote:{},
            loading:false,
            total_product_cost:0,
            additional_cost:0,
            buying_orders:0,
            work_order_cost:0,
            work_order_margin:0,
        }
    },
    computed:{
        data(){
            return this.$store.getters.getworkOrderCostResume
        }
    },
    created(){
        if(this.work_order){
            this.getData()
        }
    },
    methods:{
        async getData(){
            this.loading=true
            await this.$store.dispatch('fetchWorkOrderResume',this.work_order.id)
            .then(()=>{
            })
            .catch(e=>{
                notifications.showError(e)
            })
            .finally(()=>{
                this.loading = false
            })
        },
    }
    
}
</script>

<style>

</style>