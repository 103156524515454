<template>
  <v-container>
    <v-row class="mb-10" justify="space-between" align-content="center">
      <h1 class="text text-h4 titulo"><v-icon class="mr-2" x-large>fas fa-cog</v-icon>Mantenedores</h1>
      <v-btn  @click.prevent="$router.push('/customers')" text ><v-icon>fas fa-chevron-left</v-icon></v-btn>
    </v-row>
    <v-tabs 
      background-color="transparent"
      color="primary"
      center-active
      v-model="tab"
      
    >
      <v-tab v-model="tab" class="primary--text">Industrias</v-tab>
      <v-tab v-model="tab" class="primary--text">Rubros</v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item class="pa-4">
          <industries/>
        </v-tab-item>
        <v-tab-item class="pa-4">
          <rubros/>
        </v-tab-item>
    </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import Industries from './Industries.vue';
import Rubros from './Rubros.vue';
export default {
  name:'CustomersConfiguration',
  components:{Industries,Rubros},
  data() {
    return {
      tab: null,
    }
  },
}
</script>

<style>

</style>