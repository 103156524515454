<template>
<div>
  <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchDTE} from '@/api/salesNotes'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportInvoices",
  props:['filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      dataReady:false,
      labels:{ 
        document_id:{title:'Nro'},
        document_tipo:{title:'Tipo'},
        date_emited:{title:'Emitida'},
        rutRecep:{title:'RUT'},
        rznSocRecep: { title: 'Razón Social' },
        seller_email: { title: 'Vendedor' },
        work_order:{title:'OT'},
        sales_note:{title:'Cot.'},
        glosa:{title:'Referencia'},
        get_subtotal:{title:'Neto'},
        get_iva:{title:'IVA'},
        get_total:{title:'Total'},
        get_payed:{title:'Pagado'},
        payment_date:{title:'Vencimiento'},
       },
       csvTitle:`export_invoices_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='customer_name'){
        sort = 'customer__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.filter.not_null) this.filter.not_null=''
      if (!this.filter.tipo) this.filter.tipo=''
      if (!this.filter.seller) this.filter.seller=''
      console.log(this.filter)
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&tipo=${this.filter.tipo}&&emited_after=${this.filter.created_after}&emited_before=${this.filter.created_before}&customer__seller__id=${this.filter.seller}&not_null=${this.filter.not_null}`
      await fetchDTE(query)
      .then(res=>{
        console.log(res)
        this.items=res.results
        this.items.forEach(item=>{
          item.date_emited=moment(item.date_emited).format('DD-MM-YYYY')
          item.payment_date=moment(item.payment_date).format('DD-MM-YYYY')
          item.seller_email=item.seller_email?item.seller_email:''
          item.work_order=item.work_order?item.work_order:''
          item.sales_note=item.sales_note?item.sales_note:''
          item.get_subtotal=this.$options.filters.number(item.get_subtotal,'0')
          item.get_iva=this.$options.filters.number(item.get_iva,'0')
          item.get_total=this.$options.filters.number(item.get_total,'0')
          const document_tipo=(item)=>{
            if(item.tipo==30) return 'Factura'
            if(item.tipo==60) return 'Nota de Crédito'
            else return '-'
          }
          item.document_tipo=document_tipo(item)
        })
        this.dataReady=true
      })
      .catch((err)=>{
        notifications.showError('Error cargando documentos '+err)
      })
      this.loading=false
    },
    handleSuccess(val){
      notifications.showSuccess('Documentos Exportados')
      this.dataReady=false
    },
    handleError(val){
      notifications.showError('Error Exportando Documentos')
      this.dataReady=false
    }
  }

}
</script>