<template>
  <v-container>
     <div>
       <v-row class="mb-4" justify="space-between" align-content="center">
        <h1 class="text text-h4 titulo">Lista de Distribución</h1>
        <export-distribution-list :filter="filter" :options="options" :disabled="loading" :search="search"/>
       </v-row>
      <v-row class="mb-4" justify="end">
        <v-col align-self="center" cols="12" md="6">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_after' 
          class="mr-4" 
          outlined 
          clearable 
          type="date" 
          label="Creada Desde:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_before' 
          clearable 
          outlined 
          type="date" 
          label="Creada Hasta:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        
        <v-col justify-end cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            @keyup="searchTimeOut"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
     </div>
     <div>
      <v-data-table
        class="pa-4"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
         @update:options="fetch"
      >
        <template v-slot:top>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{item.created_at | moment("DD/MM/YYYY")}}</div>
        </template>
        
        <template v-slot:item.customer="{item}">
            <div class="caption mb-1">{{item.customer_rut}}</div>
            <div class="caption mb-1">{{item.customer_name}}</div>
        </template>
        <template v-slot:item.workorder="{item}">
          <show-work-order :work_order_id="item.workorder">
            <div class="caption mb-1">{{item.name}}</div>
            {{item.workorder}}
          </show-work-order>
        </template>
        <template v-slot:item.price="{item}">
          {{item.price | currency('$',0)}}
        </template>
        <template v-slot:item.ordered="{ item }">
          {{item.ordered |number('0,0')}}
        </template>
        <template v-slot:item.delivered="{ item }">
          {{item.delivered |number('0,0')}}
        </template>
        <template v-slot:item.returned="{ item }">
          {{item.returned |number('0,0')}}
        </template>
        <template v-slot:item.toinvoice="{ item }">
          {{item.delivered - item.returned |number('0,0')}}
        </template>
        <template v-slot:item.total="{ item }">
           {{(item.delivered - item.returned)*item.price  |currency('$',0)}}
        </template>
        <template v-slot:item.created_by_name="{ item }">
          <div class="caption">{{item.created_by_name}}</div>
        </template>
  </v-data-table>
  </div>
  </v-container>
</template>

<script>
import {fetchDistribution} from '@/api/salesNotes'

import ExportDistributionList from '@/components/sales/ExportDistributionList'
import notifications from '@/utils/notifications'
import moment from 'moment'
export default {
  name:'DistributionList',
  components:{ExportDistributionList},
  data() {
    return {
      loading:false,
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        
        },
      options:{},
      totalItems:0,
      display:false,
      search:'',
      items:[],
      
      headers:[
          {text: 'Creada',align: 'start',value: 'created_at',class: 'table-header2',divider:true,width: '2%'},
          {text: 'OT',align: 'start',value: 'workorder',class: 'table-header2',width: '20%'},
          {text:'Cot.',value:'salesnote',class:'table-header2',divider:true,width:'8%'},
          {text: 'Cliente',align: 'start',value: 'customer',divider:true,class: 'table-header2',width: '20%'},
          {text: 'Pedido',align: 'end',value: 'ordered',class: 'table-header2',sortable:false},
          {text: 'Despachado',align: 'end',value: 'delivered',class: 'articlePrice',sortable:false},
          {text: 'Retornado',align: 'end',value: 'returned',class: 'table-header2',sortable:false,divider:true,},
          {text: 'Facturar',align: 'end',value: 'toinvoice',class: 'table-header2',sortable:false,divider:true,},
          {text: 'Precio Unit.',align: 'end',value: 'price',class: 'table-header2',sortable:false},
          {text:'Total',align:'end',value:'total',class:'table-header2',divider:true,sortable:false},
          {text:'Usuario',align:'start',value:'created_by_name',class:'table-header2',sortable:false},
          
        ]
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      
      const {sortBy, sortDesc,page, itemsPerPage }=this.options

      //Definición del sort
      let sort='created_at'
      switch(sortBy[0]){
        case 'created_at':
            sort='created_at'
            break
        case 'workorder':
            sort='salesnote__work_order'
            break
        case 'salesnote':
            sort='salesnote'
            break
        case 'customer':
            sort='customer_name'
            break
        case 'ordered':
            sort='ordered'
            break
        case 'delivered':
            sort='delivered'
            break
        case 'returned':
            sort='returned'
            break
        case 'price':
            sort='price'
            break
        case 'created_by_name':
            sort='created_by__first_name'
            break
        default:
            sort='salesnote__work_order'
            break
      }
      
      const query=`ordering=${sortDesc[0]?sort:'-'+sort}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}`
      await fetchDistribution(query)
      .then((data)=>{
        this.totalItems=data.count
        this.items = data.results
      })
      .catch((err)=>notifications.showError(err))
      .finally(()=>this.loading=false)
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
  async mounted(){
    await this.fetch()
    
  },
  computed:{
      
    }
}
</script>