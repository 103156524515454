<template>
  <v-container>
    <v-row class="justify-space-between">
      <div class="text text-h4 titulo">
        <v-icon class="mr-2">fas fa-file-import</v-icon>
        Importar Procesos
      </div>
      <v-btn @click="$router.push('/products/processes')" class="ml-2" ><v-icon>fas fa-arrow-left</v-icon></v-btn>  
    </v-row>
    <!-- <v-data-table
      :headers="header"
      :items="newCustomers"
      :items-per-page="50"
      dense
    >
      <template v-slot:item.error="{ item }">
         <v-icon :color="item.error?'error':'success'">{{item.error?'fas fa-times-circle':'fas fa-check-circle'}}</v-icon>
      </template>
    </v-data-table> -->
    <v-row justify="center" class="mt-6">
      <v-col cols="12" class="text-center">
        <v-stepper
              v-model="e6"
              vertical
          >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
            color="info"
          >
            Descargue la Muestra
            <small>Descarga el archivo de muestra CSV</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              height="100px"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                <v-btn href="https://runflow-evo-bucket.s3.us-east-2.amazonaws.com/files/correcto_customer_import.csv"><v-icon class="mr-2">fas fa-download</v-icon> Descargar</v-btn>
              </v-row>
            </v-card>
            <v-btn
              color="success"
              @click="e6 = 2"
            >
              Continuar
            </v-btn>
            <v-btn text >
              Atrás
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
            color="info"
          >
            Seleccione el archivo
            <small>Seleccione el archivo CSV con los datos a importar</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              color=""
              class="mb-12"
              height="200px"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                  <v-file-input
                  class="pa-10"
                  style="max-width:500px"
                  v-model=file
                  accept = '.csv'
                  prepend-icon="fas fa-cloud-upload-alt"
                  outlined
                  show-size
                  hint="Selecciona el archivo CSV a importar"
                  persistent-hint
                ></v-file-input>
              </v-row>
              <v-row>
                <v-col>
                  máquinas
                </v-col>
                <v-col>
                  unidades
                </v-col>
                <v-col>
                  grupos
                </v-col>
              </v-row>
            </v-card>
            <v-btn :disabled="file==''" color="success" @click="onFileChange" :loading="loading">
              <v-icon class="mr-2">fas fa-file-upload</v-icon>Subir Archivo
            </v-btn>
            <v-btn text @click="e6=1">
              Atrás
            </v-btn>
          </v-stepper-content>
          <v-stepper-step
            :complete="e6 > 3"
            step="3"
            color="info"
            :rules="[() => errorCustomers.length>0?false:true]"
          >
            Resultados
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card
              color=""
              class="mb-12"
              min-height="100px" 
              flat
            >
              <v-row class="justify-center" v-if="errorCustomers.length>0 && newCustomers.length>0">
                <v-col class="text-center" cols="6">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>
                          Item
                        </th>
                        <th>
                          RUT
                        </th>
                        <th>
                          Error
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in errorCustomers" :key="item.item">
                        <td>
                          {{item.item}}
                        </td>
                        <td>
                          {{item.rut}}
                        </td>
                        <td>
                          {{item.message}}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              
              <v-row  v-else-if="newCustomers.length>0" class="justify-center align-center" style="height: 100%">
                <div class="text text-h6">
                  Se verificaron <strong>{{newCustomers.length}}</strong> registros correctamente
                </div>
              </v-row>
              
            </v-card>
            <v-btn
              color="success"
              @click="upload"
              :disabled="errorCustomers.length>0 && newCustomers.length>0"
              :loading="loading"
            >
              <v-icon class="mr-2">fas fa-sd-card</v-icon>
              Guardar
            </v-btn>
            <v-btn text @click="e6=2">
              Atrás
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import notifications from '@/utils/notifications'
import {validaRUT } from '@/utils/rut_validator'
import {ValidateEmail} from '@/utils/validateIsEmail'
import {fetchCustomers,uploadCustomers} from '@/api/customers'
import {fetchSellers} from '@/api/auth'
import axios from 'axios'
export default {
  name:'ImportProcesses',
  data() {
    return {
      file: '',
      newCustomers:[],
      errorCustomers:[],
      header:[],
      loading:false,
      e6:1
    }
  },
  methods:{
    forceFileDownload(response, title) {
      console.log(title)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(url, title) {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response, title)
        })
        .catch(() => console.log('error occured'))
    },
    onFileChange(){
      const config={
        header: true,
        complete: (results) =>{
          this.verifyData(results)
        },
      }
     this.$papa.parse(this.file, config)
    },
    async upload(){
      this.loading=true
      await uploadCustomers(this.newCustomers)
      .then((res)=>{
        notifications.showSuccess('Registros cargado correctamente' + res)

        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando clientes '+err)
        this.loading=false
      })
    },
    async verifyData(data){
      this.loading=true
      console.log(data)
      this.newCustomers=[]
      this.errorCustomers=[]
      this.header=[]
      let loadedCustomers=''
      let sellers=''

      //Carga todos los clientes en BBDD
      await fetchCustomers(1,-1)
      .then((data)=>{
        loadedCustomers=data.results
      })
      .catch((err)=>{
        notifications.showError('Error cargando clientes '+err)
        this.loading=false
        return 0
      })

      //Carga todos los Vendedores
      await fetchSellers()
      .then((data)=>{
        sellers=data
      })
      .catch((err)=>{
        notifications.showError('Error cargando vendedores '+err)
        this.loading=false
        return 0
      })

      //Verifica que la cantidad de campos sea correcta
      if(data.meta.fields.length!=17){
        notifications.showError('Error en cantidad de campos')
        this.loading=false
        return 0
      }

      //Construcción de los encabezados
      this.header.push({
        text:'Estatus',
        value:'error',
        sortable:false,
      })
      data.meta.fields.map((item)=>{
        this.header.push({
          text: item,
          value:item,
          sortable:false,
        })
      })

      //Validación de Datos
      data.data.map((item)=>{
        let error = false
        let message=''
        let foundSeller=''
        //Valida el RUT
        if(!validaRUT(item.rut)) {
          error = true
          message='RUT Inválido'
        }

        //Valida que el resto de los campos no estén vaciós
        Object.keys(item).map(key=>{
          if(item[key].length==0){
            error=true
            message=`Campo ${key} vacío`
          } 
        })

        //Valida que no haya un RUT duplicado
        this.newCustomers.map(newCustomer=>{
          if (newCustomer.rut== item.rut){
            error = true
            message='RUT Duplicado'
          } 
        })
        loadedCustomers.map(customer=>{
          if(customer.rut==item.rut){
            error = true
            message='RUT ya existe está creado en la plataforma'
          } 
        })
        
        //Valida si el vendedor existe y agrega el vendedor al objecto
        sellers.map(seller=>{
          if(seller.email==item.seller){
            item.seller=seller.id
            foundSeller=seller.id
          }
        })
        if(!foundSeller){
            error=true
            message='Vendedor no encontrado'
        }

        //Valida que el campo comisión_agencia es un número
        if(isNaN(item.com_agencia)||parseInt(item.com_agencia)<0){
          error=true
          message='El campo <com_agencia> no es un número o es inválido'
        }

        //Valida que payment es un número
        if(isNaN(item.payment)||parseInt(item.payment)<0){
          error=true
          message='El campo <payment> de agencia no es un número o es inválido'
        }
        //Valida que el campo email es un email
        if (!ValidateEmail(item.email)){
          error=true
          message='El campo <email> es inválido'
        }

        if(error==true){
          this.errorCustomers.push({
            item: data.data.indexOf(item)+1,
            rut:item.rut,
            message:message
          })
        }
        item.error = error
        this.newCustomers.push(item)
      })
      this.e6=3
      this.loading=false
    }
  }
}
</script>

<style lang="scss">
.v-stepper{
  box-shadow: 0  !important;
}
.v-stepper__content{
  margin: -8px -36px -16px 42px !important;
}
.v-stepper__step__step{
  height: 40px !important;
  width: 40px !important;
  font-size: 1rem !important;
}

</style>