<template>
      <v-autocomplete
        v-model="value"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="primary"
        hide-selected
        item-text="text"
        item-value="id"
        label="Agregar artículo"
        prepend-icon="mdi-magnify"
        return-object
        outlined
        clearable
        dense
        cache-items
        @input="updateValue"
        @change="cambio"
        hide-details
        :disabled="disabled"
      >
        <template v-slot:selection="{ item}">
          <div class="d-flex">
            <div class="text mr-2">{{item.sku}} - {{item.name}}</div>
          </div>
        </template>
        <template v-slot:item="data">
          <div class="d-flex">
            <div class="text mr-2">{{data.item.sku}} - {{data.item.name}}</div>
          </div>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Buscar 
              <strong>Artículo</strong>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    
</template>

<script>
import {fetchArticles} from '@/api/stocks'
import notifications from '@/utils/notifications'

  export default {
    name:'ArticleComplete',
    data: () => ({
      descriptionLimit: 60,
      customers: [],
      isLoading: false,
      model: null,
      search:null,
      select:null,
      items: [],
      timer:'',
    }),
    props:['value','disabled','article_name'],
    methods:{
      updateValue(){
        this.value ? this.$emit('input',this.value) : this.$emit('input','')
      },
      cleared(){
        this.$emit('input','')
      },
      cambio(){
        console.log('cambio en el value')
      },
      async querySelections (v) {
        this.isLoading = true
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          const query=`ordering=-sku&search=${v}&page=1&&page_size=20&status=true`
          await fetchArticles(query)
          .then((res)=>{
            const data = res.results.map(i=>{
              i.text=i.sku+i.name
              return i
            })
            this.items=data
            this.isLoading=false
          })
        }, 1000); 
      },
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
      article_name(val){
        console.log(val)
        if(val) this.search=val
      }
    },
    
    
  }
</script>