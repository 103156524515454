<template>
  <v-container>
    <v-row class="justify-space-between">
      <div class="text text-h4 titulo">
        <v-icon class="mr-2">fas fa-file-import</v-icon>
        Actualizar Productos
      </div>
      <v-btn text @click="$router.push({name:'Products'})" class="ml-2" ><v-icon>fas fa-chevron-left</v-icon></v-btn>  
    </v-row>
    <v-row justify="center" class="mt-6">
      <v-col cols="12" class="text-center">
        <v-stepper
              v-model="e6"
              vertical
          >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
            color="info"
          >
            Descargue la Muestra
            <small>Descarga el archivo de muestra CSV</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              height="100px"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                <export-products :for_update="true" family="" line="">Descargar</export-products>
              </v-row>
            </v-card>
            <v-btn
              color="success"
              @click="e6 = 2"
            >
              Continuar
            </v-btn>
            <v-btn text >
              Atrás
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
            color="info"
          >
            Seleccione el archivo
            <small>Seleccione el archivo CSV con los datos a importar</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              color=""
              class="mb-12"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                  <v-file-input
                  class="pa-10"
                  style="max-width:500px"
                  v-model=file
                  accept = '.csv'
                  prepend-icon="fas fa-cloud-upload-alt"
                  outlined
                  show-size
                  hint="Selecciona el archivo CSV a importar"
                  persistent-hint
                ></v-file-input>
              </v-row>
              <v-row justify="space-between" class="pa-2">
                <v-col>
                  <v-card>
                    <v-card-title>Familias [family]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="family in families" :key="family.id">
                          <td><strong>{{family.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{family.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Lineas [line]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="line in lines" :key="line.id">
                          <td><strong>{{line.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{line.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Status</v-card-title>
                    <v-card-subtitle>Ingrese el Valor Correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Valor</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td class="d-flex justify-start align-center">Habilitado</td>
                        </tr>
                        <tr>
                          <td>0</td>
                          <td class="d-flex justify-start align-center">Deshabilitado</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                 <v-col>
                  <v-card>
                    <v-card-title>Venta por [sell_unit_m2]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Valor</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><strong>0</strong></td>
                          <td class="d-flex justify-start align-center">Precio por Unidad</td>
                        </tr>
                        <tr>
                          <td><strong>1</strong></td>
                          <td class="d-flex justify-start align-center">Precio por Metro<sup>2</sup></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Instrucciones Adicionales</v-card-title>
                    <v-list-item>
                          <v-icon class="mr-2" small>fas fa-info-circle</v-icon>
                          <div class="text text-body-2">
                          No pueden existir columnas vacías
                          </div>
                    </v-list-item>
                    <v-list-item>
                          <v-icon class="mr-2" small>fas fa-info-circle</v-icon>
                          <div class="text text-body-2">
                          No pueden existir filas vacías
                          </div>
                    </v-list-item>
                    
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-btn :disabled="file==''" color="success" @click="upload" :loading="loading">
              <v-icon class="mr-2">fas fa-file-upload</v-icon>Subir Archivo
            </v-btn>
            <v-btn text @click="e6=1">
              Atrás
            </v-btn>
          </v-stepper-content>
          <v-stepper-step
            :complete="e6 > 3"
            step="3"
            color="info"
            :rules="[() => errorsMessages.length>0?false:true]"
          >
            Resultados
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-list-item>
                <v-icon class="mr-2" small>fas fa-info-check</v-icon>
                <div class="text text-body-2">
                  <v-icon class="mr-2 green--text">fas fa-check</v-icon>
                  Se actualizaron <strong>{{resultsCount}}</strong> registros
                </div>
            </v-list-item>
            <v-list-item v-if="errorsMessages.length>0">
              <v-list-item-content>
              <v-list-item-title class="text-start mt-4 error--text">Errores</v-list-item-title>
              <v-simple-table
                fixed-header
                height="300px"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Codigo</th>
                    <th>Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(error, index) in errorsMessages" :key="index">
                    <td class="text-start">{{index+1}}</td>
                    <td class="text-start">{{error.sku}}</td>
                    <td class="text-start">{{error.errors}}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-btn text @click="e6=2">
                Atrás
              </v-btn>
            </v-list-item>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
    <!-- Dialogo para mostrar el progreso de la carga -->
    <v-dialog 
    v-model="showProgress"
    persistent
    max-width="500"
    >
      <v-card class="pa-10"> 
        <v-card-title class="titulo text-h4">Registrando...</v-card-title>
        <v-row>
          <v-col>
            <v-progress-linear 
            indeterminate
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-start">
            Actualizacion en proceso. Por favor no cierre esta ventana
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import notifications from '@/utils/notifications'
import {fetchFamily, fetchLines, BatchUpdate} from '@/api/products'
import ExportProducts from '@/components/products/products/ExportProducts'
import axios from 'axios'
export default {
  name:'BatchUpdate',
  components:{
    ExportProducts
  },
  data() {
    return {
      file: '',
      loading:false,
      errorsMessages:[],
      resultsCount:0,
      uploadingError:false,
      uploadingErrorMessage:'',
      e6:1,
      showProgress:false,
      families:[],
      lines:[],
    }
  },
  async created(){
    this.families=this.$store.getters.getFamilies
    this.lines=this.$store.getters.getLines
  },
  methods:{
    onFileChange(){
      
    },
    async upload(){
      var formData = new FormData()
      if(this.file!='') formData.append('data',this.file)
      else {
        notifications.showError('Seleccione un archivo')
        loading=false
        return 0
      }
      this.loading=true
      this.showProgress=true
      await BatchUpdate(formData)
      .then(res=>{
        this.loading=false
        this.showProgress=false
        this.e6=3
        this.resultsCount=res.updated.length
        this.errorsMessages=res.errors
      })
      .catch(e=>{
        this.loading=false
        this.showProgress=false
        notifications.showError(e)
      })
    }
  }
}
</script>

<style lang="scss">
.v-stepper{
  box-shadow: 0  !important;
}
.v-stepper__content{
  margin: -8px -36px -16px 42px !important;
}
.v-stepper__step__step{
  height: 40px !important;
  width: 40px !important;
  font-size: 1rem !important;
}

</style>