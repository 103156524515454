<template>
<div>
  <v-card class="pa-10">
      <SalesDetail :salesNote='salesNote' :approbing="true" @close="$emit('close')"/>
   <v-divider class="mt-8"></v-divider>
   <v-divider></v-divider>
   <v-divider></v-divider>
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(save)">
     
        <v-row justify="space-between" align-content="center" class="mt-6">
          <v-col>
            <div class="text text-h4 titulo">
              <v-icon class="mr-2">fas fa-hard-hat</v-icon>
              Detalles de la Orden de Trabajo
            </div>
          </v-col>
          <v-col cols="4">
            <delivery-estimation :products="salesNote.details.products"/>
          </v-col>
        </v-row>
      
        <v-row justify="space-between">
          <v-col>
              <v-text-field
              dense
            label='Orden de Compra'
            
            v-model='purchase_order'
            outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <ValidationProvider name="Fecha de Entrega" rules="required" v-slot="{ errors }">
              <v-text-field
              dense
              label='Fecha y Hora de Entrega'
              v-model='delivery_date'
              type="datetime-local"
              outlined
              :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-select
            dense
              :items="deliveryTypeList"
              item-text="name"
              item-value="value"
              v-model='delivery_type'
              label="Tipo de Despacho"
              outlined
          />  
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-text-field
            dense
              class="mt-2"
              label='Fecha y Hora de Instalación'
              v-model='install_date'
              type="datetime-local"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
          <v-text-field
          dense
            class="mt-2"
            label='Fecha y Hora de Desinstalación'
            v-model='uninstall_date'
            type="datetime-local"
            outlined
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center">
            <v-checkbox v-model="customer_approval"/>
            <v-text-field
              dense
              :disabled="!customer_approval"
              placeholder='Visto Bueno del Cliente'
              v-model='approval_notes'
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="d-flex align-center" v-if="preproduction_active">
            <v-checkbox v-model="in_prestage" label="Saltar etapas de Pre-produccion"/>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="8">
            <v-textarea
            dense
            label='Observaciones'
            v-model='notes'
            outlined
            rows="2"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>  
          <v-btn class="mx-auto" :loading='loading' large type="submit" color="success"><v-icon left>fas fa-thumbs-up</v-icon>Confirmar</v-btn>
        </v-card-actions>
    </v-form>
    </ValidationObserver>
  
  </v-card>
</div>
  
</template>

<script>
import notifications from '@/utils/notifications'
import SalesCard from './SalesCard.vue'
import SalesDetail from '@/components/sales/SalesDetail'
import {createWorkOrder,getWorkOrderEstimate} from '@/api/production'
import DeliveryEstimation from '@/components/sales/DeliveryEstimation.vue'

export default {
  components: { SalesCard,SalesDetail,DeliveryEstimation},
  name:'ApproveSalesNote',
  props:['salesNote'],
  data() {
    return {
      purchase_order: '',
      delivery_date:'',
      deliveryTypeList:[
      {
        name:'Despacho',
        value: 0
      },
      {
        name:'Retira el Cliente',
        value:1
      },
      {
        name:'Retira Instalador',
        value:2
      },
      ],
      delivery_type:0,
      install_date:'',
      uninstall_date:'',
      instalation: false,
      uninstalation: false,
      notes:'',
      customer_approval:false,
      approval_notes:'',
      in_prestage:false,
      estimatedDelivery:'',
      estimationLoading:false,
      loading:false,
      preproduction_active:this.$store.getters.getCompany.preproduction_active,
    }
  },
  methods:{
    async save(){
      this.loading=true
      //Preparo la data para enviar al endpoint
      const salesNote = {
        sales_note:this.salesNote.id,
        delivery_date:new Date(this.delivery_date).toISOString(),
        purchase_order:this.purchase_order,
        customer_approval:this.customer_approval,
        approval_notes:this.approval_notes,
        notes:this.notes,
        delivery_type:this.delivery_type,
        in_prestage:this.preproduction_active?!this.in_prestage:false
      }
      if(this.install_date) salesNote.install_date=new Date(this.install_date).toISOString()
      if(this.uninstall_date) salesNote.uninstall_date=new Date(this.uninstall_date).toISOString()
      
      await createWorkOrder(salesNote)
      .then((data)=>{
          this.$emit('close',data.id)
          notifications.showSuccess('Orden de Trabajo Creada')
          this.loading=false
        })
        .catch((e)=>{
          notifications.showError('Error creando Orden de Trabajo '+e)
          this.loading=false
        })
    },
    async estimateWorkOrderDelivery(){
      this.estimationLoading=true
      await getWorkOrderEstimate(this.salesNote.details.products)
      .then((data)=>{
        this.salesNote.estimatedDelivery = data
        this.estimationLoading=false
        notifications.showSuccess('Fecha Estimada')
      })
      .catch((e)=>{
        notifications.showError('Error calculando estimación '+e)
      })
    }
  },
  computed:{
    utcdate(){
      return new Date(this.delivery_date).toISOString()
    }
  },
}
</script>

<style>

</style>