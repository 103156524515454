<template>
  <v-container>
    <!-- <v-skeleton-loader
          v-if="loading"
          type="actions,text,text,text,text,card,card"
     ></v-skeleton-loader> -->

     
       <v-row class="mb-10 justify-space-between">
        <h1 class="text text-h4 titulo">Ordenes de Trabajo</h1>
        <export-work-orders :disabled="loading" :filter="filter" :options="options"/>
       </v-row>
       <v-card class="pa-6">
      <v-row>
        <v-col>
          <v-text-field :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada Desde:" @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada Hasta:" @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :disabled="loading" dense v-model='filter.delivery_after' class="mr-4" outlined clearable type="date" label="Entrega Desde:" @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :disabled="loading" dense v-model='filter.delivery_before' clearable outlined type="date" label="Entrega Hasta:" @change="()=>{options.page=1;fetch()}" ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="filter.status"
            :items="status"
            item-text="text"
            item-value="value"
            label="Estatus"
            placeholder="Todos"
            prepend-inner-icon="filter_alt"
            outlined
            clearable
            dense
            :disabled="loading"
            @change="()=>{options.page=1;fetch()}"
          />
        </v-col>
        <v-col>
          <Can I="readAll" a="SalesNote">
            <v-select
              dense
              v-model="filter.seller"
              :items="sellers"
              item-text="email"
              item-value="id"
              label="Vendedor"
              placeholder="Todos los vendedores"
              prepend-inner-icon="filter_alt"
              :loading="sellersLoading"
              outlined
              clearable
              :disabled="loading"
              @change="()=>{options.page=1;fetch()}"
            />
          </Can>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="px-0">
          <customer-complete2 v-model="filter.customer"/>
        </v-col>
      </v-row> -->
      
      <v-data-table
        :headers="headers"
        :items="workOrders"
        :options.sync="options"
        :server-items-length="totalWorkOrders"
        :search="search"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        show-expand
        expanded.sync="expanded"
        expand-icon="fas fa-chevron-down"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
        @update:options="fetch"
      >
        <template v-slot:top>
        <v-row justify="end">
          <v-col cols="12" md="6">
            <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="fas fa-search"
                @keyup="searchTimeOut"
                :disabled="loading"
              ></v-text-field>
          </v-col>
        </v-row>
        </template>
        <template v-slot:item.id="{ item }">
          <div class="d-flex inline-flex">
            <show-work-order :work_order_id="item.id">{{item.id}}</show-work-order>

            <v-icon x-small class="ml-1" v-if="has_prestage(item)">fas fa-hourglass-half</v-icon>
            <v-icon x-small class="ml-1 error--text" v-if="item.has_reprocess">fas fa-clock-rotate-left</v-icon>
            <v-icon x-small class="ml-1" v-if="item.has_invoices">fas fa-file-invoice-dollar</v-icon>
            <!-- Integracion Softnet -->
            <span v-if="$store.getters.getCompany.softnet_integration_enabled" >
              <v-icon  v-if="item.have_softnet_documents" x-small class="ml-1 info--text" >fas fa-rss</v-icon>
            </span>
            <span v-if="$store.getters.getCompany.kame_integration_enabled" >
              <v-icon  v-if="item.have_kame_documents" x-small class="ml-1 orange--text" >fas fa-rss</v-icon>
            </span>
            <!-- Integracion Softnet -->
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{item.created_at | moment("DD/MM/YYYY")}}</div>
        </template>
        <template v-slot:item.sales_note__name="{item}">
          {{item.name}}
        </template>
        <template v-slot:item.sales_note__customer__name="{item}">
          {{item.customer_name}}
        </template>
        <template v-slot:item.delivery_date="{ item }">
          {{item.delivery_date | moment("DD/MM/YYYY HH:mm")}}
        </template>
        <template v-slot:item.progress="{ item }">
          <v-progress-linear
            :value="item.progress"
            color="custom_yellow"
            height="25"
            >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </template>
        <template v-slot:item.estimated="{ item }">
          <div v-if="item.estimated!=0">
            {{item.estimated | moment("DD/MM/YYYY")}}
          </div>
          <div v-else class="text-center">
            <v-icon color="success">fas fa-check-circle</v-icon>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
             
                <v-icon 
                v-bind="attrs"
                v-on="on"
                text
                 class="ml-2">more_vert</v-icon> 
              
            </template>
            <v-list dense>
              <v-list-item>
                <!-- <v-btn dense small text @click.stop="printWorkOrder(item)" :loading="loading_print_work_order"><v-icon class="mr-2">fas fa-print</v-icon> Imprimir</v-btn> -->
                <print-work-order :workorder_id="item.id" :loadWorkOrder="true"/>
              
              </v-list-item>
              <v-list-item v-if="!item.completed && $can('update', 'WorkOrder')">
                <v-btn dense small text @click.stop="update(item)" class="mr-2" ><v-icon class="mr-2">fas fa-pen</v-icon>Modificar</v-btn>
              </v-list-item>
              <v-list-item v-if="$can('readCost', 'SalesNote')">
                <v-btn text small @click.stop="generateCostReport(item)" class="dark_blue--text" :loading="loading_print_cost_report"><v-icon class="mr-2">fas fa-calculator</v-icon>Informe de Costos</v-btn>
              </v-list-item>
              
              <!-- Integracion Softnet -->
              <v-list-item v-if="$can('create', 'Softnet')&&$store.getters.getCompany.softnet_integration_enabled">
                <softnet-button :sales_note="item.sales_note" />
              </v-list-item>
              <!-- Integracion Kame -->
              <v-list-item v-if="$can('create', 'Kame')&&$store.getters.getCompany.kame_integration_enabled">
                <kame-button :sales_note="item.sales_note" />
              </v-list-item>

              <v-list-item v-if="!item.completed && $can('delete', 'WorkOrder')">
                <v-btn dense small text @click.stop="()=>{ 
                  workOrdertoDelete=item
                  showDeleteOrder=true
                  }" class="mr-2 red--text" ><v-icon small class="mr-2">fas fa-trash</v-icon>Eliminar</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="pa-4" :colspan="headers.length">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Item
                    </th>
                    <th class="text-left">
                      Producto
                    </th>
                    <th class="text-left">
                      Cant.
                    </th>
                    <th class="text-left">
                      Mts2
                    </th>
                    <th class="text-left">
                      Avance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="product in item.products"
                    :key="item.products.indexOf(product)"
                  >
                    <td>
                      <div class="d-flex">
                        {{item.products.indexOf(product)+1}}
                      </div>
                    </td>
                    <td>{{product.name}}</td>
                    <td>{{product.qty}}</td>
                    <td class="">{{product.mts2}}</td>
                    <td>
                      <v-chip
                        class="ml-2"
                        v-if="product.in_prestage"
                        :color="product.prestage_color"
                        label
                        outlined
                        small
                        >
                        {{product.prestage_name}}
                      </v-chip>
                      <v-progress-linear
                          v-else
                          :value="product.progress"
                          color="primary"
                          height="25"
                        >
                        <template v-slot:default="{ value }">
                          <strong>{{ Math.floor(value) }}%</strong>
                        </template>
                      </v-progress-linear>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
  </v-data-table>
  </v-card>
  <v-dialog v-model='display' max-width="1200">
    <v-card class="pa-4" >
      <work-order-card 
      :work_order='workOrderOnDisplay'
      @close="display=false" 
      @refresh="fetch"
      :loading="workOrderOnDisplayLoading"/> 
    </v-card>
  </v-dialog>
  <confirm
    v-if="workOrdertoDelete"
    v-model="showDeleteOrder" 
    :message="`¿Confirma eliminar orden # ${workOrdertoDelete.id}?`"
    @ok="deleteWorkOrder(workOrdertoDelete)"
    @cancel="showDeleteOrder=false"/>
  </v-container>
</template>

<script>
import {fetchWorkOrders,getWorkOrder,deleteWorkOrder} from '@/api/production'
import {fetchSellers} from '@/api/auth'
import {getSalesNote} from '@/api/salesNotes'
import CustomerComplete2 from '@/components/customers/CustomerComplete2.vue'
import WorkOrderCard from '@/components/production/WorkOrderCard'
import ExportWorkOrders from '@/components/production/ExportWorkOrders'
import CreateDocument from '@/components/softnet/CreateDocument'
import {pdfWorkOrder} from '@/doc_generators/workOrder'
import notifications from '@/utils/notifications'
import {pdfCostReport} from '@/doc_generators/costReport'
import SoftnetButton from '@/components/softnet/SoftnetButton.vue'
import KameButton from '@/components/kame/KameButton.vue'
import PrintWorkOrder from '@/components/production/PrintWorkOrder'
import moment from 'moment'

export default {
  name:'WorkOrders',
  components:{CustomerComplete2,WorkOrderCard,ExportWorkOrders,CreateDocument,SoftnetButton,KameButton,PrintWorkOrder},
  data() {
    return {
      loading:false,
      status:[
          {text:'Vigentes',value:false},
          {text:'Completadas',value:true},
          {text:'Todas',value:''}
        ],
      filter:{
        created_after:'',
        created_before:'',
        delivery_after:'',
        delivery_before:'',
        status:false,
        seller:''
        },
      workOrderOnDisplay:{},
      workOrderOnDisplayLoading:[],
      options:{},
      totalWorkOrders:0,
      display:false,
      displaySoftnet:false,
      search:'',
      workOrders:[],
      sellers:[],
      sellersLoading:false,
      showDeleteOrder:false,
      workOrdertoDelete:{},
      headers:[
          {text: 'Núm',align: 'start',value: 'id',class: 'table-header2'},
          {text: 'Creada',align: 'start',value: 'created_at',class: 'table-header2'},
          {text: 'Proyecto',align: 'start',value: 'sales_note__name',class: 'table-header2'},
          {text: 'Ct',value:'sales_note',class: 'table-header2'},
          // {text: 'Mts2',align: 'start',value: 'total_mts2',class: 'table-header',sortable:false},
          {text: 'Cliente',align: 'start',value: 'sales_note__customer__name',class: 'table-header2'},
          {text: 'Entrega',align: 'start',value: 'delivery_date',class: 'table-header2'},
          { text: 'Avance',value:'progress',sortable: false,class: 'table-header2'},
          { text: '', value: 'actions',class: 'table-header2' ,sortable:false},
          { text: '', value: 'data-table-expand',class: 'table-header2',sortable:false },
        ],
        loading_print_work_order:false,
        loading_print_cost_report:false,
    }
  },
  methods:{
    has_prestage(workOrder){
      return workOrder.products.some(product=>product.in_prestage)
    },
    async generateCostReport(item){
      this.loading_print_cost_report=true
      const {sales_note}=item
      await getSalesNote(sales_note)
      .then(data=>{
        pdfCostReport(data)
        this.loading_print_cost_report=false
      })
      .catch(err=>{
        console.log(err)
        this.loading_print_cost_report=false
      })
    },
    async fetch(){
      this.loading=true
      
      //Arreglando variables de fecha en caso de ser null
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
       
      if(!this.filter.delivery_after) this.filter.delivery_after=''
      if(!this.filter.delivery_before) this.filter.delivery_before=''
      if(!this.filter.seller) this.filter.seller=''
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      if (!this.$can('readAll', 'SalesNote')){
          const user = this.$store.getters.getUser
          this.filter.seller=user.id
        }
      

      await fetchWorkOrders(
        page,
        itemsPerPage,
        this.search,
        //this.filter.created_after,
        //this.filter.created_before,
        created_after,
        created_before,
        this.filter.delivery_after,
        this.filter.delivery_before,
        this.filter.status,
        this.filter.seller,
        sortDesc[0]?`${sortBy[0]}`:`-${sortBy[0]}`
        )
      .then((data)=>{
        this.totalWorkOrders=data.count
        this.workOrders = data.results
        this.workOrders.forEach(w=>{
        let completedMts2=0
        w.products = w.products.sort((a, b) => {
          if (a.sales_note_key < b.sales_note_key) return -1;
          if (a.sales_note_key > b.sales_note_key) return 1;
          return 0;
        });
        w.products.forEach(p=>{
          //Calculando la fecha final de entrega del producto
          /* var estimatedFinal=[]
           p.process_orders.map(process=>{
            estimatedFinal.push(new Date(process.estimated))
          })
          p.estimated=Math.max(...estimatedFinal)
          workOrderEstimatedFinal.push(p.estimated) */
          //Calculo de Avance
          const completed=p.process_orders.filter(c=>c.completed==true)
          
          p.progress=completed.length*1/p.process_orders.length*100
          //completedMts2+=completed.length*1/p.process_orders.length*p.mts2
          //Cambio el calculo de completado para tomar solo los productos completados
          completedMts2+=p.progress/100*p.mts2
        })
        w.progress=completedMts2/w.total_mts2*100
        //w.estimated=Math.max(...workOrderEstimatedFinal)
        })
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    async showWorkOrder(item){
      this.workOrderOnDisplayLoading=true
      this.display=true
      await getWorkOrder(item.id)
      .then((data)=>{
        console.log(data)
        this.workOrderOnDisplay=data
        this.workOrderOnDisplayLoading=false
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        this.display=false
        this.workOrderOnDisplayLoading=false
      })
    },
    async printWorkOrder(item){
      this.loading_print_work_order=true
      await getWorkOrder(item.id)
      .then((data)=>{
        pdfWorkOrder(data,this.$store.getters.getUser)
        this.loading_print_work_order=false
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        this.loading_print_work_order=false
      })
      
    },
    update(item){
      this.$router.push({name:'WorkOrderUpdate',params:{id:item.id}})
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    itemRowStyle(item){
      return 'row-style-normal'
      /* //return item.estimated==0 ? 'row-style-completed':'row-style-normal'
      if(item.estimated>0){
        return new Date(item.estimated).toISOString()>item.delivery_date?'error--text row-style-normal':'row-style-normal'
      }
      else{
        return 'row-style-completed success--text'
      } */
    },
    async deleteWorkOrder(item){
      await deleteWorkOrder(item.id)
      .then(()=>{
        this.showDeleteOrder=false
        this.fetch()
        notifications.showSuccess(`Orden de Trabajo ${item.id} Eliminada`)
      })
      .catch((e)=>{
        notifications.showError(`Error ${e}`)
      })
    },
  },
  async mounted(){
    await this.fetch()
    this.sellersLoading=true
    this.sellers=this.$store.getters.getSellers
    this.sellersLoading=false
  },
  
}
</script>

<style lang="scss">
  .row-style-completed{
    cursor: pointer;
  }
  .row-style-normal{
    cursor: pointer;
  }
</style>



