import axios from "axios"

export async function fetchCompany() {
    return await axios.get(`parameters/company`)
        .then(res => {
            console.log(res.data)
            if (res.data.length > 0) return res.data[0]
            else return {}
        })
        .catch(e => {
            throw e
        })
}

export async function updateCompany(rut, data) {
    return await axios.put(`parameters/company/${rut}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            console.log(res)
        })
        .catch(e => {
            throw e
        })
}

export const createUserParameters=async (data)=> (
    await axios.post(`parameters/user-parameters`,data)
    .then(res=>{
        console.log(res)
        return res.data
    })
    .catch(e  => {if(e.response.data.error) throw e.response.data.error})
)

export const updateUserParameters=async (data)=> (
    await axios.patch(`parameters/user-parameters`,data)
    .then(res=>{
        console.log(res)
        return res.data
    }
    )
    .catch(e  => {if(e.response.data.error) throw e.response.data.error})
)

export const fetchUserParameters=async (data)=> (
    await axios.get(`parameters/user-parameters`)
    .then(res=>{
        return res.data
    }
    )
    .catch(e  => {if(e.response.data.error) throw e.response.data.error})
)