var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Materiales")])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ml-4",attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.$router.push({name:'ImportSupplies',params:{data:{units:_vm.units,groups:_vm.groups,supplies:_vm.costs}}})}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-file-import")]),_vm._v("Importar ")],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":_vm.create}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1)],1)],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.costs,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":"Cargando Datos","search":_vm.search,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right'
            }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.groups,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Todos los Grupos","prepend-inner-icon":"filter_alt","single-line":"","outlined":"","dense":"","clearable":"","footer-props":{
                    showFirstLastPage: true,
                    firstIcon: 'fas fa-angle-double-left',
                    lastIcon: 'fas fa-angle-double-right',
                    prevIcon: 'fas fa-angle-left',
                    nextIcon: 'fas fa-angle-right'
                    }},model:{value:(_vm.groupFilter),callback:function ($$v) {_vm.groupFilter=$$v},expression:"groupFilter"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.loading,"label":"Buscar","append-icon":"fas fa-search"},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.has_dimension)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 info--text",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-ruler-combined")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.width)+"x"+_vm._s(item.height))])]):_vm._e(),(item.has_article)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 info--text",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-link")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.article.sku))])]):_vm._e()]}},{key:"item.price",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price,'$',0))+" ")]}},{key:"item.is_active",fn:function(ref){
                    var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":!_vm.$can('update','Cost')},on:{"change":function($event){return _vm.updateStatus(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.unit_name",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticClass:"chip",attrs:{"outlined":"","label":""}},[_vm._v(_vm._s(item.unit_name))])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_c('v-icon',[_vm._v("fas fa-edit")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showPriceLog(item)}}},[_c('v-icon',{},[_vm._v("fas fa-clipboard-list")])],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('cost-create-update',{attrs:{"cost":_vm.newCost,"groups":_vm.groups},on:{"save":_vm.save}})],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.showPriceLogDialog),callback:function ($$v) {_vm.showPriceLogDialog=$$v},expression:"showPriceLogDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('price-log-table',{attrs:{"cost":_vm.selectedCost},on:{"close":function($event){_vm.showPriceLogDialog = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }