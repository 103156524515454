import interpolate from 'interpolate'
import ability from './ability'
require('./ability')


const  updateAbility=(user)=> {
    const permissions = user.user_role.permits
    const rules=[]
    permissions.forEach(p => {
      const conditionString = interpolate(p.condition,{user})
      rules.push({
        action:p.action,
        subject:p.subject,
        conditions:JSON.parse(conditionString)
      })
    });
    console.log(rules)
    ability.update(rules); 
    console.log(ability.rules)
}

export default updateAbility

