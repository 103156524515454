var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success","dark":""},on:{"click":_vm.create}},[_c('v-icon',[_vm._v("add")]),_vm._v(" Nuevo ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.costs,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":"Cargando Datos","search":_vm.search,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right'
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-select',{attrs:{"items":_vm.groups,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Todos los Grupos","single-line":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.groupFilter),callback:function ($$v) {_vm.groupFilter=$$v},expression:"groupFilter"}}),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search"},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('cost-create-update',{attrs:{"cost":_vm.newCost,"groups":_vm.groups},on:{"save":_vm.save}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_c('v-icon',[_vm._v("create")])],1),_c('v-btn',{staticClass:"error--text",attrs:{"x-small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }