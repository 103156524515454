<template>
 <v-container class="">
   <v-row class="justify-end py-2">
     
        <export-transactions
        :filter="filter"
        :options="options"
        :disabled="loading"
        :search="search"
        />
     
   </v-row>
     <v-row class="" >
      <v-col align-self="center">
        <v-text-field hide-details @change="()=>{options.page=1;fetch()}" :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada Desde:"></v-text-field>
      </v-col>
      <v-col align-self="center">
        <v-text-field hide-details @change="()=>{options.page=1;fetch()}" :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada Hasta:" ></v-text-field>
      </v-col>
     </v-row>
     <v-row>
      <v-col>
        <v-select
          v-model="filter.tipo"
          :items="tipos"
          item-text="name"
          item-value="id"
          label="Tipo"
          placeholder="Todas los Tipos"
          @change="()=>{options.page=1;fetch()}"
          :disabled="loading"
          outlined
          hide-details
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="filter.store"
          :items="stores"
          item-text="name"
          item-value="id"
          label="Bodega"
          placeholder="Todas las Bodegas"
          @change="()=>{options.page=1;fetch()}"
          :disabled="loading"
          outlined
          hide-details
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col>
      </v-row>
      <v-row class="justify-end">
      <v-col cols="6" align-self="center">
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="fas fa-search"
          @keyup="searchTimeOut"
          :disabled="loading"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="headers"
          :items="transactions"
          :items-per-page="10"
          :options.sync="options"
          :server-items-length="parseInt(totalItems)"
          :loading="loading"
          :search="search"
          @update:options="fetch"
          sort-by='created_at'
          
          :item-class="itemRowStyle"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }">
          <template v-slot:item.name="{ item }">
            <div class="caption">
              {{item.name}}
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div class="caption">
              {{item.created_at | moment('DD/MM/YYYY')}}
            </div>
          </template>
          <template v-slot:item.article_sku="{ item }">
            <div class="caption">
              {{item.article_sku}}
            </div>
          </template>
          <template v-slot:item.article_name="{ item }">
            <div class="caption">
              [{{item.article_sku}}]
            </div>
            <div class="caption">
              {{item.article_name}}
            </div>
          </template>
          <template v-slot:item.price="{ item }">
            <div v-if="item.tipo_in_out">{{item.price | currency('$', 0)}}</div>
            <div v-else class="error--text">({{item.price | currency('$', 0)}})</div>
          </template>
          <template v-slot:item.total="{ item }">
            <div v-if="item.tipo_in_out">{{item.price*item.qty | currency('$', 0)}}</div>
            <div v-else class="error--text">({{Math.round(item.price)*Math.round(item.qty)  | currency('$', 0)}})</div>
          </template>
          <template v-slot:item.created_by="{ item }">
            {{`${item.created_by.first_name} ${item.created_by.last_name}`}}
          </template>
          <template v-slot:item.store_name="{item}">
            <div class="caption">
              {{item.store_name}}
            </div>
          </template>
          <template v-slot:item.created_by_full_name="{item}">
            <div class="caption">
              {{item.created_by_full_name}}
            </div>
          </template>
          <template v-slot:item.tipo="{ item }">
          <div class="caption">
            {{item.tipo_name}}<v-icon class="ml-2 align-items-center">{{item.tipo_in_out?'login':'logout'}}</v-icon>
          </div>
          </template>
          <template v-slot:item.qty="{ item }">
             <div v-if="item.tipo_in_out">{{item.qty|number('0,0[.]0')}}</div>
            <div v-else class="error--text">({{item.qty|number('0,0[.]0')}})</div>
          </template>
          <template v-slot:item.balance="{ item }">
             {{item.balance|number('0,0[.]0')}}
          </template>
          <template v-slot:item.actions="{ item }">
             <consume-by-o-t  v-if="!item.tipo_in_out" :article="item.article" :transaction="item"/>
          </template>
        </v-data-table>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import {fetchTransactions, fetchTipos} from '@/api/stocks'
import ExportTransactions from '@/components/stocks/ExportTransactions.vue'
import ConsumeByOT from '@/components/stocks/ConsumeByOT.vue'
import moment from 'moment'
export default{
    name: 'Transactions',
    components:{
      ExportTransactions,
      ConsumeByOT
    },
    async mounted(){
     this.tipos=await fetchTipos()
     this.fetch()
    },
    computed:{
        stores(){
          return this.$store.getters.getStores
        }
    },
    data(){
        return{
            headers:[
            { text: 'Creada', value: 'created_at' ,class:'table-header-2',sortable:false,},
            {text:'Nro.',value:'id',class:'table-header-2',sortable:false,},
            { text: 'Usuario', value: 'created_by_full_name' ,class:'table-header-2',sortable:false,},
            { text: 'Articulo', value: 'article_name' ,class:'table-header-2',sortable:false,},
            { text: 'Ref.', value: 'notes',class:'table-header-2',sortable:false},
            { text: 'Bodega', value: 'store_name',class:'table-header-2',sortable:false},
            { text: 'Movimiento', value: 'tipo',class:'table-header-2' ,sortable:false,width:'10%'},
            { text: 'Cant.', value: 'qty',class:'table-header-2',sortable:false,align:'end' },
            { text: 'Valor', value: 'price',class:'table-header-2',sortable:false,align:'end'},
            { text: 'Total', value: 'total',class:'table-header-2',sortable:false,align:'end'},
            { text: '', value: 'actions',class:'table-header-2',sortable:false,align:'end'},
            ],
            tipos:[],
            transactions:[],
            transactionLoading:false,
            search:'',
            filter:{
                created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
                created_before:'',
                store:'',
                tipo:'',
            },
            loading:false,
            totalItems:0,
            timer:''

        }
    },
    methods:{
        async fetch(){
            this.loading=true
            let sort=''
            if(!this.filter.created_after) this.filter.created_after=''
            if(!this.filter.created_before) this.filter.created_before=''
            if (!this.filter.store) this.filter.store=''
            const {sortBy, sortDesc, page, itemsPerPage }=this.options
            sort=sortBy[0]
            const query=`ordering=${sortDesc[0]?sort:'-' + sort}&page=${page}&page_size=${itemsPerPage}&store=${this.filter.store}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&search=${this.search}&tipo=${this.filter.tipo}`
            await fetchTransactions(query)
            .then(res=>{
                this.totalItems=res.count
                this.transactions=res.results.map(r=>{
                  r.article.consume=r.consume
                  return r
                })
                this.loading=false
            })
        },
        searchTimeOut() {  
            //Función para hacer búsqueda lazy
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.options.page=1
                this.fetch()
            }, 1000);
        },
        itemRowStyle(item){
            return item.tipo_in_out&&item.tipo_price_effect ? 'font-weight-black':''
        },
    }
}

</script>