<template>
<div>
    <v-btn text small @click.stop="displayDialog" :loading="loading">
        <v-icon class="mr-2">fas fa-print</v-icon>
        Imprimir
    </v-btn>
    <v-dialog v-model='display' max-width="400">
            <v-card>
                <v-card-title><span class="titulo text-h5">Imprimir Orden de Trabajo</span> </v-card-title>
                <v-card-text>
                    <v-checkbox
                    v-model="user_parameters.wodocumentprintprocesstable"
                    label="Incluir tabla de procesos"
                    dense
                    @change="changeParameters"
                    />
                    <v-checkbox
                    v-model="user_parameters.wodocumentprintsuppliestable"
                    label="Incluir tabla de materiales"
                    dense
                    @change="changeParameters"
                    />
                    <v-checkbox
                    v-model="user_parameters.wodocumentprintsupppliesresume"
                    label="Incluir resumen de materiales"
                    dense
                    @change="changeParameters"
                    />
                    <v-checkbox
                    v-model="user_parameters.wodocumentprintorderbyfamily"
                    label="Productos ordenados por familia"
                    dense
                    @change="changeParameters"
                    />
                </v-card-text>
                <v-card-actions class="d-flex justify-center pb-4">
                        <v-btn :loading="loading" color="primary" @click.stop="print" :disabled="disable_button">
                            Aceptar
                        </v-btn>
                        <v-btn text @click.stop="display=false">
                            Cancelar
                        </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</div>
</template>
<script>
import notifications from '@/utils/notifications'
import {pdfWorkOrder} from '@/doc_generators/workOrder'
import {getWorkOrder} from '@/api/production'
export default{
    name:'PrintWorkOrder',
    props:{
        workorder: {
            type: Object,
            required: false
        },
        workorder_id: {
            type: Number,
            required: false,
            default: 0
        },
        //Si esta la orden completa o no si no esta completa tengo que traerla de la base de datos
        //Esto me permite usarlo de las 2 maneras sin hacer doble llamado a la base de datos
        loadWorkOrder:{
            type: Boolean,
            required: false,
            default:false
        }
    },
    data() {
        return {
            display: false,
            loading: false,
            disable_button: false,
            user_parameters:this.$store.getters.getUserParameters ||{
                wodocumentprintprocesstable: true,
                wodocumentprintsuppliestable: true,
                wodocumentprintsupppliesresume: true,
                wodocumentprintorderbyfamily: true,
            }
        }
    },
    methods:{
        displayDialog(){
            if(this.workorder||(this.workorder_id&&this.loadWorkOrder)) this.display = true
            else notifications.showError('Por favor seleccione una orden de trabajo')
        },
        async print(){
            this.loading = true
            const workorder = this.loadWorkOrder? await getWorkOrder(this.workorder_id):this.workorder
            pdfWorkOrder(workorder,this.$store.getters.getUser,this.user_parameters)
            .then(()=>{
                this.display = false
            })
            .catch(err=>{
                notifications.showError(err)
            })
            .finally(()=>{
                this.loading = false
            })
        },
        async getWorkOrderData(){
            await getWorkOrder(this.workorder_id)
            .then(async (data)=>{
                return data
            })
            .catch((e)=>{
                notifications.showError('Error '+e)
            })
        },
        changeParameters(){
            this.disable_button = true
            this.$store.dispatch('updateUserParameters', this.user_parameters)
            .then(()=>{
                this.disable_button = false
            })

        }

    }
}

</script>