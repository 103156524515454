<template>
  <div>
    <v-simple-table dense>  
      <tbody>
        <tr class="text-end">
          <td colspan="2">
            <file-input @change="addFiles" class="pa-2 my-4"/>
          </td>
        </tr>
        <tr v-for="oldFile in cloudFiles" :key="oldFile.url">
          <td :class="oldFile.delete?'red lighten-5':''">
              <a :href="oldFile.url" target="_blank">{{oldFile.name}}</a>
          </td>
          <td class="text-end red lighten-5" v-if="oldFile.delete">
            <v-icon small class="info--text" @click="oldFile.delete=!oldFile.delete">fas fa-redo</v-icon>
          </td>
          <td class="text-end" v-else>
            <v-icon x-small class="error--text" @click="oldFile.delete=!oldFile.delete">fas fa-trash</v-icon>
          </td>
        </tr>
        <tr v-for="(file,index) in files" :key="index">
          <td>
            {{file.name}}
          </td>
          <td class="text-end">
            <v-icon x-small @click="removeLocalFile(file)" class="error--text">fas fa-trash</v-icon>
          </td>
        </tr>
      </tbody>
    </v-simple-table> 
    <div class="d-flex justify-center pa-2">
      <v-btn color="success" :loading="loading" class="mt-4" @click="saveFiles"><v-icon>fas fa-cloud-upload-alt</v-icon> Guardar</v-btn>           
    </div> 
  </div>
</template>
<script>
import FileInput from '@/components/utils/FileInput.vue'
import {updateSalesNote} from '@/api/salesNotes'
import notifications from '@/utils/notifications'
export default {
  name:'FileManager',
  components:{
    FileInput
  },
  props:['oldFiles','sales_note'],
  data(){
    return {
      files:[],
      cloudFiles:this.oldFiles,
      loading:false
    }
  },
  methods:{
    addFiles(files){
      this.files=[...this.files,...files]
    },
    removeLocalFile(file){
      this.files.splice(this.files.indexOf(file),1)
    },
    async saveFiles(){
      this.loading=true
      var formData = new FormData()
      this.files.forEach(file => {
        formData.append(`file${this.files.indexOf(file)}`, file)
      })
      formData.append('oldFiles', JSON.stringify(this.cloudFiles))
      await updateSalesNote(this.sales_note,formData)
      .then(response => {
        notifications.showSuccess('Documentos actualizados corectamente')
        this.loading=false
        this.$emit('refresh')
      })
      .catch(error => {
        notifications.showError(error.response.data.message)
        this.loading=false
      })
    }
  },
  watch:{
    oldFiles(){
      this.cloudFiles=this.oldFiles
      this.files=[]
    }
  }
  
}
</script>