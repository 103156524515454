<template>
  <v-container>
    <div>
      <v-card-title
        class=""
      >
       {{item.id>-1?'Actualizar Origen':'Crear Origen'}}
      </v-card-title>
      <v-card-subtitle>
        {{item.id>-1?'Actualice los datos del Origen':'Ingrese los datos del Origen'}}
      </v-card-subtitle>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Nombre'
            v-model='item.name'
            dense
            outlined
            :error-messages="errors"
            :loading="loading"
            ></v-text-field>
          </ValidationProvider>
            <div class="text-center">
              <v-btn v-if="item.id>-1" color="error" class="mr-2" @click="()=>{showConfirmDelete=true}" outlined :loading="loading"><v-icon class="mr-2">fas fa-trash</v-icon>Eliminar</v-btn>
              <v-btn type="submit" color="success" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
            </div>
        </v-form>
      </ValidationObserver>
    </div>
    <confirm
      v-if="item"
      v-model="showConfirmDelete" 
      :message="`¿Confirma eliminar el registro? Esta operación no se puede deshacer.`"
      @ok="deleteItem(item)"
      @cancel="()=>{showConfirmDelete=false;itemToDelete=''}"/>
  </v-container>
</template>
<script>

import {getOrigin,createOrigin,updateOrigin,deleteOrigin} from '@/api/salesNotes'
import notifications from '@/utils/notifications'
export default {
  name:'OriginCreateUpdate',
  props:['id'],
  data() {
    return {
      item:'',
      loading:false,
      showConfirmDelete:false,
    }
  },
  methods:{
    async save(){
      this.loading=true
      if(this.id>-1){
        await updateOrigin(this.id,{
          name:this.item.name,
        })
        .then(async (res)=>{
          notifications.showSuccess('Origen guardado correctamente')
          this.item=""
          this.$emit('updated')
          await this.$store.dispatch('getOrigins')
          this.cancel()
        })
        .catch(err=>{
          notifications.showError('Error', 'Actualizando Origen '+err)
        })
        this.loading=false
      }
      else{
        await createOrigin({
          name:this.item.name,
        })
        .then(async (res)=>{
          notifications.showSuccess('Éxito','Origen creado correctamente')
          await this.$store.dispatch('getOrigins')
          this.$emit('close')
          this.$emit('updated')
          this.cancel()
        })
        .catch(err=>{
          notifications.showError('Error', 'Actualizando Origen '+err)
        })
        this.loading=false
      }
      
    },
    async get(){
      if(this.id>-1){
        this.loading=true
        await getOrigin(this.id)
        .then(res=>{
          this.item=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando Industria '+err)
        })
        this.loading=false
      }
      else{
        this.item={
          id:this.id,
          name:'',
        }
      }
    },
    deleteItem(item){
      this.loading=true
      deleteOrigin(item.id)
      .then(async res=>{
        notifications.showSuccess('Industria eliminada correctamente')
        this.showConfirmDelete=false
        await this.$store.dispatch('getIndustries')
        this.$emit('close')
        this.$emit('updated')
        this.cancel()
      })
      .catch(err=>{
        notifications.showError('Error eliminando Industria '+err)
      })
      this.loading=false
    },
    cancel(){
      this.name=""
      this.loading=false
      this.showConfirmDelete=false
      this.$emit('close')
    }
  },
  watch:{
    id:{
      handler:'get'
    }
  },
  created(){
    this.get()
  }

}
</script>

<style>

</style>