<template>
  <v-container>
    <v-row class="mb-10 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo">Roles</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn class="ml-4" color="success"  :to="`users/role-create-update/-1`">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="10"
          :search="search"
          :loading="loading"
          loading-text="Cargando Datos"
          :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
          }"
        >
          <template v-slot:top>
            <v-row justify="end">
              <v-col cols="6">
                <v-text-field
              hide-details
              prepend-inner-icon="fas fa-search"
              single-line
              placeholder="Buscar"
              v-model="search"
              ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn text :to="`users/role-create-update/${item.id}`" small><v-icon >fas fa-edit</v-icon></v-btn>
            <v-btn small  text class="ml-2 error--text"><v-icon small>fas fa-trash</v-icon></v-btn>
          </template>
        </v-data-table>
  </v-container>
</template>

<script>
import {fetchRoles} from '@/api/auth'
export default {
  name: 'RolesList',
  data() {
    return {
      items: [],
      search:'',
      headers: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
            class:'table-header'
          },
          { text: 'Acciones',value:'actions',align:'center',class:'table-header'}
        ],
      loading:false
    }
  },
  async created(){
    this.loading=true
    this.items = await fetchRoles()
    .catch((e)=>notifications.showError('Error de conexión '+e))
    this.loading=false
  },

}
</script>

<style>

</style>