<template>
    <div>
        <v-btn text small @click.stop="displayDialog">
            <v-icon class="mr-2">fas fa-rss</v-icon>
            Importar Factronica
        </v-btn>
        <v-dialog v-model='show' max-width="500" persistent>
            <ValidationObserver v-slot="{handleSubmit}">
                <v-form @submit.prevent="handleSubmit(submitCustomer)" v-if="customer.rut!=''">
                    <v-card >
                        <v-card-item>
                            <v-card-title color="primary">Importar Cliente</v-card-title>
                            <v-card-subtitle>Cliente Encontrado ID:{{customer.id_empresa}}</v-card-subtitle>
                            <v-card-text>
                                <v-text-field
                                dense
                                type="text"
                                label='RUT'
                                v-model='customer.rut'
                                filled
                                outlined
                                readonly
                                ></v-text-field>
                                <v-text-field
                                dense
                                type="text"
                                label='Razon Social'
                                v-model='customer.razon_social'
                                filled
                                outlined
                                readonly
                                ></v-text-field>
                                <v-text-field
                                dense
                                type="text"
                                label='Nombre Fantasia'
                                v-model='customer.nombre_fantasia'
                                filled
                                outlined
                                readonly
                                ></v-text-field>
                                <ValidationProvider name="Vendedor" rules="required" v-slot="{ errors }">
                                    <seller-selection 
                                    v-model="customer.seller"
                                    icon="fas fa-id-badge"
                                    :errors="errors"/>
                                </ValidationProvider>
                            </v-card-text>
                        </v-card-item>
                        <v-card-actions>
                            <v-btn type="submit" :loading="loading" color="success">
                                Enviar
                            </v-btn>
                            <v-btn @click="close" text>
                                Cancelar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </ValidationObserver>
            <ValidationObserver v-slot="{handleSubmit}">
                <v-form @submit.prevent="handleSubmit(submitLookforCustomer)" v-if="customer.rut==''">
                    <v-card >
                        <v-card-item>
                            <v-card-title>Importar Cliente</v-card-title>
                            <v-card-subtitle>Busqueda de cliente en FACTRONICA</v-card-subtitle>
                            <v-card-text>
                                <ValidationProvider name="RUT" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                    dense
                                    type="text"
                                    label='RUT del Cliente'
                                    hint="Sin puntos con guion"
                                    v-model='customer_rut'
                                    outlined
                                    :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-card-text>
                        </v-card-item>
                        <v-card-actions>
                            <v-btn type="submit" :loading="loading" color="success">
                                Enviar
                            </v-btn>
                            <v-btn text @click="close">
                                Cancelar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </ValidationObserver>
        </v-dialog>
    </div>
    </template>
    <script>
    import {getFactronicaCustomer,importFactronicaCustomer} from '@/api/factronica'
    import SellerSelection from '@/components/customers/SellerSelection'
    import notifications from '../../utils/notifications'
    export default {
        components: {
            SellerSelection
        },
        props: {
            
        },
        data() {
            return {
                show: false,
                customer_rut:'',
                loading:false,
                customer:{
                    rut:'',
                    razon_social:'',
                    nombre_fantasia:'',
                    seller:{email:''}
                }
            }
        },
        methods: {
            displayDialog() {
                this.show=true

            },
            close(){
                this.customer_rut=''
                this.show=false
                this.customer={rut:'',
                    razon_social:'',
                    nombre_fantasia:'',
                    seller:''}
            },
            async submitLookforCustomer(){
                this.loading=true
                await getFactronicaCustomer(this.customer_rut.trim())
                .then((res)=>{
                    console.log(res)
                    this.customer=res
                    this.customer.seller=''
                })
                .catch((e)=>{
                    notifications.showError(e)
                })
                .finally(()=>{
                    this.loading=false
                })

            },
            async submitCustomer(){
                this.loading=true
                await importFactronicaCustomer(this.customer.rut,this.customer.seller.id)
                .then(res=>{
                    notifications.showSuccess('Cliente creado con éxito')
                    this.show=false
                })
                .catch(err=>{
                    notifications.showError(err)
                })
                .finally(()=>{
                    this.loading=false
                })
            }
        }
    }
    
    </script>