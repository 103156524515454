import axios from "axios"
import store from '@/store'


    //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')

export async function fetchCost(query) {
    return await axios.get(`product/cost?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getCost(id) {
    return await axios.get(`product/cost/${id}`)
        .then(res => {
            res.data
        })
        .catch(e => {
            throw e
        })
}


export async function createCost(item) {
    console.log('Item en la API',item)
    var cost={
        group: item.group,
        unit: item.unit,
        name: item.name,
        price: item.price,
        min_price: item.min_price,
        is_supply: item.is_supply,
        has_dimension: item.has_dimension||false,
        width: item.width||0,
        height: item.height||0,
        merma:item.unit==2?item.merma||0:0
    }
    
    if (item.has_article) cost={...cost, has_article: item.has_article,article_id: item.article_id,price_update_option: item.price_update_option}
    
    if(!item.is_supply) cost={...cost, machine: item.machine} 
    
    return await axios.post("product/cost", cost)
    .then(res => {
        return res.data
    })
    .catch(e => {
        throw e
    })
    
}

export async function uploadProcesses(processes) {
    return await axios.post(`product/cost/process-batch-upload`, processes)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function uploadSupplies(supplies) {
    return await axios.post(`product/cost/supply-batch-upload`, supplies)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function updateCost(item) {
    if (item.is_supply) delete item.machine
    
    return await axios.patch(`product/cost/${item.id}`, item)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchPriceUpdateLog(query){
    return await axios.get(`product/cost/price-log-list?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchGroups(is_supply) {
    return await axios.get(`product/group?is_supply=${is_supply}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createGroup(group) {
    return await axios.post("product/group", {
            name: group.name,
            is_supply: group.is_supply,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateGroup(group) {
    return await axios.put(`product/group/${group.id}`, {
            name: group.name,
            is_supply: group.is_supply,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchUnits() {
    return await axios.get("product/unit")
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

async function getUnit(id) {
    return await axios.get(`product/unit/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

async function getGroup(id) {
    return await axios.get(`product/group/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createUnit(item) {
    return await axios.post("product/unit", {
            name: item.name,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateUnit(item) {
    return await axios.put(`product/unit/${item.id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function fetchProducts(query) {
    return await axios.get(`product/?${query}`) 
        .then(res => {
            const products = res.data
            return products
        })
        .catch(e => {
            throw e
        })
}

export async function getProduct(productId) {
    return await axios.get(`product/${productId}`)
        .then(async res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getProductbySKU(sku) {
    return await axios.get(`product/bysku/${sku}`)
        .then(async res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function createProduct(product) {
    return await axios.post("product/", product)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e 
        })
}

export async function updateProduct(product) {
    console.log('Product Update Data',product)
    const data = {
        name: product.name,
        sku: product.sku,
        height: parseFloat(product.height).toFixed(3),
        width: parseFloat(product.width).toFixed(3),
        price: parseFloat(product.price).toFixed(3),
        sell_unit_m2: product.sell_unit_m2,
        status: product.status,
        customer: product.customer,
        family: product.family,
        line: product.line,
        receta: product.receta,
        recipe: product.recipe,
        supplies: product.supplies,
        logistic: product.logistic,
        is_margin: product.is_margin,
        margin_price: product.margin_price,
    }
   
    return await axios.put(`product/${product.id}`,data )
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateProductStatus(id, status) {
    return await axios.patch(`product/${id}`, {
            status: status,
        })
        .then(res => {
            return ''
        })
        .catch(e => {
            throw e
        })
}

export async function BatchUpdate(data) {
    return await axios.patch(`product/csv-loader`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchFamily() {
    return await axios.get(`product/family`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createFamily(family) {
    return await axios.post("product/family", {
            name: family.name,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getFamily(familyId) {
    return await axios.get(`product/family/${familyId}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}



export async function updateFamily(family) {
    return await axios.put(`product/family/${family.id}`, {
            name: family.name,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchLines() {
    return await axios.get(`product/line`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createLine(line) {
    return await axios.post("product/line", {
            name: line.name,
        })
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(e => {
            throw e
        })
}

async function getLine(lineId) {
    return await axios.get(`product/line/${lineId}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateLine(line) {
    return await axios.put(`product/line/${line.id}`, {
            name: line.name,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}