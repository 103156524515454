<template>
  <v-container>
    <v-text-field
    v-model='work_order'
    label="Orden de Trabajo"
    outlined
    :append-icon="work_order_finded?'fas fa-check':'fas fa-search'"
    :loading="loadingWorkOrder"
    @keydown="searchWorkOrder"
    ></v-text-field>
    <v-textarea
    v-model='work_order_details'
    label="Detalles"
    :readonly="true"
    outlined
    rows="3"
    no-resize
    overflow="auto"
    :loading="loadingWorkOrder"
    v-if="work_order_finded"
    ></v-textarea>
    <v-row justify="end">
        <v-btn 
        color="success"
        :disabled="!work_order_finded"
        :loading="updating"
        @click="update()"
        >Vincular</v-btn>
        <v-btn
        color="error"
        text
        @click="update(true)"
        :loading="updating"
        >
            Desvincular
        </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import {getWorkOrder} from '@/api/production'
import {updatePurchase} from '@/api/stocks'
import notifications from '@/utils/notifications'
import moment from 'moment'

export default {
data: () => ({
    work_order: '',
    work_order_details: '',
    work_order_finded: false,
    loadingWorkOrder: false,
    timer:'',
    updating: false,
    
}),
props:['purchase_order'],
methods:{
    searchWorkOrder() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(async () => {
        this.loadingWorkOrder = true
        await getWorkOrder(this.work_order)
        .then((data)=>{
            this.work_order_finded=true 
            this.loadingWorkOrder=false
            const {customer_name,created_at,name}=data
            this.work_order_details=`${customer_name} ${moment(created_at).format('DD/MM/yyy')} ${name}`
        })
        .catch((e)=>{
            notifications.showInfo('Orden de Trabajo no encontrada')
            this.work_order_finded=false
            this.loadingWorkOrder=false
        })
      }, 800);
    },
    async update(unlink=false) {
        if(unlink) this.work_order=''   //Si se desvincula se limpia el campo
        this.updating=true
        await updatePurchase(this.purchase_order.id,{work_order:this.work_order})
        .then(()=>{
            this.$emit('updated')
            this.work_order=''
            this.work_order_finded=false
            this.work_order_details=''
            notifications.showSuccess(`Orden de Compra #${this.purchase_order.id} actualizada`)
            this.updating=false
        })
        .catch((e)=>{
            this.updating=false
            notifications.showError('Error al actualizar la Orden de Compra '+e)
        })
    }
},


}
</script>

<style>

</style>