<template>
<v-container>
  <h1>Procesos Completados</h1>
  <v-card class="pa-4">
    <v-row>
      <v-btn-toggle
        v-model="showingMachines"
        dense
        multiple
      >
        <v-btn class="mx-2" :value="machine" v-for="machine in machines" :key='machine.id'>{{machine.name}}</v-btn>
      </v-btn-toggle>
    </v-row>
  </v-card>
  <v-tabs 
      background-color="transparent"
      color="basil"
      center-active
      v-model="tab"
    >
      <v-tab v-model="tab" class="green--text" v-for='machine in showingMachines' :key='showingMachines.indexOf(machine)'>{{machine.name}}</v-tab>
      
      <v-tabs-items v-model="tab">
      <v-tab-item v-for='machine in showingMachines' :key='showingMachines.indexOf(machine)'>
        {{machine.name}}
        <v-simple-table>
        <thead>
          <tr>
            <th>
              Orden
            </th>
            <th>
              Proceso
            </th>
            <th>
              Orden Trabajo
            </th>
            <th>
              Producto
            </th>
            <th>
              Mt2
            </th>
            <th>
              Cantidad
            </th>
          </tr>
        </thead>
          <tr 
          v-for="item in machine.process_orders" 
          :key="machine.process_orders.indexOf(item)" 
          class="recipe-row"
          >
            <td>
              {{machine.process_orders.indexOf(item) + 1}}
            </td>
            <td>
              {{item.name}}
            </td>
            <td>
              <v-btn text outlined @click="displayWorkOrder(item)">{{item.product.work_order}}</v-btn>
            </td>
            <td>
              {{item.product.name}}
            </td>
            <td>
              {{item.product.mts2}}
            </td>
            <td>
              {{item.product.qty}}
            </td>
          </tr>
      </v-simple-table>
      </v-tab-item>
      
    </v-tabs-items>
    </v-tabs>
    <v-dialog  v-model='showWorkOrder' max-width="1200">
    <v-card class="pa-4" >
      <work-order-card :work_order='workOrderDisplay' /> 
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import {fetchCompletedMachines,getWorkOrder} from '@/api/production'
import WorkOrderCard from '@/components/production/WorkOrderCard'
import notifications from '@/utils/notifications'

export default {
  name:'CompletedProcess',
  components:{WorkOrderCard},
  data() {
    return {
      machines: [],
      showingMachines:[],
      showWorkOrder:false,
      workOrderDisplay:{},
      work_order_card_loading:false,
      saving:false,
      tab:null
    }
  },
  methods:{
    async getMachines(){
      this.machines = await fetchCompletedMachines()
      .catch((e)=>{
        notifications.showError('Error '+e)
      })
    },
    async displayWorkOrder(item){
      await getWorkOrder(item.product.work_order)
      .then((data)=>{
        this.workOrderDisplay=data
        this.showWorkOrder=true
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
      })
    },
  },
  async created(){
    await this.getMachines()
  },
}
</script>

<style>

</style>