<template>
<v-container>
  <ValidationObserver v-slot="{handleSubmit}">
  <v-form @submit.prevent="handleSubmit(saveUser)">
    <v-row class="mb-10 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo">{{user.id ? 'Actualizar Usuario':'Crear Usuario'}}</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
        <v-btn @click="$router.push('/users')" text class="ml-2"><v-icon>fas fa-angle-left</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
          <v-text-field
          label='Nombre'
          v-model='user.first_name'
          outlined
          :error-messages="errors"
          dense
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider name="Apellido" rules="required" v-slot="{ errors }">
          <v-text-field
          label='Apellido'
          v-model='user.last_name'
          outlined
          :error-messages="errors"
          dense
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
          <v-text-field
          label='Correo Electrónico'
          :disabled="user.id"
          v-model='user.email'
          outlined
          :error-messages="errors"
          dense
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider v-if="!user.id" name="Contraseña" rules="required" v-slot="{ errors }">
          <v-text-field
          label='Contraseña'
          v-model='user.password'
          placeholder="Contraseña"
          persistent-hint
          hint="Debe poseer mas de 4 caracteres números y letras"
          outlined
          :error-messages="errors"
          dense
          ></v-text-field>
        </ValidationProvider>
        <div class="mb-6" v-if="user.id">
          <v-icon>lock</v-icon>
          <v-btn v-if="user.id" @click="sendEmail" :loading="loading" :disabled="sent?true:false" :color="error?'error':'primary'">{{sent? 'Correo Enviado':'Restaurar Contraseña'}}</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <ValidationProvider name="Teléfono" rules="required" v-slot="{ errors }">
          <v-text-field
          label='Teléfono'
          v-model='user.phone'
          outlined
          :error-messages="errors"
          dense
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <div class="d-flex">
          <v-checkbox
          v-model="user.is_seller"
          default
          label="Vendedor"
          class="mr-4"
          />
          <v-checkbox
            v-model="user.is_staff"
            default
            label="Staff"
            />
        </div>
      </v-col>
      <v-col col="12" md="6">
        <ValidationProvider v-if="user.is_seller" name="Comision" rules="required" v-slot="{ errors }">
          <v-text-field
          label='Comisión'
          v-model='user.comision'
          type="number"
          outlined
          :error-messages="errors"
          :min="0"
          dense
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col col="12" md="6">
          <v-text-field
          label='Unidad de Negocios'
          v-model='user.bussiness_unit'
          placeholder="Unidad de Negocios"
          outlined
          :error-messages="errors"
          :min="0"
          dense
          ></v-text-field>
      </v-col>
      <v-col col="12" md="6" v-if="hasSoftnetIntegration">
        <v-text-field
          label='SOFTNET ID'
          v-model='user.softnet_id'
          outlined
          dense
          ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          outlined
          :items="roles"
          item-value="id"
          item-text="name"
          v-model=user.role
          label="Rol"
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-form>
  </ValidationObserver>
</v-container>
</template>
<script>
import {getUser,createUser,updateUser,passwordResetSendEmail,fetchRoles} from '@/api/auth'
import notifications from '@/utils/notifications'
export default {
  name:'CreateupdateUser',
  data:()=>({
    user:{
      first_name:'',
      last_name:'',
      email:'',
      phone:'',
      password:'',
      is_seller:false,
      is_staff:false,
      comision:0,
      role:'2',
      bussiness_unit:'',
    },
    loading:false,
    sent:false,
    error:false,
    roles:[]
  }),
  async created(){
    this.loading=true
    if(this.$route.params.id){
      await getUser(this.$route.params.id)
      .then((user)=>{
        this.user=user
      })
      .catch((e)=>notifications.showError('Error de conexión '+e))
    }
    await fetchRoles()
    .then((roles)=>{
      this.roles=roles
    })
    .catch((e)=>notifications.showError('Error de conexión '+e))
    this.loading=false
  },
  methods:{
    async saveUser(){
      if(this.user.id){
        await updateUser(this.user)
        .then(()=>{
        notifications.showSuccess('Usuario '+ this.user.first_name + ' '+ this.user.last_name + ' Actualizado')
        this.$router.push({name:'Users'})
        })
        .catch((e)=>{
          notifications.showError('Error actualizando el Usuario '+e)
        })
      }
      else{
        await createUser({
          email:this.user.email,
          username:this.user.email,
          password:this.user.password,
        })
        .then(async (res)=>{
          this.user.id=res.id
          await updateUser(this.user)
          .then(()=>{
          notifications.showSuccess('Usuario '+ this.user.first_name + ' '+ this.user.last_name + ' Actualizado')
          this.$router.push({name:'Users'})
          })
          .catch((e)=>{
            notifications.showError('Error actualizando el Usuario '+e)
          })
          console.log(res)
        })
        .catch((e)=>{
          notifications.showError('Error Creando Nuevo Usuario '+e)
        })
      }
      //Actualizo el store con los Sellers
      this.$store.dispatch('getSellers')
    },
    async sendEmail(){
      this.loading=true
      await passwordResetSendEmail(this.user.email)
      .then(()=>{
        this.loading=false
        this.sent=true
        this.success=true
      })
      .catch(()=>{
        this.loading=false
        this.sent=true
        this.error=true
      })
    },
  },
  computed:{
    hasSoftnetIntegration(){
      return this.$store.getters.getCompany.softnet_integration_enabled
    }
  }
}
</script>