<template>
  <div class="d-flex justify-end" v-if="$can('create', 'Contact')">
    <v-btn v-if="action=='create'" small @click="showDialog=true" >
      Agregar Contacto
      <v-icon class="ml-2">fas fa-plus</v-icon>
    </v-btn>
    <v-btn icon small v-if="action=='edit'" @click="actionEdit">
      <v-icon small>fas fa-edit</v-icon>
    </v-btn>
    <v-dialog v-model="showDialog" max-width="600">
      <v-card :loading="loading">
        <v-card-title>
          <span v-if="action=='create'">Crear Contacto</span>
          <span v-if="action=='edit'">Editar Contacto</span>
          <span v-if="action=='delete'">Eliminar Contacto</span>
        </v-card-title>
        <v-card-subtitle>
          <span v-if="action=='create'">Llene el formulario con los datos del contacto</span>
          <span v-if="action=='edit'">Modifique los datos del contacto</span>
        </v-card-subtitle>
        <v-card-text>
          <ValidationObserver v-slot="{handleSubmit}">
            <v-form @submit.prevent="handleSubmit(submitContact)">
              <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="first_name"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Apellido" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  label="Apellido"
                  placeholder="Apellido"
                  v-model="last_name"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  label="Correo Electrónico"
                  placeholder="Email"
                  v-model="email"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Teléfono" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  label="Teléfono"
                  placeholder="Teléfono"
                  v-model="phone"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Cargo" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  label="Cargo"
                  placeholder="Cargo"
                  v-model="cargo"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <v-btn v-if="action!='create'" small color="error" class="mb-4" @click="showConfirmDelete=true">Eliminar<v-icon class="ml-2" small>fas fa-trash</v-icon></v-btn>
              <div class="d-flex justify-end">
                <v-btn text @click="cancel()">
                  Cancelar
                </v-btn>
                <v-btn type="submit" color="success" >
                  <v-icon class="mr-2">fas fa-sd-card</v-icon>
                  Guardar
                </v-btn>
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirm
      v-model="showConfirmDelete" 
      :message="`¿Confirma eliminar este contacto? Esta operación no se puede deshacer.`"
      @ok="deleteContact"
      @cancel="cancel"/>
  </div>
</template>

<script>
import notifications from '@/utils/notifications'
import {createContact,updateContact,deleteContact} from '@/api/customers'
export default {
  name: 'ContactCreateUpdate2',
  props:{
    customerId:{  // Propiedad para el id del cliente
      type:Number,
      required:true,
      default:null
    },
    value:{
      type:Object,
      default:null
    },
    action:{
      type:String,
      default:'create'
    },
    small:{
      type:Boolean,
      default:true
    }
  },
  data:()=>({
    first_name:'',
    last_name:'',
    email:'',
    phone:'',
    cargo:'',
    showDialog:false,
    showConfirmDelete:false,
    loading:false
  }),
  methods:{
    submitContact(){
      this.loading=true;
      if(this.action=='create'){
        this.addContact();
      }else if(this.action=='edit'){
        this.editContact();
              
      }else return
    },
    async addContact(){
      console.log('Creando contacto');
      //Verifico si existe un cliente para asignarle el contacto
      if(!this.customerId){
        notifications.showError('Error','Debe seleccionar un cliente para asignar el contacto');
        this.loading=false;
      }
      //Creo el contacto
      let contact={
        first_name:this.first_name,
        last_name:this.last_name,
        email:this.email,
        phone:this.phone,
        cargo:this.cargo,
        customer:this.customerId
      }
      await createContact(contact)
      .then(()=>{
        notifications.showSuccess('Contacto creado','El contacto ha sido creado exitosamente');
        this.$emit('created',contact);
        this.cancel();
      })
      .catch(()=>{
        notifications.showError('Error','Ha ocurrido un error al crear el contacto');
      })
      .finally(()=>{
        this.loading=false;
      });
    },
    async editContact(){
      console.log('Editando contacto');
      let contact={
        id:this.value.id,
        first_name:this.first_name,
        last_name:this.last_name,
        email:this.email,
        phone:this.phone,
        cargo:this.cargo,
        customer:this.customerId
      }
      await updateContact(contact)
      .then(()=>{
        notifications.showSuccess('Contacto actualizado','El contacto ha sido actualizado exitosamente');
        this.$emit('updated',contact);
      })
      .catch(()=>{
        notifications.showError('Error','Ha ocurrido un error al actualizar el contacto');
      })
      .finally(()=>{
        this.cancel();
      });
    },
    actionEdit(){
      this.first_name=this.value.first_name;
      this.last_name=this.value.last_name;
      this.email=this.value.email;
      this.phone=this.value.phone;
      this.cargo=this.value.cargo;
      this.showDialog=true;
    },
    async deleteContact(){
      this.loading=true;
      await deleteContact({id:this.value.id})
      .then(()=>{
        notifications.showSuccess('Contacto eliminado','El contacto ha sido eliminado exitosamente');
        this.$emit('deleted',this.value);
      })
      .catch(()=>{
        notifications.showError('Error','Ha ocurrido un error al eliminar el contacto');
      })
      .finally(()=>{
        this.cancel();
      });

    },
    cancel(){
      this.showDialog=false;
      this.showConfirmDelete=false;
      this.first_name='';
      this.last_name='';
      this.email='';
      this.phone='';
      this.cargo='';
      this.loading=false;
    }
  },
  mounted(){
    
  }

  // Your component's properties, methods, etc. go here
}
</script>

<style scoped>
/* Your component's styles go here */
</style>