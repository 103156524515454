<template>
  <div>
    <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="getExcel()" text><v-icon class="mr-2">far fa-file-excel</v-icon> Exportar</v-btn>
  </div>
  </template>
  
  <script>
  import notifications from '@/utils/notifications'
  import {excelCustomers} from '@/api/customers'
  import moment from 'moment'
  import numeral from 'numeral'
  export default {
    name:"ExportCustomers",
    props:['filters','options','disabled','search'],
    data() {
      return {
        loading: false,
      }
    },
    methods:{
      async getExcel(detail){
        this.loading=true
        const {sortBy, sortDesc, page, itemsPerPage }=this.options
        let sort = ''
        if(sortBy[0]=='seller'){
          sort = 'seller__first_name'
        }
        else {
          sort = sortBy[0]
        }
        var query = `ordering=${sortDesc[0]?`${sort}`:`-${sort}`}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&tipo=${this.filters.tipo}`
        if (this.filters.industry){
          query+=`&industry=${this.filters.industry}`
        }
        if (this.filters.rubro){
          query+=`&rubro=${this.filters.rubro}`
        }
        await excelCustomers(query)
        .then((response) => {
          const link = document.createElement('a');
          link.href=window.URL.createObjectURL(response);
          link.setAttribute('download', `export_customers_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loading=false
        })
        .catch((error) => {
          if(error.code=="ECONNABORTED") notifications.showError('Error Exportando: Tiempo de Espera Excedido')
          else notifications.showError('Error Exportando')
          this.loading=false
        });
      }
    }
  
  }
  </script>