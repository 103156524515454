<template>
  <v-card class="pa-8 px-10">
    <v-card-title v-if="!global&&!workOrder" class="titulo text-h4 mb-2">
      <v-icon class="mr-2">{{cost.is_supply?'fas fa-calculator':'fas fa-cogs'}}</v-icon>
      {{cost.id>0 ? 'Actualizar ':'Crear '}}
      {{cost.is_supply?'Material ':'Proceso '}}
      {{custom ? 'Adicional':''}}
    </v-card-title>
    <v-card-title v-else-if="global" class="titulo text-h4 mb-2">
      Agregar Costo Global <v-icon >language</v-icon>
    </v-card-title>
    <v-card-title v-else-if="workOrder" class="titulo text-h4 mb-2">
      Agregar Costo
    </v-card-title>
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(savecost)">
      <ValidationProvider v-if="!(custom||workOrder)" name="Group" rules="required" v-slot="{ errors }">
          <v-select
            v-model="cost.group"
            prepend-icon="fas fa-object-group"
            dense
            :items="groups"
            item-text="name"
            item-value="id"
            label="Grupo"
            placeholder="Seleccione un Grupo"
            single-line
            outlined
            clearable
            :error-messages="errors"
          />
        </ValidationProvider>
      <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
        <v-text-field
        label='Nombre'
        prepend-icon="mdi-file-document"
        v-model='cost.name'
        dense
        outlined
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Precio" rules="required"  v-slot="{ errors }">
        <v-text-field
        label='Costo'
        type="number"
        prepend-icon="fas fa-money-bill-wave"
        dense
        v-model='cost.price'
        
        outlined
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider v-if="!(global||workOrder)" name="Mínimo" rules="required" v-slot="{ errors }">
        <v-text-field
        label='Costo Mínimo'
        type="number"
        prepend-icon="fas fa-hand-holding-usd"
        dense
        v-model='cost.min_price'
        outlined
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Units" v-if="!workOrder" rules="required" v-slot="{ errors }">
          <v-select
            v-model="cost.unit"
            :items="units"
            item-text="name"
            item-value="id"
            label="Unidades"
            prepend-icon="mdi-gauge"
            dense
            placeholder="Seleccione la Unidad"
            single-line
            outlined
            clearable
            :loading="loading"
            :error-messages="errors"
          />
        </ValidationProvider>
        <ValidationProvider v-if='!cost.is_supply' name="Máquina" rules="required" v-slot="{ errors }">
          <v-select
            v-if="!cost.is_supply"
            v-model="cost.machine"
            prepend-icon="fas fa-cog"
            dense
            :items="machines"
            item-text="name"
            item-value="id"
            label="Área de Producción"
            placeholder="Seleccione un Área de Producción"
            clearable
            single-line
            outlined
            :loading="loadingMachines"
            :error-messages="errors"
          />
        </ValidationProvider>
        <!---Campo para MERMA -->
        <ValidationProvider v-if='!cost.is_supply && cost.unit==2 && !global' name="Merma" rules="required" v-slot="{ errors }">
          <v-text-field
          label='Merma Cant Fija'
          type="number"
          prepend-icon=""
          dense
          v-model='cost.merma'
          outlined
          :error-messages="errors"
          ></v-text-field>
        </ValidationProvider>
        <!-- Agregando dimensiones -->
        <div v-if="cost.is_supply && cost.unit==2 && !global">
          <v-checkbox
          v-model="cost.has_dimension"
          label="¿Tiene Dimensiones?"
          dense
          />
          <div v-if="cost.has_dimension" class="d-flex">
            <ValidationProvider  name="Dimensiones" rules="required" v-slot="{ errors }">
              <v-text-field
              label='Ancho (cms)'
              prepend-icon="fas fa-ruler-horizontal"
              v-model='cost.width'
              dense
              outlined
              :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider  name="Dimensiones" rules="required" v-slot="{ errors }">
              <v-text-field
              label='Alto (cms)'
              class="ml-4"
              prepend-icon="fas fa-ruler-vertical"
              v-model='cost.height'
              outlined
              dense
              :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
        </div>
          <!--- Vincular con Articulo de Inventario -->
        <div v-if="cost.is_supply && !custom && !global && !workOrder">
          <v-checkbox
          v-model="cost.has_article"
          label="¿Vincular con articulo de inventario?"
          dense
          />
          <div v-if="cost.has_article" class="d-block">
              <article-complete v-model="cost.article" :article_name="cost.article?cost.article.name:''" class="mb-6"/>
                <v-text-field
                v-if="cost.article"
                label='Articulo'
                class="mt-2"
                filled
                v-model='cost.article.name'
                outlined
                dense
                disabled
                ></v-text-field>
              <v-select
                v-model="cost.price_update_option"
                dense
                :items="price_update_options"
                item-text="name"
                item-value="value"
                label="Actualizar Precio"
                single-line
                outlined
                @change="updatePrice"
              />
              <v-alert
                type="info"
                dense
              >
                No se considera la unidad de compra del articulo a vincular
              </v-alert>
          </div>
        </div>
        <v-card-actions>  
          <v-btn :loading="loading" :disabled="loadingMachines" color="success" class="mx-auto" type="submit"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
        </v-card-actions>
    </v-form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import {fetchUnits} from '@/api/products'
import {fetchMachines} from '@/api/production'
import notifications from '@/utils/notifications'
import ArticleComplete from '@/components/stocks/ArticleComplete'

export default {
  name:'CostCreateUpdate',
  components:{
    ArticleComplete,
  },
  data:()=>({
     units:[],
     machines:[],
     loading:false,
     loadingMachines:false,
     //Usado para el formulario de enlace de articulo
     article:{
       name:'',
       price:0,
       avg:0,
       last_buy_price:0,
       },
     fetchingSku:false,
     price_update_options:[
       {name:'No actualizar Precio',value:0},
       {name:'Actualizar Precio promedio',value:1},
       {name:'Actualizar Ultimo precio',value:2},
     ],
  }),
  props:['cost','groups','custom','global','workOrder',],
  methods:{
    savecost(){
      //Revisa si tiene articulo y agrega el id de articulo al costo
      if(this.cost.has_article){
        this.cost.article_id = this.cost.article.id
      }
      this.$emit('close')
      //Si es un costo custom el objeto no posee cost.unit_name asi que lo agregamos
      if(this.custom||this.global){
        this.cost.unit_name=this.cost_unit.name
      }
      this.$emit('save',this.cost)
    },
    updatePrice(){
      if(this.cost.price_update_option==1){
        this.cost.price = this.cost.article.avg
      }else if(this.cost.price_update_option==2){
        this.cost.price = this.cost.article.last_buy_price ||0
      }
    },
  },
  computed:{
    //Computada que sirve para agregar costos custom que no traen la 
    //propiedad cost_unit
    cost_unit(){
      return this.units.find(u=>u.id==this.cost.unit)
    },
    
  },
  async created(){
    //crea la lista de unidades
    this.units=this.$store.getters.getUnits
  },
  async mounted(){
    if(!this.cost.is_supply){
    this.loadingMachines=true
    await fetchMachines()
    .then((data)=>{
      this.machines=data
      this.loadingMachines=false
    })
    .catch((err)=>{
        notifications.showError('Error en carga de datos '+err)
        this.loadingMachines=false
    })
    }
  },
}
</script>