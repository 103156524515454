var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loading)?_c('v-skeleton-loader',_vm._b({attrs:{"type":"actions,text,text,text,text,card,card"}},'v-skeleton-loader',_vm.attrs,false)):_vm._e(),(!_vm.loading)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveCustomer)}}},[_c('v-row',{staticClass:"mb-10 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.customer.id ? 'fas fa-edit':'fas fa-user-plus'))]),_vm._v(_vm._s(_vm.customer.id ? 'Actualizar Cliente':'Crear Cliente'))],1)]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-switch',{staticClass:"mt-0 pt-2 mr-2",attrs:{"inset":"","color":_vm.customer.status ? 'success':'error',"label":_vm.customer.status ? 'Activo':'Inactivo',"disabled":!_vm.canUpdateMainInformation},model:{value:(_vm.customer.status),callback:function ($$v) {_vm.$set(_vm.customer, "status", $$v)},expression:"customer.status"}}),_c('v-btn',{attrs:{"loading":_vm.saveLoading,"type":"submit","color":"success"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/customers')}}},[_c('v-icon',[_vm._v("fas fa-chevron-left")])],1)],1)],1),_c('v-card',{attrs:{"shaped":""}},[_c('v-card-title',[_vm._v("Información General")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.customer.id ? 'Modifique la información general del cliente.':'Ingrese la información general del cliente')+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"RUT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"RUT","prepend-icon":"fas fa-id-badge","hint":"Ingresar número con guión y dígito verificador","outlined":"","rules":[_vm.rules.rut],"error-messages":errors,"append-icon":_vm.rutValido?'fas fa-check':'',"disabled":!_vm.canUpdateMainInformation},on:{"change":function (){}},model:{value:(_vm.customer.rut),callback:function ($$v) {_vm.$set(_vm.customer, "rut", $$v)},expression:"customer.rut"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Giro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","prepend-icon":"fas fa-exclamation-circle","label":"Giro","outlined":"","error-messages":errors,"disabled":!_vm.canUpdateMainInformation},model:{value:(_vm.customer.giro),callback:function ($$v) {_vm.$set(_vm.customer, "giro", $$v)},expression:"customer.giro"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Razón Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Razón Social","prepend-icon":"fas fa-file-alt","outlined":"","error-messages":errors,"disabled":!_vm.canUpdateMainInformation},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Fantasía","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Nombre de Fantasía","prepend-icon":"fas fa-hat-wizard","disabled":!_vm.canUpdateMainInformation,"outlined":"","error-messages":errors},model:{value:(_vm.customer.fantasy),callback:function ($$v) {_vm.$set(_vm.customer, "fantasy", $$v)},expression:"customer.fantasy"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Teléfono","prepend-icon":"fas fa-phone","outlined":"","error-messages":errors,"disabled":!_vm.canUpdateMainInformation},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Condición de Pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Condición de Pago (Días)","prepend-icon":"fas fa-calendar-alt","type":"number","min":0,"outlined":"","error-messages":errors,"disabled":!_vm.canUpdateMainInformation},model:{value:(_vm.customer.payment),callback:function ($$v) {_vm.$set(_vm.customer, "payment", $$v)},expression:"customer.payment"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Vendedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('seller-selection',{attrs:{"disabled":!_vm.canUpdateMainInformation,"icon":"fas fa-id-badge","errors":errors},model:{value:(_vm.customer.seller),callback:function ($$v) {_vm.$set(_vm.customer, "seller", $$v)},expression:"customer.seller"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('tipo-selection',{attrs:{"filtro":false,"icon":"fas fa-id-badge","disabled":!_vm.canUpdateMainInformation},model:{value:(_vm.customer.tipo),callback:function ($$v) {_vm.$set(_vm.customer, "tipo", $$v)},expression:"customer.tipo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('industry-selection',{attrs:{"icon":"fas fa-space-shuttle","disabled":!_vm.canUpdateMainInformation,"filtro":false},model:{value:(_vm.customer.industry),callback:function ($$v) {_vm.$set(_vm.customer, "industry", $$v)},expression:"customer.industry"}})],1),_c('v-col',{attrs:{"col":"12","md":"6"}},[_c('rubro-selection',{attrs:{"icon":"fas fa-gifts","disabled":!_vm.canUpdateMainInformation,"filtro":false},model:{value:(_vm.customer.rubro),callback:function ($$v) {_vm.$set(_vm.customer, "rubro", $$v)},expression:"customer.rubro"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Comisión","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Comisión de Agencia","prepend-icon":"mdi-chart-timeline-variant","disabled":!_vm.canUpdateMainInformation,"type":"number","min":0,"outlined":"","error-messages":errors},model:{value:(_vm.customer.com_agencia),callback:function ($$v) {_vm.$set(_vm.customer, "com_agencia", $$v)},expression:"customer.com_agencia"}})]}}],null,true)})],1)],1)],1)],1)],1)]}}],null,false,3059032537)})],1):_vm._e(),(_vm.customer.id)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"shaped":""}},[_c('v-card-title',[_vm._v("Contactos")]),_c('v-card-subtitle',[_vm._v("Lista de Contactos")]),_c('v-card-text',[_c('contact-list',{attrs:{"customerId":_vm.customer.id},model:{value:(_vm.customer.contacts),callback:function ($$v) {_vm.$set(_vm.customer, "contacts", $$v)},expression:"customer.contacts"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"shaped":""}},[_c('v-card-title',[_vm._v("Direcciones")]),_c('v-card-subtitle',[_vm._v("Lista de Direcciones")]),_c('v-card-text',[_c('address-list',{attrs:{"customerId":_vm.customer.id},model:{value:(_vm.customer.addresses),callback:function ($$v) {_vm.$set(_vm.customer, "addresses", $$v)},expression:"customer.addresses"}})],1)],1)],1)],1):_vm._e(),_c('confirm',{attrs:{"message":"El RUT ingresado ya existe. Desea crear una sucursal?"},on:{"ok":function (){
      if(_vm.customer.id) { _vm.modifyCustomer() }
      else { _vm.addNewCustomer() }
      },"cancel":function (){_vm.customer.branch_office=0;_vm.showConfirm=false}},model:{value:(_vm.showConfirm),callback:function ($$v) {_vm.showConfirm=$$v},expression:"showConfirm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }