import axios from "axios"

export async function fetchMachines(query='') {
    return await axios.get(`production/machine?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchCompletedMachines() {
    return await axios.get(`production/machine-completed-processes`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchPendingMachines(query) {
    return await axios.get(`production/machine-pending-processes${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getPendingProcesses(id) {
    return await axios.get(`production/machine-pending-processes/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createMachine(machine) {
    return await axios.post("production/machine", machine)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateMachine(machine) {
    return await axios.patch(`production/machine/${machine.id}`, {
            name: machine.name,
            capacity: machine.capacity,
            hours_per_day:machine.hours_per_day,
            hour_control:machine.hour_control,
            saturday:machine.saturday,
            sunday:machine.sunday,
            sort:machine.sort,
            enabled:machine.enabled,
            production_unit_m2: machine.production_unit_m2
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createEquipment(equipment) {
    return await axios.post(`production/equipment`, equipment)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateEquipment(equipment) {
    return await axios.patch(`production/equipment/${equipment.id}`, {
            name: equipment.name,
            enabled:equipment.enabled
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function fetchWorkOrders(page = '', page_size = '', search = '', created_after = '', created_before = '', delivery_after = '', delivery_before = '', status = '', seller = '', sort = '') {
    return await axios.get(
            `production/work-order?ordering=${sort}&page=${page}&page_size=${page_size}&search=${search}&created_after=${created_after}&created_before=${created_before}&delivery_after=${delivery_after}&delivery_before=${delivery_before}&completed=${status}&sales_note__customer__seller=${seller}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getWorkOrder(id) {
    return await axios.get(`production/work-order/${id}`)
        .then(res => {
            res.data.additional_costs=res.data.additional_costs.length==0? []: res.data.additional_costs
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteWorkOrder(id) {
    return await axios.delete(`production/work-order/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateWorkOrder(workOrder) {
    return await axios.patch(`production/work-order/${workOrder.id}`, workOrder)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}



export async function createWorkOrder(workOrder) {
    console.log(workOrder)
    return await axios.post("production/work-order", workOrder)
        .then(res => {
            return res.data
        })
        .catch(e => {
            console.log(e)
            throw e
        })
}

//Especial para actualizar todo el planificador
export async function updatePlanner(machines) {
    return await axios.post("production/update-planner", machines)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateProcessOrder(process) {
    return await axios.patch(`production/process-order/${process.id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchProcessOrders(machine = '', completed = '') {
    return await axios.get(`production/process-order?machine=${machine}&completed=${completed}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getWorkOrderEstimate(products) {
    return await axios.post(`production/work-order-estimate`, {
            products: products
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function postProcessOrderComplete(data) {
    return await axios.post(`production/process-order-completion`, {
            processes: data.processes,
            equipment: data.equipment,
            notes: data.notes
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createSupplyConsumption(data) {
    return await axios.post(`production/supply-consumption`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createSupplyOrder(data) {
    return await axios.post(`production/supply-order`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteSupplyOrder(id) {
    return await axios.delete(`production/supply-order/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchStages(query = '') {
    return await axios.get(`production/prestage?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getStage(id) {
    return await axios.get(
            `production/prestage/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createStage(item) {
    return await axios.post(
            `production/prestage`,item)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateStage(id,data) {
    return await axios.patch(
            `production/prestage/${id}`,data)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchProductionProducts(query = '') {
    return await axios.get(`production/products?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateProductionProduct(id,data) {
    return await axios.patch(`production/products/${id}`,data)
    .then(res=>{
        return res.data
    })
    .catch(e=>{
        throw e
    })

}

export async function fetchBitacora(query = '') {
    return await axios.get(`production/bitacora?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteBitacora(id) {
    return await axios.delete(`production/bitacora/${id}`)
    .then(res=>{
        return res.data
    }
    )
    .catch(e  => {if(e.response.data.error) throw e.response.data.error})
}




//API bitacora de consumo de materiales

export async function createComsumptionBitacora(data) {
    return await axios.post(`production/consumption`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {if(e.response.data.error) throw e.response.data.error})
}

export const fetchConsumtionBitacora = async (query = '') => {
    return await axios.get(`production/consumption?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {if(e.response.data.error) throw e.response.data.error})
}

export const deleteConsumptionBitacora = async (id) => {
    return await axios.delete(`production/consumption/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {if(e.response.data.error) throw e.response.data.error})
}


// API Bitacora de consumo por OT
export async function createConsumptionBitacoraByOT(data) {
    return await axios.post(`production/consumption-by-ot`, data)
        .then(res => {
            return res.data
        })
        .catch(e => {if(e.response.data.error) throw e.response.data.error})
}

export async function fetchConsumtionBitacoraByOT (query = ''){
    return await axios.get(`production/consumption-by-ot?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {if(e.response.data.error) throw e.response.data.error})
}

export async function deleteConsumptionBitacoraByOT (id){
    return await axios.delete(`production/consumption-by-ot/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {if(e.response.data.error) throw e.response.data.error})
}



export const getWorkOrderCostResume = async id =>{
    return axios.get(`production/work-order/${id}/cost-resume`)
        .then(res => {
            return res.data
        }
        ).catch(e  => {if(e.response.data.error) throw e.response.data.error})
    }

export const reprocess = async id =>{
    return axios.post(`production/process-order/${id}/reprocess`)
        .then(res => {
            return res.data
        }
        ).catch(e  => {if(e.response.data.error) throw e.response.data.error})
}

export const asignEquipment = async (data) =>{
    return axios.post('production/process-order/asign-equipment',data)
        .then(res => {
            return res.data
        }
        ).catch(e  => {if(e.response.data.error) throw e.response.data.error})
}

export async function excelPrestage(query) {
    return await axios.get(
            `production/prestage-export?${query}`,{
                responseType: 'blob',
                timeout: 1000 * 60
            })
        .then(blob => {
            return blob.data
        })
        .catch(e => {
            throw e
        })
}