import axios from "axios"

const BASEOPENFACTURA= "https://dev-api.haulmer.com/v2/dte/document";

const apiKey = '928e15a2d14d4a6292345f04960f4bd3'



export async function createOpenFacturaDTE(dte) {
  const data={
    response: ["FOLIO"],
    custom:{
      informationNote:'Con OT42',
      paymentNote:'Vencimiento el dia 13 de enero de 2021'
    },
    
    dte: {
      Referencia: [
        {
        FchRef: "2020-02-17",
        FolioRef: 3425324,
        NroLinRef: 1,
        OT: 103
        },
      ],
      Encabezado: {
        IdDoc: {
          TipoDTE: 33,
          Folio: 0,
          FchEmis: "2021-01-13",
          TpoTranCompra: "1",
          TpoTranVenta: "1",
          FmaPago: "2"
        },
        Emisor: {
          RUTEmisor: "76795561-8",
          RznSoc: "HAULMER SPA",
          GiroEmis: "VENTA AL POR MENOR POR CORREO, POR INTERNET Y VIA TELEFONICA",
          Acteco: "479100",
          DirOrigen: "ARTURO PRAT 527   CURICO",
          CmnaOrigen: "Curicó",
          Telefono: "0 0",
          CdgSIISucur: "81303347"
        },
        Receptor: {
          RUTRecep: "77143054-6",
          RznSocRecep: "Smart Graphics SpA",
          GiroRecep: "ACTIVIDADES DE CONSULTORIA DE INFORMATIC",
          DirRecep: "Palacio Riesco",
          CmnaRecep: "Huechuraba"
        },
        Totales: {
          MntNeto: 2000,
          TasaIVA: "19",
          IVA: 380,
          MntTotal: 2380,
          MontoPeriodo: 2380,
          VlrPagar: 2380
        },
      },
      Detalle: [  {
        NroLinDet: 1,
        NmbItem: "Soporte Runflow",
        QtyItem: 1,
        PrcItem: 2000,
        MontoItem: 2000
      }],
    }
  }
  var config = {
    method:'post',
    url: BASEOPENFACTURA,
    headers: {
      'apiKey': apiKey,
    },
    data:data
  }
  await axios(config)
  .then(response=>{
    console.log(response.data)
  })
  .catch(error=>{
    console.log(error.response.data)
  })
}
