<template>
<v-card class="pa-4" >
      <v-card-text>
          <v-simple-table>
          <thead>
            <tr>
              <th>Codigo</th>
              <th>Producto</th>
              <th>OT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{product.library_product__sku}}</td>
              <td>{{product.name}}</td>
              <td>{{product.work_order__id}}</td>
            </tr>
            <tr>
              <td colspan="4">
                <p class="d-flex">
                  <div v-for="(log,index) in product.pre_stage_logs.slice().reverse()" :key="log.id" class="d-inline-block">
                    <v-icon x-small v-if="index!=0">fas fa-arrow-right</v-icon>
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            class="ma-2"
                            v-if="findStage(log.stage)"
                            :color="findStage(log.stage).color"
                            label
                            outlined
                            style="max-width:100px"
                            small
                            v-on="on"
                            >
                            {{findStage(log.stage).name}}
                            </v-chip>
                            
                            <v-chip
                              v-else
                              class="ma-2"
                              v-on="on"
                              label
                              dark
                              color="green"
                              style="max-width:100px"
                              small
                              >
                              <strong> Produccion</strong>
                              </v-chip>
                            
                        </template>
                        <v-list-item two-line dark>
                          <v-list-item-content>
                            <v-list-item-title>{{log.email}}</v-list-item-title>
                            <v-list-item-subtitle>{{log.date| moment("DD/MM/YYYY HH:mm")}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-tooltip>
                  </div>
                </p>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>

</template>
<script>
export default{
    name: 'DetailPrestages',
    props: ['product'],
    data() {
        return {
            stages:this.$store.getters.getStages,
        }
    },
    methods:{
        findStage(id){
            return this.stages.find(stage => stage.id == id)
        }
    }
}
</script>