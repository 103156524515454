<template>
<div>
    <div class="slot-item" @click="displayDialog">
        <slot></slot>
    </div>
    <v-dialog v-model='displaySoftnet' max-width="1000" persistent>
        <v-card min-height="300">
        <document-detail 
        :document_id='item.id'
        @close="displaySoftnet=false"/>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import DocumentDetail from '@/components/softnet/DocumentDetail.vue'
import { integer } from 'vee-validate/dist/rules'
import notifications from '../../utils/notifications'
export default {
    components: {
        DocumentDetail
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            displaySoftnet: false,
        }
    },
    methods: {
        displayDialog() {
            this.displaySoftnet = true
        },
    }
}

</script>
<style scoped>
.slot-item{
    cursor: pointer;
}
</style>