var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-10 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Grupos")])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":_vm.create}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groups,"items-per-page":10,"search":_vm.search,"sort-by":['name'],"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right'
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mx-4 text--end",attrs:{"prepend-inner-icon":"fas fa-search","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.newGroup.id>0? handleSubmit(_vm.updateGroup): handleSubmit(_vm.createGroup)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Agregar Nuevo Grupo")])]),_c('v-card-text',[_c('v-container',[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de Grupo","error-messages":errors,"autofocus":""},model:{value:(_vm.newGroup.name),callback:function ($$v) {_vm.$set(_vm.newGroup, "name", $$v)},expression:"newGroup.name"}})]}}],null,true)}),_c('v-checkbox',{attrs:{"label":"Grupo de Materiales"},model:{value:(_vm.newGroup.is_supply),callback:function ($$v) {_vm.$set(_vm.newGroup, "is_supply", $$v)},expression:"newGroup.is_supply"}})],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-spacer'),_c('v-btn',{staticClass:"success--text",attrs:{"type":"submit"}},[_c('v-icon',[_vm._v("save")]),_vm._v("Guardar ")],1)],1)],1)],1)]}}])})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_vm._v("fas fa-edit")]),_c('v-icon',{staticClass:"error--text",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v("fas fa-trash")])]}},{key:"item.is_supply",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.is_supply ? 'fas fa-calculator':'fas fa-cogs')+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }