<template>
  <v-container>
    <v-row class="justify-space-between pa-4">
      <h1>Nota de Venta #{{salesNote.id}}</h1>
      <h1 v-if="salesNote.work_order">OT #{{salesNote.work_order}}</h1>
    </v-row>
          <v-row class="pa-6" align-content="center">
            <v-col cols="4" align-self="center">
                <v-row mt-2>
                  <label><v-icon>fas fa-calendar-day</v-icon> {{salesNote.created_at| moment("DD/MMM/YYYY h:mm a")}}</label>
                </v-row>
                <v-row>
                  <label class="mt-4"><v-icon>fas  fa-user-tie</v-icon> {{`${salesNote.created_by.first_name} ${salesNote.created_by.last_name}` }}</label>
                </v-row>
            </v-col>
            <v-col v-if="salesNote.updated_by" cols="4">
              <label><v-icon>history</v-icon> {{salesNote.updated_at| moment("DD/MMM/YYYY h:mm a")}}</label>
              <br>
              <label><v-icon>face</v-icon> {{`${salesNote.updated_by.first_name} ${salesNote.updated_by.last_name}` }}</label>
            </v-col>
            <v-col cols="4" align-self="center">
              <v-text-field dense label="Estatus" readonly v-model="salesNote.status_name"></v-text-field>
            </v-col>
          </v-row>
        <v-row>
          <v-col>
            <v-text-field dense label="Cliente" readonly :value="salesNote.sales_note_customer.name"/>
          </v-col>
          <v-col>
            <v-text-field dense label="Contacto" readonly :value="`${salesNote.sales_note_contact.first_name} ${salesNote.sales_note_contact.last_name}`"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field 
            dense
            v-model='salesNote.name'
            prepend-icon="receipt"
            label="Proyecto"
            readonly
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <delivery-estimation :products="salesNote.details.products"/>
          </v-col>
          <v-col cols="4">
            <p class="font-italic">{{salesNote.details.notes}}</p>
          </v-col>
        </v-row>
       
        <v-row justify="center">
          <v-data-table
          :headers="productsTableHeaders"
          :items="salesNote.details.products"
          :single-expand="true"
          expanded.sync="expanded"
          expand-icon="add"
          item-key="key"
          show-expand
          class="flex d-block"
          >
            <template v-slot:top>
              <div class="text text-h6 pa-4">Productos <v-icon right>fas fa-shopping-cart</v-icon></div>
            </template>
            <template v-slot:item.name="{item}">
              {{item.name}}
            </template>
            <template v-slot:item.width="{item}">
              {{item.width | number('0,0.00')}}
            </template>
            <template v-slot:item.height="{item}">
              {{item.height | number('0,0.00')}}
            </template>
            <template v-slot:item.qty="{item}">
              {{item.qty}}
            </template>
            <template v-slot:item.totalMts2="{item}">
              {{item.totalMts2 | number('0,0.00')}}
            </template>
            <template  v-slot:item.unitCost="{item}">
              <strong v-if="item.sell_unit_m2"  class="cost" color-text="primary">{{(item.totalCost/item.totalMts2) | currency('$',0)}}</strong>
              <strong v-else class="cost" color-text="primary">{{(item.totalCost/item.qty) | currency('$',0)}}</strong>
              <!-- <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong> -->
              <v-icon small class="ml-2">{{item.sell_unit_m2 ? 'square_foot':'view_comfy'}}</v-icon> 
            </template>
            <template v-slot:item.totalCost="{item}">
              <strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
            </template>
            <template v-slot:item.price="{item}">
                  {{item.price | currency('$',0)}}
                  <v-icon>{{item.sell_unit_m2 ? 'square_foot':'view_comfy'}}</v-icon>
            </template>
            <template v-slot:item.totalPrice="{item}">
              <div :class="item.totalPrice>item.totalCost ? 'green--text':'red white--text'">
                <strong>{{item.totalPrice | currency('$',0)}}</strong>
              </div>
            </template> 
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col>
                    <recipe 
                      :mts2="item.totalMts2" 
                      :total="item.totalCost" 
                      :qty="item.qty" 
                      :items="item.product_receta"
                      :readonly="true"
                      />
                  </v-col>
                  <v-col>
                    <supplies 
                      :mts2="item.totalMts2" 
                      :total="item.totalCost" 
                      :qty="item.qty" 
                      :items="item.product_supplies"
                      :readonly="true"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-2 ml-4">
                  <p class="font-italic">{{item.notes}}</p>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="salesNote.details.costs.length>0" justify="center" class="mt-4">
          <v-data-table
          :headers="proyectCostsHeaders"
          :items="salesNote.details.costs"
          item-key="key2"
          class="flex d-block"
          dense
          >
            <template v-slot:top>
              <div class="text text-h6 pa-4">Costos Adicionales <v-icon class="mx-2">language</v-icon></div>
            </template>
            <template v-slot:item.name="{item}">
              {{item.name}}
            </template>
            <template v-slot:item.qty="{item}">
              {{item.qty}}
            </template>
            <template v-slot:item.unit="{item}">
              {{item.cost_unit.name}}
            </template>
            <template  v-slot:item.unitCost="{item}">
              <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong>
            </template>
            <template v-slot:item.totalCost="{item}">
              <strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
            </template>
            <template v-slot:item.price="{item}">
              {{item.price| currency('$',0)}}
              <v-icon>{{item.unit.id==1?'square_foot':''}}</v-icon>
            </template>
            <template v-slot:item.totalPrice="{item}">
              <div :class="item.totalPrice>item.totalCost ? 'green--text':'red white--text'">
                <strong>{{item.totalPrice | currency('$',0)}}</strong>
              </div>
            </template> 
          </v-data-table>
        </v-row>  
        <v-row>
          <v-col>
            <v-card>
              <v-simple-table dense class='strip-table'>
                <thead>
                  <tr>
                    <th class="text-center strip-header">Tot. Mts<sup>2</sup></th>
                    <th class="text-center strip-header">Costo</th>
                    <th class="text-center strip-header">Precio</th>
                    <th class="text-center strip-header">Descuento</th>
                    <th class="text-center strip-header">Total Venta</th>
                    <th class="text-center strip-header">Margen</th>  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center strip-header"><strong>{{salesNote.totals.mts2| number('0,0.00')}}</strong></td>
                    <td class="text-center strip-header"><strong class="purple--text ">{{salesNote.totals.cost | currency('$',0) }}</strong></td>
                    <td class="text-center strip-header"><strong :class="salesNote.totals.price>salesNote.totals.cost?'purple--text':'red white--text pa-2'" >{{salesNote.totals.price | currency('$',0) }}</strong></td>
                    <td class="text-center strip-header"><strong>{{salesNote.totals.discount}}% ({{salesNote.totals.totalDiscount | currency('$',0)}})</strong></td>
                    <td class="text-center strip-header"><strong :class="salesNote.totals.total>salesNote.totals.cost?'purple--text':'red white--text pa-2'">{{salesNote.totals.total | currency('$',0) }}</strong></td>
                    <td class="text-center strip-header"><strong :class="salesNote.totals.margin>0?'purple--text':'red white--text pa-2'">{{salesNote.totals.margin | percent(1)  }}</strong></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
  </v-container>
</template>
<script>
import Recipe from '@/components/products/products/Recipe'
import DeliveryEstimation from '@/components/sales/DeliveryEstimation.vue'
import Supplies from '@/components/products/products/Supplies'
export default {
  name:'SalesCard',
  components:{Recipe,Supplies,DeliveryEstimation},
  props:['salesNote'],
  data() {
    return {
      productsTableHeaders:[
      { text: 'Descripción', value: 'name' },
      { text: 'Cantidad', value: 'qty' },
      {text:'Ancho',value:'width'},
      {text: 'Alto',value:'height'},
      { text: 'Total mts2', value: 'totalMts2' },
      { text: 'Costo Unit.', value: 'unitCost' },
      { text: 'Costo Total', value: 'totalCost' },
      { text: 'Precio Unit.', value:'price'},
      { text: 'Precio Total',value:'totalPrice'},
      { text: '', value: 'data-table-expand' },
    ],
    proyectCostsHeaders:[
      { text: 'Descripción', value: 'name' },
      { text: 'Cantidad', value: 'qty' },
      { text: 'Unidad', value: 'unit' },
      { text: 'Costo Unit.', value: 'unitCost' },
      { text: 'Costo Total', value: 'totalCost' },
      { text: 'Precio Unit.', value:'price'},
      { text: 'Precio Total',value:'totalPrice'},
    ]
    }
  },

}
</script>

<style lang="scss">
.expanded{
  background-color: greenyellow !important;
}
</style>