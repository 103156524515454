<template>
<v-container>
  <v-row justify="center">
    <v-col>
      <v-data-table
        :headers="headers"
        :items="lines"
        :items-per-page="10"
        :search="search"
        :loading="loading"
        :sort-by="['name']"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }"
      >
          <template v-slot:top>
          <v-row justify="space-between">
            <v-col>
              <v-text-field
                v-model="search"
                label="Buscar"
                class="mx-4"
                append-icon="fas fa-search"
              ></v-text-field>
            </v-col>
            <v-col class="text-end">
              <v-btn
                color="success"
                dark
                class="mb-2"
                @click="create"
              >
                <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
              </v-btn>
            </v-col>
            
            <v-dialog
              v-model="showDialog"
              max-width="500px"
            >
              <ValidationObserver v-slot="{handleSubmit}">
                <v-form @submit.prevent="newLine.id>0? handleSubmit(updateLine): handleSubmit(createLine)">
                  <v-card class="pa-4">
                    <v-card-title>
                      <span class="headline titulo">Agregar Nueva Línea</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                              <v-text-field
                                v-model="newLine.name"
                                outlined
                                label="Nombre de Línea"
                                :error-messages="errors"
                                autofocus
                              ></v-text-field>
                            </ValidationProvider>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                      <v-spacer></v-spacer>
                      <v-btn type="submit" color="success">
                        <v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </ValidationObserver>
            </v-dialog>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn text class="mr-2" @click.stop="edit(item)"><v-icon>fas fa-edit</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import {fetchLines,createLine, updateLine} from '@/api/products'
import notifications from '@/utils/notifications'

export default {
  name:'ProductLine',
  data:()=>({
    lines:[],
    search:'',
    showDialog:false,
    loading:false,
    newLine:{
      id:-1,
      name:'',
    },
    headers: [
          {
            text: 'Descripción',
            align: 'start',
            value: 'name',
            class:'table-header'
          },
          { class:'table-header',text: 'Acciones',value:'actions',sortable: false, align:"center"}
        ],
  }),
  methods:{
    edit(item){
      this.newLine=item
      this.showDialog=true
    },
    create(){
      this.newLine={
        id:-1,
        name:''
      }
      this.showDialog=true
    },
    async createLine(){
      await createLine(this.newLine)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.lines=await fetchLines()
        notifications.showSuccess('Línea '+ this.newLine.name + ' Creada')
        this.$store.dispatch('getLines')
        })
      .catch(()=>{
        notifications.showError('Error Creando Nuevo Línea ')
        this.loading=false
        })
    },
    async updateLine(){
      await updateLine(this.newLine)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.lines=await fetchLines()
        notifications.showSuccess('Línea '+ this.newLine.name + ' Actualizado')
        this.$store.dispatch('getLines')
        })
      .catch(async (e)=>{
        this.loading=false
        notifications.showError('Error Actualizando Línea' + e)
        this.lines=await fetchLines()
        })
    }
  },
  async created(){
    this.loading=true
    await fetchLines()
    .then((res)=>{
      this.lines=res
      this.loading=false
    })
  }
}
</script>