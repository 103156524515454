import { Ability } from '@casl/ability';


export default new Ability([

])

// Permisos
// Las acciones read se usan para activar los menus laterales
// En el caso de SalesNote se usa una acción especial llamada realAll que permite visualizar
// todos las NV. Se activan en los filtros por vendedor

//Los objectos son 
// SalesNote
//Customer
//Product
//WorkOrder
//Planner
//FloorControl
//Calendar
//PostCost
//Cost
//User
//Parameter
//Role

