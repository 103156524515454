var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text-h4 titulo"},[_vm._v("Industrias")])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.update(-1)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1)],1)],1),_c('v-row',{attrs:{"justify-md":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"loading":_vm.loading,"search":_vm.search,"sort-by":"name","item-class":"table-body","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.update(item.id)}}},[_vm._v("fas fa-edit")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"px-10 py-4"},[_c('industry-create-update',{attrs:{"id":_vm.item},on:{"updated":_vm.fetch,"close":function($event){_vm.showDialog=false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }