var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h5 titulo"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fa fa-search")]),_c('v-icon',{staticClass:"mr-2 align-items-center"},[_vm._v("fas fa-box")]),_vm._v("Buscar Artículos")],1)])],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.families,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Todas las Familias","single-line":"","outlined":"","dense":"","clearable":"","prepend-inner-icon":"filter_alt"},model:{value:(_vm.family),callback:function ($$v) {_vm.family=$$v},expression:"family"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.articles,"items-per-page":5,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total,"search":_vm.search,"sort-by":"sku","item-class":"table-body","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"px-4",attrs:{"fab":"","color":"success","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.add(item)}}},[_c('v-icon',[_vm._v("fas fa-plus")])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"px-10 py-4"},[_c('article-create-update',{attrs:{"families":_vm.families,"units":_vm.units,"id":_vm.article},on:{"updated":_vm.fetch,"close":function($event){_vm.showDialog=false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }