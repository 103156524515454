
const rejectedReasons= [
  { text: "Precio", value: "price" },
  { text: "Fecha de entrega", value: "delivery_date" },
  { text:"Sin respuesta",value:"no_response"},
  { text:"Desierta",value:"desert"},
  { text: "Diseño", value:"design"},
  {text:"Duplicada",value:"double"},
  {text:"Nuevos en el mercado",value:"market"}
]

export default rejectedReasons;