<template>
  <v-container>
    
     <div>
       <v-row class="mb-4" justify="space-between" align-content="center">
        <v-col cols="12" md="8">
          <h1 class="text text-h4 titulo">Actividad Comercial</h1>
        </v-col>
        <v-col cols="12" md="4" class="text-end justify-end align-center d-flex">
            <export-activities :filters="filter" :options="options" :disabled="loading" :search="search"/>
            <activity-create-update @updated="fetch"/>
        </v-col>
       </v-row>
      <v-row class="mb-4" justify="end">
        <v-col align-self="center" cols="12" md="6">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_after' 
          class="mr-4" 
          outlined 
          clearable 
          type="date" 
          label="Ingreso Desde:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_before' 
          clearable 
          outlined 
          type="date" 
          label="Ingreso Hasta:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
          <tipo-activity-selection
                v-model='filter.tipo'
                :loading="loading"
                :disabled="loading"
                :filtro="true"
                @change="()=>{options.page=1;fetch()}"
                />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="filter.seller"
            :items="sellers"
            item-text="email"
            item-value="id"
            label="Vendedor"
            placeholder="Todos"
            outlined
            clearable
            dense
            hide-details
            @change="()=>{options.page=1;fetch()}"
          >
          <template v-slot:selection="{ item }">
            <div class="d-flex">
              {{item.first_name + ' '+item.last_name }}
              <div class="caption ml-2">
                {{item.email}}
              </div>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <div class="d-block py-1">
              {{item.first_name + ' '+item.last_name }}
              <div class="caption">
                {{item.email}}
              </div>
            </div>
          </template>
          </v-select>
        </v-col>
        <!-- <v-col cols="12" md="6" align-self="center">
            <v-select
              v-model="filter.equipment"
              :items="equipments"
              item-text="name"
              item-value="id"
              label="Equipo"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              hide-details
              :disabled="!filter.machine"
              @change="()=>{options.page=1;fetch()}"
            />
        </v-col> -->
        <v-col justify-end cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            @keyup="searchTimeOut"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        class="pa-4"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
         @update:options="fetch"
      >
        <template v-slot:top>
        </template>
        <template v-slot:item.date="{ item }">
          <div>{{item.date | moment("DD/MM/YYYY")}}</div>
        </template>
        <template v-slot:item.notes="{ item }">
          
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div 
                v-bind="attrs"
                v-on="on" class="caption">
                  {{item.notes.substring(0,20)}}{{item.notes.length>20?'...':'' }}
              </div>
            </template>
            {{item.notes?item.notes:'Sin Observaciones'}}
          </v-tooltip>
        </template>
        <template v-slot:item.created_by__first_name="{ item }">
          {{item.created_by__first_name}}
        </template>
        <template v-slot:item.actions="{ item }">
          <div v-if="$can('delete','Activity')" class="d-flex">
              <activity-create-update :item="item" @updated="fetch"/>
          </div>
        </template>
  </v-data-table>
  </div>
  <confirm
    v-if="itemToDelete"
    v-model="showConfirmDelete" 
    :message="`¿Confirma eliminar el registro de OT ${itemToDelete.work_order}? Esta operación no se puede deshacer.`"
    @ok="deleteItem(itemToDelete)"
    @cancel="()=>{showConfirmDelete=false;itemToDelete=''}"/>
  </v-container>
</template>

<script>
import {fetchActivities} from '@/api/salesNotes'
import notifications from '@/utils/notifications'

import moment from 'moment'
export default {
  name:'ActivitiesBitacora',
  components:{
     ActivityCreateUpdate:()=>import('@/components/sales/ActivityCreateUpdate'),
     TipoActivitySelection:()=>import('@/components/sales/TipoActivitySelection'),
     ExportActivities:()=>import('@/components/sales/ExportActivities'),
  },
  data() {
    return {
      loading:false,
      filter:{
        created_after:'',//moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        tipo:'',
        customer:'',
        seller:'',
        },
      options:{},
      totalItems:0,
      display:false,
      search:'',
      items:[],
      itemToDelete:'',
      showConfirmDelete:false,
      machinesLoading:false,
      headers:[
          {text: 'Fecha Ingreso',align: 'start',value: 'date',class: 'table-header2',divider:true},
          {text: 'Cliente',align: 'start',value: 'customer_name',class: 'table-header2',divider:true,},
          {text: 'Tipo',align: 'start',value: 'tipo',class: 'table-header2',divider:true,},
          {text: 'Contacto',align: 'start',value: 'contact',class: 'table-header2',divider:true,},
          {text: 'Observaciones',align: 'start',value: 'notes',class: 'table-header2',divider:true,sortable:false},
          {text:  'Usuario',align:'start',value:'created_by__first_name',class:'table-header2',sortable:true},
          {text:  '',align:'end',value:'actions',class:'table-header2',sortable:false},
        ],
        sellers:this.$store.getters.getSellers,
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = '' 
      if(this.filter.created_after) created_after = moment(this.filter.created_after).format('yyyy-MM-DD')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).format('yyyy-MM-DD')
      this.filter.seller=this.filter.seller?this.filter.seller:''
      
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&date_after=${created_after}&date_before=${created_before}&tipo=${this.filter.tipo}&seller=${this.filter.seller}`
      await fetchActivities(query)
      .then((data)=>{
        this.totalItems=data.count
        this.items = data.results
      })
      .catch((err)=>notifications.showError('Error', err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    confirmDelete(item){
      this.itemToDelete=item
      this.showConfirmDelete=true
    },
    async deleteItem(item){
      this.loading=true
      this.showConfirmDelete=false
      await deleteActivity(item.id)
      .then(()=>{
        notifications.showSuccess('Registro Eliminado')
      })
      .catch((err)=>notifications.showError(err))
      .finally(()=>{
        this.loading=false
        this.fetch()
      })
    }
  },
  async mounted(){
    await this.fetch()
    
  },
  
}
</script>
