<template>
  <div class="d-flex align-center">
    <article-complete v-model="selected" :disabled="disabled"/>
    <v-btn :disabled="disabled" class="ml-2" @click="show=true"><v-icon>fas fa-table</v-icon></v-btn>
    <v-dialog v-model="show" max-width="900">
      <v-card class="pa-4">
        <articles-table v-model="selected"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ArticleComplete from '@/components/stocks/ArticleComplete'
import ArticlesTable from '@/components/stocks/ArticlesTable'
export default {
  name:'AddArticle',
  components:{ArticleComplete,ArticlesTable},
  props:['disabled'],
  data() {
    return {
      show:false,
      selected:'',
    }
  },
  watch:{
    selected(){
      if(this.selected!=''){
        var selected={}
        selected = Object.assign({}, selected, this.selected)
        this.$emit('add',selected)
        this.$emit('close')
      }
      
    }
  }
  
}
</script>

<style>

</style>