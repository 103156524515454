<template>
<div>
    <v-btn text small @click.stop="displayDialog">
        <v-icon class="mr-2">fas fa-rss</v-icon>
        Softnet
    </v-btn>
    <v-dialog v-model='displaySoftnet' max-width="1200" persistent>
        <v-card min-height="300">
        <create-document 
        :sales_note_id='sales_note'
        @close="displaySoftnet=false"/>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import CreateDocument from '@/components/softnet/CreateDocument.vue'
import { integer } from 'vee-validate/dist/rules'
import notifications from '../../utils/notifications'
export default {
    components: {
        CreateDocument
    },
    props: {
        sales_note: {
            required: true
        }
    },
    data() {
        return {
            displaySoftnet: false,
        }
    },
    methods: {
        displayDialog() {
            if(this.sales_note) this.displaySoftnet = true
            else notifications.showError('Por favor seleccione una nota de venta')
        },
    }
}

</script>