<template>
  <v-container>
    <v-row class="justify-space-between">
      <div class="text text-h4 titulo">
        <v-icon class="mr-2">fas fa-file-import</v-icon>
        Importar Proveedores
      </div>
      <v-btn @click="$router.push({name:'StocksConfiguration'})" class="ml-2" ><v-icon>fas fa-arrow-left</v-icon></v-btn>  
    </v-row>
    <v-row justify="center" class="mt-6">
      <v-col cols="12" class="text-center">
        <v-stepper
              v-model="e6"
              vertical
          >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
            color="info"
          >
            Descargue la Muestra
            <small>Descarga el archivo de muestra CSV</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              height="100px"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                <v-btn href="https://runflow-evo-bucket.s3.us-east-2.amazonaws.com/files/correcto_providers_import.csv"><v-icon class="mr-2">fas fa-download</v-icon> Descargar</v-btn>
              </v-row>
            </v-card>
            <v-btn
              color="success"
              @click="e6 = 2"
            >
              Continuar
            </v-btn>
            <v-btn text >
              Atrás
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
            color="info"
          >
            Seleccione el archivo
            <small>Seleccione el archivo CSV con los datos a importar</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              color=""
              class="mb-12"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                  <v-file-input
                  class="pa-10"
                  style="max-width:500px"
                  v-model=file
                  accept = '.csv'
                  prepend-icon="fas fa-cloud-upload-alt"
                  outlined
                  show-size
                  hint="Selecciona el archivo CSV a importar"
                  persistent-hint
                ></v-file-input>
              </v-row>
            </v-card>
            <v-btn :disabled="file==''" color="success" @click="onFileChange" :loading="loading">
              <v-icon class="mr-2">fas fa-file-upload</v-icon>Subir Archivo
            </v-btn>
            <v-btn text @click="e6=1">
              Atrás
            </v-btn>
          </v-stepper-content>
          <v-stepper-step
            :complete="e6 > 3"
            step="3"
            color="info"
            :rules="[() => errors.length>0?false:true]"
          >
            Resultados
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card
              color=""
              class="mb-12"
              min-height="100px" 
              flat
            >
              <v-row class="justify-center" v-if="errors.length>0 && newProviders.length>0">
                <v-col class="text-center" cols="8">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>
                          Item
                        </th>
                        <th>
                          RUT
                        </th>
                        <th>
                          Nombre
                        </th>
                        <th>
                          Error
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in errors" :key="item.item">
                        <td>
                          {{item.item}}
                        </td>
                        <td>
                          {{item.rut}}
                        </td>
                        <td>
                          {{item.name}}
                        </td>
                        <td>
                          {{item.message}}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              
              <v-row  v-else-if="newProviders.length>0" class="justify-center align-center" style="height: 100%">
                <div class="text text-h6">
                  Se verificaron <strong>{{newProviders.length}}</strong> registros correctamente
                </div>
              </v-row>
              
            </v-card>
            <v-btn
              color="success"
              @click="upload"
              :disabled="errors.length>0 && newProviders.length>0"
              :loading="loading"
            >
              <v-icon class="mr-2">fas fa-sd-card</v-icon>
              Guardar
            </v-btn>
            <v-btn text @click="e6=2">
              Atrás
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
    <!-- Dialogo para mostrar el progreso de la carga -->
    <v-dialog 
    v-model="showProgress"
    persistent
    max-width="500"
    >
      <v-card class="pa-10"> 
        <v-card-title class="titulo text-h4">Registrando...</v-card-title>
        <v-row>
          <v-col>
            <v-progress-linear v-model="progress" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center">
            <h1 class="text text-h4 titulo">{{progress | number('0.0')}}%</h1>
            <h1 v-if="uploadingError"  class="text text-h6 titulo error--text mt-4">{{uploadingErrorMessage}}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="progress>=100 && !uploadingError">
            <h1 class="text text-h6 titulo text-center" v-if="!uploadingError">Proveedores Cargados Correctamente</h1>
            <div class="text-center">
            <v-btn class="mt-4" color="success" @click="$router.push({name:'StocksConfiguration'})">Cerrar</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import notifications from '@/utils/notifications'
import {createProvider,fetchProvider} from '@/api/stocks'
import {validaRUT } from '@/utils/rut_validator'

import axios from 'axios'
export default {
  name:'ImportProviders',
  data() {
    return {
      file: '',
      providers:[],
      newProviders:[],
      errors:[],
      keys:[
        'rut',
        'name',
        'payment',
        ],
      loading:false,
      progress:0,
      showProgress:false,
      uploadingError:false,
      uploadingErrorMessage:'',
      e6:1,
    }
  },
  async created(){
    await fetchProvider()
    .then(res=>{
      this.providers=res.results
    })
    .catch((err)=>{
      notifications.showError('Error cargando proveedores '+err)
    })
  },
  methods:{
    onFileChange(){
      const config={
        header: true,
        complete: (results) =>{
          this.verifyData(results)
        },
      }
     this.$papa.parse(this.file, config)
    },
    async upload(){
      this.loading=true
      var progressStep=this.newProviders.length!=0?(100/this.newProviders.length):100
      this.showProgress=true
      //Carga manual de cada artículo
      //1. Se crea el artículo, 2. Se crea la Transacción, 3. Se registra en Inventario
      //this.newProviders.forEach(item=>{
      for(let i=0;i<this.newProviders.length;i++){
        const item=this.newProviders[i]
        try {
          //1. Se Crea el artículo
          const data={
            rut: item.rut,
            name: item.name,
            payment: item.payment,
          }
          await createProvider(data)
          .then(()=>{
            this.progress+=progressStep
          })
        }
        catch(error) {
          this.uploadingError=true
          this.loading=false
          this.uploadingErrorMessage='Error Cargando item: '+this.newProviders.indexOf(item)+ ' '+item.rut
          break
        }
      }
      this.loading=false
    },
    async verifyData(data){
      this.loading=true
      console.log(data)
      this.newProviders=[]
      this.errors=[]
      this.header=[]
      
      //Verifica que la cantidad de campos sea correcta
      if(data.meta.fields.length!=3){
        notifications.showError('Error en cantidad de campos')
        this.loading=false
        return 0
      }
      
      //Validación de Datos
      data.data.map((item)=>{
        let error = false
        let message='' 
        //Valida que el resto de los campos no estén vaciós
        // y que existan todas las claves necesarias
        Object.keys(item).map(key=>{
          if(item[key].length==0){
            error=true
            message=`Campo ${key} vacío`
          }
          if (!this.keys.includes(key)){
            error = true
            message=`Nombre de campo <${key}> inválido`
          }
        })

        //Valida el RUT
        if(!validaRUT(item.rut)) {
          error = true
          message='RUT Inválido'
        }

        

        //Valida que no haya un nombre duplicado
        this.newProviders.map(newItem=>{
          if (newItem.rut.toLowerCase() == item.rut.toLowerCase()){
            error = true
            message='RUT Duplicado'
          } 
        })

        this.providers.map(provider=>{
          if(provider.rut.toLowerCase()==item.rut.toLowerCase()){
            error = true
            message='RUT ya existe en la plataforma'
          } 
        })
        
        //Valida que el campo crítico es un número mayor que cero
        if(isNaN(item.payment)||parseInt(item.payment)<0){
          error=true
          message='El campo <payment> no es un número o es inválido'
        }

        if(error==true){
          this.errors.push({
            item: data.data.indexOf(item)+1,
            rut:item.rut,
            name:item.name,
            message:message
          })
        }
        item.error = error
        this.newProviders.push(item)
      })
      this.e6=3
      this.loading=false
    }
  }
}
</script>

<style lang="scss">
.v-stepper{
  box-shadow: 0  !important;
}
.v-stepper__content{
  margin: -8px -36px -16px 42px !important;
}
.v-stepper__step__step{
  height: 40px !important;
  width: 40px !important;
  font-size: 1rem !important;
}

</style>