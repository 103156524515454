<template>
  <v-container>
    <v-row class="mb-2 justify-space-between">
      <v-col cols="8">
        <h1 class="text text-h4 titulo"><v-icon class="mr-2 align-items-center">fas fa-shopping-cart</v-icon>Nota de Venta #{{salesNote.id}}</h1>
        <div class="text mt-2">Creada por: <strong>{{salesNote.created_by_full_name}} - {{salesNote.created_at| moment('DD/MMM/YYYY h:mm a')}}</strong></div>
        <div class="text" v-if="salesNote.updated_by" >Actualizada: <strong>{{salesNote.updated_by_full_name}} - {{salesNote.updated_at| moment('DD/MMM/YYYY h:mm a')}}</strong></div>
      </v-col>
      <v-col class="text-end">
        <div class="flex justify-space-around">
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  offset-x
                >
                  Acciones<v-icon small class="ml-2">fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item>
                  <v-btn text :disabled="Boolean(salesNote.work_order)" @click.stop="edit()" class="mr-2" ><v-icon class="mr-2">fas fa-pen</v-icon>Modificar</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn text @click.stop="generateCostReport" class="mr-2" ><v-icon class="mr-2">fas fa-calculator</v-icon>Informe Costos</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn text @click.stop="generateQuotation" class="mr-2" ><v-icon class="mr-2">print</v-icon>Presupuesto Cliente</v-btn>
                </v-list-item>
                <v-list-item v-if="$can('duplicate', 'SalesNote')">
                  <v-btn text @click.stop="duplicate" class="mr-2" ><v-icon class="mr-2">content_copy</v-icon>Duplicar</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          <v-btn @click="$router.go(-1)" class="ml-2" ><v-icon class="mr-2">fas fa-arrow-left</v-icon></v-btn>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center" align-content="center">
        <div v-if="salesNote.work_order" class="text text-h4 success--text"><v-icon class="mr-2 success--text">fas fa-handshake</v-icon><strong>Aprobada [OT #{{salesNote.work_order}}</strong>]</div>
        <div v-else class="text text-h4 info--text"> <strong>Costeada</strong></div>
  </v-row>
  <v-divider class="my-4"></v-divider>
  <v-row justify="space-around">
    <v-col>
      <div class="text text-caption">Cliente:</div>
      <div class="text text-h5">{{salesNote.sales_note_customer.name}}</div>
    </v-col>
    <v-col>
      <div class="text text-caption">Contacto:</div>
      <div class="text text-h5">{{`${salesNote.sales_note_contact.first_name} ${salesNote.sales_note_contact.last_name}`}}</div>
    </v-col>
    <v-col cols="6">
      <div class="text text-caption">Proyecto:</div>
      <div class="text text-h5">{{salesNote.name}}</div>
    </v-col>
  </v-row>
  <v-row v-if="salesNote.details.notes">
    <v-col>
      <div class="text text-caption">Notas:</div>
      <div class="font-italic text-h5">{{salesNote.details.notes}}</div>
    </v-col>
  </v-row>
  <v-divider class="my-4"></v-divider>
  <v-row>
    <v-col cols="4">
      Hola
      <distribution-list :sales_note="salesNote"/>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card>
        <v-simple-table dense class='strip-table'>
          <thead>
            <tr>
              <th class="text-center strip-header">Tot. Mts<sup>2</sup></th>
              <th class="text-center strip-header">Costo</th>
              <th class="text-center strip-header">Precio</th>
              <th class="text-center strip-header">Descuento</th>
              <th class="text-center strip-header">Total Venta</th>
              <th class="text-center strip-header">Margen</th>  
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center strip-header"><strong>{{salesNote.totals.mts2| number('0,0.00')}}</strong></td>
              <td class="text-center strip-header"><strong class="purple--text ">{{salesNote.totals.cost | currency('$',0) }}</strong></td>
              <td class="text-center strip-header"><strong :class="salesNote.totals.price>salesNote.totals.cost?'purple--text':'red white--text pa-2'" >{{salesNote.totals.price | currency('$',0) }}</strong></td>
              <td class="text-center strip-header"><strong>{{salesNote.totals.discount}}% ({{salesNote.totals.totalDiscount | currency('$',0)}})</strong></td>
              <td class="text-center strip-header"><strong :class="salesNote.totals.total>salesNote.totals.cost?'purple--text':'red white--text pa-2'">{{salesNote.totals.total | currency('$',0) }}</strong></td>
              <td class="text-center strip-header"><strong :class="salesNote.totals.margin>0?'purple--text':'red white--text pa-2'">{{salesNote.totals.margin | percent(1)  }}</strong></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
  <v-divider class="my-4"></v-divider>
  <v-row justify="center">
    <v-data-table
    :headers="productsTableHeaders"
    :items="salesNote.details.products"
    :single-expand="true"
    expanded.sync="expanded"
    expand-icon="fas fa-chevron-down"
    item-key="key"
    show-expand
    class="flex d-block"
    hide-default-footer
    >
      <template v-slot:top>
        <div class="text text-h6 pa-4">Productos <v-icon right>fas fa-shopping-cart</v-icon></div>
      </template>
      <template v-slot:item.name="{item}">
        {{item.name}}
      </template>
      <template v-slot:item.width="{item}">
        {{item.width | number('0,0.00')}}
      </template>
      <template v-slot:item.height="{item}">
        {{item.height | number('0,0.00')}}
      </template>
      <template v-slot:item.qty="{item}">
        {{item.qty}}
      </template>
      <template v-slot:item.totalMts2="{item}">
        {{item.totalMts2 | number('0,0.00')}}
      </template>
      <template  v-slot:item.unitCost="{item}">
        <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong>
      </template>
      <template v-slot:item.totalCost="{item}">
        <strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
      </template>
      <template v-slot:item.price="{item}">
            {{item.price}}
            <v-icon>{{item.sell_unit_m2 ? 'square_foot':'view_comfy'}}</v-icon>
      </template>
      <template v-slot:item.totalPrice="{item}">
        <div :class="item.totalPrice>item.totalCost ? 'green--text':'red white--text'">
          <strong>{{item.totalPrice | currency('$',0)}}</strong>
        </div>
      </template> 
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
              <recipe 
                :mts2="item.totalMts2" 
                :total="item.totalCost" 
                :qty="item.qty" 
                :items="item.product_receta"
                :readonly="true"
                />
            </v-row>
            <v-row>
              <supplies 
                :mts2="item.totalMts2" 
                :total="item.totalCost" 
                :qty="item.qty" 
                :items="item.product_supplies"
                :readonly="true"
              />
            </v-row>
          
          <v-row class="mt-2 ml-4">
            <p class="font-italic">{{item.notes}}</p>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </v-row>
  <v-divider></v-divider>
  <v-row v-if="salesNote.details.costs.length>0" justify="center" class="mt-4">
    <v-data-table
    :headers="proyectCostsHeaders"
    :items="salesNote.details.costs"
    item-key="key2"
    class="flex d-block"
    dense
    >
      <template v-slot:top>
        <div class="text text-h6 pa-4">Costos Adicionales <v-icon class="mx-2">language</v-icon></div>
      </template>
      <template v-slot:item.name="{item}">
        {{item.name}}
      </template>
      <template v-slot:item.qty="{item}">
        {{item.qty}}
      </template>
      <template v-slot:item.unit="{item}">
        {{item.cost_unit.name}}
      </template>
      <template  v-slot:item.unitCost="{item}">
        <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong>
      </template>
      <template v-slot:item.totalCost="{item}">
        <strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
      </template>
      <template v-slot:item.price="{item}">
        {{item.price| currency('$',0)}}
        <v-icon>{{item.unit.id==1?'square_foot':''}}</v-icon>
      </template>
      <template v-slot:item.totalPrice="{item}">
        <div :class="item.totalPrice>item.totalCost ? 'green--text':'red white--text'">
          <strong>{{item.totalPrice | currency('$',0)}}</strong>
        </div>
      </template> 
    </v-data-table>
  </v-row>  
  </v-container>
</template>
<script>
import Recipe from '@/components/products/products/Recipe'
import DeliveryEstimation from '@/components/sales/DeliveryEstimation.vue'
import Supplies from '@/components/products/products/Supplies'
import DistributionList from '@/components/sales/DistributionList'
import {pdfQuotation} from '@/doc_generators/quotation'
import {pdfCostReport} from '@/doc_generators/costReport'
export default {
  name:'SalesNoteDetail',
  components:{Recipe,Supplies,DeliveryEstimation,DistributionList},
  created(){
    if(this.$route.params.sales_note){
      this.salesNote = this.$route.params.sales_note
    }
  },
  data() {
    return {
      salesNote:'',
      productsTableHeaders:[
      { text: 'Descripción', value: 'name' ,class:"table-header-dense"},
      { text: 'Cant.', value: 'qty',class:"table-header-dense" },
      {text:'An.',value:'width',class:"table-header-dense"},
      {text: 'Alt.',value:'height',class:"table-header-dense"},
      { text: 'Mts2', value: 'totalMts2' ,class:"table-header-dense"},
      { text: 'Costo Unit.', value: 'unitCost' ,class:"table-header-dense"},
      { text: 'Costo Total', value: 'totalCost' ,class:"table-header-dense"},
      { text: 'Prec. Unit.', value:'price',class:"table-header-dense"},
      { text: 'Prec. Total',value:'totalPrice',class:"table-header-dense"},
      { text: '', value: 'data-table-expand' ,class:"table-header-dense"},
    ],
    proyectCostsHeaders:[
      { text: 'Descripción', value: 'name' },
      { text: 'Cantidad', value: 'qty' },
      { text: 'Unidad', value: 'unit' },
      { text: 'Costo Unit.', value: 'unitCost' },
      { text: 'Costo Total', value: 'totalCost' },
      { text: 'Precio Unit.', value:'price'},
      { text: 'Precio Total',value:'totalPrice'},
    ]
    }
  },
  methods:{
    edit(){
      this.$router.push({name:'SalesNoteCreateUpdate',params:{id:this.salesNote.id}})
    },
    duplicate(){
      this.$router.push({name:'SalesNoteCreateUpdate',params:{id:this.salesNote.id, duplicate:true}})
    },
    generateQuotation(){
      pdfQuotation(this.salesNote)
    },
    generateCostReport(){
      pdfCostReport(this.salesNote)
    },
  }

}
</script>
