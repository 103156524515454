<template>
  <div>
    <v-select
          dense
          :items="items"
          placeholder='Seleccione un Tipo de Cliente'
          label="Tipo de Cliente"
          :prepend-icon="icon"
          item-value="value"
          v-model="value"
          @input="updateValue"
          @change="change"
          outlined
          :error-messages="errors"
          return-object
          :disabled="disabled"
    >
      
    </v-select>
  </div>
</template>

<script>
import tipoCustomer from '@/utils/tipoCustomer.js'
import notifications from '../../utils/notifications'

export default {
  name:'TipoSelection',
  //props:['value','errors','icon','loading','disabled','empty'],
  props:{
    value:{
      type:Object,
      default:()=>({value:''})
    },
    errors:{
      type:Array,
      default:()=>[]
    },
    icon:{
      type:String,
      default:''
    },
    loading:{
      type:Boolean,
      default:false
    },
    disabled:{
      type:Boolean,
      default:false
    },
    filtro:{
      type:Boolean,
      default:true
    }
  },
  data:()=>({
    items:[]
  }),
  methods:{
    updateValue(){
      this.$emit('input',this.value.value)
    },
    change(){
      this.$emit('change',this.value.value)
    }
  },
  mounted(){
    if (this.filtro){
      this.items=[{text:'Todos',value:''},...tipoCustomer]
    }else this.items=tipoCustomer
  } 
  

}
</script>