<template>
  <v-container> 
       <v-row class="mb-10 justify-space-between">
        <h1 class="text text-h4 titulo">Pre produccion</h1>
        <div class="d-flex">
          <export-prestage :disabled="loading" :filter="filter" :options="options"/>
          <export-prestage-products :filter="filter" :options="options"/>
        </div>
       </v-row>
       <v-card class="pa-6">
      <v-row>
        <v-col>
          <v-text-field 
          :disabled="loading" 
          dense 
          v-model='filter.created_after' 
          class="mr-4" 
          outlined clearable 
          type="date" 
          label="OT Creada Desde:"
          @change="()=>{this.options.page=1;fetch()}"
          />
        </v-col>
        <v-col>
          <v-text-field 
          :disabled="loading" 
          dense 
          v-model='filter.created_before' 
          clearable 
          outlined 
          type="date" 
          label="OT Creada Hasta:" 
          @change="()=>{this.options.page=1;fetch()}"
          />
        </v-col>
      </v-row>
     <!--  <v-row>
        <v-col>
          <v-text-field 
          :disabled="loading" 
          dense 
          v-model='filter.delivery_after' 
          class="mr-4" 
          outlined 
          clearable 
          type="date" 
          label="Entrega Desde:"
          @change="()=>{this.options.page=1;fetch()}"
          />
        </v-col>
        <v-col>
          <v-text-field 
          :disabled="loading" 
          dense 
          v-model='filter.delivery_before' 
          clearable 
          outlined 
          type="date" 
          label="Entrega Hasta:" 
          @change="()=>{this.options.page=1;fetch()}"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            dense
            v-model="filter.seller"
            :items="sellers"
            item-text="email"
            item-value="id"
            label="Vendedor"
            placeholder="Todos"
            :loading="sellersLoading"
            outlined
            clearable
            :disabled="loading"
            @change="()=>{this.options.page=1;fetch()}"
          />
        </v-col>
        <v-col>
          <v-select
              v-model="filter.stage"
              :items="stages"
              label="Etapa"
              item-text="name"
              item-value="id"
              outlined
              placeholder="Todas"
              dense
              clearable
              @change="()=>{this.options.page=1;fetch()}"
              >
              <template v-slot:item="data">
                <v-chip
                  class="ma-2"
                  :color="data.item.color"
                  label
                  outlined
                  style="max-width:150px"
                  >
                  {{data.item.name}}
                </v-chip>
                </div>
              </template>
            </v-select>
        </v-col>
      </v-row> -->
      <v-row class="mb-6" justify="center">
        <v-col>
          <v-btn-toggle mandatory v-model="filter.stage" @change="fetch">
            <v-btn
              :value="null"
              >
              Todos
            </v-btn>
            <v-btn
              v-for="(stage,index) in stages"
              :key="index"
              :value="stage.id"
              :style="`color:${stage.color}`"
              >
              {{stage.name}}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        show-select
        v-model="selected"
        item-key="id"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        selectable-key="in_prestage"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        @click:row='showProduct'
        @update:options="fetch"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
        
      >
        <template v-slot:top>
        <v-row justify="end">
          <v-col cols="12" md="6">
            <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="fas fa-search"
                @keyup="searchTimeOut"
                :disabled="loading"
              ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="start" class="mb-2">
          
            <v-col cols="12" md="6">
            <v-btn color="success" :disabled="selected.length==0" @click.stop="()=>{
                    productToChangePrestage=item;
                    showChangePrestage=true;
                    }">
                    <v-icon class="mr-2">fas fa-bars-staggered</v-icon>
              Cambio Etapa
            </v-btn>
          </v-col>
          
        </v-row>
        </template>
        <template v-slot:item.work_order__id="{ item }">
          <div style="text-decoration: underline; cursor:pointer;" @click.stop="showWorkOrder({id:item.work_order__id})">{{item.work_order__id}}</div>
        </template>
        <template v-slot:item.work_order__created_at="{ item }">
          <div>{{item.work_order__created_at| moment("DD/MM/YYYY")}}</div>
        </template>
        <template v-slot:item.prestage="{item}">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="ma-2"
                v-if="item.prestage && item.in_prestage"
                :color="item.prestage.color"
                label
                outlined
                style="width:100px"
                v-on="on"
                >
                {{item.prestage.name}}
              </v-chip>
              <v-chip
                v-else
                class="ma-2"
                v-on="on"
                label
                dark
                color="green"
                style="max-width:100px"
                small
                >
                <strong> Produccion</strong>
              </v-chip>
            </template>
            <v-list-item two-line dark v-if="item.pre_stage_logs.length>0">
              <v-list-item-content>
                <v-list-item-title>{{item.pre_stage_logs[0].email}}</v-list-item-title>
                <v-list-item-subtitle>{{item.pre_stage_logs[0].date| moment("DD/MM/YYYY HH:mm")}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-tooltip>
        </template>
        <template v-slot:item.work_order__delivery_date="{ item }">
          {{item.work_order__delivery_date | moment("DD/MM/YYYY HH:mm")}}
        </template>
        
        
        <template v-slot:item.actions="{ item }">
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
             
                <v-icon 
                v-bind="attrs"
                v-on="on"
                text
                 class="ml-2">more_vert</v-icon> 
              
            </template>
            <v-list dense>
              <v-list-item v-if="item.in_prestage">
                <v-btn dense small text @click.stop="()=>{
                  productToChangePrestage=item;
                  showChangePrestage=true;
                  }"><v-icon class="mr-2">fas fa-bars-staggered</v-icon>Cambio Etapa</v-btn>
              </v-list-item>
              <v-list-item v-if="item.in_prestage">
                <v-btn dense small color="green" dark @click.stop="()=>{
                  productToProduction=item;
                  showProductToProduction=true;
                  }"><v-icon class="mr-2">fas fa-square-caret-right</v-icon>Pasar a Produccion</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn dense small text @click.stop="printWorkOrder(item)"><v-icon class="mr-2">fas fa-print</v-icon>Imprimir OT</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
  </v-data-table>
  </v-card>
  <v-dialog  v-model='display' max-width="1200">
    <v-card class="pa-4" >
      <work-order-card 
      :work_order='workOrderOnDisplay'
      @close="display=false" 
      @refresh="fetch"
      :loading="workOrderOnDisplayLoading"/> 
    </v-card>
  </v-dialog>
  <v-dialog  v-model='showChangePrestage' persistent max-width="700" transition="dialog-bottom-transition">
    <v-card class="pa-4" >
      <change-prestage 
      :products="selected"
      @close="showChangePrestage=false"
      @updated="()=>{selected=[];fetch()}"
      /> 
    </v-card>
  </v-dialog>
  <v-dialog  v-model='showProductModal' max-width="700">
    <detail-prestages
    :product="productOnDisplay"
    />
  </v-dialog>
  
  <confirm
    v-if="productToProduction"
    v-model="showProductToProduction" 
    :message="`¿Confirma pasar a Produccion [${productToProduction.library_product__sku}] ${productToProduction.name} de la orden # ${productToProduction.work_order__id}?`"
    @ok="changeToProduction(productToProduction)"
    @cancel="()=>{
      showProductToProduction=false
      productToProduction='';
      }"
      />
  </v-container>
</template>

<script>
import {fetchProductionProducts,getWorkOrder,deleteWorkOrder,updateProductionProduct} from '@/api/production'
import {fetchSellers} from '@/api/auth'
import {getSalesNote} from '@/api/salesNotes'
import CustomerComplete2 from '@/components/customers/CustomerComplete2.vue'
import WorkOrderCard from '@/components/production/WorkOrderCard'
import DetailPrestages from '@/components/production/DetailPrestages'
import ExportWorkOrders from '@/components/production/ExportWorkOrders'
import ChangePrestage from '@/components/production/ChangePrestage'
import ExportPrestageProducts from '@/components/production/ExportPrestageProducts'
import ExportPrestage from '@/components/production/ExportPrestage.vue'
import {pdfWorkOrder} from '@/doc_generators/workOrder'
import notifications from '@/utils/notifications'
import {pdfCostReport} from '@/doc_generators/costReport'


export default {
  name:'ProductsInPrestage',
  components:{CustomerComplete2,WorkOrderCard,ExportPrestageProducts,ChangePrestage,DetailPrestages,ExportPrestage},
  data() {
    return {
      loading:false,
      stages:[],
      filter:{
        created_after:'',
        created_before:'',
        delivery_after:'',
        delivery_before:'',
        stage:'',
        seller:''
        },
      options:{},
      totalItems:0,
      display:false,
      showChangePrestage:false,
      productToChangePrestage:{},
      workOrderOnDisplay:{},
      workOrderOnDisplayLoading:false,
      search:'',
      items:[],
      selected:[],
      sellers:[],
      sellersLoading:false,
      showProductModal:false,
      productOnDisplay:{pre_stage_logs:[]},
      productToProduction:{},
      showProductToProduction:false,
      headers:[
          {text: 'OT',align: 'start',value: 'work_order__id',class: 'table-header2'},
          {text: 'Codigo',align: 'start',value: 'library_product__sku',class: 'table-header2'},
          {text: 'Producto',align: 'start',value: 'name',class: 'table-header2'},
          {text: 'Cliente',value:'work_order__sales_note__customer__name',class: 'table-header2'},
          {text: 'Creada',align: 'start',value: 'work_order__created_at',class: 'table-header2'},
          {text: 'Entrega',align: 'start',value: 'work_order__delivery_date',class: 'table-header2'},
          { text: 'Etapa',value:'prestage',align: 'center',sortable: false,class: 'table-header2'}
        ]
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.delivery_after) this.filter.delivery_after=''
      if(!this.filter.delivery_before) this.filter.delivery_before=''
      if(!this.filter.seller) this.filter.seller=''
      
      //Todo el cuento para que el filtro muestre las cosas bien
      var in_prestage=true
      var stage = this.filter.stage
      if (!stage&&stage!==-1) {
        stage=''
        in_prestage=''
      }
      else if(stage===-1) {
        in_prestage=false
      
      }
      else in_prestage=true

      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`search=${this.search}&ordering=${sortDesc[0]?'-'+sortBy[0]:sortBy[0]}&in_prestage=${in_prestage}&page=${page}&page_size=${itemsPerPage}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&delivery_after=${this.filter.delivery_after}&delivery_before=${this.filter.delivery_before}&prestage__id=${stage<0?'':stage}&seller=${this.filter.seller}`
      this.items=[]
      await fetchProductionProducts(query)
      .then((data)=>{
        this.totalItems=data.count
        this.items = data.results
      })
      .catch((err)=>notifications.showError('Error Cargando Productos || ' + err))
      this.loading=false
    },
    showProduct(item){ 
      this.showProductModal=true
      this.productOnDisplay=item
    },
    async showWorkOrder(item){
      this.workOrderOnDisplayLoading=true
      this.display=true
      await getWorkOrder(item.id)
      .then((data)=>{
        console.log(data)
        this.workOrderOnDisplay=data
        this.workOrderOnDisplayLoading=false
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        this.display=false
        this.workOrderOnDisplayLoading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    itemRowStyle(item){
      return 'row-style-normal'
    },
    findStage(id){
      //Funcion para conseguir el stage dando el id
      return this.stages.find(stage=>stage.id==id)
    },
  },
  async mounted(){
    await this.fetch()
    this.stages=[...this.$store.getters.getStages,{id:-1,name:'Produccion',color:'green'}]
    this.sellers=this.$store.getters.getSellers
  },
}
</script>

<style lang="scss">
  .row-style-completed{
    cursor: pointer;
  }
  .row-style-normal{
    cursor: pointer;
  }
</style>



