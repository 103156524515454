<template>
  <v-container>
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(submitAddress)">
      <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
        <v-text-field
        outlined
        label="Nombre"
        placeholder="Principal"
        prepend-icon="fas fa-id-badge"
        v-model="name"
        clearable
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Región" rules="required" v-slot="{ errors }">
        <v-select
          v-model="region"
          :items="regions"
          item-text="name"
          item-value="name"
          label="Region"
          placeholder="Seleccione una Región"
          prepend-icon="fas fa-map-marked-alt"
          return-object
          single-line
          outlined
          clearable
          :error-messages="errors"
        />
      </ValidationProvider>
      <ValidationProvider name="Región" rules="required" v-slot="{ errors }">
        <v-select
          :items="region.communes"
          item-text="name"
          item-value="name"
          v-model="commune"
          placeholder='Seleccione una Comuna'
          label="Comuna"
          prepend-icon="fas fa-map-marked-alt"
          outlined
          single-line
          :error-messages="errors"
          clearable
          >
            <template v-slot:selection="{ item }">
              <div class="text">{{item.name}}</div>
            </template>
            <template v-slot:item="{ item }">
              <div class="text">{{item.name}}</div>
            </template>
        </v-select>
      </ValidationProvider>
      <ValidationProvider name="Calle" rules="required" v-slot="{ errors }">
        <v-text-field
        outlined
        placeholder="Calle"
        v-model="street"
        prepend-icon="fas fa-road"
        clearable
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Número" rules="required" v-slot="{ errors }">
        <v-text-field
        outlined
        placeholder="Número"
        v-model="number"
        prepend-icon="fas fa-hashtag"
        clearable
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Oficina" rules="required" v-slot="{ errors }">
        <v-text-field
        outlined
        placeholder="Oficina"
        v-model="suite"
        clearable
        prepend-icon="fas fa-building"
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <div class="d-flex justify-end">
        <v-btn type="submit" color="success"> <v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
      </div>
    </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import regions_comunes from '@/utils/regions_comunes.json'
export default {
  name:'AddressCreateUpdate',
  data:()=>({
    regions: [],
    name:'',
    region:'',
    commune:'',
    street:'',
    number:'',
    suite:'',
  }),
  methods:{
    submitAddress(){
      this.$emit('add',{
        name: this.name,
        region: this.region.name,
        comune: this.commune,
        street:this.street,
        number: this.number,
        suite: this.suite,
        default: false,
      })
      this.$emit('close')
    }
  },
  async created(){
    this.regions = regions_comunes.regions 
  }
}
</script>