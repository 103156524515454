<template>
  <v-container>
   <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(submit)">
     <v-row justify="end">
      <v-btn :loading='loading' color="success" type="submit"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
     </v-row>
     <v-row>
       <v-col>
        <v-text-field
        dense
        label='RUT'
        prepend-icon="label_important"
        v-model='rut'
        outlined
        :disabled=true
        ></v-text-field>
        <ValidationProvider name="Razón Social" rules="required" v-slot="{ errors }">
        <v-text-field
        dense
        label='Razón Social'
        prepend-icon="label_important"
        v-model='name'
        outlined
        :error-messages="errors"
        ></v-text-field>
        </ValidationProvider>
        <v-text-field
        dense
        label='Giro'
        prepend-icon="autorenew"
        v-model='giro'
        outlined
        ></v-text-field>
        <v-text-field
        dense
        label='Nombre Fantasía'
        prepend-icon="mdi-creation"
        v-model='fantasy'
        outlined
        ></v-text-field>
        <v-text-field
        dense
        label='Dirección'
        prepend-icon="location_on"
        v-model='address'
        outlined
        ></v-text-field>
        <v-text-field
        dense
        label='Teléfono'
        prepend-icon="phone"
        v-model='phone'
        outlined
        ></v-text-field>
        <v-text-field
        dense
        label='Correo Electrónico'
        prepend-icon="email"
        v-model='email'
        outlined
        ></v-text-field>
        <v-text-field
        dense
        label='Página Web'
        prepend-icon="public"
        v-model='webpage'
        outlined
        ></v-text-field>
        <div class="flex d-inline-flex">
          <v-file-input
            v-model=logoFile
            accept = 'image/*'
            label='logo'
            prepend-icon="fas fa-cloud-upload-alt"
            outlined
            show-size
            hint="Agregue el logo de su empresa"
            @change="onFileChange"
            persistent-hint
          ></v-file-input>
        <v-img
          class="ml-4"
          max-height="150"
          max-width="250"
          :src='logoFile?url:logo'
          ></v-img>
        </div>
        <v-text-field
        dense
        type="number"
        :min="0"
        label='Límite Aprovación de PO'
        prepend-icon="fas fa-shopping-basket"
        v-model='po_approve_limit'
        outlined
        ></v-text-field>
        <v-text-field
        dense
        label='Moneda por Defecto'
        prepend-icon="fas fa-coins"
        v-model='default_currency'
        outlined
        ></v-text-field>
        <v-text-field
        dense
        type="number"
        :min="0"
        label='IVA'
        prepend-icon="fas fa-coins"
        v-model='tax'
        outlined
        ></v-text-field>
        <v-textarea
        v-model="fixed_text_quotation"
        outlined
        label="Texto Fijo Cotización"
        dense
        />
          
       
        <v-checkbox
          v-model='preproduction_active'
          label="Usar Preproducción"
          dense
          />
          <v-checkbox
          v-model='complete_ot_without_logistics'
          label="Completar OT sin Productos Logistica"
          dense
          />
          <v-checkbox
          v-model='dashboard_card_quoted'
          label="Dashboard mostrar cotizado"
          dense
          />
          <v-checkbox
          v-model='dashboard_card_confirmed'
          label="Dashboard mostrar confirmado"
          dense
          />
          <v-checkbox
          v-model='dashboard_card_confirmed_finished'
          label="Dashboard mostrar finalizado"
          dense
          />
          <v-checkbox
          v-model='dashboard_card_confirmed_finished_not_invoiced'
          label="Dashboard mostrar finalizado no facturado"
          dense
          />
          <v-checkbox
          v-model='dashboard_card_invoiced'
          label="Dashboard mostrar facturado"
          dense
          />
          <v-checkbox
          v-model='dashboard_card_paid'
          label="Dashboard mostrar pagado"
          dense
          />
          <v-checkbox
          v-model='factronica_integration_activated'
          label="Activar FACTRONICA"
          dense
          />
         </v-col>
        </v-row>
    </v-form>
   </ValidationObserver>
  </v-container>
</template>

<script>
import {fetchCompany,updateCompany} from '@/api/parameters'
import notifications from '@/utils/notifications'
export default {
  name:'Company',
  data:()=>({
    rut:'',
    name:'',
    giro:'',
    fantasy:'',
    address:'',
    phone:'',
    email:'',
    webpage:'',
    logo:'',
    logoFile:'',
    url:'',
    po_approve_limit:0,
    default_currency:'',
    tax:0,
    preproduction_active:false,
    dashboard_card_quoted:true,
    dashboard_card_confirmed:true,
    dashboard_card_confirmed_finished:true,
    dashboard_card_invoiced:true,
    dashboard_card_paid:true,
    dashboard_card_confirmed_finished_not_invoiced:false,
    complete_ot_without_logistics:false,
    fixed_text_quotation:'',
    factronica_integration_activated:false,

    loading:false
  }),
  methods:{
    async submit(){
      this.loading=true
      var formData = new FormData()
      formData.append('rut',this.rut)
      formData.append('name',this.name)
      formData.append('fantasy',this.fantasy)
      formData.append('giro',this.giro)
      formData.append('address',this.address)
      formData.append('phone',this.phone)
      formData.append('email',this.email)
      formData.append('webpage',this.webpage)
      formData.append('stocks_po_approve_limit',this.po_approve_limit)
      formData.append('default_currency',this.default_currency)
      formData.append('tax',this.tax)
      formData.append('preproduction_active',this.preproduction_active)
      formData.append('dashboard_card_quoted',this.dashboard_card_quoted)
      formData.append('dashboard_card_confirmed',this.dashboard_card_confirmed)
      formData.append('dashboard_card_confirmed_finished',this.dashboard_card_confirmed_finished)
      formData.append('dashboard_card_invoiced',this.dashboard_card_invoiced)
      formData.append('dashboard_card_paid',this.dashboard_card_paid)
      formData.append('dashboard_card_confirmed_finished_not_invoiced',this.dashboard_card_confirmed_finished_not_invoiced)
      formData.append('complete_ot_without_logistics',this.complete_ot_without_logistics)
      formData.append('fixed_text_quotation',this.fixed_text_quotation)
      formData.append('factronica_integration_activated',this.factronica_integration_activated)
      if(this.logoFile!='') formData.append('logo',this.logoFile)
      await updateCompany(this.rut,formData)
      .then(()=>{
        notifications.showSuccess('Parámetros Actualizados')
        this.$store.dispatch('getParameters')
        this.loading=false
      })
      .catch((e)=>{
        this.loading=false
        notifications.showError('Error: '+e)
      })
    },
    onFileChange: function(e) {
    //const file = e.target.files[0];
     if(this.logoFile!='') this.url = URL.createObjectURL(this.logoFile);
     else this.url = ''
    },
  },
  async created(){
    await fetchCompany()
    .then((res)=>{
      if(res!={}){
        console.log(res)
        this.rut = res.rut
        this.old_rut=res.rut
        this.name = res.name
        this.fantasy = res.fantasy
        this.giro=res.giro
        this.address=res.address
        this.phone=res.phone
        this.email=res.email
        this.webpage=res.webpage
        this.logo=res.logo
        this.po_approve_limit=res.stocks_po_approve_limit
        this.default_currency=res.default_currency
        this.tax=res.tax
        this.preproduction_active=res.preproduction_active
        this.dashboard_card_quoted=res.dashboard_card_quoted
        this.dashboard_card_confirmed=res.dashboard_card_confirmed
        this.dashboard_card_confirmed_finished=res.dashboard_card_confirmed_finished
        this.dashboard_card_invoiced=res.dashboard_card_invoiced
        this.dashboard_card_paid=res.dashboard_card_paid
        this.dashboard_card_confirmed_finished_not_invoiced=res.dashboard_card_confirmed_finished_not_invoiced
        this.complete_ot_without_logistics=res.complete_ot_without_logistics
        this.fixed_text_quotation=res.fixed_text_quotation
        this.factronica_integration_activated=res.factronica_integration_activated

      }
    })
  }
}
</script>