<template>
  <v-container>
    <v-row class="justify-space-between">
      <div class="text text-h4 titulo">
        <v-icon class="mr-2">fas fa-file-import</v-icon>
        Importar Materiales
      </div>
      <v-btn @click="$router.push('/products/supplies')" class="ml-2" ><v-icon>fas fa-arrow-left</v-icon></v-btn>  
    </v-row>
    <!-- <v-data-table
      :headers="header"
      :items="newCustomers"
      :items-per-page="50"
      dense
    >
      <template v-slot:item.error="{ item }">
         <v-icon :color="item.error?'error':'success'">{{item.error?'fas fa-times-circle':'fas fa-check-circle'}}</v-icon>
      </template>
    </v-data-table> -->
    <v-row justify="center" class="mt-6">
      <v-col cols="12" class="text-center">
        <v-stepper
              v-model="e6"
              vertical
          >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
            color="info"
          >
            Descargue la Muestra
            <small>Descarga el archivo de muestra CSV</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              height="100px"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                <v-btn href="https://runflow-evo-bucket.s3.us-east-2.amazonaws.com/files/correcto_supplies_import.csv"><v-icon class="mr-2">fas fa-download</v-icon> Descargar</v-btn>
              </v-row>
            </v-card>
            <v-btn
              color="success"
              @click="e6 = 2"
            >
              Continuar
            </v-btn>
            <v-btn text >
              Atrás
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
            color="info"
          >
            Seleccione el archivo
            <small>Seleccione el archivo CSV con los datos a importar</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              color=""
              class="mb-12"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                  <v-file-input
                  class="pa-10"
                  style="max-width:500px"
                  v-model=file
                  accept = '.csv'
                  prepend-icon="fas fa-cloud-upload-alt"
                  outlined
                  show-size
                  hint="Selecciona el archivo CSV a importar"
                  persistent-hint
                ></v-file-input>
              </v-row>
              <v-row justify="space-between">
                <v-col>
                  <v-card>
                    <v-card-title>Unidades [unit]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="unit in units" :key="unit.id">
                          <td><strong>{{unit.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{unit.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Grupos [group]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="group in groups" :key="group.id">
                          <td><strong>{{group.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{group.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-btn :disabled="file==''" color="success" @click="onFileChange" :loading="loading">
              <v-icon class="mr-2">fas fa-file-upload</v-icon>Subir Archivo
            </v-btn>
            <v-btn text @click="e6=1">
              Atrás
            </v-btn>
          </v-stepper-content>
          <v-stepper-step
            :complete="e6 > 3"
            step="3"
            color="info"
            :rules="[() => errorSupplies.length>0?false:true]"
          >
            Resultados
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card
              color=""
              class="mb-12"
              min-height="100px" 
              flat
            >
              <v-row class="justify-center" v-if="errorSupplies.length>0 && newSupplies.length>0">
                <v-col class="text-center" cols="6">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>
                          Item
                        </th>
                        <th>
                          Nombre
                        </th>
                        <th>
                          Error
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in errorSupplies" :key="item.item">
                        <td>
                          {{item.item}}
                        </td>
                        <td>
                          {{item.name}}
                        </td>
                        <td>
                          {{item.message}}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              
              <v-row  v-else-if="newSupplies.length>0" class="justify-center align-center" style="height: 100%">
                <div class="text text-h6">
                  Se verificaron <strong>{{newSupplies.length}}</strong> registros correctamente
                </div>
              </v-row>
              
            </v-card>
            <v-btn
              color="success"
              @click="upload"
              :disabled="errorSupplies.length>0 && newSupplies.length>0"
              :loading="loading"
            >
              <v-icon class="mr-2">fas fa-sd-card</v-icon>
              Guardar
            </v-btn>
            <v-btn text @click="e6=2">
              Atrás
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import notifications from '@/utils/notifications'
import {uploadSupplies} from '@/api/products'

import axios from 'axios'
export default {
  name:'ImportSupplies',
  data() {
    return {
      file: '',
      newSupplies:[],
      errorSupplies:[],
      supplies:[],
      groups:[],
      units:[],
      loading:false,
      e6:1
    }
  },
  created(){
    const data = this.$route.params.data
    this.units=data.units
    this.groups=data.groups
    this.Supplies=data.supplies
  },
  methods:{
    onFileChange(){
      const config={
        header: true,
        complete: (results) =>{
          this.verifyData(results)
        },
      }
     this.$papa.parse(this.file, config)
    },
    async upload(){
      this.loading=true
      await uploadSupplies(this.newSupplies)
      .then((res)=>{
        notifications.showSuccess('Registros cargado correctamente' + res)
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando materiales '+err)
        this.loading=false
      })
    },
    async verifyData(data){
      this.loading=true
      console.log(data)
      this.newSupplies=[]
      this.errorSupplies=[]
      this.header=[]
      
      //Verifica que la cantidad de campos sea correcta
      if(data.meta.fields.length!=5){
        notifications.showError('Error en cantidad de campos')
        this.loading=false
        return 0
      }
      
      //Validación de Datos
      data.data.map((item)=>{
        let error = false
        let message=''
        let foundGroup=false
        let foundUnit=false
        
        //Valida que el resto de los campos no estén vaciós
        Object.keys(item).map(key=>{
          if(item[key].length==0){
            error=true
            message=`Campo ${key} vacío`
          } 
        })

        //Valida que no haya un nombre duplicado
        this.newSupplies.map(newProcess=>{
          if (newProcess.name.toLowerCase() == item.name.toLowerCase()){
            error = true
            message='Nombre Duplicado'
          } 
        })

        this.supplies.map(process=>{
          if(process.name.toLowerCase()==item.name.toLowerCase()){
            error = true
            message='Nombre ya existe en la plataforma'
          } 
        })
        
        //Valida que el grupo existe
        this.groups.map(group=>{
          if(group.id==item.group){
            foundGroup=true
          }
        })
        if(!foundGroup){
            error=true
            message='Grupo no encontrado'
        }

        //Valida que la unidad existe
        this.units.map(unit=>{
          if(unit.id==item.unit){
            foundUnit=true
          }
        })
        if(!foundUnit){
            error=true
            message='Unidad no encontrada'
        }

        //Valida que el campo valor es un número
        if(isNaN(item.cost)||parseInt(item.cost)<0){
          error=true
          message='El campo <cost> no es un número o es inválido'
        }

        //Valida que el campo min_cost un número
        if(isNaN(item.min_cost)||parseInt(item.min_cost)<0){
          error=true
          message='El campo <min_cost> no es un número o es inválido'
        }
        
        if(error==true){
          this.errorSupplies.push({
            item: data.data.indexOf(item)+1,
            name:item.name,
            message:message
          })
        }
        item.error = error
        this.newSupplies.push(item)
      })
      this.e6=3
      this.loading=false
    }
  }
}
</script>

<style lang="scss">
.v-stepper{
  box-shadow: 0  !important;
}
.v-stepper__content{
  margin: -8px -36px -16px 42px !important;
}
.v-stepper__step__step{
  height: 40px !important;
  width: 40px !important;
  font-size: 1rem !important;
}

</style>