const unit_list = [
    {
        id:1,
        name: "MTS 2"
    },
    {
        id:2,
        name:"UN"
    },
    {
        id:3,
        name:"FIJO"
    }
]

export default unit_list