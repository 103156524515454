<template>
  <v-container grid-list-xs>
  <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(save)">
      <v-row class="mb-10 justify-space-between">
        <v-col>
          <h1 class="text text-h4 titulo">{{role.id>0 ? 'Actualizar Rol':'Nuevo Rol'}}</h1>
        </v-col>
        <v-col class="text-end">
          <v-btn :loading="loading" type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
           <v-btn @click="$router.push('/users')" class="ml-2" text><v-icon>fas fa-angle-left</v-icon></v-btn>
        </v-col>
      </v-row>
      <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
        <v-text-field
        label='Nombre'
        v-model='name'
        prepend-icon="fas fa-sliders-h"
        outlined
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
    </v-form>
  </ValidationObserver>
  <v-select
    v-model="permits"
    :items="access_list"
    item-text="name"
    label="Select"
    return-object
    multiple
    outlined
    chips
    hint="Seleccione la lista de permisos"
    persistent-hint
  ></v-select>
  </v-container>
</template>

<script>
import notifications from '@/utils/notifications'
import {getRole,createRole,updateRole} from '@/api/auth'
import RolesAccess from '@/components/users/RolesAccess'
import access_list from '@/utils/access_list'
export default {
  name:'RoleCreateUpdate',
  components:{RolesAccess},
  data() {
    return {
      loading: false,
      access_list:access_list,
      id:'',
      name:'',
      permits:[],
      role:{
        id:'',
        name:'',
        access:[],
        permits:[],
      },
    }
  },
  methods:{
    async save(){
      this.loading=true
      if(this.id>0){
        await updateRole(
          {
            id:this.id,
            name:this.name,
            permits:this.permits,
          })
        .then((role)=>{
          notifications.showSuccess('Rol '+ role.name + ' Actualizado')
          this.$router.push({name:'Users'})
          this.loading=false
        })
        .catch((e)=>{
          notifications.showError('Error Actualizando el Rol '+e)
          this.loading=false
          })
      }
      else{
        await createRole(
          {
            name:this.name,
            permits:this.permits,
            access:[]
          })
        .then((role)=>{
          notifications.showSuccess('Rol '+ role.name + ' Creado')
          this.$router.push({name:'Users'})
        })
        .catch((e)=>{
          notifications.showError('Error Actualizando el Rol '+e)
          this.loading=false
          })
      }
    }
  },
  async created(){
    this.loading=true
    if(this.$route.params.id>0){
      await getRole(this.$route.params.id)
      .then(res=>{
        this.id=res.id
        this.name=res.name
        this.permits=res.permits
      })
      .catch((e)=>notifications.showError('Error de conexión '+e))
    }
    else this.id=-1
    this.loading=false
  },

}
</script>

<style>

</style>
