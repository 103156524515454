<template>
      <v-autocomplete
        v-model="value"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="primary"
        hide-selected
        item-text="text"
        item-value="id"
        label="Buscar una Orden de Compra"
        return-object
        outlined
        clearable
        cache-items
        @input="updateValue"
        @change="cambio"
        hide-details
        :disabled="disabled"
      >
        <template v-slot:selection="{ item}">
          <div class="d-flex">
            <div class="text mr-2">{{item.id}} - {{item.title}} - {{item.provider_name}}</div>
          </div>
        </template>
        <template v-slot:item="data">
          <div class="d-flex">
            <div class="text mr-2">{{data.item.id}} - {{data.item.title}} - {{data.item.provider_name}}</div>
          </div>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Buscar 
              <strong>Orden de Compra</strong>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    
</template>

<script>
import {fetchPurchase} from '@/api/stocks'
import notifications from '@/utils/notifications'

  export default {
    name:'PurchaseOrderComplete',
    data: () => ({
      descriptionLimit: 60,
      customers: [],
      isLoading: false,
      model: null,
      search:null,
      select:null,
      items: [],
    }),
    props:['value','disabled'],
    methods:{
      updateValue(){
        this.value ? this.$emit('input',this.value) : this.$emit('input','')
      },
      cleared(){
        this.$emit('input','')
      },
      cambio(){
        console.log('cambio en el value')
      },
      async querySelections (v) {
        this.isLoading = true
        //sort = '', page = '', page_size = '', search = '', seller = '', status = ''
        const query=`ordering=-created_at&search=${v}&page=1&&page_size=-1&estatus=2&stock_service=true`
        await fetchPurchase(query)
        .then((res)=>{
          const data = res.results.map(i=>{
            i.text=i.id+i.title+i.provider_name
            return i
          })
          this.items=data
          this.isLoading=false
        })
      },
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
      
    },
    
    
  }
</script>