<template>
<div class="mr-2">
    <v-icon :class="article.consume.length>0?'green--text':''" small @click="toogleDisplay">fas fa-basket-shopping</v-icon>
    <v-dialog persistent v-model="display" max-width="500">
        <v-card class="py-4">
            <v-card-title>
                <div>
                    Agregar consumo
                </div>
                 [{{article.sku}}] {{article.name}}
                 <div class="d-flex">
                    <v-chip outlined label small title="Unidad de compra">
                        Compra: <strong class="ml-1">{{article.unit_name}}</strong> 
                    </v-chip>
                    <v-chip outlined label small class="ml-2" title="Unidad de consumo">
                        Consumo:  <strong class="ml-1">{{article.unit_consume_name ||article.unit_name}}</strong> 
                    </v-chip>
                    <v-chip outlined label small class="ml-2" title="Factor de conversion">
                        Factor Conv.:  <strong class="ml-1">{{article.unit_consume_factor}}</strong> 
                    </v-chip>
                 </div>
            </v-card-title>
            
            <v-card-text class="mt-2">
                <v-simple-table>
                    <thead>
                        <th class="table-header2">
                            OT
                        </th>
                        <th class="table-header2">
                            Cant.
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in items" :key="index" class="py-2">
                            <td width="250">
                                <v-text-field
                                v-model='item.work_order'
                                placeholder="Orden de Trabajo"
                                outlined
                                :append-icon="item.work_order_finded?'fas fa-check':'fas fa-search'"
                                :loading="item.loadingWorkOrder"
                                @keydown="searchWorkOrder(item)"
                                hide-details
                                dense
                                />
                            </td>
                            <td width="250">
                                <v-text-field
                                    v-model="item.qty"
                                    placeholder="Cantidad"
                                    type="number"
                                    min="1"
                                    required
                                    outlined
                                    hide-details 
                                    dense   
                                />
                            </td>
                            <td>
                                <v-icon class="error--text" x-small @click.stop="removeItem(item)">fas fa-trash</v-icon>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-center pa-4">
                                <v-btn 
                                fab 
                                small 
                                class="pa-4" 
                                color="primary" 
                                @click.stop="addRow"
                                title="Agregar fila">
                                    <v-icon>fas fa-add</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-divider></v-divider>
                <div class="d-flex justify-space-around">
                    <div>
                        Total {{article.unit_consume_name ||article.unit_name}}: <strong class="ml-1">{{total | number('0,0[.]00')}}</strong>
                    </div>
                    <div>
                        Total {{article.unit_name}}: <strong class="ml-1" :class="total_consume>article.qty?'error--text':''">{{total_consume | number('0,0[.]00')}}</strong>
                    </div>
                </div>
                <v-divider></v-divider>
            </v-card-text>    
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loading" @click="save" :disabled="cannotSave">Guardar</v-btn>
                <v-btn text  
                @click="cancel"
                :disabled="loading"
                >Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import {getWorkOrder,createConsumptionBitacoraByOT} from '@/api/production'
import notifications from '@/utils/notifications'
export default{
    name:'ConsumeByOT',
    props:['article','addConsume','transaction'],
    data(){
        return{
            display:false,
            text:'Consumo por OT',
            work_order: '',
            work_order_finded: false,
            loadingWorkOrder: false,
            loading: false,
            timer:'',
            items:[{
                work_order:'',
                qty:'',
                work_order_finded:false,
                loadingWorkOrder:false,
            }],
        }
    },
    methods:{
        async toogleDisplay(){
            this.display=!this.display
            if(this.display&&this.article.consume.length>0){
                this.items=[]
                this.article.consume.forEach(i=>this.items.push(i))
            }
        },
        searchWorkOrder(item) {  
      //Función para hacer búsqueda lazy
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(async () => {
            item.loadingWorkOrder = true
            await getWorkOrder(item.work_order)
            .then((data)=>{
                item.work_order_finded=true 
                item.loadingWorkOrder=false
                //const {customer_name,created_at,name}=data
                //this.work_order_details=`${customer_name} ${moment(created_at).format('DD/MM/yyy')} ${name}`
            })
            .catch((e)=>{
                notifications.showError('Orden de Trabajo no encontrada')
                item.work_order_finded=false
                item.loadingWorkOrder=false
            })
        }, 800);
        },
        addRow(){
            this.items.push({
                id:-1,
                work_order:'',
                qty:'',
                work_order_finded:false,
                loadingWorkOrder:false,
            })
        },
        removeItem(item){
            this.items.splice(this.items.indexOf(item),1)
        },
        async save(){
            this.article.consume=[]
            const filtered = this.items.filter(item=>item.work_order_finded&&item.qty)
            filtered.forEach(i=>this.article.consume.push(i))
            
            //Repito el codigo para cuando se actualiza una transaccion
            if(this.transaction){
                this.loading=true
                const consume_data=this.items.map(item=>({
                    article_id:this.article.id,
                    workorder_id:item.work_order,
                    articlePrice:parseFloat(this.article.avg),
                    convertedPrice:parseFloat(this.article.avg)*(this.article.unit_consume_factor||1),
                    qty:item.qty,
                    unit:this.article.unit_name,
                    unitConsume:this.article.unit_consume_name||this.article.unit_name,
                    unitConsume_factor:this.article.unit_consume_factor||1
                }))

                await createConsumptionBitacoraByOT({'transaction_id':this.transaction.id,consume_data})
                .then(()=>{
                    notifications.showSuccess('Consumos actualizados correctamente')
                    
                })
                .catch(err=>{
                    notifications.showError('Error creando Consumo '+err)
                    return 0
                })
                .finally(()=>{
                    this.loading=false
                })
            }
            this.items=[]
            this.display=false
        },
        cancel(){
            this.items=this.article.consume.length>0?[]:[{
                work_order:'',
                qty:'',
                work_order_finded:false,
                loadingWorkOrder:false,
            }]
            this.display=false
        }
    },
    computed:{
        total(){
            return this.items.filter(i=>i.work_order_finded).reduce((total,item)=>{
                return total+parseFloat(item.qty)
            },0)
        },
        total_consume(){
            return this.total*this.article.unit_consume_factor
        },
        cannotSave(){
            return this.items.filter(i=>!i.work_order_finded || !i.qty).length>0 
        }
    },

}
</script>