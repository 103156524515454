<template>
  <div>
    <v-select
          dense
          :items="items"
          :prepend-icon="icon"
          label="Vendedor"
          :placeholder="value.email ? value.email:'Seleccione un Vendedor'"
          item-value="id"
          v-model="value"
          @input="updateValue"
          outlined
          :error-messages="errors"
          return-object
          :loading="loading"
          :disabled="loading || disabled"
    >
      <template v-slot:selection="{ item }">
        <div class="text">{{item.first_name + ' '+item.last_name + ' - ' }}</div>
      </template>
      <template v-slot:item="{ item }">
        <div class="text">{{item.first_name + ' '+item.last_name + ' - ' }}</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import {fetchSellers} from '@/api/auth'
import notifications from '../../utils/notifications'

export default {
  name:'SellerSelection',
  data:()=>({
    items: [],
    loading:false
  }),
  methods:{
    updateValue(){
      this.$emit('input',this.value)
    }
  },
  props:{
    value:'',
    errors:'',
    icon:'',
    disabled:{
      type:Boolean,
      default:false
    }
  },
  async created(){
    this.loading=true

    await fetchSellers()
    .then((res)=>this.items=res)
    .catch(e=>notifications.showError(e))
    .finally(()=>this.loading=false)
  }
}
</script>