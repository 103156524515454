<template>
  <div>
    <v-select
          dense
          :items="items"
          placeholder='Seleccione un Rubro'
          label="Rubro"
          :prepend-icon="icon"
          item-value="id"
          item-text="name"
          v-model="value"
          @input="updateValue"
          @change="change"
          outlined
          :error-messages="errors"
          return-object
          :disabled="disabled"
    >
    </v-select>
  </div>
</template>

<script>

import notifications from '../../utils/notifications'

export default {
  name:'RubroSelection',
  props:{
    value:{
      type:Object,
      default:()=>({id:''})
    },
    errors:{
      type:Array,
      default:()=>[]
    },
    icon:{
      type:String,
      default:''
    },
    loading:{
      type:Boolean,
      default:false
    },
    disabled:{
      type:Boolean,
      default:false
    },
    filtro:{
      type:Boolean,
      default:true
    },
  },
  data:()=>({
    items:[]
  }),
  methods:{
    updateValue(){
      this.$emit('input',this.value.id)
    },
    change(){
      this.$emit('change',this.value.id)
    }
  },
  mounted(){
    if (this.filtro){
      this.items=[{name:'Todos',id:''},...this.$store.state.rubros]
    }else this.items=this.$store.state.rubros
  }

}
</script>