import axios from "axios"

export async function getFactronicaCustomer(customer_rut) {
    return await axios.get(`factronica/import-customer/?rut=${customer_rut}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            if (e.response.data.error) {
                throw e.response.data.error
            }
            else throw e.response.data
        })
}

export async function importFactronicaCustomer(customer_rut,seller) {
    return await axios.post(`factronica/import-customer/?rut=${customer_rut}&&seller=${seller}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            if (e.response.data.error) {
                throw e.response.data.error
            }
            else throw e.response.data
        })
}

export async function createFactronicaDTE(dte) {
    return await axios.post('factronica/dte/create/',dte)
        .then(res => {
            return res.data
        })
        .catch(e => {
            if (e.response.data.error) {
                throw e.response.data.error
            }
            else throw e.response.data
        })
}