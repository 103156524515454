<template>
  <v-container>
      <h1 class="text text-h4 titulo mb-6">
        <v-icon class="mr-2" large>event_available</v-icon>Calendario de Compromisos [Mts<sup>2</sup>]
      </h1>
      <div class="d-flex justify-space-between align-center mb-2">
        <div class="d-flex align-center">
          <v-select
            v-model="line"
            :items="getLines"
            item-text="name"
            item-value="name"
            label="Todas las lineas"
            placeholder="Todas las Líneas"
            single-line
            outlined
            hide-details
            dense
            clearable
            prepend-inner-icon="filter_alt"
            @change="groupWorkOrders"
          />
          <v-switch
            class="d-flex d-inline-flex ml-4"
            inset
            dense
            v-model="productionCalendar"
            :color="productionCalendar ? 'primary':'error'"
            :label="productionCalendar ? 'Produccion':'Comprometido total'"
            @change="groupWorkOrders"
            ></v-switch>
        </div>
        <div>
          <v-chip small label dark color="main">Comprometido</v-chip>
          <v-chip small label color="green" dark>Terminado</v-chip>
          <v-chip small label color="error">Pendiente</v-chip>
        </div>
      </div>
        <div>
          <v-sheet
            tile
            height="54"
            class="d-flex align-center"
          >
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.prev()"
              :disabled="loading"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="text text-h6 titulo">{{currentMonth}}</div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.next()"
              :disabled="loading"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-sheet>
          <v-sheet height="600">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
                v-show="loading"
              ></v-progress-linear>
            <v-calendar
              v-show="!loading"
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :event-height="25"
              :events="events"
              :event-more="false"
              :event-overlap-mode="mode"
              :event-overlap-threshold="100"
              :event-color="getEventColor"
              @change="changeinCalendar"
              @click:event="showEvent"
              
            ></v-calendar>
            <!-- Tabla de productos comprometidos -->
            <v-dialog  v-model='selectedOpen' max-width="1200">
              <v-card class="" >
                <v-toolbar dense :color="selectedEvent.color" class="justify-space-between">
                  <div v-if="selectedEvent.type=='all'" class="text white--text text-h6 mr-2">Comprometido</div>
                  <div v-else-if="selectedEvent.type=='completed'" class="text white--text text-h6 mr-2">Terminado</div>
                  <div v-else class="text white--text text-h6 mr-2">Pendiente</div>
                  <div class="text white--text text-h6">{{selectedEvent.start | moment('DD/MM/YYYY')}}</div>
                  <v-spacer></v-spacer>
                  <div>
                    <vue-json-to-csv
                      :json-data="selectedEventProducts"
                      :labels="labels"
                      :csv-title="selectedEventTitle"
                      :separator="';'"
                      >
                      <v-btn dark text>
                        <v-icon class="mr-2">fas fa-file-export</v-icon>
                      </v-btn>
                    </vue-json-to-csv> 
                  </div>
                </v-toolbar>
                <v-data-table
                  :headers="headersSelectedEvent"
                  :items="selectedEventProducts"
                  :items-per-page="10"
                  class="pa-3"
                  :footer-props="{
                    'items-per-page-options': [10,20]
                  }"
                  >
                  <template v-slot:item.id="{ item }">
                    <show-work-order :work_order_id="item.id">{{item.id}}</show-work-order>
                  </template>


                  <template v-slot:item.calculatedTotalMts2="{ item }">
                    <span class="info--text">{{item.calculatedTotalMts2 |number('0,0.0')}}</span>
                  </template>
                  <template v-slot:item.completed_mts2="{ item }">
                    <span class="green--text">{{item.completed_mts2 |number('0,0.0')}}</span>
                    
                  </template>
                  <template v-slot:item.pending="{ item }">
                    <span class="error--text">{{item.pending |number('0,0.0')}}</span>
                    
                  </template>
                
                </v-data-table>

              </v-card>
            </v-dialog>
            <!-- -------------------------------------------- -->
            <!-- <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            >
            <v-card
              min-width="350px"
              flat
              style="opacity:1"
            >
              <v-toolbar dense :color="selectedEvent.color" class="justify-space-around">
                  <div v-if="selectedEvent.type=='all'" class="text white--text text-h6 mr-2">Comprometido</div>
                  <div v-else-if="selectedEvent.type=='completed'" class="text white--text text-h6 mr-2">Terminado</div>
                  <div v-else class="text white--text text-h6 mr-2">Pendiente</div>
                  <div class="text white--text text-h6">{{selectedEvent.start | moment('DD/MM/YYYY')}}</div>
              </v-toolbar>
              <v-list>
                <div v-if="selectedEvent.type=='all'">
                  <v-list-item v-for="workOrder in selectedEvent.workOrders" :key="workOrder.id">
                    <show-work-order :work_order_id="workOrder.id">
                      <div class="d-flex">
                        <div class="text mr-2">OT {{workOrder.id}} - {{workOrder.name}}</div>
                        <div class="text"><strong :class="`${selectedEvent.color}--text`">{{workOrder.total_mts2 | number('0,0.00')}}</strong> Mts<sup>2</sup></div>
                      </div>
                    </show-work-order>
                  </v-list-item>
                </div>
                <div v-else-if="selectedEvent.type=='completed'">
                  <div v-for="workOrder in selectedEvent.workOrders" :key="workOrder.id">
                    <v-list-item v-if="workOrder.completed_mts2>0">
                      <show-work-order :work_order_id="workOrder.id">
                        <div class="d-flex">
                          <div class="text mr-2">OT {{workOrder.id}} {{workOrder.name}}</div>
                          <div class="text"><strong :class="`${selectedEvent.color}--text`">{{workOrder.completed_mts2 | number('0,0.00')}}</strong> Mts<sup>2</sup></div>
                        </div>
                      </show-work-order>
                    </v-list-item>
                  </div>
                </div>
                <div v-else>
                  <div v-for="workOrder in selectedEvent.workOrders" :key="workOrder.id">
                    <v-list-item>
                      <show-work-order :work_order_id="workOrder.id">
                        <div class="d-flex">
                          <div class="text mr-2">OT {{workOrder.id}} - {{workOrder.name}}</div>
                          <div class="text"><strong :class="`${selectedEvent.color}--text`">{{workOrder.total_mts2-workOrder.completed_mts2 | number('0,0.00')}}</strong> Mts<sup>2</sup></div>
                        </div>
                      </show-work-order>
                    </v-list-item>
                  </div>
                </div>
                <v-list-item>
                  Total: <span class="ml-2" ><strong :class="`${selectedEvent.color}--text`">{{selectedEvent.value | number('0,0.00')}}</strong> Mts<sup>2</sup></span> 
                </v-list-item>
              </v-list>  
                
            </v-card>
          </v-menu> -->
          </v-sheet>
        </div>
        <v-dialog  v-model='display' max-width="1200">
          <v-card class="pa-4" >
            <work-order-card :work_order='workOrderOnDisplay' @close="display=false" :loading="workOrderOnDisplayLoading"/> 
          </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import numeral from 'numeral'
import {fetchWorkOrders} from '@/api/production'
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
export default {
  name:'CompromiseCalendar',
  components:{
    VueJsonToCsv
  },
  data() {
    return {
      
      currentMonth: '',
      workOrders:[],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [1, 2, 3, 4, 5,6,0],
      weekdays: [
        { text: 'Lunes - Domingo', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Lunes - Viernes', value: [1, 2, 3, 4, 5] },
      ],
      value: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1','orange darken-4'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      workOrderOnDisplay:{},
      workOrderOnDisplayLoading:false,
      display:false,
      loading:false,
      line:null,
      headersSelectedEvent:[
        {text:'OT',value:'id'},
        {text:'Cliente',value:'customer_name'},
        {text:'Proyecto',value:'name'},
        {text:'Total',value:'calculatedTotalMts2',align:'right'},
        {text:'Terminados',value:'completed_mts2',align:'right'},
        {text:'Pendientes',value:'pending',align:'right'},
      ],
      productionCalendar:false,
      labels:{ 
        id: { title: 'OT' },
        customer_name:{title:'Cliente'},
        name:{title:'Proyecto'},
        calculatedTotalMts2:{title:'Total'},
        completed_mts2:{title:'Terminados'},
        pending:{title:'Pendiente'},
       },
    }
  },
  methods:{
      getEventColor (event) {
        return event.color
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      groupWorkOrders(){
        
        //Reset de events
        this.events=[]

        //Cambio el formato de la fecha
        this.workOrders.forEach(wO=>{
            wO.stringDeliveryDate=moment(wO.delivery_date).format('YYYY-MM-DD')
        })

        //Agrupo por día
        const groupByDay = this.workOrders.reduce((acc, value) =>{
          if (!acc[value.stringDeliveryDate]) {
            acc[value.stringDeliveryDate] = [];
          }
          acc[value.stringDeliveryDate].push(value);
          return acc;
        }, {});

        console.log(groupByDay)
        
        //Recorro los días
        Object.keys(groupByDay).map(day=>{
            var start = ''
            var totalMts2=0
            var totalMts2Completed=0
          
            groupByDay[day].map(wO=>{

              


              //Cambio de algoritmo para calcular los totales por linea
              // de negocio
              //wO.filteredproducts=wO.products 
              // filtro lor productos logistics
              
              if(this.productionCalendar) wO.filteredproducts=wO.products.filter(p=>!p.logistic)
              else wO.filteredproducts=wO.products
              
              if (this.line){
                // console.log('linea existe')
                // console.log(wO.products)
                wO.filteredproducts=wO.products.filter(p=>p.line_name==this.line)

                //Calculo total de Mts2 por orden de trabajo sumando el total de mts2 de cada producto
                wO.calculatedTotalMts2=wO.filteredproducts.reduce((acc,val)=>{return acc + parseFloat(val.mts2);},0)

                //Ingreso esta linea para formatear el valor de mts2
                wO.calculatedTotalMts2=parseFloat(wO.calculatedTotalMts2.toFixed(2))

                totalMts2+=wO.calculatedTotalMts2
              }
              else{
                //Calculo total de Mts2 por orden de trabajo
                wO.calculatedTotalMts2=wO.filteredproducts.reduce((acc,val)=>{return acc + parseFloat(val.mts2);},0)

                //Ingreso esta linea para formatear el valor de mts2
                wO.calculatedTotalMts2=parseFloat(wO.calculatedTotalMts2.toFixed(2))

                totalMts2+=wO.calculatedTotalMts2
              }

              //Calculo total de Mts2
              //totalMts2+=parseFloat(wO.total_mts2) //Ya no se usa  
              

              start = new Date(wO.delivery_date)
              wO.completed_mts2=0

              wO.filteredproducts.filter(p=>p.completed).forEach(p=>{ wO.completed_mts2+=parseFloat(p.mts2)})
              //Ingreso funcion para quitar decimales al numero
              wO.completed_mts2=parseFloat(wO.completed_mts2.toFixed(2))
              totalMts2Completed+=wO.completed_mts2
              wO.pending=parseFloat(wO.calculatedTotalMts2-wO.completed_mts2).toFixed(2)
              
            })

            this.events.push({
              name:`${numeral(totalMts2).format('0,0[.]0')}`,
              value: totalMts2,
              start:start,
              color:'main',
              workOrders:groupByDay[day],
              type:'all'
            })
            this.events.push({
              name:`${numeral(totalMts2Completed).format('0,0[.]0')}`,
              value: totalMts2Completed,
              start:start,
              color:'green',
              workOrders:groupByDay[day],
              type:'completed',
              alignment:'center'
            })
            this.events.push({
              name:`${numeral(totalMts2-totalMts2Completed).format('0,0[.]0')}`,
              value: totalMts2-totalMts2Completed,
              start:start,
              color:'error',
              workOrders:groupByDay[day].filter(wO=>!wO.completed),
              type:'pending'
            })
              
        })
      },
      async changeinCalendar(event){
        this.events=[]
        this.currentMonth=moment(event.start.date).format('MMMM').toUpperCase()+' - '+event.start.year
        this.loading=true
        //Aplicando restricción para vendedor
        var filterSeller=''
        if (!this.$can('realAll', 'SalesNote')){
          const user = this.$store.getters.getUser
          filterSeller=user.id
        }
        if(this.$can('read','WorkOrder')){
          filterSeller=''
        }
        await fetchWorkOrders(1,-1,'','','',event.start.date,event.end.date,'',filterSeller)
        .then((data)=>{
          this.workOrders = data.results
          
          //Se va a la funcion que agrupa OTs por día
          this.groupWorkOrders()
          
        })
        .catch(error=>{
          notifications.showError(error)
        })
        .finally(()=>{
          this.loading=false
        })
      }
  },
  computed:{
    getLines(){
      return this.$store.getters.getLines
    },
    selectedEventProducts(){
      if(this.selectedEvent.type=='all'){
        if(this.line){
          return this.selectedEvent.workOrders.filter(wO=>wO.filteredproducts.length>0)
        }
        else {
          return this.selectedEvent.workOrders
        }
      }
      else if(this.selectedEvent.type=='completed'){
        return this.selectedEvent.workOrders.filter(wO=>wO.calculatedTotalMts2>=wO.completed_mts2 && wO.completed_mts2>0)
      }
      else if(this.selectedEvent.type=='pending'){
        return this.selectedEvent.workOrders.filter(wO=>wO.calculatedTotalMts2>wO.completed_mts2)
      }
    },
    selectedEventTitle(){
      if(this.selectedEvent?.type=='all'){
        return 'Comprometida_'+this.$options.filters.moment(this.selectedEvent?.start,'DD/MM/YYYY')
      }
      else if(this.selectedEvent?.type=='completed'){
        return 'Completada_'+this.$options.filters.moment(this.selectedEvent?.start,'DD/MM/YYYY')
      }
      else if(this.selectedEvent?.type=='pending'){
        return 'Pendiente_'+this.$options.filters.moment(this.selectedEvent?.start,'DD/MM/YYYY')
      }
    }
  },
}
</script>

<style  lang="scss">
.v-event{
  font-size:15px !important;
  height:20px !important;
}
.v-past{
  background-color: lightgrey !important;
}
.v-calendar-weekly__day-label{
  .v-btn{
    height: 20px !important;
  }
}

</style>