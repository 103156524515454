var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-8 px-10"},[(!_vm.global&&!_vm.workOrder)?_c('v-card-title',{staticClass:"titulo text-h4 mb-2"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.cost.is_supply?'fas fa-calculator':'fas fa-cogs'))]),_vm._v(" "+_vm._s(_vm.cost.id>0 ? 'Actualizar ':'Crear ')+" "+_vm._s(_vm.cost.is_supply?'Material ':'Proceso ')+" "+_vm._s(_vm.custom ? 'Adicional':'')+" ")],1):(_vm.global)?_c('v-card-title',{staticClass:"titulo text-h4 mb-2"},[_vm._v(" Agregar Costo Global "),_c('v-icon',[_vm._v("language")])],1):(_vm.workOrder)?_c('v-card-title',{staticClass:"titulo text-h4 mb-2"},[_vm._v(" Agregar Costo ")]):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.savecost)}}},[(!(_vm.custom||_vm.workOrder))?_c('ValidationProvider',{attrs:{"name":"Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"prepend-icon":"fas fa-object-group","dense":"","items":_vm.groups,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Seleccione un Grupo","single-line":"","outlined":"","clearable":"","error-messages":errors},model:{value:(_vm.cost.group),callback:function ($$v) {_vm.$set(_vm.cost, "group", $$v)},expression:"cost.group"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","prepend-icon":"mdi-file-document","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.cost.name),callback:function ($$v) {_vm.$set(_vm.cost, "name", $$v)},expression:"cost.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Precio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Costo","type":"number","prepend-icon":"fas fa-money-bill-wave","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.cost.price),callback:function ($$v) {_vm.$set(_vm.cost, "price", $$v)},expression:"cost.price"}})]}}],null,true)}),(!(_vm.global||_vm.workOrder))?_c('ValidationProvider',{attrs:{"name":"Mínimo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Costo Mínimo","type":"number","prepend-icon":"fas fa-hand-holding-usd","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.cost.min_price),callback:function ($$v) {_vm.$set(_vm.cost, "min_price", $$v)},expression:"cost.min_price"}})]}}],null,true)}):_vm._e(),(!_vm.workOrder)?_c('ValidationProvider',{attrs:{"name":"Units","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.units,"item-text":"name","item-value":"id","label":"Unidades","prepend-icon":"mdi-gauge","dense":"","placeholder":"Seleccione la Unidad","single-line":"","outlined":"","clearable":"","loading":_vm.loading,"error-messages":errors},model:{value:(_vm.cost.unit),callback:function ($$v) {_vm.$set(_vm.cost, "unit", $$v)},expression:"cost.unit"}})]}}],null,true)}):_vm._e(),(!_vm.cost.is_supply)?_c('ValidationProvider',{attrs:{"name":"Máquina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.cost.is_supply)?_c('v-select',{attrs:{"prepend-icon":"fas fa-cog","dense":"","items":_vm.machines,"item-text":"name","item-value":"id","label":"Área de Producción","placeholder":"Seleccione un Área de Producción","clearable":"","single-line":"","outlined":"","loading":_vm.loadingMachines,"error-messages":errors},model:{value:(_vm.cost.machine),callback:function ($$v) {_vm.$set(_vm.cost, "machine", $$v)},expression:"cost.machine"}}):_vm._e()]}}],null,true)}):_vm._e(),(!_vm.cost.is_supply && _vm.cost.unit==2 && !_vm.global)?_c('ValidationProvider',{attrs:{"name":"Merma","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Merma Cant Fija","type":"number","prepend-icon":"","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.cost.merma),callback:function ($$v) {_vm.$set(_vm.cost, "merma", $$v)},expression:"cost.merma"}})]}}],null,true)}):_vm._e(),(_vm.cost.is_supply && _vm.cost.unit==2 && !_vm.global)?_c('div',[_c('v-checkbox',{attrs:{"label":"¿Tiene Dimensiones?","dense":""},model:{value:(_vm.cost.has_dimension),callback:function ($$v) {_vm.$set(_vm.cost, "has_dimension", $$v)},expression:"cost.has_dimension"}}),(_vm.cost.has_dimension)?_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{attrs:{"name":"Dimensiones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ancho (cms)","prepend-icon":"fas fa-ruler-horizontal","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.cost.width),callback:function ($$v) {_vm.$set(_vm.cost, "width", $$v)},expression:"cost.width"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Dimensiones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Alto (cms)","prepend-icon":"fas fa-ruler-vertical","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.cost.height),callback:function ($$v) {_vm.$set(_vm.cost, "height", $$v)},expression:"cost.height"}})]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.cost.is_supply && !_vm.custom && !_vm.global && !_vm.workOrder)?_c('div',[_c('v-checkbox',{attrs:{"label":"¿Vincular con articulo de inventario?","dense":""},model:{value:(_vm.cost.has_article),callback:function ($$v) {_vm.$set(_vm.cost, "has_article", $$v)},expression:"cost.has_article"}}),(_vm.cost.has_article)?_c('div',{staticClass:"d-block"},[_c('article-complete',{staticClass:"mb-6",attrs:{"article_name":_vm.cost.article?_vm.cost.article.name:''},model:{value:(_vm.cost.article),callback:function ($$v) {_vm.$set(_vm.cost, "article", $$v)},expression:"cost.article"}}),(_vm.cost.article)?_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Articulo","filled":"","outlined":"","dense":"","disabled":""},model:{value:(_vm.cost.article.name),callback:function ($$v) {_vm.$set(_vm.cost.article, "name", $$v)},expression:"cost.article.name"}}):_vm._e(),_c('v-select',{attrs:{"dense":"","items":_vm.price_update_options,"item-text":"name","item-value":"value","label":"Actualizar Precio","single-line":"","outlined":""},on:{"change":_vm.updatePrice},model:{value:(_vm.cost.price_update_option),callback:function ($$v) {_vm.$set(_vm.cost, "price_update_option", $$v)},expression:"cost.price_update_option"}}),_c('v-alert',{attrs:{"type":"info","dense":""}},[_vm._v(" No se considera la unidad de compra del articulo a vincular ")])],1):_vm._e()],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loadingMachines,"color":"success","type":"submit"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }