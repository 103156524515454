<template>
  <v-container>
    <v-row justify="space-between">
      
     <Can I="readAll" a="SalesNote">
      <v-col>
        <v-card class="pa-2" :loading="loading">
          <v-card-title >
            <div class="subheading font-weight-light grey--text text-center">
                Ranking Clientes
            </div>
          </v-card-title>
          <v-card-text>
            <bar-chart
              :chart-data="customerDataColletion"
              :options='horizontalBarChartOptions'
              label="Ranking Vendedores"
              :height="180"
            />
          </v-card-text>
        </v-card>
      </v-col>
      </Can>
      <Can I="readAll" a="SalesNote">
      <v-col>
        <v-card class="pa-2" :loading="loading">
          <v-card-title >
            <div class="subheading font-weight-light grey--text text-center">
                Ranking de Vendedores
            </div>
          </v-card-title>
          <v-card-text>
            <bar-chart
              :chart-data="sellerDataColletion"
              :options='horizontalBarChartOptions'
              label="Ranking Vendedores"
              :height="180"
            />
          </v-card-text>
        </v-card>
      </v-col>
      </Can>
    </v-row>
    
  </v-container>
</template>

<script>
import {salesByDate,confirmedByDate,salesByCustomer,salesBySeller} from '@/utils/salesData'
import {fetchSalesNote,fetchDayGraph} from '@/api/salesNotes'
import {fetchWorkOrders} from '@/api/production'
import LineChart from '@/components/charts/LineChart'
import BarChart from '@/components/charts/BarChart'
import DoughnutChart from '@/components/charts/DoughnutChart'
import notifications from '@/utils/notifications'


const numeral = require('numeral');
const moment = require('moment')
export default {
  name:'DayGraph',
  components:{LineChart,BarChart,DoughnutChart},
  data:()=>({
    salesNotes:[],
    loading:false,
    workOrders:[],
    quoted:'',
    confirmed:'',
    dataSet:[],
    donutTitle:'',
    chartOptions:{
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: value => numeral(value).format('$0,0')
          }
        }],
      },
      tooltips: {
        mode: 'index',
        displayColors:true,
        callbacks: {
          label: function(context) {
            console.log(context)
            var label = numeral(context.value).format('$0,0')
            return label;
          }
        }
      }
    },
    horizontalBarChartOptions:{
      indexAxis: 'y',
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: false,
            callback: value => numeral(value).format('$0,0a')
          }
        }],
      },
      tooltips: {
        mode: 'index',
        displayColors:false,
        callbacks: {
          label: function(context) {
            var label = numeral(context.value).format('$0,0')
            return label;
          }
        }
      }
    },
    dognutChartOptions:{
      responsive: true,
      maintainAspectRatio: false,
    },
    dataRange:[
      {
      text:  moment(new Date().toString()).format('MMMM-YYYY'),
      after: moment(new Date().toString()).format('YYYY')+'-'+moment(new Date().toString()).format('MM')+'-01',
      before:moment(new Date().toString()).format('YYYY-MM-DD'),
      format:'DD-MM-YYYY',
      monthly:''
      },
      {
        text:'30 días',
        after: moment(new Date().toString()).add(-1,'month').format('YYYY-MM-DD'),
        before:moment(new Date().toString()).format('YYYY-MM-DD'),
        format:'DD-MM-YYYY',
        monthly:''
      },
      {
        text:'12 Meses',
        after:moment(new Date().toString()).add(-1,'year').format('YYYY-MM-DD'),
        before:moment(new Date().toString()).format('YYYY-MM-DD'),
        format:'MMM',
        monthly:true
      }
    ],
    optionSelected:{},
    datacollection:[],
    workOrdersDataColletion:[],
    customerDataColletion:[],
    sellerDataColletion:[],
    percentageConfirmedDataCollection:[]
  }),
  methods:{
    async fetch(){
      this.loading=true
      //Trayendo el las Sales Notes para saber lo cotizado
      //sort = '', search = '', created_after = '', created_before = '', customer = '', seller = '', has_work_order = '', page = '', page_size = '' 
      var filterSeller=''
      if (!this.$can('readAll', 'SalesNote')){
        const user = this.$store.getters.getUser
        filterSeller=user.id
      }
   
      await fetchDayGraph(`created_after=${this.optionSelected.after}&created_before=${this.optionSelected.before}&seller=${filterSeller}&monthly=${this.optionSelected.monthly}`)
      .then((data)=>{
          const {quoted,confirmed,total_quoted,total_confirmed,customers,sellers}=data
          this.quoted=total_quoted
          this.confirmed=total_confirmed

          //Grafico de Cotizado y Confirmado
          this.datacollection={
            labels: quoted.map(d=>this.optionSelected.monthly===true?moment(d.date).format('MMM'):d.date),
            datasets: [
              {
                label: 'Cotizado',
                borderColor: '#1092b2',
                data: quoted.map(d=>d.total)
              },
              {
                label: 'Confirmado',
                borderColor: '#ffb224',
                data: confirmed.map(d=>d.total)
              }
            ]
          }

          /* this.workOrdersDataColletion={
            labels:confirmed.map(d=>d.date),
            datasets:[
              {
                label:'Confirmado',
                borderColor: '#00C853',
                borderColor:'#00C853',
                data: confirmed.map(d=>d.total)
              }
            ]
          } */

          this.customerDataColletion={
            labels: customers.map(d=>d.name),
            datasets: [
              {
                axis:'y',
                label:'Confirmado',
                backgroundColor: '#1092b2',
                borderColor:'#33425B',
                data: customers.map(d=>d.total)
              }, 
            ]
          }

          this.sellerDataColletion={
            labels: sellers.map(d=>d.full_name),
            datasets: [
              {
                axis:'y',
                label:'Confirmado',
                backgroundColor: '#1092b2',
                borderColor:'#33425B',
                data: sellers.map(d=>d.total)
              }, 
            ]
          }

      })

      
      this.loading=false

      //Solicitando los datos filtrados. El formato determina si es por año, por
      //mes o por día, y además indica el formato de la celda eje X del gráfico
      //retorna los totales.
      
    
      

      

      
      

      const sellerDataSet=salesBySeller(this.workOrders)
      

    }

      //numeral(content.totals.cost).format('$0,0')
      
  },
  async created(){
    this.optionSelected = this.dataRange[0]
    this.fetch()
  },
  watch:{
    //Revisa cambios en options para solicitar data nuevamente
    optionSelected:{
      deep: true,
      handler: 'fetch'
    }
  },
}
</script>

