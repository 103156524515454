<template>
  <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        v-if="user"
        color="#EEEEEE"
       
      >
        <v-list-group
          v-for="(menu,i) in menus"
          :key="i"
        >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item-content>
        </template>
        
         
            <!-- <Can I="read" :a="item.gate" :key="item.name">
            <v-row
              v-if="item.heading"
              :key="i"
              align="center"
            >
              <v-col cols="12">
                <h5 class="text ml-4 text-h6 titulo primary--text" v-if="item.heading">
                  {{ item.heading }}
                </h5>
              </v-col>
              
            </v-row>
            <v-divider
              v-else-if="item.divider"
              :key="i"
              dark
              class="my-4"
            ></v-divider>
            
            <v-list-item
              v-else
              :key="i"
              link
              @click="goTo(item.link)"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </Can> -->
          

        <div v-for="(item,index) in menu.items" :key="index" >
        <Can I="read" :a="item.gate" :key="item.name">
          <v-list-item :to="`/${item.link}`">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>

            </v-list-item>
           </Can>
        </div>
     

          
          
       
        </v-list-group>
      </v-navigation-drawer>
</template>

<script>
export default {
  name:'SideMenu',
  props:['drawer','user'],
  data() {
    return {
      items: [
        { icon: 'fas fa-home', text: 'Inicio', link:'',gate:'SalesNote'},
        { heading: 'Comercial' },
        { icon: 'fas fa-shopping-cart', text: 'Cotizaciones', link:'sales',gate:'SalesNote'},
        { icon: 'fas fa-city', text: 'Clientes', link:'customers',gate:'Customer'},
        { icon: 'fas fa-book', text: 'Productos', link:'products',gate:'Product'},
        { icon: 'tune', text: 'Familia y Línea', link:'products/family-line',gate:'Product' },
        { divider: true },
        { heading: 'Producción' },
        { icon: 'fas fa-hard-hat', text: 'Órdenes de Trabajo', link:'production/work-orders',gate:'WorkOrder'},
        { icon: 'fas fa-calendar-alt', text: 'Planificador', link:'production/main-planner',gate:'Planner'},
        { icon: 'fas fas fa-gamepad', text: 'Control de Piso', link:'production/floor-control',gate:'FloorControl'},
        { icon: 'event_available', text: 'Compromisos', link:'production/compromise-calendar',gate:'Calendar'},
        { divider: true },
        { heading: 'Inventarios' },
        { icon: 'fas fa-boxes', text: 'Inventario', link:'stocks/stock',gate:'Stocks'},
        { icon: 'fas fa-shopping-basket', text: 'Ordenes de Compra', link:'stocks/purchase-orders',gate:'Stocks'},
        { icon: 'fas fa-dolly-flatbed', text: 'Entradas/Salidas', link:'stocks/create-transaction',gate:'Stocks'},
        { icon: 'fas fa-box', text: 'Artículos', link:'stocks/articles',gate:'Stocks'},
        { icon: 'fas fa-cog', text: 'Mantenedores', link:'stocks/configuration',gate:'Stocks'},
        { divider: true },
        { heading: 'Gestión' },
        { icon: 'fas fa-flag-checkered', text: 'Post Costeo', link:'reports/post-cost',gate:'PostCost'},
        { icon: 'fas fa-chart-bar', text: 'Ventas por Producto', link:'reports/product-sales',gate:'PostCost'},
        { icon: 'fas fa-chart-area', text: 'Facturación por Producto', link:'reports/product-invoice',gate:'PostCost'},
        { icon: 'leaderboard', text: 'Ventas por Familia', link:'reports/family-sales',gate:'PostCost'},
        { icon: 'fas fa-truck', text: 'Material Comprometido', link:'reports/supply-orders',gate:'PostCost'},
        { divider: true },
        { heading: 'Finanzas' },
        { icon: 'fas fa-file-invoice-dollar', text: 'Facturación', link:'sales/invoices',gate:'SalesNote'},
        { divider: true },
        { heading: 'Configuración' },
        { icon: 'fas fa-cogs', text: 'Procesos', link:'products/processes', gate:'Cost'},
        { icon: 'fas fa-calculator', text: 'Materiales', link:'products/supplies',gate:'Cost' },
        { icon: 'tune', text: 'Grupos', link:'products/costs/groups-units',gate:'Cost' },
        { icon: 'fas fa-atom', text: 'Areas de Producción', link:'production/machines',gate:'Cost' },
        { divider: true },
        { icon: 'people', text: 'Usuarios', link:'users',gate:'User',gate:'User' },
        { icon: 'tune', text: 'Parámetros', link:'parameters',gate:'Parameter',gate:'Parameter' },
      ],
      menus:[
        {
          title: 'Comercial',
          items:[
            { icon: 'fas fa-shopping-cart', text: 'Cotizaciones', link:'sales',gate:'SalesNote'},
            { icon: 'fas fa-city', text: 'Clientes', link:'customers',gate:'Customer'},
            { icon: 'fas fa-book', text: 'Productos', link:'products',gate:'Product'},
            { icon: 'tune', text: 'Familia y Línea', link:'products/family-line',gate:'Product' },
          ]
        }, 
        {
          title: 'Producción',
          items:[
            { icon: 'fas fa-hard-hat', text: 'Órdenes de Trabajo', link:'production/work-orders',gate:'WorkOrder'},
            { icon: 'fas fa-calendar-alt', text: 'Planificador', link:'production/main-planner',gate:'Planner'},
            { icon: 'fas fas fa-gamepad', text: 'Control de Piso', link:'production/floor-control',gate:'FloorControl'},
            { icon: 'event_available', text: 'Compromisos', link:'production/compromise-calendar',gate:'Calendar'},
          ]
        }, 
        {
          title: 'Inventarios',
          items:[
            { icon: 'fas fa-boxes', text: 'Inventario', link:'stocks/stock',gate:'Stocks'},
            { icon: 'fas fa-shopping-basket', text: 'Ordenes de Compra', link:'stocks/purchase-orders',gate:'Stocks'},
            { icon: 'fas fa-dolly-flatbed', text: 'Entradas/Salidas', link:'stocks/create-transaction',gate:'Stocks'},
            { icon: 'fas fa-box', text: 'Artículos', link:'stocks/articles',gate:'Stocks'},
            { icon: 'fas fa-cog', text: 'Mantenedores', link:'stocks/configuration',gate:'Stocks'},
          ]
        }, 
        {
          title: 'Informes',
          items:[
            { icon: 'fas fa-flag-checkered', text: 'Post Costeo', link:'reports/post-cost',gate:'PostCost'},
            { icon: 'fas fa-chart-bar', text: 'Ventas por Producto', link:'reports/product-sales',gate:'PostCost'},
            { icon: 'leaderboard', text: 'Ventas por Familia', link:'reports/family-sales',gate:'PostCost'},
            { icon: 'fas fa-truck', text: 'Material Comprometido', link:'reports/supply-orders',gate:'PostCost'},
          ]
        },
        {
          title: 'Configuración',
          icon:'fas fa-cog',
          items:[
            { icon: 'fas fa-cogs', text: 'Procesos', link:'products/processes', gate:'Cost'},
          { icon: 'fas fa-calculator', text: 'Materiales', link:'products/supplies',gate:'Cost' },
          { icon: 'tune', text: 'Grupos y Unidades', link:'products/costs/groups-units',gate:'Cost' },
          { icon: 'fas fa-atom', text: 'Areas de Producción', link:'production/machines',gate:'Cost' },
          { icon: 'people', text: 'Usuarios', link:'users',gate:'User',gate:'User' },
          { icon: 'tune', text: 'Parámetros', link:'parameters',gate:'Parameter',gate:'Parameter' },
          ]
        } 
      ],
    }
  },
  methods:{
    goTo(link){
        this.$router.push('/'+link)
      },
  }
}
</script>

<style>

</style>
