var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('div',{staticClass:"text text-h6"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-basket-shopping")]),_vm._v(" Consumo")],1),_c('add-consumption',{attrs:{"productId":_vm.productId,"work_order_id":_vm.work_order_id},on:{"added":_vm.fetch}})],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-4",attrs:{"value":true,"icon":"fas fa-info-circle"}},[_vm._v(" No existen artículos registrados ")])]},proxy:true},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.indexOf(item) + 1)+" "),(item.reprocess)?_c('v-icon',{staticClass:"error--text ml-2",attrs:{"x-small":""}},[_vm._v("fas fa-clock-rotate-left")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY"))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v("["+_vm._s(item.article.sku)+"]")]),_vm._v(" "+_vm._s(item.article.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"error--text",attrs:{"x-small":""},on:{"click":function($event){return _vm.confirmDelete(item)}}},[_vm._v("fas fa-trash")])]}}])}),_c('confirm',{attrs:{"message":_vm.confirmationMessage},on:{"ok":_vm.deleteComsumption,"cancel":function($event){_vm.displayConfirmation=false}},model:{value:(_vm.displayConfirmation),callback:function ($$v) {_vm.displayConfirmation=$$v},expression:"displayConfirmation"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }