<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo"><v-icon class="mr-2 align-items-center">fas fa-boxes</v-icon>Inventario</h1>
    </v-col>
    <v-col class="text-end">
      <export-stock 
      :disabled="loading" 
      :family="family" 
      :store="store"
      />
    </v-col>
  </v-row>
  <v-card class='pa-4'>
    <v-row>
      <v-col>
        <v-select
          v-model="family"
          :items="families"
          item-text="name"
          item-value="id"
          label="Grupo"
          placeholder="Todas las Familias"
          :disabled="loading"
          single-line
          outlined
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="store"
          :items="stores"
          item-text="name"
          item-value="id"
          label="Bodega"
          placeholder="Todas las Bodegas"
          single-line
          :disabled="loading"
          outlined
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          @keyup="searchTimeOut"
          label="Buscar"
          append-icon="fas fa-search"
          :disabled="loading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
            class=""
            :headers="computedHeaders"
            :items="articles"
            :items-per-page="10"
            :loading="loading"
            :options.sync="options"
            :server-items-length="parseInt(total)"
            :search="search"
            sort-by='sku'
            :sort-desc="true"
            item-class="table-body"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right',
              itemsPerPageOptions: [10, 20, 30, 40, 50,100],
            }">
            <template v-slot:item.sku="{ item }">
              <v-icon class="mr-2 error--text" v-if="alertCritic(item)">fas fa-exclamation-circle</v-icon>{{item.sku}}
            </template>
            <template v-slot:item.name="{ item }">
              {{item.name}}
              <v-tooltip top v-if="item.cost">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 info--text" small v-bind="attrs" v-on="on">fas fa-link</v-icon>
                </template>
                <span>{{item.cost.name}}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.avg="{ item }">
              {{item.avg | currency('$', 0)}}
            </template>
            <template v-slot:item.total_stock="{ item }">
              {{totalStock(item) | number('0,0[.]0')}} 
              <v-menu bottom left offset-y> 
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                    v-on="on"
                    text
                  small class="ml-2">fas fa-chevron-down</v-icon>
                </template>
                <v-card class="pa-2">
                  <div v-if="item.stocks.length>0">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>
                            Bodega
                          </th>
                          <th>
                            Existencia
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for='register in item.stocks' :key='register.id'>
                          <td :class="register.add_stock?'':'error--text'">
                            {{register.store_name}}
                          </td>
                          <td class="text-end" :class="register.add_stock?'':'error--text'">
                            {{register.qty | number('0,0[.]0')}}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                  <h3 v-else class="text text-center pa-4">
                    No hay existencias
                  </h3>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:item.total_price="{ item }">
              {{totalStock(item)*item.avg | currency('$', 0)}}
            </template>
            <template v-slot:item.compromised="{ item }">
              <div v-if="item.compromised>0">
                {{item.compromised| number('0,0[.]0')}}
              </div>
              
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon  @click="$router.push({name:'DetailedArticle',params:{id:item.id}})">fas fa-eye</v-icon>
            
            </template>
          </v-data-table>
      </v-col>
    </v-row>
  </v-card>
  <v-dialog v-model="showDialog" max-width="500">
    
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchArticles,fetchFamilies,fetchStores} from '@/api/stocks'
import notifications from '@/utils/notifications'
import ExportStock from '@/components/stocks/ExportStock'

export default {
  name:'Stock',
  components:{ExportStock},
  data() {
    return {
      loading: false,
      family:'',
      families:[],
      store:'',
      stores:[],
      articles:[],
      article:'',
      timer:'',
      options:{},
      search:'',
      total:'',
      showDialog:false,
      headers: [
      {
        text: 'Código',
        align: 'start',
        value: 'sku',
        class: 'table-header2',
        show:true
      },
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        class: 'table-header2',
        show:true
      },
      
      {
        text: 'Unid.',
        align: 'start',
        value: 'unit_name',
        class: 'table-header2',
        show:true
      },
      {
        text: 'Exist.',
        align: 'start',
        value: 'total_stock',
        class: 'table-header2',
        sortable: false,
        align:'end',
        show:true
      },
      {
        text: 'Comp.',
        align: 'start',
        value: 'compromised',
        class: 'table-header2',
        sortable: false,
        align:'end',
        show:true
      },
      {
        text: 'Valor Prom.',
        align: 'start',
        value: 'avg',
        class: 'table-header2',
        sortable: false,
        align:'end',
        show:false
      },
      {
        text: 'Valor Total.',
        align: 'start',
        value: 'total_price',
        class: 'table-header2',
        sortable: false,
        align:'end',
        show:false
      },
      { text: '',value:'actions',class: 'table-header2',align:'center',sortable: false,show:true}
    ],
    }
  },
  methods:{
    totalStock(item){
      if(this.store){
        const filteredStocks = item.stocks.filter(i=>i.store==this.store)
        return filteredStocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
      }
      else return item.stocks.filter(i=>i.add_stock).reduce((acc, val)=>{ return acc + parseInt(val.qty) }, 0)
    },
    alertCritic(item){
      return this.totalStock(item)<item.critic&&item.alert
    },
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='family_name'){
        sort = 'family__name'
      }
      else if(sortBy[0]=='unit_name'){
        sort = 'unit__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.family) this.family=''
      if (!this.store) this.store=''
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&page=${page}&&page_size=${itemsPerPage}&&search=${this.search}&&family=${this.family}&&stocks__store=${this.store}&&status=true`
      await fetchArticles(query)
      .then(res=>{
        this.total=res.count
        this.articles=res.results
      })
      .catch((err)=>{
        notifications.showError('Error cargando artículos '+err)
      })
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    update(item){
      this.article=item
      this.showDialog=true
    },
  },
  watch:{
    async family(){
      this.fetch()
    },
    async store(){
      this.fetch()
    },
    options:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.fetch()
    this.loading=true
    this.families=await fetchFamilies()
    this.stores=await fetchStores()
    this.loading=false
  },
  computed:{
    computedHeaders(){
      return this.headers.filter(h=>h.show&&this.store||!this.store)
    }
  }
}
</script>

<style>

</style>