<template>
  <v-container>
    <h5 class="titulo text-center">{{title}}</h5>
    <bar-chart
      :chart-data="datacollection"
      :options='chartOptions'
      :height="height"
    />
  </v-container>
</template>

<script>
import VerticalBarChart from '@/components/charts/VerticalBarChart'
import BarChart from '@/components/charts/BarChart'
const numeral = require('numeral');
const moment = require('moment')
export default {
  name:'SalesProductGraph',
  components:{VerticalBarChart,BarChart},
  props:['data','icon','values','height','title'],
  data:()=>({
    salesNotes:[],
    loading:false,
    workOrders:[],
    quoted:'',
    confirmed:'',
    datacollection:[],
    chartOptions:{
      indexAxis: 'y',
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: false,
            callback: value => numeral(value).format('0,0a')
          }
        }],
      },
      tooltips: {
        mode: 'index',
        displayColors:false,
        callbacks: {
          label: function(context) {
            var label = numeral(context.value).format('0,0')
            return label;
          }
        }
      }
    },
  }),
  methods:{
    fillData(){
      //Ordenando de mayor a menor
      this.datacollection={
        labels: this.data.map(d=>d.name),
        datasets: [
          {
            axis:'y',
            label:'Confirmado',
            backgroundColor: '#33425B',
            borderColor:'#33425B',
            data: this.values
          }, 
        ]
      }
    },
  },
  async created(){
    this.fillData()
  },
  watch:{
    data:{
      handler: 'fillData'
    }
  }
}
</script>