<template>
  <v-container>
    <v-row class="mb-2 justify-space-between">
    <v-col>
      <h1 class="text text-h5 titulo">Historial</h1>
      <h2>{{cost.name}}</h2>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="headers"
          :items="items"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
          sort-by='created_at'
          item-class="table-body"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right'
          }">
          <template v-slot:item.created_at="{ item }">
            {{item.created_at | moment("DD/MM/YYYY HH:mm")}}
          </template> 
        </v-data-table>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import {fetchPriceUpdateLog} from '@/api/products'
import notifications from '@/utils/notifications'

export default {
  name:'PriceLogTable',
  props:['cost'],
  data() {
    return {
      loading: false, 
      options:'',
      items:[],
      headers: [
      {
        text: 'Creado',
        align: 'start',
        value: 'created_at',
        class: 'table-header',
        
      },
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        class: 'table-header',
        sortable:'false'
      }
    ],
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      
      const  sort = sortBy[0]
      
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&page=${page}&&page_size=${itemsPerPage}&&cost=${this.cost.id}`
      await fetchPriceUpdateLog(query)
      .then(res=>{
        this.total=res.count
        this.items=res.results
      })
      .catch((err)=>{
        notifications.showError('Error cargando el registro '+err)
      })
      this.loading=false
    },
  },
  watch:{
    async cost(){
      this.fetch()
    },
    options:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.fetch()
  }
}
</script>

<style>

</style>