<template>
<v-container>
  <v-row class="mb-4 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo">Familia y Línea</h1>
    </v-col>
  </v-row>
    <v-tabs 
      background-color="transparent"
      color="primary"
      grow
      v-model="tab"
    >
      <v-tab v-model="tab" class="primary--text">Familia</v-tab>
      <v-tab v-model="tab" class="primary--text">Línea</v-tab>
      <v-tabs-items v-model="tab">
      <v-tab-item class="pa-4">
        <family/>
      </v-tab-item>
      <v-tab-item class="pa-4">
        <product-line/>
      </v-tab-item>
    </v-tabs-items>
    </v-tabs>
</v-container>
</template>
<script>
import Family from '@/components/products/products/Family'
import ProductLine from '@/components/products/products/Line'



export default {
  name:'FamilyLine',
  components:{Family,ProductLine},
  data:()=>({
    tab: null,
  })
}
</script>