<template>
<v-container>
  <v-row>
    <v-col>
      <v-select
        v-model="family"
        :items="families"
        item-text="name"
        item-value="id"
        label="Familia"
        placeholder="Todos las Familias"
        single-line
        outlined
        dense
        clearable
        prepend-inner-icon="fas fa-filter"
        
        :disabled="loading||this.families.length==0"
      />
    </v-col>
    <v-col>
      <v-select
        v-model="line"
        :items="lines"
        item-text="name"
        item-value="id"
        label="Linea"
        placeholder="Todas las Líneas"
        single-line
        outlined
        dense
        clearable
        prepend-inner-icon="fas fa-filter"
        
        :disabled="loading||this.lines.length==0"
      />
    </v-col>
  </v-row>
  <v-row justify="end">
    <v-col cols="6">
      <v-text-field
        v-model="search"
        @keyup="searchTimeOut"
        label="Buscar"
        append-icon="fas fa-search"
        :disabled="loading"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
        class=""
        :headers="headers"
        :items="products"
        :loading="loading"
        :options.sync="options"
        :server-items-length="total"
        :search="search"
        item-class="table-body"
        @update:options="fetch"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }">
        <template v-slot:item.name="{ item }">
          {{item.name}}
          <v-icon>
            {{item.sell_unit_m2 ? 'square_foot':'view_comfy'}}
          </v-icon>
        </template>
        <template v-slot:item.customer_name="{ item }">
          <v-tooltip top v-if="item.customer_name">
            <template v-slot:activator="{ on,attrs }">
              <v-icon  x-small class="medium_blue--text" style="cursor: pointer" v-bind="attrs" v-on="on">fas fa-circle</v-icon>
            </template>
            <span>Solo para {{customer.name.slice(0,20)+"..."}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn text class="mr-2 success--text" @click="add(item)"><v-icon>fas fa-cart-plus</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import {fetchProducts,fetchFamily,fetchLines} from '@/api/products'
import CustomerComplete from '@/components/customers/CustomerComplete'
import notifications from '@/utils/notifications'

export default {
  name:'AddProduct',
  components:{CustomerComplete},
  props:['customer'],
  data:()=>({
    products:[],
    search:'',
    timer:'',
    options:{},
    total:0,
    showDialog:false,
    loading:false,
    lines:[],
    families:[],
    family:'',
    line:'',
    headers: [
          {
            text: 'Código',
            align: 'start',
            value: 'sku',
            class: 'table-header2'
          },
          {
            text: 'Descripción',
            align: 'start',
            value: 'name',
            class: 'table-header2'
          },
          {
            text: '',
            align: 'start',
            value: 'customer_name',
            class: 'x-small'
          },
          {
            text: 'Familia',
            align: 'start',
            value: 'family_name',
            class: 'table-header2'
          },
          {
            text: 'Línea',
            align: 'start',
            value: 'line_name',
            class: 'table-header2'
          },
          { text: 'Agregar',value:'actions',sortable: false,class: 'table-header2'}
        ],
  }),
  methods:{
    add(item){
      this.$emit('add',item)
      this.search=''
      this.family=''
      this.line=''
      this.$emit('close')
    },
    async fetch(){
      this.loading=true
      
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort = ''
      if(sortBy[0]=='family_name'){
        sort = 'family__name'
      }
      else if(sortBy[0]=='line_name'){
        sort = 'line__name'
      }
      else if(sortBy[0]=='customer_name'){
        sort = 'customer__name'
      }
      else {
        sort = sortBy[0]
      }
      if(!this.family) this.family=''
      if(!this.line) this.line=''
      const customer_and_null=this.customer?this.customer.id:0
      //(sort = '', search = '', page = '', page_size = '', customer = '', family = '', line = '', status = '',customer_and_null='')
      //ordering=${sort}&page=${page}&page_size=${page_size}&search=${search}&customer=${customer}&family=${family}&line=${line}&status=${status}&customer_and_null=${customer_and_null}
      const query=`ordering=${sortDesc[0]?sort:'-'+sort}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&family=${this.family}&line=${this.line}&customer_and_null=${customer_and_null}&status=true`
      await fetchProducts(query)
      .then((data)=>{
        this.total=data.count
        this.products = data.results
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando productos '+err)
        this.loading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.options.page=1
          this.fetch()
      }, 1000);
    },

  },
  watch:{
    async family(){
      this.options.page=1
      this.fetch()
    },
    async line(){
      this.options.page=1
      this.fetch()
    },
    async customer(){
      this.options.page=1
      if(this.customer){
        this.options.sortBy[0]='customer_name'
        this.options.sortDesc[0]=true
      }
      else {
        this.options.sortBy[0]='sku'
        this.options.sortDesc[0]=false
      }
      this.fetch()
    },
    
  },
  async mounted(){
    
    if(this.customer){
      this.options.sortBy[0]='customer_name'
      this.options.sortDesc[0]=true
    }
    this.fetch()
    this.families=this.$store.getters.getFamilies
    this.lines=this.$store.getters.getLines
    
  }
}
</script>