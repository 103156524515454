<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col>
      <h1 class="text text-h5 titulo"><v-icon class="mr-2" >fa fa-search</v-icon><v-icon class="mr-2 align-items-center">fas fa-box</v-icon>Buscar Artículos</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-select
        v-model="family"
        :items="families"
        item-text="name"
        item-value="id"
        label="Grupo"
        placeholder="Todas las Familias"
        single-line
        outlined
        dense
        clearable
        prepend-inner-icon="filter_alt"
      />
    </v-col>
    <v-col>
      <v-text-field
        v-model="search"
        @keyup="searchTimeOut"
        label="Buscar"
        append-icon="fas fa-search"
        :disabled="loading"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="headers"
          :items="articles"
          :items-per-page="5"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
          :search="search"
          sort-by='sku'
          item-class="table-body"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right'
          }"> 
          <template v-slot:item.actions="{ item }">
            <v-btn fab color="success" class="px-4" x-small @click.stop="add(item)">
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
    </v-col>
  </v-row>
  <v-dialog v-model="showDialog" max-width="500">
    <v-card class="px-10 py-4">
      <article-create-update 
      :families="families" 
      :units="units" 
      :id="article"
      @updated="fetch"
      @close="showDialog=false"
      />
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchArticles,fetchFamilies,fetchUnits} from '@/api/stocks'
import notifications from '@/utils/notifications'

export default {
  name:'ArticlesTable',
  props:['value'],
  data() {
    return {
      loading: false,
      family:'',
      families:[],
      units:[],
      articles:[],
      article:'',
      timer:'',
      options:'',
      search:'',
      total:'',
      showDialog:false,
      headers: [
      {
        text: 'Código',
        align: 'start',
        value: 'sku',
        class: 'table-header2',
        
      },
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        class: 'table-header2'
      },
      {
        text: 'Familia',
        align: 'start',
        value: 'family_name',
        class: 'table-header2'
      },
      {
        text: 'Unidad',
        align: 'start',
        value: 'unit_name',
        class: 'table-header2'
      },
      { text: '',value:'actions',class: 'table-header',align:'center',sortable: false}
    ],
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='family_name'){
        sort = 'family__name'
      }
      else if(sortBy[0]=='unit_name'){
        sort = 'unit__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.family) this.family=''
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&page=${page}&&page_size=${itemsPerPage}&&search=${this.search}&&family=${this.family}&&status=true`
      await fetchArticles(query)
      .then(res=>{
        this.total=res.count
        this.articles=res.results
      })
      .catch((err)=>{
        notifications.showError('Error cargando artículos '+err)
      })
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    add(item){
      this.value=item
      this.$emit('input',this.value)
    }
  },
  watch:{
    async family(){
      this.fetch()
    },
    options:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.fetch()
    this.loading=true
    this.families=await fetchFamilies()
    this.units=await fetchUnits()
    this.loading=false
  }
}
</script>

<style>

</style>