
<script>
import {Pie,mixins} from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  extends: Pie,
  mixins: [reactiveProp],
  props:['chartData','options','title'],
  methods: {
    update() {
      this.$data._chart.update()
    },
   },
  mounted(){
    this.renderChart(this.chartData, this.options)
  }
}
</script>