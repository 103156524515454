<template>
  <v-container grid-list-xs> 
    <v-tabs 
      background-color="transparent"
      color="basil"
      grow
      v-model="tab"
    >
      <Can I="read" a="User">
        <v-tab v-model="tab" class="primary--text">Usuarios</v-tab>
      </Can>
      <Can I="read" a="Role">
        <v-tab v-model="tab" class="primary--text">Roles</v-tab>
      </Can>
      <v-tabs-items v-model="tab">
      <v-tab-item>
        <users-list />
      </v-tab-item>
      <v-tab-item>
        <roles-list/>
      </v-tab-item>
    </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>

import UsersList from '@/components/users/UsersList'
import RolesList from '@/components/users/RolesList'
export default {
  name: 'Users',
  components: {
    UsersList,RolesList
  },
  data() {
    return {
      tab: null,
    }
  },
}
</script>
