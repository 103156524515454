<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col>
      <h1 class="text-h4 titulo">Industrias</h1>
    </v-col>
    <v-col class="text-end">
      <v-btn class="ml-4" color="success" @click="update(-1)">
          <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify-md="end">
    <v-col cols="12" md="6">
      <v-text-field
        v-model="search"
        label="Buscar"
        append-icon="fas fa-search"
        :disabled="loading"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="headers"
          :items="items"
          :items-per-page="10"
          :loading="loading"
          :search="search"
          sort-by='name'
          item-class="table-body"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right'
          }">
          <template v-slot:item.name="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon  class="mr-2" @click.stop="update(item.id)">fas fa-edit</v-icon>
            <!-- <v-icon class="error--text" @click.stop="deleteItem(item)">delete</v-icon> -->
          </template>
        </v-data-table>
    </v-col>
  </v-row>
  <v-dialog v-model="showDialog" max-width="500">
    <v-card class="px-10 py-4">
      <industry-create-update  
      :id="item"
      @updated="fetch"
      @close="showDialog=false"
      />
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchIndustries} from '@/api/customers'
import notifications from '@/utils/notifications'

import IndustryCreateUpdate from '@/components/customers/IndustryCreateUpdate.vue'

export default {
  name:'Industries',
  components:{IndustryCreateUpdate},
  data() {
    return {
      loading: false,
      item:'',
      items:[],
      search:'',
      total:'',
      showDialog:false,
      headers: [
      {
        text: 'Item',
        align: 'start',
        value: 'id',
        class: 'table-header'
      },
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        class: 'table-header'
      },
      
      { text: 'Acciones',value:'actions',class: 'table-header',align:'center',sortable: false}
    ],
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      await fetchIndustries()
      .then(res=>{
        this.items=res
      })
      .catch((err)=>{
        notifications.showError('Error cargando Bodegas '+err)
      })
      this.loading=false
    },
    update(item){
      this.item=item
      this.showDialog=true
    }
  },
 
  async mounted(){
    
    this.fetch()
    
  }
}
</script>

<style>

</style>