<template>
  <div>
    <v-autocomplete
      v-model="value"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      color="primary"
      hide-selected
      item-text="text"
      item-value="id"
      label="Buscar un Proveedor"
      
      return-object
      outlined
      clearable
      cache-items
      @input="updateValue"
      @change="cambio"
      :disabled="disabled"
      :error-messages="error_messages"
    >
      <template v-slot:prepend-item>
        <div class="d-flex justify-center">
          <v-btn small text @click="showDialog=true"><v-icon class="mr-2">fas fa-plus</v-icon> Nuevo Proveedor</v-btn>
        </div>
      </template>
      <template v-slot:selection="{ item}">
        <div class="d-flex">
          <div class="text mr-2">[{{item.rut}}] {{item.name}}</div>
        </div>
      </template>
      <template v-slot:item="data">
        <div class="d-flex">
          <div class="text mr-2">[{{data.item.rut}}] {{data.item.name}}</div>
        </div>
      </template>
    </v-autocomplete>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card class="px-10 py-4">
        <provider-create-update :id="-1" @updated="addProvider" @close="showDialog=false"/>
      </v-card>
    </v-dialog>

  </div>
      
    
</template>

<script>
import {fetchProvider} from '@/api/stocks'
import notifications from '@/utils/notifications'
import ProviderCreateUpdate from '@/components/stocks/ProviderCreateUpdate'
  export default {
    name:'ProviderComplete',
    components:{ProviderCreateUpdate},
    data: () => ({
      descriptionLimit: 60,
      customers: [],
      isLoading: false,
      model: null,
      search:null,
      select:null,
      items: [],
      showDialog:false,
    }),
    props:['value','disabled','error_messages','provider_name'],
    methods:{
      updateValue(){
        this.value ? this.$emit('input',this.value) : this.$emit('input','')
      },
      cleared(){
        this.$emit('input','')
      },
      addProvider(item){
        this.$emit('added',item)
      },
      cambio(){
        console.log('cambio en el value')
      },
      async querySelections (v) {
        this.isLoading = true
        //sort = '', page = '', page_size = '', search = '', seller = '', status = ''
        const query=`ordering=-name&estatus=true&search=${v}`
        await fetchProvider(query)
        .then((res)=>{
          const data = res.results.map(i=>{
            i.text=i.rut+i.name
            return i
          })
          this.items=data
          this.isLoading=false
        })
      },
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
      provider_name(val){
        if(val) this.search=val
      }
    },
    
    
  }
</script>