<template>
  <v-container>
    <div>
      <v-card-title
        class="titulo text-h4 mb-4"
      >
       <v-icon class="mr-2 align-items-center">fas fa-box</v-icon>{{article.id>-1?'Actualizar Artículo':'Crear Artículo'}}
      </v-card-title>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
        <v-row>
          <v-col>
            <ValidationProvider  name="Familia" rules="required" v-slot="{ errors }">
              <v-select
                v-model="article.family"
                dense
                prepend-icon="fas fa-object-group"
                :items="families"
                item-text="name"
                item-value="id"
                label="Familia"
                placeholder="Seleccione una Familia"
                outlined
                :error-messages="errors"
                :loading="loading"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <ValidationProvider name="Codigo" rules="required" v-slot="{ errors }">
              <v-text-field
              dense
              label='Código'
              prepend-icon="fas fa-barcode"
              v-model='article.sku'
              outlined
              :error-messages="errors"
              :loading="loading"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          
          <v-col>
            <ValidationProvider  name="Nombre" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="article.name"
                label="Nombre"
                :error-messages="errors"
                :loading="loading"
                prepend-icon="fas fa-tag"
                outlined
                dense
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ValidationProvider name="Unidades" rules="required" v-slot="{ errors }">
              <v-select
                v-model="article.unit"
                class="text-end mr-2"
                prepend-icon="fas fa-tachometer-alt"
                :items="units"
                dense
                item-text="name"
                item-value="id"
                label="Unidad de Compra"
                placeholder="Seleccione una Unidad"
                outlined
                :error-messages="errors"
                :loading="loading"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col>
            <div class="d-flex d-inline-flex">
            <ValidationProvider  name="Crítico" rules="required" v-slot="{ errors }">
              <v-text-field
              dense
              label='Inventario Crítico'
              type="number"
              prepend-icon="fas fa-exclamation"
              v-model='article.critic'
              outlined
              :min="0"
              :error-messages="errors"
              :loading="loading"
              />
            </ValidationProvider>
              <v-checkbox
              dense
                class="ml-4"
                v-model="article.alert"
                label="Alertar"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            
              <v-select
                v-model="article.unit_consume"
                class="text-end mr-2"
                prepend-icon="fas fa-tachometer"
                :items="units"
                dense
                item-text="name"
                item-value="id"
                label="Unidad de Consumo"
                placeholder="Seleccione una Unidad"
                outlined
                clearable
                :loading="loading"
              ></v-select>
           
          </v-col>
          <v-col>
            
            <ValidationProvider  name="Factor de Conversion" rules="minvalue:0" v-slot="{ errors }">
              <v-text-field
              dense
              :value="1"
              label='Factor de Conversión'
              type="number"
              prepend-icon="fas fa-repeat"
              v-model='article.unit_consume_factor'
              outlined
              :min="0"
              :error-messages="errors"
              :loading="loading"
              />
            </ValidationProvider>
           
          </v-col>
        </v-row>
        <ValidationProvider  name="Observaciones" rules="max:200" v-slot="{ errors }">
          <v-text-field
            label='Observaciones'
            prepend-icon="fas fa-sticky-note"
            v-model='article.notes'
            outlined
            :loading="loading"
            :error-messages="errors"
            />
        </ValidationProvider>
            <div class="text-center">
              <v-btn type="submit" color="success" :disabled="loading" :loading="saving"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
            </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-container>
</template>
<script>
import {getArticle,createArticle,updateArticle} from '@/api/stocks'
import notifications from '@/utils/notifications'
export default {
  name:'ArticleCreateUpdate',
  props:['id','families','units'],
  data() {
    return {
      article:'',
      loading:false,
      saving:false,
    }
  },
  methods:{
    async save(){
      this.saving=true
      const article_data={
        sku:this.article.sku,
        name:this.article.name,
        family_id:this.article.family,
        unit_id:this.article.unit,
        unit_consume_factor:this.article.unit_consume_factor,
        critic:this.article.critic,
        alert:this.article.alert,
        notes:this.article.notes,
      }
      if(this.article.unit_consume) article_data.unit_consume_id=this.article.unit_consume

      if(this.id>-1){
        await updateArticle(this.id,article_data)
        .then((res)=>{
          notifications.showSuccess('Artículo guardado correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Artículo - Revisar SKU duplicado'+err)
        })
        .finally(()=>{
          this.saving=false
        })
      }
      else{
        await createArticle(article_data)
        .then((res)=>{
          notifications.showSuccess('Artículo creado correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error creando Artículo - Revisar SKU duplicado '+err)
        })
        .finally(()=>{
          this.saving=false
        })
      }
    },
    async get(){
      this.article=''
      if(this.id>-1){
        this.loading=true
        await getArticle(this.id)
        .then(res=>{
          this.article=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando artículo '+err)
        })
        this.loading=false
      }
      else{
        this.article={
          id:this.id,
          sku:'',
          name:'',
          family:'',
          unit:'',
          critic:0,
          alert:false,
          notes:''
        }
      }
    }
  },
  watch:{
    id(val){
      this.get()
    }
  },
  created(){
    this.get()
  }
}
</script>

<style>

</style>