<template>
  <v-container>
    <v-skeleton-loader
          v-if="loading"
          type="actions,text,text,text,text,card,card"
    />
    <div v-if="!loading">
      <v-row class="mb-2 justify-space-between">
        <v-col cols="10" class="d-flex">
          <h1 class="text text-h4 titulo">Post Costeo <strong>Orden de Trabajo #{{work_order.id}}</strong></h1>
          <div class="d-block ml-6">
          <div class="text caption" title="Creada"> <v-icon class="mr-2" small>fas fa-square-plus</v-icon>{{work_order.created_by}} - {{work_order.created_at| moment('DD/MM/YYYY h:mm a')}}</div>
          <div v-if="work_order.updated_by" class="text caption" title="Actualizada"><v-icon class="mr-2" small>fas fa-square-pen</v-icon>{{work_order.updated_by}} - {{work_order.updated_at| moment('DD/MM/YYYY h:mm a')}}</div>
        </div>
        </v-col>
        <v-col class="text-end">
        <div class="flex justify-space-around">
          <v-btn fab @click="$router.push('/reports/post-cost')" small><v-icon>fas fa-chevron-left</v-icon></v-btn>
          </div>
        </v-col>  
      </v-row>
      <v-row justify="start">
        <v-col cols="12" md="3">
          <v-text-field
            type="text"
            filled
            dense
            :value="work_order.sales_note_data.id"
            label="Cotización"
            outlined
            readonly
            hide-details         
            />
        </v-col>
        <v-col cols="12" md="9">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.sales_note_data.name"
          label="Proyecto"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.sales_note_data.customer"
          label="Cliente"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.sales_note_data.seller"
          label="Vendedor"
          outlined
          readonly
          hide-details         
          />
        </v-col>

        
        <v-col cols="12" md="6">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.delivery_date|moment('DD/MM/YYYY HH:mm')"
          label="Fecha Comprometida"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.completed_at|moment('DD/MM/YYYY HH:mm')"
          label="Fecha Finalizada"
          outlined
          readonly
          hide-details         
          />
        </v-col>
    </v-row>
    <!-- Resumen -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Resumen </v-card-title>
          <v-card-text>
            <running-cost :work_order="work_order"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Tabla de Productos -->
    
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>
            Productos
          </v-card-title>
          <v-card-text>  
            <v-data-table
            :headers="productsTableHeaders"
            :items="work_order.products"
            :single-expand="true"
            :items-per-page="-1"
            expanded.sync="expanded"
            expand-icon="fas fa-chevron-down"
            :item-key="id"
            show-expand
            class="flex d-block"
            hide-default-footer
            >
              <template v-slot:item.name="{item}">
                {{item.name}}
              </template>
              <template v-slot:item.width="{item}">
                {{item.width | number('0,0.00')}}
              </template>
              <template v-slot:item.height="{item}">
                {{item.height | number('0,0.00')}}
              </template>
              <template v-slot:item.qty="{item}">
                {{item.qty}}
              </template>
              <template v-slot:item.totalMts2="{item}">
                {{item.mts2 | number('0,0.00')}}
              </template>
              <template v-slot:item.total_estimated_cost="{item}">
                {{item.sales_cost | currency('$', 0)}}
              </template>
              <template v-slot:item.real_price="{item}">
                {{item.total_cost | currency('$', 0)}}
              </template>
              <template v-slot:item.total_sales_price="{ item }">
                {{item.sales_price| currency('$', 0)}}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
            <td class="py-4 px-10" :colspan="headers.length">
              <p v-if="item.notes" class="font-italic">Observaciones:{{item.notes}}</p>
              <v-row>
                <v-col>
                  <div class="text text-h6">Procesos</div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Item
                          </th>
                          <th class="text-left">
                            Proceso
                          </th>
                          <th class="text-left">
                            Unidad
                          </th>
                          <th>
                            PP
                          </th>
                          <th>
                            Total
                          </th>
                          <th class="text-right">
                            Costo Unit.
                          </th>
                          <th class="text-right">
                            Costo Teórico
                          </th>
                          <th class="text-center">
                            Mts<sup>2</sup> Real
                          </th>
                          <th class="text-right">
                            Qty Real
                          </th>
                          <th class="text-right">
                            Costo Real
                          </th>
                          <th class="text-center">
                            Bitácora
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="process,index in item.processes"
                          :key="process.id"
                        >
                          <td>{{index+1}} <v-icon class="error--text ml-2" x-small v-if="process.reprocess">fas fa-clock-rotate-left</v-icon></td>
                          <td>{{process.name}}<v-chip v-if="process.merma>0" class="ml-2" x-small dark color=" text-center orange darken-1">+ {{process.merma }}</v-chip></td>
                          <td>{{process.unit_name}}</td>
                          <td>{{process.qtypp }}</td>
                          <td>{{process.unit==2?process.qtypp*item.qty+process.merma:(process.unit==1?process.qtypp*item.mts2:process.qtypp)| number('0,0.00')}}</td>
                          <td  class="text-right">
                            {{process.price|currency('$',0)}}
                          </td>
                          <td v-if="process.unit==1" class="text-right">
                            {{process.min_price>(process.price*item.mts2) ? process.min_price :(process.price*process.qtypp*item.mts2) |currency('$',0)}}
                            <span v-if="process.min_price>(process.price*process.qtypp*item.mts2)">
                              <v-chip x-small dark color="blue darken-1">min.</v-chip>
                            </span>
                          </td>
                          <td v-else-if="process.unit==2" class="text-right">
                            {{process.min_price>(process.price*item.qty) ? process.min_price :(process.price*(process.qtypp*item.qty+process.merma)) |currency('$',0)}}
                            <span v-if="process.min_price>(process.price*process.qtypp*item.qty)">
                              <v-chip x-small dark color="blue darken-1">min.</v-chip>
                            </span>
                          </td>
                          <td v-else class="text-right">
                            {{process.price|currency('$',0)}}
                          </td>
                          <td class="text-end">{{process.completed_mts2_qty.mts2 | number('0,0[.]00')}}</td>
                          <td class="text-end">{{process.completed_mts2_qty.qty | number('0,0[.]00')}}</td>
                          <td class="text-right">
                            {{process.cost| currency('$', 0)}}
                          </td>
                          <td class="text-center">
                            <v-menu offset-y offset-overflow>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  offset-x
                                  text
                                  dense
                                >
                                  <v-icon>fas fa-eye</v-icon>
                                </v-btn>
                              </template>
                              <v-card class="pa-2">
                                <div v-if="process.bitacora.length>0">
                                  <v-simple-table>
                                    <thead>
                                      <tr>
                                        <th>
                                          Fecha
                                        </th>
                                        <th>
                                          Usuario
                                        </th>
                                        <th>
                                          Mts<sup>2</sup>
                                        </th>
                                        <th>
                                          Cantidad
                                        </th>
                                        <th>
                                          Equipo
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for='register in process.bitacora' :key='register.id'>
                                        <td>
                                          {{register.created_at | moment("D/MM/YYYY")}}
                                        </td>
                                        <td>
                                          {{`${register.created_by.first_name} ${register.created_by.last_name}`}}
                                        </td>
                                        <td>
                                          {{register.mts2}}
                                        </td>
                                        <td>
                                          {{register.qty}}
                                        </td>
                                        <td>
                                          {{register.equipment.name}}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>
                                </div>
                                <h3 v-else class="text text-center pa-4">
                                  No existen registros
                                </h3>
                              </v-card>
                            </v-menu>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="text text-h6">Materiales Cotizados</div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Item
                          </th>
                          <th class="text-left">
                            Material
                          </th>
                          <th class="text-left">
                            Unidad
                          </th>
                          <th class="text-right">
                            <sub>p</sub>P
                          </th>
                          <th class="text-right">
                            Total
                          </th>
                          <th class="text-right">
                            Costo Unit.
                          </th>
                          <th class="text-right">
                            Costo Teórico
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(supply,index) in item.supplies"
                          :key="index"
                        >
                          <td class="text-center">{{index+1}}</td>
                          <td><v-icon v-if="supply.additional" small class="mr-2">fas fa-plus-square</v-icon>{{supply.name}}</td>
                          <td>{{supply.unit_name}}</td>
                          <td class="text-right">{{supply.qty}}</td>
                          <td class="text-right">
                            <div v-if="supply.unit==1">
                              {{supply.qty*item.mts2}}
                            </div>
                            <div v-else-if="supply.unit==2">
                              {{supply.qty*item.qty}}
                            </div>
                            <div v-else>
                              {{supply.qty}}
                            </div>
                          </td>
                          <td class="text-right">{{supply.price | currency('$', 0)}}</td>
                          <td class="text-right">
                            {{supply.estimated_cost.total | currency('$', 0)}}
                            <span v-if="supply.estimated_cost.min">
                              <v-chip x-small dark color="blue darken-1">min.</v-chip>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="text text-h6">Artículos Consumidos</div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Código
                          </th>
                          <th class="text-left">
                            Artículo
                          </th>
                          <th class="text-left">
                            Unid. Compra
                          </th>
                          <th class="text-left">
                            Precio Prom.
                          </th>
                          <th class="text-right">
                            Factor
                          </th>
                          <th class="text-right">
                            Unid. Consumo
                          </th>
                          <th class="text-right">
                            Precio Conv.
                          </th>
                          <th class="text-right">
                            Cant.
                          </th>
                          <th class="text-right">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(supply,index) in item.consumption"
                          :key="index"
                        >
                          <td class="text-center">{{index+1}}</td>
                          <td>
                            <div>
                              <div class="caption">{{supply.article.sku}}</div>
                              {{supply.article.name}}
                            </div>
                          </td>
                          <td>{{supply.unit}}</td>
                          <td class="text-right">{{supply.articlePrice | currency('$', 0)}}</td>
                          <td class="text-right">{{supply.unitConsume_factor}}</td>
                          <td>
                            {{supply.unitConsume}}
                          </td>
                          <td class="text-right">{{supply.convertedPrice | currency('$', 0)}}</td>
                          <td class="text-right">{{supply.qty}}</td>
                          <td class="text-right">{{supply.total | currency('$', 0)}}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </td>
          </template>
            </v-data-table>
        </v-card-text>
        </v-card>
      </v-col>
      </v-row>
      <!-- Costos Adicionales de la OT -->
      <v-row v-if="work_order.additional_costs.length>0" justify="start" class="mt-4">
        <v-col cols="12">
          <v-card>
          <v-card-title>Costos Adicionales de la OT: </v-card-title>
            <v-card-text>
              <v-data-table
              :loading="costLoading"
              :headers="additionalCostsHeaders"
              :items="work_order.additional_costs"
              item-key="key"
              dense
              class="flex d-block"
              hide-default-footer
              >
              <template v-slot:item.price="{item}">
                <div class="text">{{item.price | currency('$',0)}}</div>
              </template>
              <template v-slot:item.actions="{item}">
                <v-icon @click="deleteCost(item)"  small class="error--text">fas fa-trash</v-icon>
              </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
          
        </v-row>
      <!-- Consumos de Bodega -->
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Consumos de bodega</v-card-title>
            <consume-o-t-bitacora :stand_alone="true" :workorder_id="work_order.id"/>
          </v-card>
        </v-col>
      </v-row>
        <!-- Costos adicionales de la Nota de Venta -->
      <v-row v-if="work_order.sales_note_data.additional_costs.length>0" class="mt-4">
        <v-col cols="12">
          <v-card>
          <v-card-title>Costos Adicionales de la Cotización: </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="proyectCostsHeaders"
                :items="work_order.sales_note_additional_costs"
                item-key="key2"
                class="flex d-block"
                dense
                hide-default-footer
                >
                  <template v-slot:item.name="{item}">
                    {{item.name}}
                  </template>
                  <template v-slot:item.qty="{item}">
                    {{item.qty}}
                  </template>
                  <template v-slot:item.unit="{item}">
                    {{item.cost_unit.name}}
                  </template>
                  <template  v-slot:item.unitCost="{item}">
                    <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong>
                  </template>
                  <template v-slot:item.totalCost="{item}">
                    <strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
                  </template>
                  <template v-slot:item.price="{item}">
                    {{item.price| currency('$',0)}}
                    <v-icon>{{item.unit.id==1?'square_foot':''}}</v-icon>
                  </template>
                  <template v-slot:item.totalPrice="{item}">
                    <div :class="item.totalPrice>item.totalCost ? 'green--text':'red white--text'">
                      <strong>{{item.totalPrice | currency('$',0)}}</strong>
                    </div>
                  </template> 
                </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="work_order.buying_orders.length>0">
        <v-col cols="12">
            <v-card>
            <v-card-title>Órdenes de compra asociadas: </v-card-title>
              <v-card-text>
                <v-data-table
                          :headers="purchaseOrdersHeaders"
                          :items="work_order.buying_orders"
                          :single-expand="true"
                          expanded.sync="expanded"
                          expand-icon="fas fa-chevron-down"
                          item-key="id"
                          show-expand
                          class="flex d-block mt-4"
                          hide-default-footer
                          >
                            <template v-slot:no-data>
                              <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                                No existen ordenes vinculadas
                              </v-alert>
                            </template>
                            
                            <template v-slot:item.date="{item}">
                              <div class="text">{{item.created_at | moment("DD/MM/YYYY")}}</div>
                            </template>
                            <template v-slot:item.total="{item}">
                              <div class="text text-end">{{totalOrder(item) | currency('$',0)}}</div>
                            </template>
                            <template v-slot:item.estatus="{ item }">
                              <v-chip small label outlined :color="getStatusColor(item)" style="">{{getStatusName(item)}}</v-chip>
                            </template>
                            <template v-slot:item.delete="{item}">
                              <div class="text text-center">
                                <v-icon class="error--text" x-small @click="removeInvoice(item)">fas fa-trash</v-icon>
                              </div>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                              <td class="py-4 px-10" :colspan="headers.length">
                                <detailed-purchase-order :purchaseOrder="item" :inSet="true"/>
                              </td>
                            </template> 
                </v-data-table>
              </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>  
  </v-container>
</template>
<script>
import {getPostCostWorkOrders,} from '@/api/reports'
import NumberLabel from '@/components/utils/NumberLabel'
import BitacoraDetails  from '@/components/production/BitacoraDetails'
import DetailedPurchaseOrder from '@/components/stocks/DetailedPurchaseOrder'
import RunningCost from '@/components/production/workorder/RunningCost.vue'
import ConsumeOTBitacora from '@/views/Reports/ConsumeOTBitacora'
export default {
  name:'DetailedPostCost',
  components:{ NumberLabel, BitacoraDetails, DetailedPurchaseOrder, RunningCost ,ConsumeOTBitacora},
  created(){
    if(this.$route.params.id){
      this.fetch(this.$route.params.id)
    }
  },
  data() {
    return {
      loading:false,
      work_order:'',
      productsTableHeaders:[
      { text: 'Código', value: 'sku' ,class:'table-header2'},
      { text: 'Descripción', value: 'name' ,class:'table-header2'},
      { text: 'Cantidad', value: 'qty',class:'table-header2' ,align:'end'},
      { text: 'Total Mts2', value: 'totalMts2',class:'table-header2',align:'end' },
      { text: 'Precio Venta.', value: 'total_sales_price',class:'table-header2',align:'end'},
      { text: 'Cost. Teórico', value: 'total_estimated_cost',class:'table-header2' ,align:'end'},
      { text: 'Cost. Real', value: 'real_price',class:'table-header2' ,align:'end'},
      { text: '', value: 'data-table-expand' },
    ],
    additionalCostsHeaders:[
      { text: 'Descripción', value: 'name' ,class:"table-header-dense" },
      { text: 'Costo', value: 'price',class:"table-header-dense" ,align:'end' },
    ],
    proyectCostsHeaders:[
      { text: 'Descripción', value: 'name',class:"table-header-dense" },
      { text: 'Cantidad', value: 'qty',class:"table-header-dense" ,align:'end'},
      { text: 'Unidad', value: 'cost_unit',class:"table-header-dense" },
      { text: 'Costo Unit.', value: 'unitCost' ,class:"table-header-dense",align:'end'},
      { text: 'Costo Total', value: 'totalCost' ,class:"table-header-dense",align:'end'},
      { text: 'Prec. Unit.', value:'price',class:"table-header-dense",align:'end'},
      { text: 'Prec. Total',value:'totalPrice',class:"table-header-dense",align:'end'},
    ],
    purchaseOrdersHeaders:[
      { text: 'Numero', value: 'id',class:'table-header' },
      { text: 'Creada', value: 'date' ,class:'table-header'},
      { text: 'Proveedor', value: 'provider_name' ,class:'table-header',align:'start'},
      { text: 'Referencia', value: 'title' ,class:'table-header',align:'end'},
      { text: 'Neto', value: 'total' ,class:'table-header',align:'end'},
      { text: 'Estatus', value: 'estatus' ,class:'table-header',align:'end'},
      { text: '', value: 'data-table-expand',class:'table-header',aligment:'end' },
    ],
    estatuses:[
        {
          value:1,
          text:'Ingresada',
          color:'info'
        },
        {
          value:2,
          text:'Aprobada',
          color:'success'
        },
        {
          value:4,
          text:'Recepcionada',
          color:''
        },
        {
          value:3,
          text:'Anulada',
          color:'error'
        },
      ],
    }
  },
  methods:{
    async fetch(id){
      this.loading=true
      await getPostCostWorkOrders(id)
      .then((data)=>{
        this.work_order=data
        this.work_order.products=this.work_order.products.map((product)=>{
          product.production_cost=product.processes.reduce((acc,val)=>{return acc + parseFloat(val.cost);},0)
          product.consumption_cost=product.consumption.reduce((acc,val)=>{return acc + parseFloat(val.total);},0)
          product.total_cost=product.production_cost + product.consumption_cost
          return product
        })
      })
      .catch((err)=>notifications.showError('Error Cargando Orden de Trabajo ' + err))
      .finally(()=>this.loading=false)
    },
    totalOrder(item){
      const total = item.details.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
      item.neto=total
      return total-total*parseFloat(item.discount)/100
    },
     getStatusName(item){
      return this.estatuses.find(i=>i.value==item.estatus).text
    },
    getStatusColor(item){
      return this.estatuses.find(i=>i.value==item.estatus).color
    },
  }

}
</script>
