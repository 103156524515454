<template>
  <v-container>
    <v-row class="justify-space-between">
      <div class="text text-h4 titulo">
        <v-icon class="mr-2">fas fa-file-import</v-icon>
        Importar Artículos
      </div>
      <v-btn @click="$router.push({name:'Articles'})" class="ml-2" ><v-icon>fas fa-arrow-left</v-icon></v-btn>  
    </v-row>
    <v-row justify="center" class="mt-6">
      <v-col cols="12" class="text-center">
        <v-stepper
              v-model="e6"
              vertical
          >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
            color="info"
          >
            Descargue la Muestra
            <small>Descarga el archivo de muestra CSV</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              height="100px"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                <v-btn href="https://runflow-evo-bucket.s3.us-east-2.amazonaws.com/files/correcto_articles_import.csv"><v-icon class="mr-2">fas fa-download</v-icon> Descargar</v-btn>
              </v-row>
            </v-card>
            <v-btn
              color="success"
              @click="e6 = 2"
            >
              Continuar
            </v-btn>
            <v-btn text >
              Atrás
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
            color="info"
          >
            Seleccione el archivo
            <small>Seleccione el archivo CSV con los datos a importar</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card
              color=""
              class="mb-12"
              flat
            >
              <v-row class="justify-center align-center" style="height: 100%">
                  <v-file-input
                  class="pa-10"
                  style="max-width:500px"
                  v-model=file
                  accept = '.csv'
                  prepend-icon="fas fa-cloud-upload-alt"
                  outlined
                  show-size
                  hint="Selecciona el archivo CSV a importar"
                  persistent-hint
                ></v-file-input>
              </v-row>
              <v-row justify="space-between">
                <v-col>
                  <v-card>
                    <v-card-title>Unidades [unit]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="unit in units" :key="unit.id">
                          <td><strong>{{unit.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{unit.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Familias [family]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="family in families" :key="family.id">
                          <td><strong>{{family.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{family.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Bodega [store]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="store in stores" :key="store.id">
                          <td><strong>{{store.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{store.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>Movimientos [tipo]</v-card-title>
                    <v-card-subtitle>Ingrese el ID correspondiente</v-card-subtitle>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="tipo in tipos" :key="tipo.id">
                          <td><strong>{{tipo.id}}</strong></td>
                          <td class="d-flex justify-start align-center">{{tipo.name}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-btn :disabled="file==''" color="success" @click="onFileChange" :loading="loading">
              <v-icon class="mr-2">fas fa-file-upload</v-icon>Subir Archivo
            </v-btn>
            <v-btn text @click="e6=1">
              Atrás
            </v-btn>
          </v-stepper-content>
          <v-stepper-step
            :complete="e6 > 3"
            step="3"
            color="info"
            :rules="[() => errorSupplies.length>0?false:true]"
          >
            Resultados
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card
              color=""
              class="mb-12"
              min-height="100px" 
              flat
            >
              <v-row class="justify-center" v-if="errorSupplies.length>0 && newSupplies.length>0">
                <v-col class="text-center" cols="8">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>
                          Item
                        </th>
                        <th>
                          CODIGO (SKU)
                        </th>
                        <th>
                          Nombre
                        </th>
                        <th>
                          Error
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in errorSupplies" :key="item.item">
                        <td>
                          {{item.item}}
                        </td>
                        <td>
                          {{item.sku}}
                        </td>
                        <td>
                          {{item.name}}
                        </td>
                        <td>
                          {{item.message}}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              
              <v-row  v-else-if="newSupplies.length>0" class="justify-center align-center" style="height: 100%">
                <div class="text text-h6">
                  Se verificaron <strong>{{newSupplies.length}}</strong> registros correctamente
                </div>
              </v-row>
              
            </v-card>
            <v-btn
              color="success"
              @click="upload"
              :disabled="errorSupplies.length>0 && newSupplies.length>0"
              :loading="loading"
            >
              <v-icon class="mr-2">fas fa-sd-card</v-icon>
              Guardar
            </v-btn>
            <v-btn text @click="e6=2">
              Atrás
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
    <!-- Dialogo para mostrar el progreso de la carga -->
    <v-dialog 
    v-model="showProgress"
    persistent
    max-width="500"
    >
      <v-card class="pa-10"> 
        <v-card-title class="titulo text-h4">Registrando...</v-card-title>
        <v-row>
          <v-col>
            <v-progress-linear v-model="progress" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center">
            <h1 class="text text-h4 titulo">{{progress | number('0.0')}}%</h1>
            <h1 v-if="uploadingError"  class="text text-h6 titulo error--text mt-4">{{uploadingErrorMessage}}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="progress>=100 && !uploadingError">
            <h1 class="text text-h6 titulo text-center" v-if="!uploadingError">Artículos Cargados Correctamente</h1>
            <div class="text-center">
            <v-btn class="mt-4" color="success" @click="$router.push({name:'Stock'})">Cerrar</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import notifications from '@/utils/notifications'
import {fetchUnits,fetchFamilies,fetchArticles,fetchStores,fetchTipos,createArticle,createStock,createTransaction} from '@/api/stocks'

import axios from 'axios'
export default {
  name:'ImportStock',
  data() {
    return {
      file: '',
      newSupplies:[],
      errorSupplies:[],
      articles:[],
      families:[],
      units:[],
      stores:[],
      tipos:[],
      keys:[
        'sku',
        'name',
        'unit',
        'critic',
        'alert',
        'family',
        'tipo',
        'store',
        'qty',
        'price',
        'notes'
        ],
      loading:false,
      progress:0,
      showProgress:false,
      uploadingError:false,
      uploadingErrorMessage:'',
      e6:1,
    }
  },
  async created(){
    
    this.units=await fetchUnits()
    this.families=await fetchFamilies()
    this.stores=await fetchStores()
    await fetchArticles('page=1&&page_size=-1')
    .then((res)=>{
      this.articles=res.results
    })
    await fetchTipos()
    .then((res)=>{
      this.tipos=res.filter(t=>t.in_out)
    })
  },
  methods:{
    onFileChange(){
      const config={
        header: true,
        complete: (results) =>{
          this.verifyData(results)
        },
      }
     this.$papa.parse(this.file, config)
    },
    async upload(){
      this.loading=true
      var progressStep=this.newSupplies.length!=0?(100/this.newSupplies.length):100
      this.showProgress=true
      //Carga manual de cada artículo
      //1. Se crea el artículo, 2. Se crea la Transacción, 3. Se registra en Inventario
      //this.newSupplies.forEach(item=>{
      for(let i=0;i<this.newSupplies.length;i++){
        const item=this.newSupplies[i]
        try {
        //1. Se Crea el artículo
        const articleData={
          sku: item.sku,
          name: item.name,
          family: item.family,
          unit: item.unit,
          avg: item.price,
          critic:item.critic,
          alert:item.alert,
        }
        await createArticle(articleData)
        .then(async res=>{
          const articleId=res.id
          // 2. Se crea la transacción
          const transactionData={
            tipo: item.tipo,
            tarticle: articleId,
            store: item.store,
            qty: item.qty,
            price: item.price,
            balance: item.qty,
            calculated_avg: item.price,
            notes: 'Inicial'
          }
          await createTransaction(transactionData)
          .then(async res=>{
            // 3. Se crea el inventario
            const stockData={
              article: articleId,
              store: item.store,
              qty: item.qty
            }
            await createStock(stockData)
            .then(res=>{
              this.progress+=progressStep
            })
            .catch(e=>{
              throw e
            })
          })
          .catch(e=>{
            throw e
          })
        })
        .catch(e=>{
          throw e
        })
        }
        catch(error) {
          this.uploadingError=true
          this.loading=false
          this.uploadingErrorMessage='Error Cargando item: '+this.newSupplies.indexOf(item)+ ' '+item.sku
          break
        }
      }
      this.loading=false
    },
    async verifyData(data){
      this.loading=true
      console.log(data)
      this.newSupplies=[]
      this.errorSupplies=[]
      this.header=[]
      
      //Verifica que la cantidad de campos sea correcta
      if(data.meta.fields.length!=11){
        notifications.showError('Error en cantidad de campos')
        this.loading=false
        return 0
      }
      
      //Validación de Datos
      data.data.map((item)=>{
        let error = false
        let message=''
        let foundFamily=false
        let foundUnit=false
        let foundStore=false
        let foundTipo=false 
        
        //Valida que el resto de los campos no estén vaciós
        // y que existan todas las claves necesarias
        Object.keys(item).map(key=>{
          if(item[key].length==0){
            error=true
            message=`Campo ${key} vacío`
          }
          if (!this.keys.includes(key)){
            error = true
            message=`Nombre de campo <${key}> inválido`
          }
        })

        //Quitar los espacios del campo SKU
        //str = str.replace(/\s+/g, '');
        item.sku=item.sku.replace(/\s+/g, '')

        //Convierte los campos alert en boleanos
        item.alert=item.alert=='0'?false:true

        //Convierte campos critic, qty, price en flotante
        item.qty=parseFloat(item.qty)
        item.critic=parseFloat(item.critic)
        item.price=parseFloat(item.price)

        //Valida que no haya un nombre duplicado
        this.newSupplies.map(newItem=>{
          if (newItem.name.toLowerCase() == item.name.toLowerCase()){
            error = true
            message='SKU Duplicado'
          } 
        })

        this.articles.map(article=>{
          if(article.sku.toLowerCase()==item.sku.toLowerCase()){
            error = true
            message='SKU ya existe en la plataforma'
          } 
        })
        
        //Valida que el familia existe
        this.families.map(family=>{
          if(family.id==item.family){
            foundFamily=true
          }
        })
        if(!foundFamily){
            error=true
            message='Familia no encontrada'
        }

        //Valida que la unidad existe
        this.units.map(unit=>{
          if(unit.id==item.unit){
            foundUnit=true
          }
        })
        if(!foundUnit){
            error=true
            message='Unidad no encontrada'
        }

        //Valida que la bodega existe
        this.stores.map(store=>{
          if(store.id==item.store){
            foundStore=true
          }
        })
        if(!foundStore){
            error=true
            message='Bodega no encontrada'
        }

        //Valida que el tipo existe
        this.tipos.map(tipo=>{
          if(tipo.id==item.tipo){
            foundTipo=true
          }
        })
        if(!foundTipo){
            error=true
            message='Tipo no encontrado'
        }

        //Valida que el campo crítico es un número mayor que cero
        if(isNaN(item.critic)||parseInt(item.critic)<0){
          error=true
          message='El campo <critic> no es un número o es inválido'
        }

        //Valida que el campo cantidad es un número mayor que cero
        if(isNaN(item.qty)||parseInt(item.qty)<0){
          error=true
          message='El campo <qty> no es un número o es inválido'
        }

        //Valida que el campo precio es un número mayor que cero
        if(isNaN(item.price)||parseInt(item.price)<0){
          error=true
          message='El campo <price> no es un número o es inválido'
        }

        if(error==true){
          this.errorSupplies.push({
            item: data.data.indexOf(item)+1,
            sku:item.sku,
            name:item.name,
            message:message
          })
        }
        item.error = error
        this.newSupplies.push(item)
      })
      this.e6=3
      this.loading=false
    }
  }
}
</script>

<style lang="scss">
.v-stepper{
  box-shadow: 0  !important;
}
.v-stepper__content{
  margin: -8px -36px -16px 42px !important;
}
.v-stepper__step__step{
  height: 40px !important;
  width: 40px !important;
  font-size: 1rem !important;
}

</style>