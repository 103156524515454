var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_c('v-icon',{staticClass:"mr-2 align-items-center"},[_vm._v("fas fa-boxes")]),_vm._v("Inventario")],1)]),_c('v-col',{staticClass:"text-end"},[_c('export-stock',{attrs:{"disabled":_vm.loading,"family":_vm.family,"store":_vm.store}})],1)],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.families,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Todas las Familias","disabled":_vm.loading,"single-line":"","outlined":"","dense":"","clearable":"","prepend-inner-icon":"filter_alt"},model:{value:(_vm.family),callback:function ($$v) {_vm.family=$$v},expression:"family"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.stores,"item-text":"name","item-value":"id","label":"Bodega","placeholder":"Todas las Bodegas","single-line":"","disabled":_vm.loading,"outlined":"","dense":"","clearable":"","prepend-inner-icon":"filter_alt"},model:{value:(_vm.store),callback:function ($$v) {_vm.store=$$v},expression:"store"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.articles,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":parseInt(_vm.total),"search":_vm.search,"sort-by":"sku","sort-desc":true,"item-class":"table-body","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.sku",fn:function(ref){
          var item = ref.item;
return [(_vm.alertCritic(item))?_c('v-icon',{staticClass:"mr-2 error--text"},[_vm._v("fas fa-exclamation-circle")]):_vm._e(),_vm._v(_vm._s(item.sku)+" ")]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.cost)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 info--text",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-link")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cost.name))])]):_vm._e()]}},{key:"item.avg",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.avg,'$', 0))+" ")]}},{key:"item.total_stock",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(_vm.totalStock(item),'0,0[.]0'))+" "),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-chevron-down")])]}}],null,true)},[_c('v-card',{staticClass:"pa-2"},[(item.stocks.length>0)?_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v(" Bodega ")]),_c('th',[_vm._v(" Existencia ")])])]),_c('tbody',_vm._l((item.stocks),function(register){return _c('tr',{key:register.id},[_c('td',{class:register.add_stock?'':'error--text'},[_vm._v(" "+_vm._s(register.store_name)+" ")]),_c('td',{staticClass:"text-end",class:register.add_stock?'':'error--text'},[_vm._v(" "+_vm._s(_vm._f("number")(register.qty,'0,0[.]0'))+" ")])])}),0)])],1):_c('h3',{staticClass:"text text-center pa-4"},[_vm._v(" No hay existencias ")])])],1)]}},{key:"item.total_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalStock(item)*item.avg,'$', 0))+" ")]}},{key:"item.compromised",fn:function(ref){
          var item = ref.item;
return [(item.compromised>0)?_c('div',[_vm._v(" "+_vm._s(_vm._f("number")(item.compromised,'0,0[.]0'))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.$router.push({name:'DetailedArticle',params:{id:item.id}})}}},[_vm._v("fas fa-eye")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }