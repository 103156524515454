<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col align-items-center>
      <h1 class="text text-h4 titulo">Pedidos</h1>
      
    </v-col>
    <v-col class="text-end" justify-end>
      <div class="d-flex justify-end">
        <export-documents :filter="filter" :options="options" :search="search" :disabled="loading"/>
      </div>
    </v-col>
  </v-row>
    <v-card class='pa-4'>
  <v-row justify="space-between">
    <v-col cols="12" md="6" align-self="center">
      <v-text-field @change="()=>{options.page=1;fetch()}" hide-details :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada desde:"></v-text-field>
    </v-col>
    <v-col cols="12" md="6"  align-self="center">
      <v-text-field @change="()=>{options.page=1;fetch()}" hide-details :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada hasta:" ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="search"
        @keyup="searchTimeOut"
        label="Buscar"
        append-icon="fas fa-search"
        :disabled="loading"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="computedHeaders"
          :items="dtes"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalDtes"
          :search="search"
          sort-by='created_at'
          :sort-desc="false"
          @update:options="fetch"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }">
          <template v-slot:item.document="{ item }">
            <div class="d-block">
              <softnet-detail :item="item">
                {{item.document}}
              </softnet-detail>
            </div>
          </template>
          <template v-slot:item.workorder="{item}">
          <show-work-order :work_order_id="item.workorder">
            {{item.workorder}}
          </show-work-order>
        </template>
          <template v-slot:item.created_at="{ item }">
            <div class="d-block">
              {{item.created_at| moment('DD/MM/YYYY')}}
            </div>
          </template>
          <template v-slot:item.customer_name="{ item }">
            <small>{{item.customer_rut}}</small>
            <div class="d-flex d-block">
               {{item.customer_name}}
            </div>
          </template>
          <template v-slot:item.neto="{ item }">
            {{item.neto| currency('$', 0)}}
          </template>
        </v-data-table>
    </v-col>
  </v-row>
    </v-card>

  </v-container>
</template>

<script>
import {fetchDocuments,getDocument} from '@/api/kame.js'
import {fetchSellers} from '@/api/auth'
import notifications from '@/utils/notifications'

import ExportDocuments from '@/components/kame/ExportDocuments'

import moment from 'moment'

const numeral = require('numeral');

export default {
  name:'KameDocuments',
  components:{ExportDocuments,},
  data() {
    return {
      loading: false,
      loadingDetail:false,
      showDetail:false,
      detailToDisplay:'',
      dtes:[],
      sellers:[],
      invoice:'',
      timer:'',
      options:{},
      totalDtes:'',
      showDialog:false,
      search:'',
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        tipo:30,
        seller:'',
        not_null:'true',
      },
      
      headers: [
        {
          text: 'Fecha',
          align: 'start',
          value: 'created_at',
          class: 'table-header2',
          show:true
        },
        {
          text: 'Número',
          align: 'start',
          value: 'document',
          class: 'table-header2',
          show:true,
          align:'start'
        },
        {
          text: 'OT',
          align: 'start',
          value: 'workorder',
          class: 'table-header2',
          sortable:true,
          show:true
        },
        {
          text: 'Cot.',
          align: 'start',
          value: 'sales_note',
          class: 'table-header2',
          sortable:false,
          show:true
        },
        {
          text: 'Razón Social',
          align: 'start',
          value: 'customer_name',
          class: 'table-header2',
          show:true
        },
        {
          text: 'Neto',
          value: 'neto',
          class: 'table-header2',
          show:true,
          sortable:false,
          align:'end'
        },
        {
          text: 'Creada por',
          value: 'created_by_name',
          class: 'table-header2',
          show:true,
          sortable:false,
          align:'end'
        },
    ],
    }
  },
  methods:{
    displayDetails(item){
      this.detailToDisplay=item
      this.showDetail=true
    },
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='customer_name'){
        sort = 'customer__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.filter.not_null) this.filter.not_null=''
      if (!this.filter.tipo) this.filter.tipo=''
      if (!this.filter.seller) this.filter.seller=''
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&workorder__sales_note__customer__seller=${this.filter.seller}`
      await fetchDocuments(query)
      .then(res=>{
        this.totalDtes=res.count
        this.dtes=res.results
      })
      .catch((err)=>{
        notifications.showError('Error cargando documentos '+err)
      })
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
  async mounted(){
    this.loading=true
    this.fetch()
    this.sellers=await fetchSellers()
    this.loading=false
  },
  computed:{
    computedHeaders(){
      return this.headers
    }
  }
}
</script>

<style lang="scss">

</style>