<template>
<div>
      <v-autocomplete
        v-model="value"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="primary"
        hide-selected
        item-text="name"
        item-value="id"
        label="Cliente"
        placeholder="Buscar un Cliente"
        return-object
        outlined
        clearable
        class="purple--text"
        @input="updateValue"
        @change="cambio"
      >
      <template v-slot:item="data">
        <div class="d-flex">
          <div class="text mr-2">{{data.item.name}} - {{data.item.fantasy}}</div>
        </div>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Buscar 
            <strong>Cliente</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      </v-autocomplete>
    </div>  
</template>

<script>
import {fetchCustomers} from '@/api/customers'
import notifications from '@/utils/notifications'

  export default {
    name:'CustomerComplete',
    data: () => ({
      descriptionLimit: 60,
      customers: [],
      isLoading: false,
      model: null,
      search:null,
    }),
    props:['value','status'],
    computed: {
      items () {
        return this.customers.map(i => {
          const searchName = i.searchName.length > this.descriptionLimit
            ? i.searchName.slice(0, this.descriptionLimit) + '...'
            : i.searchName

          return Object.assign({}, i, { searchName })
        })
      },
    },
    created(){
      this.fillItems()
    },
    methods:{
      updateValue(){
        this.value ? this.$emit('input',this.value) : this.$emit('input','')
      },
      cleared(){
        this.$emit('input','')
      },
      cambio(){
        console.log('cambio en el value')
      },
      async fillItems(){
        // Lazy Load
        this.isLoading = true
        await fetchCustomers(this.status)
        .then((customers)=>{
          customers.forEach(customer=>{
            customer.searchName=customer.name + ' - ' + customer.fantasy
          })
          this.customers=customers
        })
        .catch(err=>{
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
      },
    },
    watch: {
      async search () {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        //this.fillItems()
      },
    },
  }
</script>