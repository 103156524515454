const moment = require('moment')

export function salesByDate(salesNotes, format) {

    const results_by_Date = salesNotes.reduce(function(r, a) {
        r[moment(a.created_at).format(format)] = r[moment(a.created_at).format(format)] || [];
        r[moment(a.created_at).format(format)].push(a);
        return r;
    }, Object.create(null));

    var dataset = []

    Object.keys(results_by_Date).forEach(date => {
        var totals = {
            date: date,
            total: 0,
        }
        results_by_Date[date].map(salesNote => {
            totals.total += salesNote.totals.total
        })
        dataset.push(totals)
    })

    return dataset
}

export function confirmedByDate(workOrders, format) {

    const results_by_Date = workOrders.reduce(function(r, a) {
        r[moment(a.created_at).format(format)] = r[moment(a.created_at).format(format)] || [];
        r[moment(a.created_at).format(format)].push(a);
        return r;
    }, Object.create(null));

    var dataset = []

    Object.keys(results_by_Date).forEach(date => {
        var totals = {
            date: date,
            total: 0,
        }
        results_by_Date[date].map(workOrder => {
            totals.total += parseFloat(workOrder.sales_note_total)
        })
        dataset.push(totals)
    })

    return dataset

}


export function salesByCustomer(workOrder) {
    //Función para determinar los clientes que mas compran 
    //Agrupo las notas de venta confirmadas por cliente
    let unique = [...new Set(workOrder.map(item =>
        item.customer_name
    ))];

    const results = unique.map(customer => {
        const customerWorkOrder = workOrder.filter(s => s.customer_name == customer)

        var total_confirmed = {
            customer: customerWorkOrder[0].customer_name,
            total: 0
        }
        customerWorkOrder.map(s => {
            total_confirmed.total += parseFloat(s.sales_note_total)
        })
        return total_confirmed
    })

    //Ordenando de mayor a menor
    const sortedResults = results.sort((a, b) => { return b.total - a.total })

    //Tomando los primeros 10
    if (sortedResults.length > 10) {
        return sortedResults.slice(0, 10)
    } else return sortedResults

}

export function salesBySeller(workOrder) {
    //Función para determinar los clientes que mas compran 
    //Agrupo las notas de venta confirmadas por cliente
    let unique = [...new Set(workOrder.map(item =>
        item.seller_full_name
    ))];

    const results = unique.map(seller_full_name => {
        const customerWorkOrder = workOrder.filter(s => s.seller_full_name == seller_full_name)

        var total_confirmed = {
            seller: customerWorkOrder[0].seller_full_name,
            total: 0
        }
        customerWorkOrder.map(s => {
            total_confirmed.total += parseFloat(s.sales_note_total)
        })
        return total_confirmed
    })

    //Ordenando de mayor a menor
    const sortedResults = results.sort((a, b) => { return b.total - a.total })

    //Tomando los primeros 10
    if (sortedResults.length > 10) {
        return sortedResults.slice(0, 10)
    } else return sortedResults
}