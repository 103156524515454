<template>
<v-container>
  <v-data-table
    :headers="headers"
    :items="units"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
    :sort-by="['id']"
    :loading="loading"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus'
    }"
  >
      <template v-slot:top>
      <v-toolbar
        flat
        class="pa-4"
      >
        <v-text-field
        v-model="search"
        label="Buscar"
        class="mx-4"
      ></v-text-field>
       <v-btn
              color="success"
              dark
              class="mb-2"
              @click="create"
            >
              <v-icon>add</v-icon> Nuevo
        </v-btn>
        <v-dialog
          v-model="showDialog"
          max-width="500px"
        >
          <ValidationObserver v-slot="{handleSubmit}">
            <v-form @submit.prevent="newUnit.id>0? handleSubmit(updateUnit): handleSubmit(createUnit)">
              <v-card>
                <v-card-title>
                  <span class="headline">Agregar Nueva Unidad</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="newUnit.name"
                            label="Nombre de Grupo"
                            :error-messages="errors"
                            autofocus
                          ></v-text-field>
                        </ValidationProvider>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <v-spacer></v-spacer>
                  <v-btn type="submit" class="success--text">
                    <v-icon>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </ValidationObserver>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn x-small fab class="mr-2" @click.stop="edit(item)"><v-icon>create</v-icon></v-btn>
      <v-btn x-small fab class="error--text" @click.stop="deleteItem(item)"><v-icon>delete</v-icon></v-btn>
    </template>
  </v-data-table>
</v-container>
</template>
<script>
import {fetchUnits,createUnit, updateUnit} from '@/api/products'
import notifications from '@/utils/notifications'

export default {
  name:'Unit',
  data:()=>({
    units:[],
    search:'',
    showDialog:false,
    loading:false,
    newUnit:{
      id:-1,
      name:'',
    },
    headers: [
          {
            text: 'Item',
            align: 'start',
            value: 'id',
          },
          {text: 'Nombre',value:'name'},
          { text: 'Acciones',value:'actions',sortable: false}
        ],
  }),
  methods:{
    edit(item){
      this.newUnit=item
      this.showDialog=true
    },
    create(){
      this.newUnit={
        id:-1,
        name:'',
      }
      this.showDialog=true
    },
    async createUnit(){
      await createUnit(this.newUnit)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.units=await fetchUnits()
        notifications.showSuccess('Unidad '+ this.newUnit.name + ' Creada')
        })
      .catch(()=>{
        notifications.showError('Error Creando Nuevo Unidad ')
        this.loading=false
        })
    },
    async updateUnit(){
      await updateUnit(this.newUnit)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.units=await fetchUnits()
        notifications.showSuccess('Unidad '+ this.newUnit.name + ' Actualizada')
        })
      .catch(async (e)=>{
        this.loading=false
        notifications.showError('Error Actualizando Unidad' + e)
        this.units=await fetchUnits()
        })
    }
  },
  async created(){
    this.loading=true
    await fetchUnits()
    .then((res)=>{
      this.loading=false
      this.units=res
    })
  }
}
</script>
