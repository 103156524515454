<template>
  <v-container>
    <v-row class="mb-10" justify="space-between" align-content="center">
      <h1 class="text text-h4 titulo"><v-icon class="mr-2" x-large>fas fa-cog</v-icon>Mantenedores</h1>
      <v-btn  @click.prevent="$router.push('/sales')" text ><v-icon>fas fa-chevron-left</v-icon></v-btn>
    </v-row>
    <v-tabs 
      background-color="transparent"
      color="primary"
      center-active
      v-model="tab"
      
    >
      <v-tab v-model="tab" class="primary--text">Origen de Cotización</v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item class="pa-4">
          <origins/>
        </v-tab-item>
    </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import Origins from './Origins.vue';
export default {
  name:'SalesNoteConfiguration',
  components:{Origins},
  data() {
    return {
      tab: null,
    }
  },
}
</script>

<style>

</style>