var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-card-title',{staticClass:"titulo text-h4 mb-4"},[_c('v-icon',{staticClass:"mr-2 align-items-center"},[_vm._v("dns")]),_vm._v(_vm._s(_vm.item.id>-1?'Actualizar Etapa':'Crear Etapa')+" ")],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","prepend-icon":"mdi-file-document","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Orden","type":"number","prepend-icon":"fas fa-sort","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.item.sort),callback:function ($$v) {_vm.$set(_vm.item, "sort", $$v)},expression:"item.sort"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(_vm.item.color),callback:function ($$v) {_vm.$set(_vm.item, "color", $$v)},expression:"item.color"}})],1)]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"type":"submit","color":"success","loading":_vm.loading}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }