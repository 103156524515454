<template>
<div>
  <v-btn :disabled="disabled" color="primary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import notifications from '@/utils/notifications'
import {fetchTransactions} from '@/api/stocks'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
import { VListItemContent } from 'vuetify/lib'
const totalOrder=(item)=> item.details.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
    
export default {
  name:"ExportTransactions",
  props:['filter','options','disabled','search','estatuses'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      items_detail:[],
      dataReady:false,
      labels:{ 
        id:{title:'Numero'},
        created_at:{title:'Creada'},
        created_by_full_name:{title:'Creada por'},
        article_sku: { title: 'Codigo' },
        article_name:{title:'Articulo'},
        notes:{title:'Referencia'},
        store_name:{title:'Bodega'},
        tipo_name:{title:'Movimiento'},
        qty:{title:'Cantidad'},
        price:{title:'Valor'},
        total:{title:'Total'},
        article_notes:{title:'Art Observaciones'},
       },
       csvTitle:`export_transacciones_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`,
    }
  },
  methods:{
    async fetch(){
        this.loading=true
        let sort=''
        if(!this.filter.created_after) this.filter.created_after=''
        if(!this.filter.created_before) this.filter.created_before=''
        if (!this.filter.store) this.filter.store=''
        const {sortBy, sortDesc}=this.options
        sort=sortBy[0]
        const query=`ordering=${sortDesc[0]?sort:'-' + sort}store=${this.filter.store}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&search=${this.search}`
        await fetchTransactions(query)
        .then(res=>{
            this.totalItems=res.count
            this.items=res.results
            this.items=this.items.map(item=>{
              item.article_name=item.article_name.replaceAll("\"", "")
              item.notes=item.notes.replaceAll("\"", "")
              item.created_at=moment(item.created_at).format('DD-MM-YYYY')
              item.total=item.price*item.qty
              return item
            })
            this.dataReady=true
            this.loading=false
        })
        .catch(err=>{
            this.loading=false
            notifications.showError(err.message)
        })
      },
      handleSuccess(val){
        console.log(val)
        notifications.showSuccess('Inventario Exportado')
        this.dataReady=false
      },
      handleError(val){
        console.log(val)
        notifications.showError('Error Exportando Inventario')
        this.dataReady=false
      }
    },
  }
</script>