<template>
<div>
  <vue-json-to-csv
    :json-data="formatedData"
    :labels="labels"
    :csv-title="csvTitle"
    :separator="';'"
    @success="val => handleSuccess(val)"
    @error="val => handleError(val)">
    <v-btn color="success" text>
      <v-icon class="mr-2">fas fa-file-export</v-icon> Exportar
    </v-btn>
  </vue-json-to-csv> 
</div>
</template>
<script>
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportSupplyPending",
  props:['data','filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      labels:{ 
        name: { title: 'Nombre' },
        group:{title:'Grupo'},
        unit:{title:'Unidades'},
        qty:{title:'Cantidad'},
        consumed:{title:'Consumido'},
        pending:{title:'Pendiente'},
       },
       csvTitle:`export_pending_supplies_${moment(new Date()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Datos Exportados')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Datos')
      this.dataReady=false
    }
  },
  computed:{
    formatedData(){
      const formated = this.data.map(d=> {return {
        sku: d.sku,
        name:d.name,
        group: d.group,
        unit: d.unit,
        qty: numeral(d.qty).format('0[.]00'),
        consumed: numeral(d.consumed).format('0[.]00'),
        pending: numeral(d.pending).format('0[.]00')
      }})
      return formated
    }
  }

}
</script>