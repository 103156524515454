<template>
<v-container>
  <v-row class="mb-10 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo">Grupos</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn class="ml-4" color="success" @click="create">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
        </v-btn>
      </v-col>
    </v-row>
  <v-data-table
    :headers="headers"
    :items="groups"
    :items-per-page="10"
    :search="search"
    :sort-by="['name']"
    :loading="loading"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right'
    }"
  >
      <template v-slot:top>
        <v-row justify="end">
          <v-col cols="6">
            <v-text-field
            v-model="search"
            prepend-inner-icon="fas fa-search"
            label="Buscar"
            class="mx-4 text--end"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-dialog
          v-model="showDialog"
          max-width="500px"
        >
          <ValidationObserver v-slot="{handleSubmit}">
            <v-form @submit.prevent="newGroup.id>0? handleSubmit(updateGroup): handleSubmit(createGroup)">
              <v-card>
                <v-card-title>
                  <span class="headline">Agregar Nuevo Grupo</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="newGroup.name"
                            label="Nombre de Grupo"
                            :error-messages="errors"
                            autofocus
                          ></v-text-field>
                        </ValidationProvider>
                        <v-checkbox
                            v-model="newGroup.is_supply"
                            label="Grupo de Materiales"
                        ></v-checkbox>
                    </v-container>
                      
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <v-spacer></v-spacer>
                  <v-btn type="submit" class="success--text">
                    <v-icon>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </ValidationObserver>
        </v-dialog>
      
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click.stop="edit(item)">fas fa-edit</v-icon>
      <v-icon class="error--text" @click.stop="deleteItem(item)" small>fas fa-trash</v-icon>
    </template>
    <template v-slot:item.is_supply="{item}">
      <v-icon>
        {{ item.is_supply ? 'fas fa-calculator':'fas fa-cogs' }}
      </v-icon>
    </template>
  </v-data-table>
</v-container>
</template>
<script>
import {fetchGroups,createGroup, updateGroup} from '@/api/products'
import notifications from '@/utils/notifications'

export default {
  name:'Group',
  data:()=>({
    groups:[],
    search:'',
    showDialog:false,
    loading:false,
    newGroup:{
      id:-1,
      name:'',
      is_supply:false
    },
    headers: [
          {
            text: 'Descripción',
            align: 'start',
            value: 'name',
            class:'table-header'
          },
          {text: 'Tipo',value:'is_supply',class:'table-header'},
          { text: 'Acciones',value:'actions',sortable: false,class:'table-header'}
        ],
  }),
  methods:{
    edit(item){
      this.newGroup=item
      this.showDialog=true
    },
    create(){
      this.newGroup={
        id:-1,
        name:'',
        is_supply:false
      }
      this.showDialog=true
    },
    async createGroup(){
      await createGroup(this.newGroup)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.groups=await fetchGroups()
        notifications.showSuccess('Grupo '+ this.newGroup.name + ' Creado')
        this.$store.dispatch('getGroups')
        })
      .catch(()=>{
        notifications.showError('Error Creando Nuevo Grupo ')
        this.loading=false
        })
    },
    async updateGroup(){
      await updateGroup(this.newGroup)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.groups=await fetchGroups()
        notifications.showSuccess('Grupo '+ this.newGroup.name + ' Actualizado')
        this.$store.dispatch('getGroups')
        })
      .catch(async (e)=>{
        this.loading=false
        notifications.showError('Error Actualizando Grupo' + e)
        this.groups=await fetchGroups()
        })
    }
  },
  async created(){
    this.groups=this.$store.getters.getGroups
  }
}
</script>
