var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.units,"items-per-page":10,"search":_vm.search,"sort-by":['id'],"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus'
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success","dark":""},on:{"click":_vm.create}},[_c('v-icon',[_vm._v("add")]),_vm._v(" Nuevo ")],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.newUnit.id>0? handleSubmit(_vm.updateUnit): handleSubmit(_vm.createUnit)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Agregar Nueva Unidad")])]),_c('v-card-text',[_c('v-container',[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de Grupo","error-messages":errors,"autofocus":""},model:{value:(_vm.newUnit.name),callback:function ($$v) {_vm.$set(_vm.newUnit, "name", $$v)},expression:"newUnit.name"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-spacer'),_c('v-btn',{staticClass:"success--text",attrs:{"type":"submit"}},[_c('v-icon',[_vm._v("save")]),_vm._v("Guardar ")],1)],1)],1)],1)]}}])})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_c('v-icon',[_vm._v("create")])],1),_c('v-btn',{staticClass:"error--text",attrs:{"x-small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }