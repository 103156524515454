<template>
  <v-text-field
    :value="toCms"
    :label="toCms"
    :prepend-icon="icon"
    type="number"
    persistent-hint
    :hint="`${inMts} metros`"
    @input="updateValue"
  >

  </v-text-field>
</template>

<script>
export default {
  name:'UnitInput',
  props:['icon','value'],
  data() {
    return {
      inputCms: ''
    }
  },
  methods:{
    updateValue(){
      const mts=this.value*0.01
      console.log(mts)
      this.$emit('input',mts)
      //this.value ? this.$emit('input',this.inMts) : this.$emit('input','')
    },
  },
  computed:{
    toCms(){
      return this.value ? parseFloat(this.value)*0.01:0
    }
  }
}

</script>

<style>

</style>