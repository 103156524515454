<template>
<div>
  <v-btn :disabled="disabled" text icon color="primary"  title="Exportar" :loading="loading" @click="fetch"><v-icon>fas fa-file-export</v-icon></v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
import {fetchDistribution} from '@/api/salesNotes'

export default {
  name:"ExportDistributionList",
  props:['options','disabled','filter','search'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      dataReady:false,
      items:[],
      options:{},
      labels:{ 
        created_at:{title:'Fecha'},
        salesnote:{title:'Cotizacion'},
        workorder:{title:'OT'},
        name:{title:'Proyecto'},
        customer_rut:{title:'Rut'},
        customer_name:{title:'Cliente'},
        ordered: { title: 'Pedido' },
        delivered:{title:'Despachado'},
        returned:{title:'Retornado'},
        toinvoice:{title:'Facturar'},
        price:{title:'Precio'},
        total:{title:'Total'},
        created_by_name:{title:'Usuario'},
       },
       csvTitle:`export_distribution_list_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      
      const {sortBy, sortDesc,page, itemsPerPage }=this.options

      //Definición del sort
      let sort='created_at'
      switch(sortBy[0]){
        case 'created_at':
            sort='created_at'
            break
        case 'workorder':
            sort='salesnote__work_order'
            break
        case 'salesnote':
            sort='salesnote'
            break
        case 'customer':
            sort='customer_name'
            break
        case 'ordered':
            sort='ordered'
            break
        case 'delivered':
            sort='delivered'
            break
        case 'returned':
            sort='returned'
            break
        case 'price':
            sort='price'
            break
        case 'created_by_name':
            sort='created_by__first_name'
            break
        default:
            sort='salesnote__work_order'
            break
      }
      
      const query=`ordering=${sortDesc[0]?sort:'-'+sort}&page=${1}&search=${this.search}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}`
      await fetchDistribution(query)
      .then((data)=>{
        this.totalItems=data.count
        this.items = data.results.map(item=>{
            item.created_at=moment(item.created_at).format('DD-MM-YYYY HH:mm')
            item.toinvoice=item.delivered-item.returned
            item.total=item.toinvoice*item.price
            return item
        })
        this.dataReady=true
      })
      .catch((err)=>notifications.showError(err))
      .finally(()=>this.loading=false)
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }
</script>