<template>
    <div>
        <!-- 1. Create the button that will be clicked to select a file -->
        <v-btn 
            class="primary--text" 
            :loading="isSelecting" 
            @click="handleFileImport"
        >
            <v-icon class="mr-2">fas fa-plus</v-icon>  Agregar Documentos
        </v-btn>
        <!-- Create a File Input that will be hidden but triggered with JavaScript -->
        <input 
            ref="uploader" 
            class="d-none" 
            type="file" 
            multiple
            @change="onFileChanged"
        >
    </div>
</template>

<script>
    export default {
        name: 'FileInput',
        data(){
            return {
                isSelecting: false,
                selectedFile: null
            }
        },
        methods: {
            handleFileImport() {
                this.isSelecting = true;

                // After obtaining the focus when closing the FilePicker, return the button state to normal
                window.addEventListener('focus', () => {
                    this.isSelecting = false
                }, { once: true });
                
                // Trigger click on the FileInput
                this.$refs.uploader.click();
            },
            onFileChanged(e) {
                this.selectedFile = e.target.files;
                this.$emit('change', this.selectedFile);
                this.selectedFile=null
                // Do whatever you need with the file, liek reading it with FileReader
            },
        }
    }
</script>