<template>
<v-container>
  <div class="text text-h2">Costos</div>
    <v-tabs 
      background-color="transparent"
      color="basil"
      grow
      v-model="tab"
    >
      <v-tab v-model="tab"><v-icon class="mr-1">mdi-cogs</v-icon>Procesos</v-tab>
      <v-tab v-model="tab"><v-icon class="mr-1">shopping_basket</v-icon>Suministros</v-tab>
      <v-tabs-items v-model="tab">
      <v-tab-item>
          <cost :is_supply='false'/>
      </v-tab-item >
      <v-tab-item>
          <cost :is_supply='true'/>
      </v-tab-item>
    </v-tabs-items>

    </v-tabs>
</v-container>
</template>
<script>
import Group from '@/components/products/costs/Group'
import Unit from '@/components/products/costs/Unit'
import Cost from '@/components/products/costs/Cost'

export default {
  name:'Costs',
  components:{Cost},
  data:()=>({
    tab: null,
  })
}
</script>
