<template>
  <v-container>
  <p v-if="product.notes" class="font-italic">Observaciones:{{product.notes}}</p>
    <v-row>
      <v-col>
        <div class="text text-h6"> <v-icon small>fas fa-cogs</v-icon> Procesos</div>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Item
                </th>
                <th class="text-left">
                  Proceso
                </th>
                <th class="text-left">
                  Area Prod.
                </th>
                <th class="text-left">
                  Avance
                </th>
                <th class="text-center">
                  Estatus
                </th>
                <th class="text-center" v-if="show_estimated_work_order">
                  Estimado
                </th>
                <th class="text-center">
                  -
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(process,index) in product.process_orders"
                :key="index"
                :class="process.completed? 'success--text':''"
              >
                <td>{{index +1}}<v-icon class="error--text ml-2" x-small v-if="process.reprocess">fas fa-clock-rotate-left</v-icon></td>
                <td>
                    {{process.name}}
                    <v-chip v-if="process.merma>0" class="ml-2" x-small dark color=" text-center orange darken-1">+ {{process.merma }}</v-chip>
                </td>
                <td>
                  <div>
                    {{process.machine.name}} 
                  </div>
                  <v-chip x-small v-if="process.equipment_asigned">
                    {{process.equipment_asigned }}
                  </v-chip>
                </td>
                <td  class="bitacora-show" v-if='process.machine.production_unit_m2'>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on">
                        <strong>{{process.completed_mts2_qty.mts2 | number('0[.]00')}}</strong> / {{product.mts2*process.qtypp| number('0[.]00')}}
                        <v-progress-linear
                            :color="process.completed? 'success':'info'"
                            height="5"
                            :value="process.completed?100: process.completed_mts2_qty.mts2/(product.mts2*process.qtypp)*100" 
                          />
                      </div>
                    </template>
                    <bitacora-details :process="process"/>
                  </v-tooltip>
                </td>
                <td class="bitacora-show" v-if='!process.machine.production_unit_m2'>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on">
                        <strong >{{process.completed_mts2_qty.qty| number('0[.]00')}}</strong> / {{product.qty*process.qtypp+process.merma| number('0[.]00')}}
                        <v-progress-linear
                          :color="process.completed? 'success':'info'"
                          height="5"
                          :value="process.completed?100: process.completed_mts2_qty.qty/(product.qty*process.qtypp+process.merma)*100"
                        />
                      </div>
                    </template>
                    <bitacora-details :process="process"/>
                  </v-tooltip>
                </td>
              <td class="text-center">
                <div v-if="process.completed">
                  <v-chip
                    class="ma-2"
                    label
                    dark
                    color="primary"
                    style="max-width:100px"
                    x-small
                    >
                    <strong> Finalizado</strong>
                  </v-chip>
                </div>
                <div v-else-if="product.in_prestage">
                  <v-icon small>fas fa-hourglass-half</v-icon>
                </div>
                <div v-else class="text-center">
                  <v-chip
                    class="ma-2"
                    label
                    dark
                    color="main"
                    style="max-width:100px"
                    x-small
                    >
                    <strong> Produccion</strong>
                  </v-chip>
                 </div>
              </td>
              <td class="text-center" v-if="show_estimated_work_order">
                <div v-if="!process.completed">
                  {{process.estimated | moment("DD/MM/YYYY")}}
                </div>
                <div v-else class="text-center">
                  <v-icon color="success">fas fa-check-circle</v-icon>
                </div>
              </td>
              <td class="text-center">
                <div class="d-flex justify-space-around">
                  <v-btn v-if="!process.completed" class="mr-2" x-small color="info" @click="addProcessRegistry(process)" :disabled="product.in_prestage" title="Agregar registro">
                    <v-icon x-small>fas fa-plus</v-icon><v-icon x-small>fas fa-r</v-icon>
                  </v-btn>
                  <v-btn x-small v-else @click="requestReprocess(process)" :disabled="product.in_prestage" title="Reproceso">
                    <v-icon class="error--text" small>fas fa-clock-rotate-left</v-icon>
                  </v-btn>
                </div>
                
              </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
   
    <!-- Tabla de Materiales -->
    <v-row class="mt-4">
      <v-col>
        <div class="text text-h6"><v-icon small>fas fa-calculator</v-icon> Materiales cotizados</div>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Item
                </th>
                <th class="text-left">
                  Material
                </th>
                <th class="text-left">
                  <sub>p</sub>P
                </th>
                <th class="text-left">
                  Unidad
                </th>
                <th class="text-right">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(supply,index) in product.supplies"
                :key="index"
              >
                <td>{{index+1}}</td>
                <td>{{supply.name}} 
                  <div>
                    <v-icon v-show="supply.has_dimension" class="info--text" x-small>fas fa-ruler-combined</v-icon>
                    <span v-show="supply.has_dimension" class="caption ml-2" >[Al:{{supply.height}} x An:{{supply.width}}]</span>
                  </div>
                </td>
                <td>
                  <div v-if="supply.qty==0">
                    <v-icon small>fas fa-times</v-icon>
                  </div>
                  <div v-else>
                    {{supply.qty | number('0,0.00')}}
                  </div>
                </td>
                <td>{{supply.unit_name}}</td>
                <td class="text-right">
                  <div v-if="supply.qty==0">
                    <v-icon small>fas fa-times</v-icon>
                  </div>
                  <div v-else>
                    <div v-if="supply.unit==1">
                      {{supply.qty*product.mts2 | number('0,0[.]00')}}
                    </div>
                    <div v-else-if="supply.unit==2">
                      {{Math.ceil((supply.qty*product.qty).toFixed(3))}}
                    </div>
                    <div v-else>
                      {{supply.qty | number('0,0[.]00')}}
                    </div>
                  </div>
                </td>
              </tr>
              <!-- <tr class="mt-2">
                <td colspan="7" class="text-center">
                  <v-btn small fab @click="addingSupply=true" color="success" :disabled="product.in_prestage"><v-icon small>fas fa-plus</v-icon></v-btn>
                </td>
              </tr> -->
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      </v-row>
      <v-row>
        <v-col>
          <product-consumption-detail :productId="this.product.id" :work_order_id="work_order_id" />
        </v-col>
      </v-row>
      <!-- Agregar nuevo Material  -->
    <v-dialog v-model="addingSupply" max-width="800">
    <v-card class='pa-8'>
      <v-card-title>
        <v-icon class="mr-2">fas fa-calculator</v-icon>
        <div class="text text-h4 titulo">Agregar Material</div>
      </v-card-title> 
      <add-cost @add="addSupply" :is_supply="true"/>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_process_completed"
      :max-width="machineToComplete.hour_control?1200:800"
      transition="dialog-bottom-transition"
    >
      <v-card>
      <process-complete 
        :processes="[processToComplete]" 
        :machine="machineToComplete"
        @refresh="$emit('updated')"
        @close="dialog_process_completed=false"
      />
    </v-card>
    </v-dialog>
    <confirm
    v-if="processToReprocess"
    v-model="showConfirmReprocess" 
    :message="`¿Confirma registrar el reproceso de ${processToReprocess.name}? Esta operación no se puede deshacer.`"
    @ok="registerReprocess(processToReprocess)"
    @cancel="()=>{showConfirmReprocess=false;processToReprocess={}}"/>
  </v-container>
</template>

<script>
import AddCost from '@/components/products/costs/AddCost'
import SupplyConsume from '@/components/production/SupplyConsume.vue'
import {createSupplyOrder,deleteSupplyOrder,reprocess} from '@/api/production'
import notifications from '@/utils/notifications'
import BitacoraDetails from '@/components/production/BitacoraDetails'
import ProcessComplete from '@/components/production/ProcessComplete'
import ProductConsumptionDetail from './ProductConsumptionDetail.vue'
export default {
  components:{ SupplyConsume, AddCost, BitacoraDetails, ProcessComplete, ProductConsumptionDetail },
  props: {
    product: {
      type: Object,
    },
    work_order_id:{
      
    }
  },
  data() {
    return {
      addingSupplyConsumption:false,
      supplyToConsume:'',
      addingSupply:false,
      addingSupplyQty:false,
      dialog:[],
      dialog_process_completed:false,
      processToComplete:{},
      processToReprocess:{},
      machineToComplete:{},
      newSupply:{
        qty:0
      },
      showConfirmReprocess:false,
      loading:false,
    }
  },
  computed:{
    show_estimated_work_order(){
      return this.$store.getters.getCompany.show_estimated_work_order
    },
  },
  methods:{
    async addSupply(item){
      this.addingSupply=false
      this.newSupply=item
      this.loading=true
      var payload = {
        product: this.product.id,
        group:this.newSupply.group,
        name:this.newSupply.name,
        sku: this.newSupply.sku ? this.newSupply.sku:'',
        unit: this.newSupply.unit,
        qty:0,
        price:this.newSupply.price,
        min_price:this.newSupply.min_price,
        additional:true,
      }
      if(item.has_article){
        payload = {...payload,article_id:item.article.id}
      }
      await createSupplyOrder(payload)
      .then((data)=>{
        notifications.showSuccess('Material agregado!')
        this.addingSupplyQty=false
        this.loading=false
        this.$emit('updated')
      })
      .catch((e)=>{
        notifications.showError('Error Realizando el Registro '+e)
        this.loading=false
      })
    },
    async deleteSupply(item){
      this.loading=true
      await deleteSupplyOrder(item.id)
      .then((data)=>{
        notifications.showSuccess('Material eliminado!')
        this.loading=false
        this.$emit('updated')
      })
      .catch((e)=>{
        notifications.showError('Error eliminado el material '+e)
        this.loading=false
      })
    },
    addProcessRegistry(item){
      const process=item
      this.$set(process,'work_order',this.work_order_id)
      this.$set(process,'product',this.product)
      this.machineToComplete=item.machine
      this.processToComplete=process
      this.$set(this.machineToComplete,'equipments',process.machine_equipments)
      this.dialog_process_completed=true
    },
    requestReprocess(item){
      this.processToReprocess=item
      this.showConfirmReprocess=true
    },
    async registerReprocess(item){
      this.loading=true
      this.showConfirmReprocess=false
      await reprocess(item.id)
      .then((data)=>{
        notifications.showSuccess('Reproceso registrado!')
        this.loading=false
        this.$emit('updated')
      })
      .catch((e)=>{
        notifications.showError('Error registrando el reproceso '+e)
        this.loading=false
      })
      this.processToReprocess={}
    }
  }

}
</script>

<style>

</style>