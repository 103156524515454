var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Artículos")])]),_c('v-col',{staticClass:"text-end"},[_c('Can',{attrs:{"I":"create","a":"StockArticle"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$router.push({name:'ImportStock'})}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-file-import")]),_vm._v("Importar ")],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.update(-1)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1)],1)],1)],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.families,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Todas las Familias","single-line":"","outlined":"","dense":"","clearable":"","prepend-inner-icon":"filter_alt"},model:{value:(_vm.family),callback:function ($$v) {_vm.family=$$v},expression:"family"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.articles,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"sort-desc":true,"server-items-length":_vm.total,"search":_vm.search,"dense":"","sort-by":"sku","item-class":"table-body","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.cost)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 info--text",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-link")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cost.name))])]):_vm._e()]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('Can',{attrs:{"I":"update","a":"StockArticle"}},[_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('Can',{attrs:{"I":"update","a":"StockArticle"}},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.update(item.id)}}},[_vm._v("fas fa-edit")])],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"px-10 py-4"},[_c('article-create-update',{attrs:{"families":_vm.families,"units":_vm.units,"id":_vm.article},on:{"updated":_vm.fetch,"close":function($event){_vm.showDialog=false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }