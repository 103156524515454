<template>
  <v-container>
    <div>
      <v-card-title
        class="titulo text-h4 mb-4"
      >
       <v-icon class="mr-2 align-items-center">fas fa-box</v-icon>{{family.id>-1?'Actualizar Familia':'Crear Familia'}}
      </v-card-title>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Nombre'
            prepend-icon="mdi-file-document"
            v-model='family.name'
            outlined
            :error-messages="errors"
            :loading="loading"
            ></v-text-field>
          </ValidationProvider>
            <div class="text-center">
              <v-btn type="submit" color="success" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
            </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-container>
</template>
<script>
import {getFamily,createFamily,updateFamily} from '@/api/stocks'
import notifications from '@/utils/notifications'
export default {
  name:'FamilyCreateUpdate',
  props:['id'],
  data() {
    return {
      family:'',
      loading:false,
    }
  },
  methods:{
    async save(){
      this.loading=true
      if(this.id>-1){
        await updateFamily(this.id,{
          name:this.family.name,
        })
        .then((res)=>{
          notifications.showSuccess('Familia guardada correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Familia '+err)
        })
        this.loading=false
      }
      else{
        await createFamily({
          name:this.family.name,
        })
        .then((res)=>{
          notifications.showSuccess('Familia creada correctamente')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Familia '+err)
        })
        this.loading=false
      }
    },
    async get(){
      if(this.id>-1){
        this.loading=true
        await getFamily(this.id)
        .then(res=>{
          this.family=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando familia '+err)
        })
        this.loading=false
      }
      else{
        this.family={
          id:this.id,
          name:'',
        }
      }
    }
  },
  watch:{
    id:{
      handler:'get'
    }
  },
  created(){
    this.get()
  }

}
</script>

<style>

</style>